import React, { useEffect, useCallback, useMemo } from "react";
import { Dialog, Box, Text } from "@mantine/core";
import { useSelector } from "react-redux";

import voca from "voca";
import { FormattedMessage, useIntl } from "react-intl";
import removeAccents from "remove-accents";
import CandidateImgs from "../../../mapbox/candidates";
import CandidateImgs2024 from "../../../mapbox/candidatesGov2024";
import { Names } from "../../../mapbox/titles";
import { PartyColors } from "../../../mapbox/colors";
import { StatesAccents } from "../../../mapbox/states";
import DoubleCheck from "../../../assets/img/doublecheck.png";

const RaceboardMayor = ({ open, onClose, app }) => {
  const year = useSelector((state) => state.year.value);
  const intl = useIntl();

  const { partyBorderLeft, partyBorderBottom, raceNameLong } = useMemo(
    () => ({
      partyBorderLeft: 0,
      partyBorderBottom: window.innerWidth > 3000 ? 20 : 10,
      raceNameLong: window.innerWidth > 3000 ? "3.5rem !important" : "1.6rem"
    }),
    []
  );

  const sanitizeName = useCallback(
    (name) =>
      removeAccents(
        name
          ?.trim()
          ?.replaceAll(" ", "_")
          .replaceAll("“", "")
          .replaceAll("”", "") || ""
      ),
    []
  );

  const runAfterRender = useCallback(() => {
    const raceboardG = document.getElementById("raceboardG");
    if (raceboardG) {
      raceboardG.style.visibility = app.showNationalResult
        ? "hidden"
        : "visible";
    }
  }, [app.showNationalResult]);

  useEffect(() => {
    runAfterRender();
  }, [app.raceboard.state, runAfterRender]);

  const renderCandidateBox = useCallback(
    (
      candidateName,
      candidateParty,
      coalitionParties,
      candidateVotes,
      candidatePct,
      reelected,
      year
    ) => {
      if (!candidateName || candidateName === "0") return null;
      const imageName = sanitizeName(candidateName);
      const partyColor = PartyColors[candidateParty]?.high;
      const partyTextColor =
        PartyColors[candidateParty]?.boardText ||
        PartyColors[candidateParty]?.high;
      const coalitionImages = (coalitionParties || "").split("-").map((img) => {
        if (img === "PES" && year === "2015") img = "PES2015";
        return CandidateImgs[img] && img !== "Independiente"
          ? CandidateImgs[img]
          : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAQAAADYv8WvAAAADklEQVR42mNkAAJGEAEAABkAA50Y5HIAAAAASUVORK5CYII=";
      });

      return (
        <Box
          className="partyBox"
          sx={{
            display: "flex",
            flex: 1,
            marginBottom: "0.5rem",
            marginTop: "2px",
            backgroundColor: "rgba(255,255,255,0.8)",
            justifyContent: "center",
            padding: "1.2rem",
            paddingBottom: "0.2rem"
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "94%" }}>
            <Box
              className="partySubBox"
              sx={{
                display: "flex",
                borderBottom: `solid ${partyBorderBottom}px ${partyColor}`,
                paddingBottom: "0rem",
                marginBottom: "0.5rem"
              }}
            >
              {CandidateImgs2024[imageName] && (
                <Box
                  sx={{
                    flexDirection: "column",
                    justifyContent: "top",
                    width: "20%",
                    display: "flex"
                  }}
                >
                  <img
                    src={CandidateImgs2024[imageName]}
                    style={{ width: window.innerWidth > 3000 ? "96%" : "97%" }}
                    alt=""
                  />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "66%",
                  marginTop: "0px"
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <div
                    className="racePartyName"
                    style={{
                      display: "flex",
                      width: "auto",
                      marginLeft: "0px",
                      overflowWrap: "break-word",
                      fontSize: "23px",
                      lineHeight: "23px",
                      color: partyTextColor
                    }}
                  >
                    <div style={{marginRight: 10}}>{candidateParty}</div>
                    {coalitionImages.map((src, index) => (
                      <img
                        key={index}
                        className="raceboardImgGov2024"
                        src={src}
                        style={{
                          width: "2rem",
                          height: "2rem",
                          marginRight: "0.5rem"
                        }}
                        alt=""
                      />
                    ))}
                  </div>
                </Box>
                <Box
                  className="raceCandBox"
                  sx={{
                    paddingTop: "9px",
                    borderLeft: `solid ${partyBorderLeft}px ${partyColor}`
                  }}
                >
                  <Box
                    className="raceName"
                    sx={{
                      position: "relative",
                      height: "3rem",
                      fontSize:
                        candidateName.length > 27 ? raceNameLong : "1.8rem",
                      lineHeight: "1.7rem",
                      width: "98%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "end"
                    }}
                  >
                    <span>
                      {Names[candidateName]
                        ? Names[candidateName].toUpperCase()
                        : candidateName.toUpperCase()}
                    </span>
                    {reelected === 1 && (
                      <img
                        src={DoubleCheck}
                        alt=""
                        className={"raceElectCheckCong"}
                        style={{
                          width: "1.25rem",
                          height: "1.25rem",
                          marginLeft: "7px"
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "right",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  flex: 1
                }}
              >
                <Text
                  className={"percentNumGov"}
                  sx={{
                    fontFamily: "FranklinGothicMedium",
                    fontSize: "4.5rem",
                    marginTop: "0rem",
                    lineHeight: "1"
                  }}
                >
                  {(candidatePct && candidatePct < 10) || candidatePct === 0
                    ? "\u00A0\u00A0"
                    : ""}
                  {candidatePct || candidatePct === 0 ? candidatePct : ""}
                  <span className={"percent"}>
                    {candidatePct || candidatePct === 0 ? "%" : ""}
                  </span>
                </Text>
                <Text
                  className={"voteNum"}
                  style={{ color: "#555", fontWeight: "normal" }}
                >
                  {(candidateVotes || candidateVotes === 0) && (
                    <FormattedMessage
                      id={"Votes2"}
                      values={{
                        votes: candidateVotes.toLocaleString("en")
                      }}
                    />
                  )}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    },
    [partyBorderLeft, partyBorderBottom, raceNameLong, sanitizeName]
  );

  return (
    <Dialog
      id="raceboardG"
      className="raceboard"
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={20}
      sx={{
        width: "500px",
        borderLeft: `solid 0px ${PartyColors[app.raceboard.p1Party]?.high}`,
        padding: "0px !important",
        fontSize: "16px",
        color: "#111111",
        top: 89,
        display:
          (app.raceboard.p1Name && app.raceboard.p1Name === "0") ||
          year === "cdmx" ||
          year === "cdmx24" ||
          year === "jal"
            ? "none"
            : "block"
      }}
      onLoad={runAfterRender}
    >
      {/* Title Box */}
      <Box
        className="raceboardTitleBox"
        sx={{
          fontWeight: "bold",
          paddingBottom: "4px",
          marginBottom: "10px"
        }}
      >
        <Box
          className="raceboardTitle"
          sx={{
            fontSize: "39px",
            lineHeight: "39px",
            paddingBottom: "3px",
            paddingTop: "9px",
            textAlign: "center"
          }}
        >
          {app.raceboard.layer === "municipals"
            ? voca.titleCase(
                StatesAccents[app.raceboard.mun.toUpperCase()] ||
                  app.raceboard.mun ||
                  ""
              )
            : app.raceboard.state === "México"
            ? intl.formatMessage({ id: "MexicoState" })
            : voca.titleCase(
                StatesAccents[app.raceboard.state.toUpperCase()] ||
                  app.raceboard.state ||
                  ""
              )}
        </Box>
        <Box
          className="raceboardSubtitle"
          sx={{
            fontSize: "25px",
            lineHeight: "25px",
            paddingBottom: "3px",
            paddingTop: "0px",
            fontWeight: "normal",
            textAlign: "center"
          }}
        >
          {voca
            .titleCase(year)
            .replace("Post-", "")
            .replace("Cdmx-", "")
            .replace("Mty-", "")
            .replace("Gdl-", "")
          }
        </Box>
      </Box>

      {/* Candidate Boxes */}
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}
      >
        {renderCandidateBox(
          app.raceboard.p1Name,
          app.raceboard.p1Party,
          app.raceboard.p1CoalitionParties,
          app.raceboard.p1Vote,
          app.raceboard.p1Percent,
          app.raceboard.reelected,
          app.raceboard.stateRaceYear
        )}
        {renderCandidateBox(
          app.raceboard.p2Name,
          app.raceboard.p2Party,
          app.raceboard.p2CoalitionParties,
          app.raceboard.p2Vote,
          app.raceboard.p2Percent,
          0,
          app.raceboard.stateRaceYear
        )}
        {renderCandidateBox(
          app.raceboard.p3Name,
          app.raceboard.p3Party,
          app.raceboard.p3CoalitionParties,
          app.raceboard.p3Vote,
          app.raceboard.p3Percent,
          0,
          app.raceboard.stateRaceYear
        )}
      </Box>
    </Dialog>
  );
};

export default RaceboardMayor;
