import React from "react";
import { Dialog, Box, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import voca from "voca";

import { Names } from "../../../mapbox/titles";
import { PartyColors } from "../../../mapbox/colors";
import CandidateImgs from "../../../mapbox/candidates";
import { StatesAccents } from "../../../mapbox/states";
//import variables from "../../../_variables.scss";
import { FormattedMessage, useIntl } from "react-intl";
import removeAccents from "remove-accents";

import GovWomen from "../../../mapbox/governorWomen";
import GovWinners from "../../../mapbox/governorWinners";

const Raceboard = ({ open, onClose, app }) => {
  const year = useSelector((state) => state.year.value);
  const intl = useIntl();

  let partyBorderLeft = 0;
  let partyBorderBottom = 10;
  let raceNameLong = "1.6rem";
  if (window.innerWidth > 3000) {
    //checkSize = 60;
    //checkStroke = 3;
    partyBorderLeft = 0;
    partyBorderBottom = 20;
    raceNameLong = "3.5rem !important";
  }
  
  return (
    <Dialog
      id={"raceboardG"}
      className={year === "women" ? "raceboardWomen" : "raceboard"}
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={20}
      sx={{
        width: year === "women" ? "375px" : "500px",
        borderLeft: "solid 0px " + PartyColors[app.raceboard.p1Party]?.high,
        padding: "0px !important",
        fontSize: "16px",
        color: "#111111",
        top: 89
      }}
    >
      <Box
        className={"raceboardTitleBoxW"}
        sx={{
          paddingLeft: "20px",
          fontWeight: "bold",
          //borderBottom: "solid 3px #111",
          paddingBottom: "4px",
          marginBottom: "6px",
          backgroundColor: "rgba(213,209,250,0.8)",
          borderRadius: "5px"
        }}
      >
        <Box
          className={"raceboardTitleW"}
          sx={{
            fontSize: "39px",
            lineHeight: "39px",
            paddingBottom: "0px",
            paddingTop: "12px",
            textAlign: "left"
          }}
        >
          {app.raceboard.layer === "municipals"
            ? app.raceboard.mun
            : app.raceboard.state === "México"
            ? intl.formatMessage({ id: "MexicoState" })
            : voca.titleCase(
                StatesAccents[app.raceboard.state.toUpperCase()] ||
                  app.raceboard.state.toUpperCase() ||
                  ""
              )}
        </Box>
        <Box
          className={"raceboardSubtitle"}
          sx={{
            fontSize: "25px",
            lineHeight: "25px",
            paddingBottom: "3px",
            paddingTop: "0px",
            fontWeight: "normal",
            textAlign: "center"
          }}
        >
          {year === "women" ? "" : year.replace(/\D/g, "")}
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}
      >
        {app.raceboard.p1Name && (
          <Box
            className={"partyBoxW"}
            sx={{
              display: "flex",
              flex: 1,
              paddingTop: "0.6rem",
              marginBottom: "0.25rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "4.5%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "94%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[app.raceboard.p1Party]?.high,
                  marginRight: "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "top",
                    width: year === "women" ? "45%" : "24%"
                  }}
                >
                  <img
                    src={
                      GovWomen[
                        removeAccents(
                          app.raceboard.p1Name.replaceAll(/\s/g, "")
                        ).toUpperCase()
                      ] ||
                      GovWinners[
                        "GOV" + app.raceboard.stateId + year.split("-")[1]
                      ]
                    }
                    style={{
                      width: year === "women" ? "100%" : "96%",
                      marginRight: "2%"
                    }}
                    alt=""
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4%",
                    width: year === "women" ? "51%" : "51%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={
                        app.raceboard.p1Party.length > 10
                          ? "racePartyNameW"
                          : "racePartyNameW"
                      }
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p1Party]?.high,
                        color:
                          PartyColors[app.raceboard.p1Party]?.boardText ||
                          PartyColors[app.raceboard.p1Party]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize:
                          app.raceboard.p1Party.length > 10 ? "23px" : "23px",
                        lineHeight:
                          app.raceboard.p1Party.length > 10 ? "23px" : "23px"
                      }}
                    >
                      {app.raceboard.p1Party}
                    </div>
                  </Box>
                  <Box
                    className={
                      year === "women" ? "raceTitleWomen" : "raceTitle"
                    }
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {app.raceboard.p1Coalition ||
                      "Candidatura " + app.raceboard.p1Party}
                  </Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[app.raceboard.p1Party]
                          ? PartyColors[app.raceboard.p1Party].high
                          : "#111111")
                    }}
                  >
                    <Box
                      className={
                        year === "women" ? "raceNameWomen" : "raceName"
                      }
                      sx={{
                        position: "relative",
                        height: "3rem",
                        fontSize:
                          app.raceboard.p1Name.length > 27
                            ? raceNameLong
                            : "1.8rem",
                        lineHeight: "1.7rem",
                        width: "98%"
                      }}
                    >
                      <span
                        className={"raceNameWomenSpan"}
                        style={{ position: "absolute", bottom: 5 }}
                      >
                        <div className={"raceInterina"}>
                          {app.raceboard.p1Interina === "Interina"
                            ? app.raceboard.p1Interina
                            : ""}
                        </div>
                        {Names[app.raceboard.p1Name]
                          ? Names[app.raceboard.p1Name].toUpperCase()
                          : app.raceboard.p1Name.toUpperCase()}
                      </span>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNum"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1"
                    }}
                  >
                    {(app.raceboard.p1Percent &&
                      app.raceboard.p1Percent < 10) ||
                    app.raceboard.p1Percent === 0
                      ? "\u00A0\u00A0"
                      : ""}
                    {app.raceboard.p1Percent || app.raceboard.p1Percent === 0
                      ? app.raceboard.p1Percent
                      : ""}
                    <span className={"percent"}>
                      {app.raceboard.p1Percent || app.raceboard.p1Percent === 0
                        ? "%"
                        : ""}
                    </span>
                  </Text>
                  <Text
                    className={"voteNum"}
                    style={{
                      fontFamily: "FranklinGothicHeavy",
                      fontWeight: "normal"
                    }}
                  >
                    {(app.raceboard.p1Vote || app.raceboard.p1Vote === 0) && (
                      <FormattedMessage
                        id={"Votes2"}
                        values={{
                          votes: app.raceboard.p1Vote.toLocaleString("en")
                        }}
                      />
                    )}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {app.raceboard.p2Name && (
          <Box
            className={"partyBoxW"}
            sx={{
              display: "flex",
              flex: 1,
              marginTop: "2px",
              backgroundColor: "rgba(255,255,255,0.8)",
              paddingTop: "0.6rem",
              marginBottom: "0.25rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "4.5%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "94%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[app.raceboard.p2Party]?.high,
                  marginRight: "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "top",
                    width: year === "women" ? "45%" : "24%"
                  }}
                >
                  <img
                    src={
                      year === "women"
                        ? GovWomen[
                            removeAccents(
                              app.raceboard.p2Name.replaceAll(/\s/g, "")
                            ).toUpperCase()
                          ]
                        : CandidateImgs[app.raceboard.p2Party]
                    }
                    style={{ width: year === "women" ? "100%" : "85%" }}
                    alt=""
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4%",
                    width: year === "women" ? "51%" : "51%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={
                        app.raceboard.p2Party.length > 10
                          ? "racePartyNameW"
                          : "racePartyNameW"
                      }
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p2Party]?.high,
                        color:
                          PartyColors[app.raceboard.p2Party]?.boardText ||
                          PartyColors[app.raceboard.p2Party]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize:
                          app.raceboard.p2Party.length > 10 ? "23px" : "23px",
                        lineHeight:
                          app.raceboard.p2Party.length > 10 ? "23px" : "23px"
                      }}
                    >
                      {app.raceboard.p2Party}
                    </div>
                  </Box>
                  <Box
                    className={
                      year === "women" ? "raceTitleWomen" : "raceTitle"
                    }
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {app.raceboard.p2Coalition ||
                      "Candidatura " + app.raceboard.p2Party}
                  </Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[app.raceboard.p2Party]
                          ? PartyColors[app.raceboard.p2Party].high
                          : "#111111")
                    }}
                  >
                    <Box
                      className={
                        year === "women" ? "raceNameWomen" : "raceName"
                      }
                      sx={{
                        position: "relative",
                        height: "3rem",
                        fontSize:
                          app.raceboard.p2Name.length > 27
                            ? raceNameLong
                            : "1.8rem",
                        lineHeight: "1.7rem",
                        width: "98%"
                      }}
                    >
                      <span
                        className={"raceNameWomenSpan"}
                        style={{ position: "absolute", bottom: 5 }}
                      >
                        <div className={"raceInterina"}>
                          {app.raceboard.p2Interina === "Interina"
                            ? app.raceboard.p2Interina
                            : ""}
                        </div>
                        {Names[app.raceboard.p2Name]
                          ? Names[app.raceboard.p2Name].toUpperCase()
                          : app.raceboard.p2Name.toUpperCase()}
                      </span>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNum"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1"
                    }}
                  >
                    {(app.raceboard.p2Percent &&
                      app.raceboard.p2Percent < 10) ||
                    app.raceboard.p2Percent === 0
                      ? "\u00A0\u00A0"
                      : ""}
                    {app.raceboard.p2Percent || app.raceboard.p2Percent === 0
                      ? app.raceboard.p2Percent
                      : ""}
                    <span className={"percent"}>
                      {app.raceboard.p2Percent || app.raceboard.p2Percent === 0
                        ? "%"
                        : ""}
                    </span>
                  </Text>
                  <Text
                    className={"voteNum"}
                    style={{ color: "#555", fontWeight: "normal" }}
                  >
                    {(app.raceboard.p2Vote || app.raceboard.p2Vote === 0) && (
                      <FormattedMessage
                        id={"Votes2"}
                        values={{
                          votes: app.raceboard.p2Vote.toLocaleString("en")
                        }}
                      />
                    )}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {app.raceboard.p3Name && (
          <Box
            className={"partyBoxW"}
            sx={{
              display: "flex",
              flex: 1,
              marginTop: "2px",
              backgroundColor: "rgba(255,255,255,0.8)",
              paddingTop: "0.6rem",
              marginBottom: "0.25rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "4.5%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "94%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[app.raceboard.p3Party]?.high,
                  marginRight: "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "top",
                    width: year === "women" ? "45%" : "24%"
                  }}
                >
                  <img
                    src={
                      year === "women"
                        ? GovWomen[
                            removeAccents(
                              app.raceboard.p3Name.replaceAll(/\s/g, "")
                            ).toUpperCase()
                          ]
                        : CandidateImgs[app.raceboard.p3Party]
                    }
                    style={{ width: year === "women" ? "100%" : "85%" }}
                    alt=""
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4%",
                    width: year === "women" ? "51%" : "51%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={
                        app.raceboard.p3Party.length > 10
                          ? "racePartyNameW"
                          : "racePartyNameW"
                      }
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p3Party]?.high,
                        color:
                          PartyColors[app.raceboard.p3Party]?.boardText ||
                          PartyColors[app.raceboard.p3Party]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize:
                          app.raceboard.p3Party.length > 10 ? "23px" : "23px",
                        lineHeight:
                          app.raceboard.p3Party.length > 10 ? "23px" : "23px"
                      }}
                    >
                      {app.raceboard.p3Party}
                    </div>
                  </Box>
                  <Box
                    className={
                      year === "women" ? "raceTitleWomen" : "raceTitle"
                    }
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {app.raceboard.p3Coalition ||
                      "Candidatura " + app.raceboard.p3Party}
                  </Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[app.raceboard.p3Party]
                          ? PartyColors[app.raceboard.p3Party].high
                          : "#111111")
                    }}
                  >
                    <Box
                      className={
                        year === "women" ? "raceNameWomen" : "raceName"
                      }
                      sx={{
                        position: "relative",
                        height: "3rem",
                        fontSize:
                          app.raceboard.p3Name.length > 27
                            ? raceNameLong
                            : "1.8rem",
                        lineHeight: "1.7rem",
                        width: "98%"
                      }}
                    >
                      <span
                        className={"raceNameWomenSpan"}
                        style={{ position: "absolute", bottom: 5 }}
                      >
                        <div className={"raceInterina"}>
                          {app.raceboard.p1Interina === "Interina"
                            ? app.raceboard.p1Interina
                            : ""}
                        </div>
                        {Names[app.raceboard.p3Name]
                          ? Names[app.raceboard.p3Name].toUpperCase()
                          : app.raceboard.p3Name.toUpperCase()}
                      </span>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNum"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1"
                    }}
                  >
                    {(app.raceboard.p3Percent &&
                      app.raceboard.p3Percent < 10) ||
                    app.raceboard.p3Percent === 0
                      ? "\u00A0\u00A0"
                      : ""}
                    {app.raceboard.p3Percent || app.raceboard.p3Percent === 0
                      ? app.raceboard.p3Percent
                      : ""}
                    <span className={"percent"}>
                      {app.raceboard.p3Percent || app.raceboard.p3Percent === 0
                        ? "%"
                        : ""}
                    </span>
                  </Text>
                  <Text
                    className={"voteNum"}
                    style={{ color: "#555", fontWeight: "normal" }}
                  >
                    {(app.raceboard.p3Vote || app.raceboard.p3Vote === 0) && (
                      <FormattedMessage
                        id={"Votes2"}
                        values={{
                          votes: app.raceboard.p3Vote.toLocaleString("en")
                        }}
                      />
                    )}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default Raceboard;
