import AMALIAGARCIA from "../assets/img/candidates/governors/women/Amalia Garcia.png";
import BEATRIZPAREDESRANGEL from "../assets/img/candidates/governors/women/Beatriz Paredes Rangel.png";
import CLAUDIAPAVLOVICH from "../assets/img/candidates/governors/women/Claudia Pavlovich.png";
import CLAUDIASHEINBAUM from "../assets/img/candidates/governors/women/Claudia Sheinbaum.png";
import DELFINAGOMEZALVAREZ from "../assets/img/candidates/governors/women/Delfina Gomez.png";
import DULCEMARIASAURI from "../assets/img/candidates/governors/women/Dulce María Sauri.png";
import EVELYNSALGADO from "../assets/img/candidates/governors/women/Evelyn Salgado.png";
import GRISELDAALVAREZPONCEDELEON from "../assets/img/candidates/governors/women/Griselda Álvarez Ponce.png";
import INDIRAVIZCAINO from "../assets/img/candidates/governors/women/Indira Vizcaíno.png";
import IVONNEORTEGA from "../assets/img/candidates/governors/women/Ivonne Ortega.png";
import LAYDASANSORES from "../assets/img/candidates/governors/women/Layda Sansores.png";
import LORENACUELLAR from "../assets/img/candidates/governors/women/Lorena Cuellar.png";
import MARALEZAMA from "../assets/img/candidates/governors/women/Mara Lezama.png";
import MARINADELPILAR from "../assets/img/candidates/governors/women/Marina del Pilar.png";
import MARTHAERIKAALONSO from "../assets/img/candidates/governors/women/Marta Erika Alonso.png";
import MARUCAMPOS from "../assets/img/candidates/governors/women/Maru Campos.png";
import ROSARIOROBLES from "../assets/img/candidates/governors/women/Rosario Robles.png";
import TERESAJIMENEZ from "../assets/img/candidates/governors/women/Teresa Jimenez.png";

const GovWomen = {
  AMALIAGARCIA,
  BEATRIZPAREDESRANGEL,
  CLAUDIAPAVLOVICH,
  CLAUDIASHEINBAUM,
  DELFINAGOMEZALVAREZ,
  DULCEMARIASAURI,
  EVELYNSALGADO,
  GRISELDAALVAREZPONCEDELEON,
  INDIRAVIZCAINO,
  IVONNEORTEGA,
  LAYDASANSORES,
  LORENACUELLAR,
  MARALEZAMA,
  MARINADELPILAR,
  MARTHAERIKAALONSO,
  MARUCAMPOS,
  ROSARIOROBLES,
  TERESAJIMENEZ
};

export default GovWomen;
