import CARLOSOROZCOSANTILLAN from "../assets/img/candidates/governors/jalisco/Carlos Orozco Santillán.png";
import MARTHAROSAARAIZASOLTERO from "../assets/img/candidates/governors/jalisco/Martha Rosa Araiza Soltero.png";
import CARLOSLOMELIBOLANOS from "../assets/img/candidates/governors/jalisco/Carlos Lomelí.png";
import MIGUELANGELMARTINEZESPINOSA from "../assets/img/candidates/governors/jalisco/Miguel Angel Martinez.png";
import SALVADORCOSIOGAONA from "../assets/img/candidates/governors/jalisco/Salvador Cosio.png";
import ENRIQUEALFARORAMIREZ from "../assets/img/candidates/governors/jalisco/Enrique Alfaro.png";
import MIGUELCASTROREYNOSO from "../assets/img/candidates/governors/jalisco/Miguel Castro Reynoso.png";

const GovJalisco = {
  CARLOSOROZCOSANTILLAN,
  MARTHAROSAARAIZASOLTERO,
  CARLOSLOMELIBOLANOS,
  MIGUELANGELMARTINEZESPINOSA,
  SALVADORCOSIOGAONA,
  ENRIQUEALFARORAMIREZ,
  MIGUELCASTROREYNOSO
};

export default GovJalisco;
