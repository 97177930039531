import React, { useEffect, useCallback, useState } from "react";
import { Dialog, Box, Text } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import Papa from "papaparse";
import { PartyColors } from "../../../mapbox/colors";
import CandidateImgs from "../../../mapbox/candidates";
import RaceData from "../../../mapbox/racechart";

const RaceboardSenNational = ({ open, onClose, app }) => {
  const dispatch = useDispatch();
  const year = useSelector((state) => state.year.value);
  const raceType = useSelector((state) => state.raceType.value);
  //const intl = useIntl();

  const [party1, setParty1] = useState("");
  const [party2, setParty2] = useState("");
  const [party3, setParty3] = useState("");
  const [party4, setParty4] = useState("");
  const [party5, setParty5] = useState("");
  const [party6, setParty6] = useState("");
  const [party7, setParty7] = useState("");
  const [party8, setParty8] = useState("");
  const [partyNum1, setPartyNum1] = useState(0);
  const [partyNum2, setPartyNum2] = useState(0);
  const [partyNum3, setPartyNum3] = useState(0);
  const [partyNum4, setPartyNum4] = useState(0);
  const [partyNum5, setPartyNum5] = useState(0);
  const [partyNum6, setPartyNum6] = useState(0);
  const [partyNum7, setPartyNum7] = useState(0);
  const [partyNum8, setPartyNum8] = useState(0);

  useEffect(() => {
    if (raceType === "sen") {
      function cmp(a, b) {
        return b[1] - a[1];
      }

      setParty1("");
      setParty2("");
      setParty3("");
      setParty4("");
      setParty5("");
      setParty6("");
      setParty7("");
      setParty8("");
      setPartyNum1(0);
      setPartyNum2(0);
      setPartyNum3(0);
      setPartyNum4(0);
      setPartyNum5(0);
      setPartyNum6(0);
      setPartyNum7(0);
      setPartyNum8(0);

      Papa.parse(RaceData["sen"], {
        download: true,
        complete: function (input) {
          let records = input.data;
          let parties = [];
          records[0].forEach(function (data, i) {
            parties[i] = data;
          });
          records.forEach(function (data, i) {
            if (data[0] === year.replace(/\D/g, "")) {
              let sortdata = [];
              data.forEach(function (sdata, j) {
                if (j !== 0) {
                  sortdata.push([j, Number(sdata)]);
                }
              });
              sortdata.sort(cmp);
              let k = 0;
              sortdata.forEach(function (sdata, j) {
                if (Number(sdata[1]) > 0) {
                  k++;
                  if (k === 1) {
                    setParty1(parties[sdata[0]].trim() === "?" ? "TOOEARLY" : parties[sdata[0]].trim() === "?" ? "TOOEARLY" : parties[sdata[0]].trim() === "?" ? "TOOEARLY" : parties[sdata[0]].trim());
                    setPartyNum1(sdata[1]);
                  } else if (k === 2) {
                    setParty2(parties[sdata[0]].trim() === "?" ? "TOOEARLY" : parties[sdata[0]].trim());
                    setPartyNum2(sdata[1]);
                  } else if (k === 3) {
                    setParty3(parties[sdata[0]].trim() === "?" ? "TOOEARLY" : parties[sdata[0]].trim());
                    setPartyNum3(sdata[1]);
                  } else if (k === 4) {
                    setParty4(parties[sdata[0]].trim() === "?" ? "TOOEARLY" : parties[sdata[0]].trim());
                    setPartyNum4(sdata[1]);
                  } else if (k === 5) {
                    setParty5(parties[sdata[0]].trim() === "?" ? "TOOEARLY" : parties[sdata[0]].trim());
                    setPartyNum5(sdata[1]);
                  } else if (k === 6) {
                    setParty6(parties[sdata[0]].trim() === "?" ? "TOOEARLY" : parties[sdata[0]].trim());
                    setPartyNum6(sdata[1]);
                  } else if (k === 7) {
                    setParty7(parties[sdata[0]].trim() === "?" ? "TOOEARLY" : parties[sdata[0]].trim());
                    setPartyNum7(sdata[1]);
                  } else if (k === 8) {
                    setParty8(parties[sdata[0]].trim() === "?" ? "TOOEARLY" : parties[sdata[0]].trim());
                    setPartyNum8(sdata[1]);
                  }
                }
              });
            }
          });
        }
      });
    }
  }, [year, raceType, dispatch]);


  let partyBorderLeft = 0;
  let partyBorderBottom = 10;
  if (window.innerWidth > 3000) {
    partyBorderLeft = 0;
    partyBorderBottom = 20;
  }

  return (
    <Dialog
      id={"raceboardGN"}
      className={true ? "raceboardGN" : "raceboard"}
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={20}
      sx={{
        width: true ? "375px" : "500px",
        borderLeft: "solid 0px " + PartyColors[party1]?.high,
        padding: "0px !important",
        fontSize: "16px",
        color: "#111111",
        top: 89
      }}
    >
      <Box
        className={"raceboardTitleBoxGN"}
        sx={{
          paddingLeft: "5px",
          fontWeight: "bold",
          //borderBottom: "solid 3px #111",
          paddingBottom: "4px",
          marginBottom: "6px",
          backgroundColor: "rgba(213,209,250,0.8)",
          borderRadius: "5px"
        }}
      >
        <Box
          className={"raceboardTitleGN"}
          sx={{
            fontSize: "39px",
            lineHeight: "39px",
            paddingBottom: "0px",
            paddingTop: "12px",
            textAlign: "center",
            fontWeight: "normal !important"
          }}
        >
          Senadores
          <br />
          por Partido
        </Box>
        <Box
          className={"raceboardSubtitle"}
          sx={{
            fontSize: "25px",
            lineHeight: "25px",
            paddingBottom: "3px",
            paddingTop: "0px",
            fontWeight: "normal",
            textAlign: "center"
          }}
        >
          {true ? "" : year.replace(/\D/g, "")}
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}
      >
        {party1 && partyNum1 > 0 && (
          <Box
            className={"partyBoxW"}
            sx={{
              display: "flex",
              flex: 1,
              paddingTop: "0.6rem",
              marginBottom: "0.25rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "4.5%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                width: "20%"
              }}
            >
              <img
                src={CandidateImgs[party1]}
                style={{
                  width: window.innerWidth > 3000 ? "94%" : "100%",
                  marginRight: "2%"
                }}
                alt=""
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "75.5%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[party1]?.high,
                  marginRight: "4%",
                  marginLeft: window.innerWidth > 3000 ? "1%" : "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4%",
                    width: true ? "51%" : "51%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={"racePartyNameW"}
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p1Party]?.high,
                        color:
                          party1 === "PRD"
                            ? "black"
                            : PartyColors[party1]?.boardText ||
                              PartyColors[party1]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize: "23px",
                        lineHeight: "23px"
                      }}
                    ></div>
                  </Box>
                  <Box
                    className={true ? "raceTitleWomen" : "raceTitle"}
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  ></Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[party1]
                          ? PartyColors[party1].high
                          : "#111111")
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNumGN"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1",
                      color:
                        party1 === "PRD"
                          ? "black"
                          : PartyColors[party1]?.boardText ||
                            PartyColors[party1]?.high,
                      display: "block"
                    }}
                  >
                    {partyNum1}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {party2 && partyNum2 > 0 && (
          <Box
            className={"partyBoxW"}
            sx={{
              display: "flex",
              flex: 1,
              paddingTop: "0.6rem",
              marginBottom: "0.25rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "4.5%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                width: "20%"
              }}
            >
              <img
                src={CandidateImgs[party2]}
                style={{
                  width: window.innerWidth > 3000 ? "94%" : "100%",
                  marginRight: "2%"
                }}
                alt=""
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "75.5%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[party2]?.high,
                  marginRight: "4%",
                  marginLeft: window.innerWidth > 3000 ? "1%" : "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4%",
                    width: true ? "51%" : "51%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={"racePartyNameW"}
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p1Party]?.high,
                        color:
                          party2 === "PRD"
                            ? "black"
                            : PartyColors[party2]?.boardText ||
                              PartyColors[party2]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize: "23px",
                        lineHeight: "23px"
                      }}
                    ></div>
                  </Box>
                  <Box
                    className={true ? "raceTitleWomen" : "raceTitle"}
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  ></Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[party2]
                          ? PartyColors[party2].high
                          : "#111111")
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNumGN"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1",
                      color:
                        party2 === "PRD"
                          ? "black"
                          : PartyColors[party2]?.boardText ||
                            PartyColors[party2]?.high,
                      display: "block"
                    }}
                  >
                    {partyNum2}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {party3 && partyNum3 > 0 && (
          <Box
            className={"partyBoxW"}
            sx={{
              display: "flex",
              flex: 1,
              paddingTop: "0.6rem",
              marginBottom: "0.25rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "4.5%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                width: "20%"
              }}
            >
              <img
                src={CandidateImgs[party3]}
                style={{
                  width: window.innerWidth > 3000 ? "94%" : "100%",
                  marginRight: "2%"
                }}
                alt=""
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "75.5%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[party3]?.high,
                  marginRight: "4%",
                  marginLeft: window.innerWidth > 3000 ? "1%" : "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4%",
                    width: true ? "51%" : "51%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={"racePartyNameW"}
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p1Party]?.high,
                        color:
                          party3 === "PRD"
                            ? "black"
                            : PartyColors[party3]?.boardText ||
                              PartyColors[party3]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize: "23px",
                        lineHeight: "23px"
                      }}
                    ></div>
                  </Box>
                  <Box
                    className={true ? "raceTitleWomen" : "raceTitle"}
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  ></Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[party3]
                          ? PartyColors[party3].high
                          : "#111111")
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNumGN"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1",
                      color:
                        party3 === "PRD"
                          ? "black"
                          : PartyColors[party3]?.boardText ||
                            PartyColors[party3]?.high,
                      display: "block"
                    }}
                  >
                    {partyNum3}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {party4 && partyNum4 > 0 && (
          <Box
            className={"partyBoxW"}
            sx={{
              display: "flex",
              flex: 1,
              paddingTop: "0.6rem",
              marginBottom: "0.25rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "4.5%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                width: "20%"
              }}
            >
              <img
                src={CandidateImgs[party4]}
                style={{
                  width: window.innerWidth > 3000 ? "94%" : "100%",
                  marginRight: "2%"
                }}
                alt=""
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "75.5%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[party4]?.high,
                  marginRight: "4%",
                  marginLeft: window.innerWidth > 3000 ? "1%" : "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4%",
                    width: true ? "51%" : "51%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={"racePartyNameW"}
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p1Party]?.high,
                        color:
                          party4 === "PRD"
                            ? "black"
                            : PartyColors[party4]?.boardText ||
                              PartyColors[party4]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize: "23px",
                        lineHeight: "23px"
                      }}
                    ></div>
                  </Box>
                  <Box
                    className={true ? "raceTitleWomen" : "raceTitle"}
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  ></Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[party4]
                          ? PartyColors[party4].high
                          : "#111111")
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNumGN"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1",
                      color:
                        party4 === "PRD"
                          ? "black"
                          : PartyColors[party4]?.boardText ||
                            PartyColors[party4]?.high,
                      display: "block"
                    }}
                  >
                    {partyNum4}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {party5 && partyNum5 > 0 && (
          <Box
            className={"partyBoxW"}
            sx={{
              display: "flex",
              flex: 1,
              paddingTop: "0.6rem",
              marginBottom: "0.25rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "4.5%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                width: "20%"
              }}
            >
              <img
                src={CandidateImgs[party5]}
                style={{
                  width: window.innerWidth > 3000 ? "94%" : "100%",
                  marginRight: "2%"
                }}
                alt=""
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "75.5%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[party5]?.high,
                  marginRight: "4%",
                  marginLeft: window.innerWidth > 3000 ? "1%" : "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4%",
                    width: true ? "51%" : "51%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={"racePartyNameW"}
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p1Party]?.high,
                        color:
                          party5 === "PRD"
                            ? "black"
                            : PartyColors[party5]?.boardText ||
                              PartyColors[party5]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize: "23px",
                        lineHeight: "23px"
                      }}
                    ></div>
                  </Box>
                  <Box
                    className={true ? "raceTitleWomen" : "raceTitle"}
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  ></Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[party5]
                          ? PartyColors[party5].high
                          : "#111111")
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNumGN"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1",
                      color:
                        party5 === "PRD"
                          ? "black"
                          : PartyColors[party5]?.boardText ||
                            PartyColors[party5]?.high,
                      display: "block"
                    }}
                  >
                    {partyNum5}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {party6 && partyNum6 > 0 && (
          <Box
            className={"partyBoxW"}
            sx={{
              display: "flex",
              flex: 1,
              paddingTop: "0.6rem",
              marginBottom: "0.25rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "4.5%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                width: "20%"
              }}
            >
              <img
                src={CandidateImgs[party6]}
                style={{
                  width: window.innerWidth > 3000 ? "94%" : "100%",
                  marginRight: "2%"
                }}
                alt=""
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "75.5%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[party6]?.high,
                  marginRight: "4%",
                  marginLeft: window.innerWidth > 3000 ? "1%" : "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4%",
                    width: true ? "51%" : "51%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={"racePartyNameW"}
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p1Party]?.high,
                        color:
                          party6 === "PRD"
                            ? "black"
                            : PartyColors[party6]?.boardText ||
                              PartyColors[party6]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize: "23px",
                        lineHeight: "23px"
                      }}
                    ></div>
                  </Box>
                  <Box
                    className={true ? "raceTitleWomen" : "raceTitle"}
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  ></Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[party6]
                          ? PartyColors[party6].high
                          : "#111111")
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNumGN"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1",
                      color:
                        party6 === "PRD"
                          ? "black"
                          : PartyColors[party6]?.boardText ||
                            PartyColors[party6]?.high,
                      display: "block"
                    }}
                  >
                    {partyNum6}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {party7 && partyNum7 > 0 && (
          <Box
            className={"partyBoxW"}
            sx={{
              display: "flex",
              flex: 1,
              paddingTop: "0.6rem",
              marginBottom: "0.25rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "4.5%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                width: "20%"
              }}
            >
              <img
                src={CandidateImgs[party7]}
                style={{
                  width: window.innerWidth > 3000 ? "94%" : "100%",
                  marginRight: "2%"
                }}
                alt=""
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "75.5%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[party7]?.high,
                  marginRight: "4%",
                  marginLeft: window.innerWidth > 3000 ? "1%" : "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4%",
                    width: true ? "51%" : "51%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={"racePartyNameW"}
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p1Party]?.high,
                        color:
                          party7 === "PRD"
                            ? "black"
                            : PartyColors[party7]?.boardText ||
                              PartyColors[party7]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize: "23px",
                        lineHeight: "23px"
                      }}
                    ></div>
                  </Box>
                  <Box
                    className={true ? "raceTitleWomen" : "raceTitle"}
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  ></Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[party7]
                          ? PartyColors[party7].high
                          : "#111111")
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNumGN"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1",
                      color:
                        party7 === "PRD"
                          ? "black"
                          : PartyColors[party7]?.boardText ||
                            PartyColors[party7]?.high,
                      display: "block"
                    }}
                  >
                    {partyNum7}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {party8 && partyNum8 > 0 && (
          <Box
            className={"partyBoxW"}
            sx={{
              display: "flex",
              flex: 1,
              paddingTop: "0.6rem",
              marginBottom: "0.25rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "4.5%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                width: "20%"
              }}
            >
              <img
                src={CandidateImgs[party8]}
                style={{
                  width: window.innerWidth > 3000 ? "94%" : "100%",
                  marginRight: "2%"
                }}
                alt=""
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "75.5%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[party8]?.high,
                  marginRight: "4%",
                  marginLeft: window.innerWidth > 3000 ? "1%" : "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4%",
                    width: true ? "51%" : "51%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={"racePartyNameW"}
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p1Party]?.high,
                        color:
                          party8 === "PRD"
                            ? "black"
                            : PartyColors[party8]?.boardText ||
                              PartyColors[party8]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize: "23px",
                        lineHeight: "23px"
                      }}
                    ></div>
                  </Box>
                  <Box
                    className={true ? "raceTitleWomen" : "raceTitle"}
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  ></Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[party8]
                          ? PartyColors[party8].high
                          : "#111111")
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNumGN"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1",
                      color:
                        party8 === "PRD"
                          ? "black"
                          : PartyColors[party8]?.boardText ||
                            PartyColors[party8]?.high,
                      display: "block"
                    }}
                  >
                    {partyNum8}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default RaceboardSenNational;
