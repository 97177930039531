export const StateOpacity = (stateName1, stateName2) => [
  "case",
  ["==", ["upcase", ["coalesce", ["get", "NOMBRE_ESTADO"], ""]], stateName1],
  1,
  ["==", ["upcase", ["coalesce", ["get", "NOMBRE"], ""]], stateName1],
  1,
  ["==", ["upcase", ["coalesce", ["get", "STATE_NAME"], ""]], stateName1],
  1,
  ["==", ["upcase", ["coalesce", ["get", "ESTADO"], ""]], stateName1],
  1,
  ["==", ["upcase", ["coalesce", ["get", "NOMBRE_ESTADO"], ""]], stateName2],
  1,
  ["==", ["upcase", ["coalesce", ["get", "NOMBRE"], ""]], stateName2],
  1,
  ["==", ["upcase", ["coalesce", ["get", "STATE_NAME"], ""]], stateName2],
  1,
  ["==", ["upcase", ["coalesce", ["get", "ESTADO"], ""]], stateName2],
  1,
  0 // Default opacity
];

export const StateFilter = (stateName1, stateName2) => [
  "any",
  ["==", ["upcase", ["coalesce", ["get", "STATE_NAME"], ""]], stateName1],
  ["==", ["upcase", ["coalesce", ["get", "NOMBRE_ESTADO"], ""]], stateName1],
  ["==", ["upcase", ["coalesce", ["get", "NOMBRE"], ""]], stateName1],
  ["==", ["upcase", ["coalesce", ["get", "ESTADO"], ""]], stateName1],
  ["==", ["get", "STATE_ID"], parseFloat(stateName1)],
  ["==", ["upcase", ["coalesce", ["get", "STATE_NAME"], ""]], stateName2],
  ["==", ["upcase", ["coalesce", ["get", "NOMBRE_ESTADO"], ""]], stateName2],
  ["==", ["upcase", ["coalesce", ["get", "NOMBRE"], ""]], stateName2],
  ["==", ["upcase", ["coalesce", ["get", "ESTADO"], ""]], stateName2],
  ["==", ["get", "STATE_ID"], parseFloat(stateName2)]
];

export const StateOlWidth = [
  "interpolate",
  ["linear"],
  ["zoom"],
  4,
  1.0,
  7,
  2.0
];

export const MunFillOpacity = (opacity) => [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  4,
  opacity,
  4.5,
  opacity
];

export const MunDisFillOpacity = (opacity) => [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  8,
  opacity,
  8.5,
  opacity
];

export const DisFillOpacity = (opacity) => [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  4,
  opacity,
  4.5,
  opacity,
  8,
  opacity,
  8.5,
  opacity
];

export const StateFillOpacity = [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  4,
  1.0,
  4.5,
  1.0,
  6.5,
  1.0,
  7,
  1.0
];

export const ForceMunFillOpacity = (opacity) => [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  4,
  opacity,
  4.5,
  opacity,
  6,
  opacity,
  7,
  opacity
];

export const ForceDisFillOpacity = (opacity) => [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  4,
  opacity,
  4.5,
  opacity,
  6,
  opacity,
  7,
  opacity
];

export const MunOutlineColor = (fillColor) => [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  5,
  ["to-color", "#cccccc"],
  6,
  ["to-color", "#cccccc"],
  7,
  ["to-color", "#cccccc"]
];

export const MunDisOutlineColor = (fillColor) => [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  5,
  ["to-color", "#cccccc"],
  6,
  ["to-color", "#cccccc"],
  7,
  ["to-color", "#cccccc"]
];

export const DisOutlineColor = (fillColor) => [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  5,
  ["to-color", "#cccccc"],
  6,
  ["to-color", "#cccccc"],
  7,
  ["to-color", "#cccccc"]
];

export const StateOutlineColor = (fillColor) => [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  4,
  ["to-color", "#cccccc"],
  4.5,
  ["to-color", "#cccccc"],
  7,
  ["to-color", "#cccccc"]
];

export const PresMunCircleRadius = [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  4,
  ["max", ["/", ["get", "FIRST_VOTES"], 12000], 6],
  8,
  ["max", ["/", ["get", "FIRST_VOTES"], 6000], 6],
  12,
  ["max", ["/", ["get", "FIRST_VOTES"], 3000], 6]
];

export const CongMunCircleRadius = [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  4,
  ["max", ["/", ["get", "FIRST_VOTES"], 12000], 6],
  8,
  ["max", ["/", ["get", "FIRST_VOTES"], 6000], 6],
  12,
  ["max", ["/", ["get", "FIRST_VOTES"], 3000], 6]
];

export const DisCircleRadius = [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  4,
  ["max", ["/", ["get", "FIRST_VOTES"], 6000], 6],
  8,
  ["max", ["/", ["get", "FIRST_VOTES"], 3000], 6],
  12,
  ["max", ["/", ["get", "FIRST_VOTES"], 1500], 6]
];

export const StateCircleRadius = ["/", ["get", "FIRST_votes"], 5000];

export const NomMunCircleRadius = [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  4,
  ["max", ["/", ["get", "LISTA_NOMINAL"], 60000], 6],
  8,
  ["max", ["/", ["get", "LISTA_NOMINAL"], 30000], 6],
  12,
  ["max", ["/", ["get", "LISTA_NOMINAL"], 15000], 6]
];

export const StateLabelLayers = {
  AGUASCALIENTES: "settlement-major-label-aguascalientes",
  "BAJA CALIFORNIA": "settlement-major-label-baja-california",
  "BAJA CALIFORNIA SUR": "settlement-major-label-baja-california-sur",
  CAMPECHE: "settlement-major-label-campeche",
  CHIAPAS: "settlement-major-label-chiapas",
  CHIHUAHUA: "settlement-major-label-chihuahua",
  COAHUILA: "settlement-major-label-coahuila",
  COLIMA: "settlement-major-label-colima",
  DURANGO: "settlement-major-label-durango",
  GUANAJUATO: "settlement-major-label-guanajuato",
  GUERRERO: "settlement-major-label-guerrero",
  HIDALGO: "settlement-major-label-hildalgo",
  JALISCO: "settlement-major-label-jalisco",
  MICHOACAN: "settlement-major-label-michoacan",
  MORELOS: "settlement-major-label-morelos",
  MEXICO: "settlement-major-label-mexico",
  "CIUDAD DE MEXICO": "settlement-subdivision-label-mx-city",
  NAYARIT: "settlement-major-label-nayarit",
  "NUEVO LEON": "settlement-major-label-nuevo-leon",
  OAXACA: "settlement-major-label-oaxaca",
  PUEBLA: "settlement-major-label-puebla",
  QUERETARO: "settlement-major-label-queretaro",
  "QUINTANA ROO": "settlement-major-label-quintana-roo",
  "SAN LUIS POTOSI": "settlement-major-label-san-luis",
  SINALOA: "settlement-major-label-sinaloa",
  SONORA: "settlement-major-label-sonora",
  TABASCO: "settlement-major-label-tabasco",
  TAMAULIPAS: "settlement-major-label-tamaulipas",
  TLAXCALA: "settlement-major-label-tlaxcala",
  VERACRUZ: "settlement-major-label-veracruz",
  YUCATAN: "settlement-major-label-yucatan",
  ZACATECAS: "settlement-major-label-zacatecas"
};
