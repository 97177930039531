import ADELARAMOSJUAREZ2021 from '../assets/img/congress_winners/2021/Adela Ramos Juarez.png';
import ADRIANABUSTAMANTECASTELLANOS2021 from '../assets/img/congress_winners/2021/Adriana Bustamante Castellanos.png';
import ADRIANACAMPOSHUIRACHE2021 from '../assets/img/congress_winners/2021/Adriana Campos Huirache.png';
import ADRIANALOZANORODRIGUEZ2021 from '../assets/img/congress_winners/2021/Adriana Lozano Rodriguez.png';
import ALANCASTELLANOSRAMIREZ2021 from '../assets/img/congress_winners/2021/Alan Castellanos Ramirez.png';
import ALBERTOVILLAVILLEGAS2021 from '../assets/img/congress_winners/2021/Alberto Villa Villegas.png';
import ALEIDAALAVEZRUIZ2021 from '../assets/img/congress_winners/2021/Aleida Alavez Ruiz.png';
import ALEJANDRAPANIBARRAGAN2021 from '../assets/img/congress_winners/2021/Alejandra Pani Barragan.png';
import ALEJANDROCARVAJALHIDALGO2021 from '../assets/img/congress_winners/2021/Alejandro Carvajal Hidalgo.png';
import ALFREDOFEMATBANUELOS2021 from '../assets/img/congress_winners/2021/Alfredo Femat Banuelos.png';
import ALFREDOPORRASDOMINGUEZ2021 from '../assets/img/congress_winners/2021/Alfredo Porras Dominguez.png';
import ALFREDOVAZQUEZVAZQUEZ2021 from '../assets/img/congress_winners/2021/Alfredo Vazquez Vazquez.png';
import ALISAYURINUNEZMENESES2021 from '../assets/img/congress_winners/2021/Ali Sayuri Nunez Meneses.png';
import ALMAANAHIGONZALEZHERNANDEZ2021 from '../assets/img/congress_winners/2021/Alma Anahi Gonzalez Hernandez.png';
import ALMADELIANAVARRETERIVERA2021 from '../assets/img/congress_winners/2021/Alma Delia Navarrete Rivera.png';
import ANAELIZABETHAYALALEYVA2021 from '../assets/img/congress_winners/2021/Ana Elizabeth Ayala Leyva.png';
import ANAKARINAROJOPIMENTEL2021 from '../assets/img/congress_winners/2021/Ana Karina Rojo Pimentel.png';
import ANALAURABERNALCAMARENA2021 from '../assets/img/congress_winners/2021/Ana Laura Bernal Camarena.png';
import ANALAURAHUERTAVALDOVINOS2021 from '../assets/img/congress_winners/2021/Ana Laura Huerta Valdovinos.png';
import ANALAURASANCHEZVELAZQUEZ2021 from '../assets/img/congress_winners/2021/Ana Laura Sanchez Velazquez.png';
import ANALILIAHERRERAANZALDO2021 from '../assets/img/congress_winners/2021/Ana Lilia Herrera Anzaldo.png';
import ANAMARIABALDERASTREJO2021 from '../assets/img/congress_winners/2021/Ana Maria Balderas Trejo.png';
import ANAMARIAESQUIVELARRONA2021 from '../assets/img/congress_winners/2021/Ana Maria Esquivel Arrona.png';
import ANATERESAARANDAOROZCO2021 from '../assets/img/congress_winners/2021/Ana Teresa Aranda Orozco.png';
import ANDRESMAURICIOCANTURAMIREZ2021 from '../assets/img/congress_winners/2021/Andres Mauricio Cantu Ramirez.png';
import ANDRESPINTOSCABALLERO2021 from '../assets/img/congress_winners/2021/Andres Pintos Caballero.png';
import ANGELBENJAMINROBLESMONTOYA2021 from '../assets/img/congress_winners/2021/Angel Benjamin Robles Montoya.png';
import ANGELDOMINGUEZESCOBAR2021 from '../assets/img/congress_winners/2021/Angel Dominguez Escobar.png';
import ANGELICAPENAMARTINEZ2021 from '../assets/img/congress_winners/2021/Angelica Pena Martinez.png';
import ANNIASARAHIGOMEZCARDENAS2021 from '../assets/img/congress_winners/2021/Annia Sarahi Gomez Cardenas.png';
import ANTOLINGUERREROMARQUEZ2021 from '../assets/img/congress_winners/2021/Antolin Guerrero Marquez.png';
import ANTONIODEJESUSRAMIREZRAMOS2021 from '../assets/img/congress_winners/2021/Antonio De Jesus Ramirez Ramos.png';
import ARACELICELESTINOROSAS2021 from '../assets/img/congress_winners/2021/Araceli Celestino Rosas.png';
import ARACELIOCAMPOMANZANARES2021 from '../assets/img/congress_winners/2021/Araceli Ocampo Manzanares.png';
import ARMANDOANTONIOGOMEZBETANCOURT2021 from '../assets/img/congress_winners/2021/Armando Antonio Gomez Betancourt.png';
import ARMANDOCORONAARVIZU2021 from '../assets/img/congress_winners/2021/Armando Corona Arvizu.png';
import ARMANDOREYESLEDESMA2021 from '../assets/img/congress_winners/2021/Armando Reyes Ledesma.png';
import ARTUROROBERTOHERNANDEZTAPIA2021 from '../assets/img/congress_winners/2021/Arturo Roberto Hernandez Tapia.png';
import AUGUSTOPEREZHERNANDEZ2021 from '../assets/img/congress_winners/2021/Augusto Perez Hernandez.png';
import AZAELSANTIAGOCHEPI2021 from '../assets/img/congress_winners/2021/Azael Santiago Chepi.png';
import BEATRIZDOMINGAPEREZLOPEZ2021 from '../assets/img/congress_winners/2021/Beatriz Dominga Perez Lopez.png';
import BEATRIZROJASMARTINEZ2021 from '../assets/img/congress_winners/2021/Beatriz Rojas Martinez.png';
import BENNELLYJOCABETHHERNANDEZRUEDAS2021 from '../assets/img/congress_winners/2021/Bennelly Jocabeth Hernandez Ruedas.png';
import BERENICEJUAREZNAVARRETE2021 from '../assets/img/congress_winners/2021/Berenice Juarez Navarrete.png';
import BERENICEMONTESESTRADA2021 from '../assets/img/congress_winners/2021/Berenice Montes Estrada.png';
import BRENDAESPINOZALOPEZ2021 from '../assets/img/congress_winners/2021/Brenda Espinoza Lopez.png';
import BRIGIDORAMIROMORENOHERNANDEZ2021 from '../assets/img/congress_winners/2021/Brigido Ramiro Moreno Hernandez.png';
import BRUNOBLANCASMERCADO2021 from '../assets/img/congress_winners/2021/Bruno Blancas Mercado.png';
import CARLOSALBERTOMANZORODRIGUEZ2021 from '../assets/img/congress_winners/2021/Carlos Alberto Manzo Rodriguez.png';
import CARLOSHUMBERTOQUINTANAMARTINEZ2021 from '../assets/img/congress_winners/2021/Carlos Humberto Quintana Martinez.png';
import CARLOSLOPEZGUADARRAMA2021 from '../assets/img/congress_winners/2021/Carlos Lopez Guadarrama.png';
import CARLOSMADRAZOLIMON2021 from '../assets/img/congress_winners/2021/Carlos Madrazo Limon.png';
import CARLOSSANCHEZBARRIOS2021 from '../assets/img/congress_winners/2021/Carlos Sanchez Barrios.png';
import CARMENROCIOGONZALEZALONSO2021 from '../assets/img/congress_winners/2021/Carmen Rocio Gonzalez Alonso.png';
import CAROLANTONIOALTAMIRANO2021 from '../assets/img/congress_winners/2021/Carol Antonio Altamirano.png';
import CAROLINABEAUREGARDMARTINEZ2021 from '../assets/img/congress_winners/2021/Carolina Beauregard Martinez.png';
import CAROLINADAVILARAMIREZ2021 from '../assets/img/congress_winners/2021/Carolina Davila Ramirez.png';
import CASIMIROZAMORAVALDEZ2021 from '../assets/img/congress_winners/2021/Casimiro Zamora Valdez.png';
import CECILIAANUNCIACIONPATRONLAVIADA2021 from '../assets/img/congress_winners/2021/Cecilia Anunciacion Patron Laviada.png';
import CESARAGUSTINHERNANDEZPEREZ2021 from '../assets/img/congress_winners/2021/Cesar Agustin Hernandez Perez.png';
import CHRISTIANJOAQUINSANCHEZSANCHEZ2021 from '../assets/img/congress_winners/2021/Christian Joaquin Sanchez Sanchez.png';
import CIRIAYAMILESALOMONDURAN2021 from '../assets/img/congress_winners/2021/Ciria Yamile Salomon Duran.png';
import CLAUDIAALEJANDRAHERNANDEZSAENZ2021 from '../assets/img/congress_winners/2021/Claudia Alejandra Hernandez Saenz.png';
import CLAUDIADELGADILLOGONZALEZ2021 from '../assets/img/congress_winners/2021/Claudia Delgadillo Gonzalez.png';
import CLAUDIAGABRIELAOLVERAHIGUERA2021 from '../assets/img/congress_winners/2021/Claudia Gabriela Olvera Higuera.png';
import CLAUDIATELLOESPINOSA2021 from '../assets/img/congress_winners/2021/Claudia Tello Espinosa.png';
import CONSUELODELCARMENNAVARRETENAVARRO2021 from '../assets/img/congress_winners/2021/Consuelo Del Carmen Navarrete Navarro.png';
import CRISTINAAMEZCUAGONZALEZ2021 from '../assets/img/congress_winners/2021/Cristina Amezcua Gonzalez.png';
import CUAUHTEMOCOCHOAFERNANDEZ2021 from '../assets/img/congress_winners/2021/Cuauhtemoc Ochoa Fernandez.png';
import DANIELGUTIERREZGUTIERREZ2021 from '../assets/img/congress_winners/2021/Daniel Gutierrez Gutierrez.png';
import DANIELMURGUIALARDIZABAL2021 from '../assets/img/congress_winners/2021/Daniel Murguia Lardizabal.png';
import DANIELASORAYAALVAREZHERNANDEZ2021 from '../assets/img/congress_winners/2021/Daniela Soraya Alvarez Hernandez.png';
import DESIDERIOTINAJEROROBLES2021 from '../assets/img/congress_winners/2021/Desiderio Tinajero Robles.png';
import DIANAMARIATERESALARACARREON2021 from '../assets/img/congress_winners/2021/Diana Maria Teresa Lara Carreon.png';
import DIONICIAVAZQUEZGARCIA2021 from '../assets/img/congress_winners/2021/Dionicia Vazquez Garcia.png';
import DULCEMARIACORINAVILLEGASGUARNEROS2021 from '../assets/img/congress_winners/2021/Dulce Maria Corina Villegas Guarneros.png';
import ECTORJAIMERAMIREZBARBA2021 from '../assets/img/congress_winners/2021/Ector Jaime Ramirez Barba.png';
import EDUARDOZARZOSASANCHEZ2021 from '../assets/img/congress_winners/2021/Eduardo Zarzosa Sanchez.png';
import EMMANUELREYESCARMONA2021 from '../assets/img/congress_winners/2021/Emmanuel Reyes Carmona.png';
import ENRIQUEGODINEZDELRIO2021 from '../assets/img/congress_winners/2021/Enrique Godinez Del Rio.png';
import ERASMOGONZALEZROBLEDO2021 from '../assets/img/congress_winners/2021/Erasmo Gonzalez Robledo.png';
import ERIKADELOSANGELESDIAZVILLALON2021 from '../assets/img/congress_winners/2021/Erika De Los Angeles Diaz Villalon.png';
import ERIKAVANESSADELCASTILLOIBARRA2021 from '../assets/img/congress_winners/2021/Erika Vanessa Del Castillo Ibarra.png';
import ESTEBANBAUTISTAHERNANDEZ2021 from '../assets/img/congress_winners/2021/Esteban Bautista Hernandez.png';
import ESTHERMANDUJANOTINAJERO2021 from '../assets/img/congress_winners/2021/Esther Mandujano Tinajero.png';
import ESTHERMARTINEZROMANO2021 from '../assets/img/congress_winners/2021/Esther Martinez Romano.png';
import EUNICEMONZONGARCIA2021 from '../assets/img/congress_winners/2021/Eunice Monzon Garcia.png';
import EVANGELINAMORENOGUERRA2021 from '../assets/img/congress_winners/2021/Evangelina Moreno Guerra.png';
import FABIOLARAFAELDIRCIO2021 from '../assets/img/congress_winners/2021/Fabiola Rafael Dircio.png';
import FATIMAALMENDRACRUZPELAEZ2021 from '../assets/img/congress_winners/2021/Fatima Almendra Cruz Pelaez.png';
import FAUSTOGALLARDOGARCIA2021 from '../assets/img/congress_winners/2021/Fausto Gallardo Garcia.png';
import FELIPEFERNANDOMACIASOLVERA2021 from '../assets/img/congress_winners/2021/Felipe Fernando Macias Olvera.png';
import FERNANDOTORRESGRACIANO2021 from '../assets/img/congress_winners/2021/Fernando Torres Graciano.png';
import FLORIVONEMORALESMIRANDA2021 from '../assets/img/congress_winners/2021/Flor Ivone Morales Miranda.png';
import FLORATANIACRUZSANTOS2021 from '../assets/img/congress_winners/2021/Flora Tania Cruz Santos.png';
import FRANCISCOFAVELAPENUNURI2021 from '../assets/img/congress_winners/2021/Francisco Favela Penunuri.png';
import FRANCISCOJAVIERBORREGOADAME2021 from '../assets/img/congress_winners/2021/Francisco Javier Borrego Adame.png';
import FRANCISCOJAVIERCASTRELLONGARZA2021 from '../assets/img/congress_winners/2021/Francisco Javier Castrellon Garza.png';
import FRANCISCOJAVIERHUACUSESQUIVEL2021 from '../assets/img/congress_winners/2021/Francisco Javier Huacus Esquivel.png';
import GABRIELRICARDOQUADRIDELATORRE2021 from '../assets/img/congress_winners/2021/Gabriel Ricardo Quadri De La Torre.png';
import GABRIELAMARTINEZESPINOZA2021 from '../assets/img/congress_winners/2021/Gabriela Martinez Espinoza.png';
import GABRIELASODIMIRANDA2021 from '../assets/img/congress_winners/2021/Gabriela Sodi Miranda.png';
import GILBERTOHERNANDEZVILLAFUERTE2021 from '../assets/img/congress_winners/2021/Gilberto Hernandez Villafuerte.png';
import GINAGERARDINACAMPUZANOGONZALEZ2021 from '../assets/img/congress_winners/2021/Gina Gerardina Campuzano Gonzalez.png';
import GRACIELASANCHEZORTIZ2021 from '../assets/img/congress_winners/2021/Graciela Sanchez Ortiz.png';
import GUSTAVOCONTRERASMONTES2021 from '../assets/img/congress_winners/2021/Gustavo Contreras Montes.png';
import GUSTAVOMACIASZAMBRANO2021 from '../assets/img/congress_winners/2021/Gustavo Macias Zambrano.png';
import HECTORIRENEOMARESCOSSIO2021 from '../assets/img/congress_winners/2021/Hector Ireneo Mares Cossio.png';
import HECTORISRAELCASTILLOOLIVARES2021 from '../assets/img/congress_winners/2021/Hector Israel Castillo Olivares.png';
import HECTORSAULTELLEZHERNANDEZ2021 from '../assets/img/congress_winners/2021/Hector Saul Tellez Hernandez.png';
import HERIBERTOMARCELOAGUILARCASTILLO2021 from '../assets/img/congress_winners/2021/Heriberto Marcelo Aguilar Castillo.png';
import HORACIOFERNANDEZCASTILLO2021 from '../assets/img/congress_winners/2021/Horacio Fernandez Castillo.png';
import IGNACIOLOYOLAVERA2021 from '../assets/img/congress_winners/2021/Ignacio Loyola Vera.png';
import INESPARRAJUAREZ2021 from '../assets/img/congress_winners/2021/Ines Parra Juarez.png';
import IRMAJUANCARLOS2021 from '../assets/img/congress_winners/2021/Irma Juan Carlos.png';
import IRMAYORDANAGARAYLOREDO2021 from '../assets/img/congress_winners/2021/Irma Yordana Garay Loredo.png';
import ISMAELBRITOMAZARIEGOS2021 from '../assets/img/congress_winners/2021/Ismael Brito Mazariegos.png';
import ITZELALELIDOMINGUEZZOPIYACTLE2021 from '../assets/img/congress_winners/2021/Itzel Aleli Dominguez Zopiyactle.png';
import ITZELJOSEFINABALDERASHERNANDEZ2021 from '../assets/img/congress_winners/2021/Itzel Josefina Balderas Hernandez.png';
import IVANARTURORODRIGUEZRIVERA2021 from '../assets/img/congress_winners/2021/Ivan Arturo Rodriguez Rivera.png';
import JAIMEBUENOZERTUCHE2021 from '../assets/img/congress_winners/2021/Jaime Bueno Zertuche.png';
import JAIMEHUMBERTOPEREZBERNABE2021 from '../assets/img/congress_winners/2021/Jaime Humberto Perez Bernabe.png';
import JANICIECONTRERASGARCIA2021 from '../assets/img/congress_winners/2021/Janicie Contreras Garcia.png';
import JASMINEMARIABUGARINRODRIGUEZ2021 from '../assets/img/congress_winners/2021/Jasmine Maria Bugarin Rodriguez.png';
import JAVIERGONZALEZZEPEDA2021 from '../assets/img/congress_winners/2021/Javier Gonzalez Zepeda.png';
import JAVIERHUERTAJURADO2021 from '../assets/img/congress_winners/2021/Javier Huerta Jurado.png';
import JAZMINJAIMESALBARRAN2021 from '../assets/img/congress_winners/2021/Jazmin Jaimes Albarran.png';
import JESUSFERNANDOGARCIAHERNANDEZ2021 from '../assets/img/congress_winners/2021/Jesus Fernando Garcia Hernandez.png';
import JOANNAALEJANDRAFELIPETORRES2021 from '../assets/img/congress_winners/2021/Joanna Alejandra Felipe Torres.png';
import JOAQUINZEBADUAALVA2021 from '../assets/img/congress_winners/2021/Joaquin Zebadua Alva.png';
import JORGEALBERTOBARRERATOLEDO2021 from '../assets/img/congress_winners/2021/Jorge Alberto Barrera Toledo.png';
import JORGEALBERTOROMEROVAZQUEZ2021 from '../assets/img/congress_winners/2021/Jorge Alberto Romero Vazquez.png';
import JORGEARMANDOORTIZRODRIGUEZ2021 from '../assets/img/congress_winners/2021/Jorge Armando Ortiz Rodriguez.png';
import JORGEARTUROESPADASGALVAN2021 from '../assets/img/congress_winners/2021/Jorge Arturo Espadas Galvan.png';
import JORGELUISLLAVENABARCA2021 from '../assets/img/congress_winners/2021/Jorge Luis Llaven Abarca.png';
import JORGETRIANATENA2021 from '../assets/img/congress_winners/2021/Jorge Triana Tena.png';
import JOSEALEJANDROAGUILARLOPEZ2021 from '../assets/img/congress_winners/2021/Jose Alejandro Aguilar Lopez.png';
import JOSEANTONIOESTEFANGILLESSEN2021 from '../assets/img/congress_winners/2021/Jose Antonio Estefan Gillessen.png';
import JOSEANTONIOGARCIAGARCIA2021 from '../assets/img/congress_winners/2021/Jose Antonio Garcia Garcia.png';
import JOSEANTONIOGUTIERREZJARDON2021 from '../assets/img/congress_winners/2021/Jose Antonio Gutierrez Jardon.png';
import JOSEANTONIOZAPATAMERAZ2021 from '../assets/img/congress_winners/2021/Jose Antonio Zapata Meraz.png';
import JOSEFRANCISCOYUNESZORRILLA2021 from '../assets/img/congress_winners/2021/Jose Francisco Yunes Zorrilla.png';
import JOSEGERARDORODOLFOFERNANDEZNORONA2021 from '../assets/img/congress_winners/2021/Jose Gerardo Rodolfo Fernandez Norona.png';
import JOSEGUADALUPEAMBROCIOGACHUZ2021 from '../assets/img/congress_winners/2021/Jose Guadalupe Ambrocio Gachuz.png';
import JOSEGUADALUPEFLETESARAIZA2021 from '../assets/img/congress_winners/2021/Jose Guadalupe Fletes Araiza.png';
import JOSELUISELORZAFLORES2021 from '../assets/img/congress_winners/2021/Jose Luis Elorza Flores.png';
import JOSELUISFLORESPACHECO2021 from '../assets/img/congress_winners/2021/Jose Luis Flores Pacheco.png';
import JOSELUISGARZAOCHOA2021 from '../assets/img/congress_winners/2021/Jose Luis Garza Ochoa.png';
import JOSEMAUROGARZAMARIN2021 from '../assets/img/congress_winners/2021/Jose Mauro Garza Marin.png';
import JUANANGELBAUTISTABRAVO2021 from '../assets/img/congress_winners/2021/Juan Angel Bautista Bravo.png';
import JUANCARLOSROMEROHICKS2021 from '../assets/img/congress_winners/2021/Juan Carlos Romero Hicks.png';
import JUANFRANCISCOESPINOZAEGUIA2021 from '../assets/img/congress_winners/2021/Juan Francisco Espinoza Eguia.png';
import JUANISAIASBERTINSANDOVAL2021 from '../assets/img/congress_winners/2021/Juan Isaias Bertin Sandoval.png';
import JUANLUISCARRILLOSOBERANIS2021 from '../assets/img/congress_winners/2021/Juan Luis Carrillo Soberanis.png';
import JUANMANUELNAVARROMUNIZ2021 from '../assets/img/congress_winners/2021/Juan Manuel Navarro Muniz.png';
import JUANPABLOMONTESDEOCAAVENDANO2021 from '../assets/img/congress_winners/2021/Juan Pablo Montes De Oca Avendano.png';
import JUANPABLOSANCHEZRODRIGUEZ2021 from '../assets/img/congress_winners/2021/Juan Pablo Sanchez Rodriguez.png';
import JUANITAGUERRAMENA2021 from '../assets/img/congress_winners/2021/Juanita Guerra Mena.png';
import JULIETAANDREARAMIREZPADILLA2021 from '../assets/img/congress_winners/2021/Julieta Andrea Ramirez Padilla.png';
import JUSTINOEUGENIOARRIAGAROJAS2021 from '../assets/img/congress_winners/2021/Justino Eugenio Arriaga Rojas.png';
import KARENMICHELGONZALEZMARQUEZ2021 from '../assets/img/congress_winners/2021/Karen Michel Gonzalez Marquez.jpg';
import KARINAMARLENBARRONPERALES2021 from '../assets/img/congress_winners/2021/Karina Marlen Barron Perales.png';
import KARLAAYALAVILLALOBOS2021 from '../assets/img/congress_winners/2021/Karla Ayala Villalobos.png';
import KARLAMARIARABELOESTRADA2021 from '../assets/img/congress_winners/2021/Karla Maria Rabelo Estrada.png';
import KARLAYURITZIALMAZANBURGOS2021 from '../assets/img/congress_winners/2021/Karla Yuritzi Almazan Burgos.png';
import KEVINANGELOAGUILARPINA2021 from '../assets/img/congress_winners/2021/Kevin Angelo Aguilar Pina.png';
import KRISHNAKARINAROMEROVELAZQUEZ2021 from '../assets/img/congress_winners/2021/Krishna Karina Romero Velazquez.png';
import LAURAIMELDAPEREZSEGURA2021 from '../assets/img/congress_winners/2021/Laura Imelda Perez Segura.png';
import LAURALYNNFERNANDEZPINA2021 from '../assets/img/congress_winners/2021/Laura Lynn Fernandez Pina.png';
import LAURAPATRICIACONTRERASDUARTE2021 from '../assets/img/congress_winners/2021/Laura Patricia Contreras Duarte.png';
import LEOBARDOALCANTARAMARTINEZ2021 from '../assets/img/congress_winners/2021/Leobardo Alcantara Martinez.png';
import LEONELGODOYRANGEL2021 from '../assets/img/congress_winners/2021/Leonel Godoy Rangel.png';
import LIDIAGARCIAANAYA2021 from '../assets/img/congress_winners/2021/Lidia Garcia Anaya.png';
import LIDIAPEREZBARCENAS2021 from '../assets/img/congress_winners/2021/Lidia Perez Barcenas.png';
import LILIAAGUILARGIL2021 from '../assets/img/congress_winners/2021/Lilia Aguilar Gil.png';
import LORENAMENDEZDENIS2021 from '../assets/img/congress_winners/2021/Lorena Mendez Denis.png';
import LORENIAIVETHVALLESSAMPEDRO2021 from '../assets/img/congress_winners/2021/Lorenia Iveth Valles Sampedro.png';
import LUISALBERTOMENDOZAACEVEDO2021 from '../assets/img/congress_winners/2021/Luis Alberto Mendoza Acevedo.png';
import LUISARMANDOMELGARBRAVO2021 from '../assets/img/congress_winners/2021/Luis Armando Melgar Bravo.png';
import LUISEDGARDOPALACIOSDIAZ2021 from '../assets/img/congress_winners/2021/Luis Edgardo Palacios Diaz.png';
import LUISENRIQUEMARTINEZVENTURA2021 from '../assets/img/congress_winners/2021/Luis Enrique Martinez Ventura.png';
import LUZADRIANACANDELARIOFIGUEROA2021 from '../assets/img/congress_winners/2021/Luz Adriana Candelario Figueroa.png';
import MADEJESUSAGUIRREMALDONADO2021 from '../assets/img/congress_winners/2021/Ma De Jesus Aguirre Maldonado.png';
import MANUELDEJESUSBALDENEBROARREDONDO2021 from '../assets/img/congress_winners/2021/Manuel De Jesus Baldenebro Arredondo.png';
import MANUELDEJESUSNARCIACOUTINO2021 from '../assets/img/congress_winners/2021/Manuel De Jesus Narcia Coutino.png';
import MANUELJESUSHERRERAVEGA2021 from '../assets/img/congress_winners/2021/Manuel Jesus Herrera Vega.png';
import MANUELRODRIGUEZGONZALEZ2021 from '../assets/img/congress_winners/2021/Manuel Rodriguez Gonzalez.png';
import MANUELADELCARMENOBRADORNARVAEZ2021 from '../assets/img/congress_winners/2021/Manuela Del Carmen Obrador Narvaez.png';
import MARCELAGUERRACASTILLO2021 from '../assets/img/congress_winners/2021/Marcela Guerra Castillo.png';
import MARCELINOCASTANEDANAVARRETE2021 from '../assets/img/congress_winners/2021/Marcelino Castaneda Navarrete.png';
import MARCIASOLORZANOGALLEGO2021 from '../assets/img/congress_winners/2021/Marcia Solorzano Gallego.png';
import MARCOANTONIOALMENDARIZPUPPO2021 from '../assets/img/congress_winners/2021/Marco Antonio Almendariz Puppo.png';
import MARCOANTONIONATALEGUTIERREZ2021 from '../assets/img/congress_winners/2021/Marco Antonio Natale Gutierrez.png';
import MARCOHUMBERTOAGUILARCORONADO2021 from '../assets/img/congress_winners/2021/Marco Humberto Aguilar Coronado.png';
import MARCOSROSENDOMEDINAFILIGRANA2021 from '../assets/img/congress_winners/2021/Marcos Rosendo Medina Filigrana.png';
import MARGARITAESTERZAVALAGOMEZDELCAMPO2021 from '../assets/img/congress_winners/2021/Margarita Ester Zavala Gomez Del Campo.png';
import MARGARITAGARCIAGARCIA2021 from '../assets/img/congress_winners/2021/Margarita Garcia Garcia.png';
import MARIAASENCIONALVAREZSOLIS2021 from '../assets/img/congress_winners/2021/Maria Asencion Alvarez Solis.png';
import MARIABERTHAESPINOZASEGURA2021 from '../assets/img/congress_winners/2021/Maria Bertha Espinoza Segura.png';
import MARIADEJESUSROSETESANCHEZ2021 from '../assets/img/congress_winners/2021/Maria De Jesus Rosete Sanchez.png';
import MARIADELOSANGELESGUTIERREZVALDEZ2021 from '../assets/img/congress_winners/2021/Maria De Los Angeles Gutierrez Valdez.png';
import MARIADELCARMENBAUTISTAPELAEZ2021 from '../assets/img/congress_winners/2021/Maria Del Carmen Bautista Pelaez.png';
import MARIADELCARMENPINETEVARGAS2021 from '../assets/img/congress_winners/2021/Maria Del Carmen Pinete Vargas.png';
import MARIADELREFUGIOCAMARENAJAUREGUI2021 from '../assets/img/congress_winners/2021/Maria Del Refugio Camarena Jauregui.png';
import MARIADELROCIOBANQUELLSNUNEZ2021 from '../assets/img/congress_winners/2021/Maria Del Rocio Banquells Nunez.png';
import MARIADELROCIOCORONANAKAMURA2021 from '../assets/img/congress_winners/2021/Maria Del Rocio Corona Nakamura.png';
import MARIADELROSARIOMERLINGARCIA2021 from '../assets/img/congress_winners/2021/Maria Del Rosario Merlin Garcia.png';
import MARIAEUGENIAHERNANDEZPEREZ2021 from '../assets/img/congress_winners/2021/Maria Eugenia Hernandez Perez.png';
import MARIAGUADALUPECHAVIRADELAROSA2021 from '../assets/img/congress_winners/2021/Maria Guadalupe Chavira De La Rosa.png';
import MARIAGUADALUPEROMANAVILA2021 from '../assets/img/congress_winners/2021/Maria Guadalupe Roman Avila.png';
import MARIAISABELALFAROMORALES2021 from '../assets/img/congress_winners/2021/Maria Isabel Alfaro Morales.png';
import MARIAJOSEFINAGAMBOATORALES2021 from '../assets/img/congress_winners/2021/Maria Josefina Gamboa Torales.png';
import MARIALETICIACHAVEZPEREZ2021 from '../assets/img/congress_winners/2021/Maria Leticia Chavez Perez.png';
import MARIAMACARENACHAVEZFLORES2021 from '../assets/img/congress_winners/2021/Maria Macarena Chavez Flores.png';
import MARIASIERRADAMIAN2021 from '../assets/img/congress_winners/2021/Maria Sierra Damian.png';
import MARIATERESACASTELLDEOROPALACIOS2021 from '../assets/img/congress_winners/2021/Maria Teresa Castell De Oro Palacios.png';
import MARIAWENDYBRICENOZULOAGA2021 from '../assets/img/congress_winners/2021/Maria Wendy Briceno Zuloaga.png';
import MARIBELAGUILERACHAIREZ2021 from '../assets/img/congress_winners/2021/Maribel Aguilera Chairez.png';
import MARIBELGUADALUPEVILLASENORDAVILA2021 from '../assets/img/congress_winners/2021/Maribel Guadalupe Villasenor Davila.png';
import MARIOGERARDORIESTRAPINA2021 from '../assets/img/congress_winners/2021/Mario Gerardo Riestra Pina.png';
import MARIOMATACARRASCO2021 from '../assets/img/congress_winners/2021/Mario Mata Carrasco.png';
import MARIOMIGUELCARRILLOCUBILLAS2021 from '../assets/img/congress_winners/2021/Mario Miguel Carrillo Cubillas.png';
import MARIORAFAELLLERGOLATOURNERIE2021 from '../assets/img/congress_winners/2021/Mario Rafael Llergo Latournerie.png';
import MARIOXAVIERPERAZARAMIREZ2021 from '../assets/img/congress_winners/2021/Mario Xavier Peraza Ramirez.png';
import MARISELAGARDUNOGARDUNO2021 from '../assets/img/congress_winners/2021/Marisela Garduno Garduno.png';
import MARTHAAZUCENACAMACHOREYNOSO2021 from '../assets/img/congress_winners/2021/Martha Azucena Camacho Reynoso.png';
import MARTHAESTELAROMOCUELLAR2021 from '../assets/img/congress_winners/2021/Martha Estela Romo Cuellar.png';
import MARTHAROBLESORTIZ2021 from '../assets/img/congress_winners/2021/Martha Robles Ortiz.png';
import MARTHAROSAMORALESROMERO2021 from '../assets/img/congress_winners/2021/Martha Rosa Morales Romero.png';
import MARYCARMENBERNALMARTINEZ2021 from '../assets/img/congress_winners/2021/Mary Carmen Bernal Martinez.png';
import MAURICIOALONSOTOLEDOGUTIERREZ2021 from '../assets/img/congress_winners/2021/Mauricio Alonso Toledo Gutierrez.png';
import MAURICIOPRIETOGOMEZ2021 from '../assets/img/congress_winners/2021/Mauricio Prieto Gomez.png';
import MELISSAESTEFANIAVARGASCAMACHO2021 from '../assets/img/congress_winners/2021/Melissa Estefania Vargas Camacho.png';
import MERARYVILLEGASSANCHEZ2021 from '../assets/img/congress_winners/2021/Merary Villegas Sanchez.png';
import MIGUELANGELVARELAPINEDO2021 from '../assets/img/congress_winners/2021/Miguel Angel Varela Pinedo.png';
import MIGUELPAVELJAREROVELAZQUEZ2021 from '../assets/img/congress_winners/2021/Miguel Pavel Jarero Velazquez.png';
import MIGUELSAMANOPERALTA2021 from '../assets/img/congress_winners/2021/Miguel Samano Peralta.png';
import MONICABECERRAMORENO2021 from '../assets/img/congress_winners/2021/Monica Becerra Moreno.png';
import MONICAHERRERAVILLAVICENCIO2021 from '../assets/img/congress_winners/2021/Monica Herrera Villavicencio.png';
import NANCYYADIRASANTIAGOMARCOS2021 from '../assets/img/congress_winners/2021/Nancy Yadira Santiago Marcos.png';
import NAVORALBERTOROJASMANCERA2021 from '../assets/img/congress_winners/2021/Navor Alberto Rojas Mancera.png';
import NELLYMACEDACARRERA2021 from '../assets/img/congress_winners/2021/Nelly Maceda Carrera.png';
import NELLYMINERVACARRASCOGODINEZ2021 from '../assets/img/congress_winners/2021/Nelly Minerva Carrasco Godinez.png';
import NOELMATAATILANO2021 from '../assets/img/congress_winners/2021/Noel Mata Atilano.png';
import ODETTENAYERIALMAZANMUNOZ2021 from '../assets/img/congress_winners/2021/Odette Nayeri Almazan Munoz.png';
import OLEGARIACARRAZCOMACIAS2021 from '../assets/img/congress_winners/2021/Olegaria Carrazco Macias.png';
import OLGAJULIANAELIZONDOGUERRA2021 from '../assets/img/congress_winners/2021/Olga Juliana Elizondo Guerra.png';
import OLIMPIATAMARAGIRONHERNANDEZ2021 from '../assets/img/congress_winners/2021/Olimpia Tamara Giron Hernandez.png';
import OMARENRIQUECASTANEDAGONZALEZ2021 from '../assets/img/congress_winners/2021/Omar Enrique Castaneda Gonzalez.png';
import OSCARDEJESUSALMARAZSMER2021 from '../assets/img/congress_winners/2021/Oscar De Jesus Almaraz Smer.png';
import OSCAREUGENIOGUTIERREZCAMACHO2021 from '../assets/img/congress_winners/2021/Oscar Eugenio Gutierrez Camacho.png';
import OSCARGUSTAVOCARDENASMONROY2021 from '../assets/img/congress_winners/2021/Oscar Gustavo Cardenas Monroy.png';
import PABLOAMILCARSANDOVALBALLESTEROS2021 from '../assets/img/congress_winners/2021/Pablo Amilcar Sandoval Ballesteros.png';
import PAOLATENORIOADAME2021 from '../assets/img/congress_winners/2021/Paola Tenorio Adame.png';
import PATRICIATERRAZASBACA2021 from '../assets/img/congress_winners/2021/Patricia Terrazas Baca.png';
import PAULOGONZALOMARTINEZLOPEZ2021 from '../assets/img/congress_winners/2021/Paulo Gonzalo Martinez Lopez.png';
import PEDRODAVIDORTEGAFONSECA2021 from '../assets/img/congress_winners/2021/Pedro David Ortega Fonseca.png';
import PEDROGARZATREVINO2021 from '../assets/img/congress_winners/2021/Pedro Garza Trevino.png';
import PEDROSALGADOALMAGUER2021 from '../assets/img/congress_winners/2021/Pedro Salgado Almaguer.png';
import RAFAELHERNANDEZVILLALPANDO2021 from '../assets/img/congress_winners/2021/Rafael Hernandez Villalpando.png';
import RAQUELBONILLAHERRERA2021 from '../assets/img/congress_winners/2021/Raquel Bonilla Herrera.png';
import RAYMUNDOATANACIOLUNA2021 from '../assets/img/congress_winners/2021/Raymundo Atanacio Luna.png';
import REYNELRODRIGUEZMUNOZ2021 from '../assets/img/congress_winners/2021/Reynel Rodriguez Munoz.png';
import RICARDOVILLARREALGARCIA2021 from '../assets/img/congress_winners/2021/Ricardo Villarreal Garcia.png';
import RIULTRIVERAGUTIERREZ2021 from '../assets/img/congress_winners/2021/Riult Rivera Gutierrez.png';
import ROBERTOALEJANDROSEGOVIAHERNANDEZ2021 from '../assets/img/congress_winners/2021/Roberto Alejandro Segovia Hernandez.png';
import ROBERTOANGELDOMINGUEZRODRIGUEZ2021 from '../assets/img/congress_winners/2021/Roberto Angel Dominguez Rodriguez.png';
import ROBERTOANTONIORUBIOMONTEJO2021 from '../assets/img/congress_winners/2021/Roberto Antonio Rubio Montejo.png';
import ROBERTOCARLOSLOPEZGARCIA2021 from '../assets/img/congress_winners/2021/Roberto Carlos Lopez Garcia.png';
import RODRIGOSANCHEZZEPEDA2021 from '../assets/img/congress_winners/2021/Rodrigo Sanchez Zepeda.png';
import ROMMELAGHMEDPACHECOMARRUFO2021 from '../assets/img/congress_winners/2021/Rommel Aghmed Pacheco Marrufo.png';
import ROSAHERNANDEZESPEJO2021 from '../assets/img/congress_winners/2021/Rosa Hernandez Espejo.png';
import ROSAMARIABAYARDOCABRERA2021 from '../assets/img/congress_winners/2021/Rosa Maria Bayardo Cabrera.png';
import ROSAMARIAGONZALEZAZCARRAGA2021 from '../assets/img/congress_winners/2021/Rosa Maria Gonzalez Azcarraga.png';
import ROSALBAVALENCIACRUZ2021 from '../assets/img/congress_winners/2021/Rosalba Valencia Cruz.png';
import SANDRASIMEYOLVERABAUTISTA2021 from '../assets/img/congress_winners/2021/Sandra Simey Olvera Bautista.png';
import SARAINUNEZCERON2021 from '../assets/img/congress_winners/2021/Sarai Nunez Ceron.png';
import SAYONARAVARGASRODRIGUEZ2021 from '../assets/img/congress_winners/2021/Sayonara Vargas Rodriguez.png';
import SERGIOBARRERASEPULVEDA2021 from '../assets/img/congress_winners/2021/Sergio Barrera Sepulveda.png';
import SERGIOENRRIQUECHALECAUICH2021 from '../assets/img/congress_winners/2021/Sergio Enrrique Chale Cauich.png';
import SHAMIRFERNANDEZHERNANDEZ2021 from '../assets/img/congress_winners/2021/Shamir Fernandez Hernandez.png';
import SHIRLEYGUADALUPEVAZQUEZROMERO2021 from '../assets/img/congress_winners/2021/Shirley Guadalupe Vazquez Romero.png';
import SOCORROIRMAANDAZOLAGOMEZ2021 from '../assets/img/congress_winners/2021/Socorro Irma Andazola Gomez.png';
import SONIAROCHAACOSTA2021 from '../assets/img/congress_winners/2021/Sonia Rocha Acosta.png';
import SUEELLENBERNALBOLNIK2021 from '../assets/img/congress_winners/2021/Sue Ellen Bernal Bolnik.png';
import TAYGETEIRISAYRODRIGUEZGONZALEZ2021 from '../assets/img/congress_winners/2021/Taygete Irisay Rodriguez Gonzalez.png';
import TERESITADEJESUSVARGASMERAZ2021 from '../assets/img/congress_winners/2021/Teresita De Jesus Vargas Meraz.png';
import TOMASGLORIAREQUENA2021 from '../assets/img/congress_winners/2021/Tomas Gloria Requena.png';
import VALENTINREYESLOPEZ2021 from '../assets/img/congress_winners/2021/Valentin Reyes Lopez.png';
import VICENTEALBERTOONOFREVAZQUEZ2021 from '../assets/img/congress_winners/2021/Vicente Alberto Onofre Vazquez.png';
import VICENTEJAVIERVERASTEGUIOSTOS2021 from '../assets/img/congress_winners/2021/Vicente Javier Verastegui Ostos.png';
import VICTORGABRIELVARELALOPEZ2021 from '../assets/img/congress_winners/2021/Victor Gabriel Varela Lopez.png';
import VICTORIANOWENCESREAL2021 from '../assets/img/congress_winners/2021/Victoriano Wences Real.png';
import WENDYGONZALEZURRUTIA2021 from '../assets/img/congress_winners/2021/Wendy Gonzalez Urrutia.png';
import WENDYMARICELACORDEROGONZALEZ2021 from '../assets/img/congress_winners/2021/Wendy Maricela Cordero Gonzalez.png';
import WILBERTALBERTOBATUNCHULIM2021 from '../assets/img/congress_winners/2021/Wilbert Alberto Batun Chulim.png';
import XAVIERGONZALEZZIRION2021 from '../assets/img/congress_winners/2021/Xavier Gonzalez Zirion.png';
import YEIMIYAZMINAGUILARCIFUENTES2021 from '../assets/img/congress_winners/2021/Yeimi Yazmin Aguilar Cifuentes.png';
import YERICOABRAMOMASSO2021 from '../assets/img/congress_winners/2021/Yerico Abramo Masso.png';
import YESSENIALETICIAOLUAGONZALEZ2021 from '../assets/img/congress_winners/2021/Yessenia Leticia Olua Gonzalez.png';

const CongressWinners = {  
    ADELARAMOSJUAREZ2021,
    ADRIANABUSTAMANTECASTELLANOS2021,
    ADRIANACAMPOSHUIRACHE2021,
    ADRIANALOZANORODRIGUEZ2021,
    ALANCASTELLANOSRAMIREZ2021,
    ALBERTOVILLAVILLEGAS2021,
    ALEIDAALAVEZRUIZ2021,
    ALEJANDRAPANIBARRAGAN2021,
    ALEJANDROCARVAJALHIDALGO2021,
    ALFREDOFEMATBANUELOS2021,
    ALFREDOPORRASDOMINGUEZ2021,
    ALFREDOVAZQUEZVAZQUEZ2021,
    ALISAYURINUNEZMENESES2021,
    ALMAANAHIGONZALEZHERNANDEZ2021,
    ALMADELIANAVARRETERIVERA2021,
    ANAELIZABETHAYALALEYVA2021,
    ANAKARINAROJOPIMENTEL2021,
    ANALAURABERNALCAMARENA2021,
    ANALAURAHUERTAVALDOVINOS2021,
    ANALAURASANCHEZVELAZQUEZ2021,
    ANALILIAHERRERAANZALDO2021,
    ANAMARIABALDERASTREJO2021,
    ANAMARIAESQUIVELARRONA2021,
    ANATERESAARANDAOROZCO2021,
    ANDRESMAURICIOCANTURAMIREZ2021,
    ANDRESPINTOSCABALLERO2021,
    ANGELBENJAMINROBLESMONTOYA2021,
    ANGELDOMINGUEZESCOBAR2021,
    ANGELICAPENAMARTINEZ2021,
    ANNIASARAHIGOMEZCARDENAS2021,
    ANTOLINGUERREROMARQUEZ2021,
    ANTONIODEJESUSRAMIREZRAMOS2021,
    ARACELICELESTINOROSAS2021,
    ARACELIOCAMPOMANZANARES2021,
    ARMANDOANTONIOGOMEZBETANCOURT2021,
    ARMANDOCORONAARVIZU2021,
    ARMANDOREYESLEDESMA2021,
    ARTUROROBERTOHERNANDEZTAPIA2021,
    AUGUSTOPEREZHERNANDEZ2021,
    AZAELSANTIAGOCHEPI2021,
    BEATRIZDOMINGAPEREZLOPEZ2021,
    BEATRIZROJASMARTINEZ2021,
    BENNELLYJOCABETHHERNANDEZRUEDAS2021,
    BERENICEJUAREZNAVARRETE2021,
    BERENICEMONTESESTRADA2021,
    BRENDAESPINOZALOPEZ2021,
    BRIGIDORAMIROMORENOHERNANDEZ2021,
    BRUNOBLANCASMERCADO2021,
    CARLOSALBERTOMANZORODRIGUEZ2021,
    CARLOSHUMBERTOQUINTANAMARTINEZ2021,
    CARLOSLOPEZGUADARRAMA2021,
    CARLOSMADRAZOLIMON2021,
    CARLOSSANCHEZBARRIOS2021,
    CARMENROCIOGONZALEZALONSO2021,
    CAROLANTONIOALTAMIRANO2021,
    CAROLINABEAUREGARDMARTINEZ2021,
    CAROLINADAVILARAMIREZ2021,
    CASIMIROZAMORAVALDEZ2021,
    CECILIAANUNCIACIONPATRONLAVIADA2021,
    CESARAGUSTINHERNANDEZPEREZ2021,
    CHRISTIANJOAQUINSANCHEZSANCHEZ2021,
    CIRIAYAMILESALOMONDURAN2021,
    CLAUDIAALEJANDRAHERNANDEZSAENZ2021,
    CLAUDIADELGADILLOGONZALEZ2021,
    CLAUDIAGABRIELAOLVERAHIGUERA2021,
    CLAUDIATELLOESPINOSA2021,
    CONSUELODELCARMENNAVARRETENAVARRO2021,
    CRISTINAAMEZCUAGONZALEZ2021,
    CUAUHTEMOCOCHOAFERNANDEZ2021,
    DANIELGUTIERREZGUTIERREZ2021,
    DANIELMURGUIALARDIZABAL2021,
    DANIELASORAYAALVAREZHERNANDEZ2021,
    DESIDERIOTINAJEROROBLES2021,
    DIANAMARIATERESALARACARREON2021,
    DIONICIAVAZQUEZGARCIA2021,
    DULCEMARIACORINAVILLEGASGUARNEROS2021,
    ECTORJAIMERAMIREZBARBA2021,
    EDUARDOZARZOSASANCHEZ2021,
    EMMANUELREYESCARMONA2021,
    ENRIQUEGODINEZDELRIO2021,
    ERASMOGONZALEZROBLEDO2021,
    ERIKADELOSANGELESDIAZVILLALON2021,
    ERIKAVANESSADELCASTILLOIBARRA2021,
    ESTEBANBAUTISTAHERNANDEZ2021,
    ESTHERMANDUJANOTINAJERO2021,
    ESTHERMARTINEZROMANO2021,
    EUNICEMONZONGARCIA2021,
    EVANGELINAMORENOGUERRA2021,
    FABIOLARAFAELDIRCIO2021,
    FATIMAALMENDRACRUZPELAEZ2021,
    FAUSTOGALLARDOGARCIA2021,
    FELIPEFERNANDOMACIASOLVERA2021,
    FERNANDOTORRESGRACIANO2021,
    FLORIVONEMORALESMIRANDA2021,
    FLORATANIACRUZSANTOS2021,
    FRANCISCOFAVELAPENUNURI2021,
    FRANCISCOJAVIERBORREGOADAME2021,
    FRANCISCOJAVIERCASTRELLONGARZA2021,
    FRANCISCOJAVIERHUACUSESQUIVEL2021,
    GABRIELRICARDOQUADRIDELATORRE2021,
    GABRIELAMARTINEZESPINOZA2021,
    GABRIELASODIMIRANDA2021,
    GILBERTOHERNANDEZVILLAFUERTE2021,
    GINAGERARDINACAMPUZANOGONZALEZ2021,
    GRACIELASANCHEZORTIZ2021,
    GUSTAVOCONTRERASMONTES2021,
    GUSTAVOMACIASZAMBRANO2021,
    HECTORIRENEOMARESCOSSIO2021,
    HECTORISRAELCASTILLOOLIVARES2021,
    HECTORSAULTELLEZHERNANDEZ2021,
    HERIBERTOMARCELOAGUILARCASTILLO2021,
    HORACIOFERNANDEZCASTILLO2021,
    IGNACIOLOYOLAVERA2021,
    INESPARRAJUAREZ2021,
    IRMAJUANCARLOS2021,
    IRMAYORDANAGARAYLOREDO2021,
    ISMAELBRITOMAZARIEGOS2021,
    ITZELALELIDOMINGUEZZOPIYACTLE2021,
    ITZELJOSEFINABALDERASHERNANDEZ2021,
    IVANARTURORODRIGUEZRIVERA2021,
    JAIMEBUENOZERTUCHE2021,
    JAIMEHUMBERTOPEREZBERNABE2021,
    JANICIECONTRERASGARCIA2021,
    JASMINEMARIABUGARINRODRIGUEZ2021,
    JAVIERGONZALEZZEPEDA2021,
    JAVIERHUERTAJURADO2021,
    JAZMINJAIMESALBARRAN2021,
    JESUSFERNANDOGARCIAHERNANDEZ2021,
    JOANNAALEJANDRAFELIPETORRES2021,
    JOAQUINZEBADUAALVA2021,
    JORGEALBERTOBARRERATOLEDO2021,
    JORGEALBERTOROMEROVAZQUEZ2021,
    JORGEARMANDOORTIZRODRIGUEZ2021,
    JORGEARTUROESPADASGALVAN2021,
    JORGELUISLLAVENABARCA2021,
    JORGETRIANATENA2021,
    JOSEALEJANDROAGUILARLOPEZ2021,
    JOSEANTONIOESTEFANGILLESSEN2021,
    JOSEANTONIOGARCIAGARCIA2021,
    JOSEANTONIOGUTIERREZJARDON2021,
    JOSEANTONIOZAPATAMERAZ2021,
    JOSEFRANCISCOYUNESZORRILLA2021,
    JOSEGERARDORODOLFOFERNANDEZNORONA2021,
    JOSEGUADALUPEAMBROCIOGACHUZ2021,
    JOSEGUADALUPEFLETESARAIZA2021,
    JOSELUISELORZAFLORES2021,
    JOSELUISFLORESPACHECO2021,
    JOSELUISGARZAOCHOA2021,
    JOSEMAUROGARZAMARIN2021,
    JUANANGELBAUTISTABRAVO2021,
    JUANCARLOSROMEROHICKS2021,
    JUANFRANCISCOESPINOZAEGUIA2021,
    JUANISAIASBERTINSANDOVAL2021,
    JUANLUISCARRILLOSOBERANIS2021,
    JUANMANUELNAVARROMUNIZ2021,
    JUANPABLOMONTESDEOCAAVENDANO2021,
    JUANPABLOSANCHEZRODRIGUEZ2021,
    JUANITAGUERRAMENA2021,
    JULIETAANDREARAMIREZPADILLA2021,
    JUSTINOEUGENIOARRIAGAROJAS2021,
    KARENMICHELGONZALEZMARQUEZ2021,
    KARINAMARLENBARRONPERALES2021,
    KARLAAYALAVILLALOBOS2021,
    KARLAMARIARABELOESTRADA2021,
    KARLAYURITZIALMAZANBURGOS2021,
    KEVINANGELOAGUILARPINA2021,
    KRISHNAKARINAROMEROVELAZQUEZ2021,
    LAURAIMELDAPEREZSEGURA2021,
    LAURALYNNFERNANDEZPINA2021,
    LAURAPATRICIACONTRERASDUARTE2021,
    LEOBARDOALCANTARAMARTINEZ2021,
    LEONELGODOYRANGEL2021,
    LIDIAGARCIAANAYA2021,
    LIDIAPEREZBARCENAS2021,
    LILIAAGUILARGIL2021,
    LORENAMENDEZDENIS2021,
    LORENIAIVETHVALLESSAMPEDRO2021,
    LUISALBERTOMENDOZAACEVEDO2021,
    LUISARMANDOMELGARBRAVO2021,
    LUISEDGARDOPALACIOSDIAZ2021,
    LUISENRIQUEMARTINEZVENTURA2021,
    LUZADRIANACANDELARIOFIGUEROA2021,
    MADEJESUSAGUIRREMALDONADO2021,
    MANUELDEJESUSBALDENEBROARREDONDO2021,
    MANUELDEJESUSNARCIACOUTINO2021,
    MANUELJESUSHERRERAVEGA2021,
    MANUELRODRIGUEZGONZALEZ2021,
    MANUELADELCARMENOBRADORNARVAEZ2021,
    MARCELAGUERRACASTILLO2021,
    MARCELINOCASTANEDANAVARRETE2021,
    MARCIASOLORZANOGALLEGO2021,
    MARCOANTONIOALMENDARIZPUPPO2021,
    MARCOANTONIONATALEGUTIERREZ2021,
    MARCOHUMBERTOAGUILARCORONADO2021,
    MARCOSROSENDOMEDINAFILIGRANA2021,
    MARGARITAESTERZAVALAGOMEZDELCAMPO2021,
    MARGARITAGARCIAGARCIA2021,
    MARIAASENCIONALVAREZSOLIS2021,
    MARIABERTHAESPINOZASEGURA2021,
    MARIADEJESUSROSETESANCHEZ2021,
    MARIADELOSANGELESGUTIERREZVALDEZ2021,
    MARIADELCARMENBAUTISTAPELAEZ2021,
    MARIADELCARMENPINETEVARGAS2021,
    MARIADELREFUGIOCAMARENAJAUREGUI2021,
    MARIADELROCIOBANQUELLSNUNEZ2021,
    MARIADELROCIOCORONANAKAMURA2021,
    MARIADELROSARIOMERLINGARCIA2021,
    MARIAEUGENIAHERNANDEZPEREZ2021,
    MARIAGUADALUPECHAVIRADELAROSA2021,
    MARIAGUADALUPEROMANAVILA2021,
    MARIAISABELALFAROMORALES2021,
    MARIAJOSEFINAGAMBOATORALES2021,
    MARIALETICIACHAVEZPEREZ2021,
    MARIAMACARENACHAVEZFLORES2021,
    MARIASIERRADAMIAN2021,
    MARIATERESACASTELLDEOROPALACIOS2021,
    MARIAWENDYBRICENOZULOAGA2021,
    MARIBELAGUILERACHAIREZ2021,
    MARIBELGUADALUPEVILLASENORDAVILA2021,
    MARIOGERARDORIESTRAPINA2021,
    MARIOMATACARRASCO2021,
    MARIOMIGUELCARRILLOCUBILLAS2021,
    MARIORAFAELLLERGOLATOURNERIE2021,
    MARIOXAVIERPERAZARAMIREZ2021,
    MARISELAGARDUNOGARDUNO2021,
    MARTHAAZUCENACAMACHOREYNOSO2021,
    MARTHAESTELAROMOCUELLAR2021,
    MARTHAROBLESORTIZ2021,
    MARTHAROSAMORALESROMERO2021,
    MARYCARMENBERNALMARTINEZ2021,
    MAURICIOALONSOTOLEDOGUTIERREZ2021,
    MAURICIOPRIETOGOMEZ2021,
    MELISSAESTEFANIAVARGASCAMACHO2021,
    MERARYVILLEGASSANCHEZ2021,
    MIGUELANGELVARELAPINEDO2021,
    MIGUELPAVELJAREROVELAZQUEZ2021,
    MIGUELSAMANOPERALTA2021,
    MONICABECERRAMORENO2021,
    MONICAHERRERAVILLAVICENCIO2021,
    NANCYYADIRASANTIAGOMARCOS2021,
    NAVORALBERTOROJASMANCERA2021,
    NELLYMACEDACARRERA2021,
    NELLYMINERVACARRASCOGODINEZ2021,
    NOELMATAATILANO2021,
    ODETTENAYERIALMAZANMUNOZ2021,
    OLEGARIACARRAZCOMACIAS2021,
    OLGAJULIANAELIZONDOGUERRA2021,
    OLIMPIATAMARAGIRONHERNANDEZ2021,
    OMARENRIQUECASTANEDAGONZALEZ2021,
    OSCARDEJESUSALMARAZSMER2021,
    OSCAREUGENIOGUTIERREZCAMACHO2021,
    OSCARGUSTAVOCARDENASMONROY2021,
    PABLOAMILCARSANDOVALBALLESTEROS2021,
    PAOLATENORIOADAME2021,
    PATRICIATERRAZASBACA2021,
    PAULOGONZALOMARTINEZLOPEZ2021,
    PEDRODAVIDORTEGAFONSECA2021,
    PEDROGARZATREVINO2021,
    PEDROSALGADOALMAGUER2021,
    RAFAELHERNANDEZVILLALPANDO2021,
    RAQUELBONILLAHERRERA2021,
    RAYMUNDOATANACIOLUNA2021,
    REYNELRODRIGUEZMUNOZ2021,
    RICARDOVILLARREALGARCIA2021,
    RIULTRIVERAGUTIERREZ2021,
    ROBERTOALEJANDROSEGOVIAHERNANDEZ2021,
    ROBERTOANGELDOMINGUEZRODRIGUEZ2021,
    ROBERTOANTONIORUBIOMONTEJO2021,
    ROBERTOCARLOSLOPEZGARCIA2021,
    RODRIGOSANCHEZZEPEDA2021,
    ROMMELAGHMEDPACHECOMARRUFO2021,
    ROSAHERNANDEZESPEJO2021,
    ROSAMARIABAYARDOCABRERA2021,
    ROSAMARIAGONZALEZAZCARRAGA2021,
    ROSALBAVALENCIACRUZ2021,
    SANDRASIMEYOLVERABAUTISTA2021,
    SARAINUNEZCERON2021,
    SAYONARAVARGASRODRIGUEZ2021,
    SERGIOBARRERASEPULVEDA2021,
    SERGIOENRRIQUECHALECAUICH2021,
    SHAMIRFERNANDEZHERNANDEZ2021,
    SHIRLEYGUADALUPEVAZQUEZROMERO2021,
    SOCORROIRMAANDAZOLAGOMEZ2021,
    SONIAROCHAACOSTA2021,
    SUEELLENBERNALBOLNIK2021,
    TAYGETEIRISAYRODRIGUEZGONZALEZ2021,
    TERESITADEJESUSVARGASMERAZ2021,
    TOMASGLORIAREQUENA2021,
    VALENTINREYESLOPEZ2021,
    VICENTEALBERTOONOFREVAZQUEZ2021,
    VICENTEJAVIERVERASTEGUIOSTOS2021,
    VICTORGABRIELVARELALOPEZ2021,
    VICTORIANOWENCESREAL2021,
    WENDYGONZALEZURRUTIA2021,
    WENDYMARICELACORDEROGONZALEZ2021,
    WILBERTALBERTOBATUNCHULIM2021,
    XAVIERGONZALEZZIRION2021,
    YEIMIYAZMINAGUILARCIFUENTES2021,
    YERICOABRAMOMASSO2021,
    YESSENIALETICIAOLUAGONZALEZ2021
}

export default CongressWinners;