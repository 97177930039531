export const DefaultLng = -103.2039292301337;
export const DefaultLat = 23.9835081002835;
export const DefaultZoom =
  window.innerWidth > 3000 ? 5.98 : 4.91;
export const MaxBounds = window.innerWidth > 3000 ? {
  sw: [-125.13070993282211, 13.43659301258404],
  ne: [-79.37526402685046, 33.779188164791876]
} : {
  sw: [-125.13070993282221, 13.43659301258279],
  ne: [-80.59031553897086, 33.779188164790554]
};
