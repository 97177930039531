export const Urls = {
  "pres-2006": {
    mun: "mapbox://wilsonli.pres-mx-2006-mun-v2",
    "mun-centroids": "mapbox://wilsonli.pres-mx-2006-mun-centroids-v7",
    state: "mapbox://wilsonli.pres-mx-2006-state-v2"
  },
  "pres-2012": {
    mun: "mapbox://wilsonli.pres-mx-2012-mun-v3",
    "mun-centroids": "mapbox://wilsonli.pres-mx-2012-mun-centroids-v7",
    state: "mapbox://wilsonli.pres-mx-2012-state-v2"
  },
  "pres-2018": {
    mun: "mapbox://wilsonli.pres-mx-2018-mun-v3",
    "mun-centroids": "mapbox://wilsonli.pres-mx-2018-mun-centroids-v7",
    state: "mapbox://wilsonli.pres-mx-2018-state-v4"
  },
  "pres-2024": {
    mun: "mapbox://wilsonli.pres-mx-2024-mun-v7",
    "mun-centroids": "mapbox://wilsonli.pres-mx-2024-mun-centroids-v3",
    state: "mapbox://wilsonli.pres-mx-2024-state-v2"
  },
  "pres-nom-2024": {
    mun: "mapbox://wilsonli.pres-mx-nom-2024-mun",
    "mun-centroids": "mapbox://wilsonli.pres-mx-nom-2024-mun-centroids",
    state: "mapbox://wilsonli.pres-mx-nom-2024-state"
  },
  "cong-2009": {
    mun: "mapbox://wilsonli.cong-mx-2009-mun-v2",
    "mun-centroids": "mapbox://wilsonli.cong-mx-2009-mun-centroids-v3",
    dis: "mapbox://wilsonli.cong-mx-2009-dis-v5",
    "dis-centroids": "mapbox://wilsonli.cong-mx-2009-dis-centroids-v3",
    state: "mapbox://wilsonli.cong-mx-2009-state"
  },
  "cong-2015": {
    mun: "mapbox://wilsonli.cong-mx-2015-mun-v6",
    "mun-centroids": "mapbox://wilsonli.cong-mx-2015-mun-centroids-v5",
    dis: "mapbox://wilsonli.cong-mx-2015-dis-v5",
    "dis-centroids": "mapbox://wilsonli.cong-mx-2015-dis-centroids-v2",
    state: "mapbox://wilsonli.cong-mx-2015-state-v4"
  },
  "cong-2021": {
    mun: "mapbox://wilsonli.cong-mx-2021-mun-v10",
    "mun-centroids": "mapbox://wilsonli.cong-mx-2021-mun-centroids-v12",
    dis: "mapbox://wilsonli.cong-mx-2021-dis-v10",
    "dis-centroids": "mapbox://wilsonli.cong-mx-2021-dis-centroids-v2",
    state: "mapbox://wilsonli.cong-mx-2021-state-v2"
  },
  "sen-2024": {
    state: "mapbox://wilsonli.sen-mx-2024-state-v3"
  },
  "gov-post-2024": {
    state: "mapbox://wilsonli.gov-mx-post-2024-state-v6"
  },
  "gov-pre-2024": {
    state: "mapbox://wilsonli.gov-mx-post-2024-state-v6"
  },
  "gov-post-2023": {
    state: "mapbox://wilsonli.gov-mx-post-2023-state-v2"
  },
  "gov-post-2018": {
    state: "mapbox://wilsonli.gov-mx-post-2018-state-v3"
  },
  "gov-post-2012": {
    state: "mapbox://wilsonli.gov-mx-post-2012-state-v3"
  },
  "gov-post-2006": {
    state: "mapbox://wilsonli.gov-mx-post-2006-state"
  },
  "gov-post-2000": {
    state: "mapbox://wilsonli.gov-mx-post-2000-state"
  },  
  "gov-post-1994": {
    state: "mapbox://wilsonli.gov-mx-post-1994-state-v2"
  },
  "gov-women": {
    state: "mapbox://wilsonli.gov-mx-women-state-v2"
  },
  "gov-jal": {
    state: "mapbox://wilsonli.gov-mx-jal-state-v2",
    mun: "mapbox://wilsonli.gov-mx-jal-mun-v3",
    center: [-103.3473, 20.6752],
    zoom: window.innerWidth > 3000 ? 8 : 7
  },
  "gov-cdmx": {
    state: "mapbox://wilsonli.gov-mx-cdmx-state",
    mun: "mapbox://wilsonli.gov-mx-cdmx-mun",
    center: [-99.12766, 19.30847],
    zoom: window.innerWidth > 3000 ? 10.5 : 9.5
  },
  "gov-cdmx24": {
    state: "mapbox://wilsonli.gov-mx-cdmx24-state-v2",
    mun: "mapbox://wilsonli.gov-mx-cdmx24-mun-v3",
    center: [-99.12766, 19.30847],
    zoom: window.innerWidth > 3000 ? 10.5 : 9.5
  },
  "mayor-cdmx-2024": {
    state: "mapbox://wilsonli.mayor-mx-cdmx-2024-state",
    neighborhoods: "mapbox://wilsonli.mayor-mx-cdmx-2024-metro-v1",
    center: [-99.12766, 19.30847],
    zoom: window.innerWidth > 3000 ? 10 : 9
  },
  "mayor-cdmx-2021": {
    state: "mapbox://wilsonli.mayor-mx-cdmx-2021-state-v3",
    neighborhoods: "mapbox://wilsonli.mayor-mx-cdmx-2021-metro-v4",
    center: [-99.12766, 19.30847],
    zoom: window.innerWidth > 3000 ? 9 : 8
  },
  "mayor-cdmx-2018": {
    state: "mapbox://wilsonli.mayor-mx-cdmx-2018-state",
    neighborhoods: "mapbox://wilsonli.mayor-mx-cdmx-2018-metro-v3",
    center: [-99.12766, 19.30847],
    zoom: window.innerWidth > 3000 ? 9 : 8
  },
  "mayor-cdmx-2015": {
    state: "mapbox://wilsonli.mayor-mx-cdmx-2015-state",
    neighborhoods: "mapbox://wilsonli.mayor-mx-cdmx-2015-metro-v2",
    center: [-99.12766, 19.30847],
    zoom: window.innerWidth > 3000 ? 9 : 8
  },
  "mayor-mty-2024": {
    state: "mapbox://wilsonli.mayor-mx-mty-2024-state-v2",
    neighborhoods: "mapbox://wilsonli.mayor-mx-mty-2024-metro-v1",
    center: [-100.316116, 25.686613],
    zoom: window.innerWidth > 3000 ? 7.5 : 6.7
  },
  "mayor-mty-2021": {
    state: "mapbox://wilsonli.mayor-mx-mty-2021-state",
    neighborhoods: "mapbox://wilsonli.mayor-mx-mty-2021-metro-v2",
    center: [-100.316116, 25.686613],
    zoom: window.innerWidth > 3000 ? 7.5 : 6.7
  },
  "mayor-mty-2018": {
    state: "mapbox://wilsonli.mayor-mx-mty-2018-state",
    neighborhoods: "mapbox://wilsonli.mayor-mx-mty-2018-metro-v2",
    center: [-100.316116, 25.686613],
    zoom: window.innerWidth > 3000 ? 7.5 : 6.7
  },
  "mayor-mty-2015": {
    state: "mapbox://wilsonli.mayor-mx-mty-2015-state",
    neighborhoods: "mapbox://wilsonli.mayor-mx-mty-2015-metro-v3",
    center: [-100.316116, 25.686613],
    zoom: window.innerWidth > 3000 ? 7.5 : 6.7
  },
  "mayor-gdl-2024": {
    state: "mapbox://wilsonli.mayor-mx-gdl-2024-state-v2",
    neighborhoods: "mapbox://wilsonli.mayor-mx-gdl-2024-metro-v5",
    center: [-103.3473, 20.6752],
    zoom: window.innerWidth > 3000 ? 8 : 7
  },
  "mayor-gdl-2021": {
    state: "mapbox://wilsonli.mayor-mx-gdl-2021-state",
    neighborhoods: "mapbox://wilsonli.mayor-mx-gdl-2021-metro-v2",
    center: [-103.3473, 20.6752],
    zoom: window.innerWidth > 3000 ? 8 : 7
  },
  "mayor-gdl-2018": {
    state: "mapbox://wilsonli.mayor-mx-gdl-2018-state",
    neighborhoods: "mapbox://wilsonli.mayor-mx-gdl-2018-metro",
    center: [-103.3473, 20.6752],
    zoom: window.innerWidth > 3000 ? 8 : 7
  },
  "mayor-gdl-2015": {
    state: "mapbox://wilsonli.mayor-mx-gdl-2015-state",
    neighborhoods: "mapbox://wilsonli.mayor-mx-gdl-2015-metro",
    center: [-103.3473, 20.6752],
    zoom: window.innerWidth > 3000 ? 8 : 7
  }
};
