import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openModal: false,
  showBookmarks: false,
  showRaceboard: false,
  showRaceChart: false,
  showNationalResult: false,
  show2023Result: false,
  show2024Murders: false,
  showTelestrator: false,
  telestratorStroke: 4,
  telestratorColor: "red",
  showColorPicker: false,
  reverse: false,
  preload: false,
  preloaded: false,
  clickedMarker: false,
  stateLayerId: "",
  metroLayerId: "",
  munLayerId: "",
  disLayerId: "",
  markerLng: -103.1453,
  markerLat: 23.9005,
  reelected: 0,
  pre2024Winners: [],
  pre2024Neutrals: [],
  raceboard: {
    layer: "",
    state: "",
    stateId: "",
    mun: "",
    munId: "",
    reg: "",
    totalVote: "",
    nationalTotalVotes: "",
    turnoutPercent: "",
    nationalTurnoutPercent: "",
    colorFirstParty: false,
    tie: false,
    stateRaceYear: "",
    p1Party: "",
    p1Name: "",
    //p1Title: '',
    p1Vote: "",
    p1Percent: "",
    //p1Color: '',
    p1PartyN: "",
    p2Party: "",
    p2Name: "",
    //p2Title: '',
    p2Vote: "",
    p2Percent: "",
    //p2Color: '',
    p2PartyN: "",
    p3Party: "",
    p3Name: "",
    //p3Title: '',
    p3Vote: "",
    p3Percent: "",
    //p3Color: '',
    p3PartyN: "",
    p4Party: "",
    p4Name: "",
    //p4Title: '',
    p4Vote: "",
    p4Percent: "",
    //p4Color: '',
    p4PartyN: "",
    title: "",
    subtitle1: "",
    subtitle2: ""
  }
};

export const app = createSlice({
  name: "app",
  initialState,
  /**
   * Functions to change the app state
   */
  reducers: {
    setOpenModal: (state, { payload }) => {
      state.openModal = payload;
    },
    setShowBookmarks: (state, { payload }) => {
      state.showBookmarks = payload;
    },
    setShowRaceboard: (state, { payload }) => {
      state.showRaceboard = payload;
    },
    setShowRaceChart: (state, { payload }) => {
      state.showRaceChart = payload;
    },
    setShowNationalResult: (state, { payload }) => {
      state.showNationalResult = payload;
    },
    setShow2023Result: (state, { payload }) => {
      state.show2023Result = payload;
    },
    setShow2024Murders: (state, { payload }) => {
      state.show2024Murders = payload;
    },
    setShowTelestrator: (state, { payload }) => {
      state.showTelestrator = payload;
    },
    setTelestratorStroke: (state, { payload }) => {
      state.telestratorStroke = payload;
    },
    setTelestratorColor: (state, { payload }) => {
      state.telestratorColor = payload;
    },
    setShowColorPicker: (state, { payload }) => {
      state.showColorPicker = payload;
    },
    setReverse: (state, { payload }) => {
      state.reverse = payload;
    },
    setPreload: (state, { payload }) => {
      state.preload = payload;
    },
    setPreloaded: (state, { payload }) => {
      state.preloaded = payload;
    },
    setClickedMarker: (state, { payload }) => {
      state.clickedMarker = payload;
    },
    setStateLayerId: (state, { payload }) => {
      state.stateLayerId = payload;
    },
    setMetroLayerId: (state, { payload }) => {
      state.metroLayerId = payload;
    },
    setMunLayerId: (state, { payload }) => {
      state.munLayerId = payload;
    },
    setDisLayerId: (state, { payload }) => {
      state.disLayerId = payload;
    },
    setMarkerLng: (state, { payload }) => {
      state.markerLng = payload;
    },
    setMarkerLat: (state, { payload }) => {
      state.markerLat = payload;
    },
    setRaceboard: (state, { payload }) => {
      for (const [key, value] of Object.entries(payload)) {
        if (typeof state.raceboard[key] !== "undefined") {
          state.raceboard[key] = value;
        }
      }
    },
    setRaceLayer: (state, { payload }) => {
      state.raceboard.layer = payload;
    },
    setRaceState: (state, { payload }) => {
      state.raceboard.state = payload;
    },
    setRaceStateId: (state, { payload }) => {
      state.raceboard.stateId = payload;
    },
    setRaceStateYear: (state, { payload }) => {
      state.raceboard.stateRaceYear = payload;
    },
    setRaceMun: (state, { payload }) => {
      state.raceboard.mun = payload;
    },
    setRaceMunId: (state, { payload }) => {
      state.raceboard.munId = payload;
    },
    setRaceReg: (state, { payload }) => {
      state.raceboard.reg = payload;
    },
    setRaceTotalVote: (state, { payload }) => {
      state.raceboard.totalVote = payload;
    },
    setRaceTurnoutPercent: (state, { payload }) => {
      state.raceboard.turnoutPercent = payload;
    },
    setNationTotalVote: (state, { payload }) => {
      state.raceboard.nationalTotalVote = payload;
    },
    setNationalTurnoutPercent: (state, { payload }) => {
      state.raceboard.nationalTurnoutPercent = payload;
    },
    setColorFirstParty: (state, { payload }) => {
      state.raceboard.colorFirstParty = payload;
    },
    setRaceParty: (state, { payload }) => {
      state.raceboard["p" + payload.i + "Party"] = payload.payload;
    },
    setRaceCoalition: (state, { payload }) => {
      state.raceboard["p" + payload.i + "Coalition"] = payload.payload;
    },
    setRaceCoalitionParties: (state, { payload }) => {
      state.raceboard["p" + payload.i + "CoalitionParties"] = payload.payload;
    },
    setRaceInterina: (state, { payload }) => {
      state.raceboard["p" + payload.i + "Interina"] = payload.payload;
    },
    setRaceColor: (state, { payload }) => {
      state.raceboard["p" + payload.i + "Color"] = payload.payload;
    },
    setRaceName: (state, { payload }) => {
      state.raceboard["p" + payload.i + "Name"] = payload.payload;
    },
    setRaceTitle: (state, { payload }) => {
      state.raceboard.title = payload;
    },
    setRaceVote: (state, { payload }) => {
      state.raceboard["p" + payload.i + "Vote"] = payload.payload;
    },
    setRacePercent: (state, { payload }) => {
      state.raceboard["p" + payload.i + "Percent"] = payload.payload;
    },
    setRaceTie: (state, { payload }) => {
      state.raceboard.tie = payload;
    },
    setReelected: (state, { payload }) => {
      state.raceboard.reelected = payload;
    },
    setRacePartyN: (state, { payload }) => {
      state.raceboard["p" + payload.i + "PartyN"] = payload.payload;
    },
    setRaceNameN: (state, { payload }) => {
      state.raceboard["p" + payload.i + "NameN"] = payload.payload;
    },
    setRacePercentN: (state, { payload }) => {
      state.raceboard["p" + payload.i + "PercentN"] = payload.payload;
    },
    setRaceVoteN: (state, { payload }) => {
      state.raceboard["p" + payload.i + "VoteN"] = payload.payload;
    },
    setRaceSubtitle1: (state, { payload }) => {
      state.raceboard.subtitle1 = payload;
    },
    setRaceSubtitle2: (state, { payload }) => {
      state.raceboard.subtitle2 = payload;
    },
    setPre2024Winners: (state, { payload }) => {
      state.pre2024Winners = payload;
    },
    setPre2024Neutrals: (state, { payload }) => {
      state.pre2024Neutrals = payload;
    },
  }
});

export const {
  setOpenModal,
  setShowBookmarks,
  setShowRaceboard,
  setShowRaceChart,
  setShowNationalResult,
  setShow2023Result,
  setShow2024Murders,
  setShowTelestrator,
  setReverse,
  setPreload,
  setPreloaded,
  setClickedMarker,
  setStateLayerId,
  setMetroLayerId,
  setMunLayerId,
  setDisLayerId,
  setMarkerLng,
  setMarkerLat,
  setTelestratorStroke,
  setTelestratorColor,
  setShowColorPicker,
  setRaceboard,
  setRaceLayer,
  setRaceState,
  setRaceStateId,
  setRaceStateYear,
  setRaceMun,
  setRaceMunId,
  setRaceReg,
  setRaceParty,
  setRaceColor,
  setRaceName,
  setRaceTitle,
  setRaceTotalVote,
  setRaceTurnoutPercent,
  setNationalTotalVote,
  setNationalTurnoutPercent,
  setColorFirstParty,
  setRaceVote,
  setRacePercent,
  setRaceTie,
  setReelected,
  setRaceCoalition,
  setRaceCoalitionParties,
  setRaceInterina,
  setRacePartyN,
  setRaceNameN,
  setRacePercentN,
  setRaceVoteN,
  setRaceSubtitle1,
  setRaceSubtitle2,
  setPre2024Winners,
  setPre2024Neutrals
} = app.actions;
export default app.reducer;
