import React from "react";
import { Dialog, Box, Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useSelector } from "react-redux";

import { /*Titles, */ Names } from "../../../mapbox/titles";
import { PartyColors } from "../../../mapbox/colors";
import CandidateImgs from "../../../mapbox/candidates";
//import variables from "../../../_variables.scss";
import { FormattedMessage /*, useIntl*/ } from "react-intl";

const RaceboardNational = ({ open, onClose, app }) => {
  const year = useSelector((state) => state.year.value);
  //const intl = useIntl();

  let checkSize = 20;
  let checkStroke = 3;
  let partyBorderLeft = 0;
  let partyBorderBottom = 10;
  if (window.innerWidth > 3000) {
    //checkSize = 60;
    //checkStroke = 3;
    partyBorderLeft = 0;
    partyBorderBottom = 20;
  }

  const runAfterRender = () => {
    if (
      document.getElementById("raceboardImg1N") &&
      document.getElementById("raceboardCheckBoxN")
    ) {
      document.getElementById("raceboardCheckBoxN").style.height =
        document.getElementById("raceboardImg1N").height +
        (window.innerWidth > 3000 ? 20 : 9) +
        "px";
    }
  };

  return (
    <Dialog
      id={"raceboardN"}
      className={"raceboardP"}
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={0}
      sx={{
        width: "530px",
        borderLeft: "solid 0px " + PartyColors[app.raceboard.p1PartyN]?.high,
        padding: "0px !important",
        fontSize: "16px",
        color: "#111111",
        top: 89
      }}
      onLoad={runAfterRender}
    >
      <Box
        className={"raceboardTitleBox"}
        sx={{
          marginLeft: "5%",
          fontWeight: "bold",
          //borderBottom: "solid 3px #111",
          paddingBottom: "4px",
          marginBottom: "10px"
        }}
      >
        <Box
          className={"raceboardTitle"}
          sx={{
            fontSize: "39px",
            lineHeight: "39px",
            paddingBottom: "3px",
            paddingTop: "9px",
            textAlign: "center"
          }}
        >
          <FormattedMessage id={"NationalResults"} />
        </Box>
        <Box
          className={"raceboardSubtitle"}
          sx={{
            fontSize: "25px",
            lineHeight: "25px",
            paddingBottom: "3px",
            paddingTop: "0px",
            fontWeight: "normal",
            textAlign: "center"
          }}
        >
          &nbsp;
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}
      >
        {app.raceboard.p1PartyN ? (
          <Box
            className={"partyBox"}
            sx={{
              display: "flex",
              flex: 1,
              paddingTop: "0.6rem",
              marginBottom: "0.5rem"
            }}
          >
            {app.raceboard.tie ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "6%",
                  marginRight: "0%"
                }}
              ></Box>
            ) : (
              <Box
                id={"raceboardCheckBoxN"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0px 0px 0px 0px",
                  width: "5%",
                  marginRight: "1%",
                  backgroundColor: !app.raceboard.tie
                    ? PartyColors[app.raceboard.p1PartyN]?.high
                    : "transparent",
                  color: "#fff",
                  fontWeight: "bold",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <IconCheck
                  className={"raceCheck"}
                  strokeWidth={checkStroke}
                  size={checkSize}
                  style={{
                    marginLeft: "0px",
                    display: !app.raceboard.tie ? "block" : "none"
                  }}
                />
              </Box>
            )}
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "94%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[app.raceboard.p1PartyN]?.high,
                  marginRight: "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "top",
                    width: "19%"
                  }}
                >
                  <img
                    id={"raceboardImg1N"}
                    src={
                      CandidateImgs[
                        app.raceboard.p1PartyN?.replaceAll("-", "") + year
                      ] || CandidateImgs.Default
                    }
                    style={{ width: "100%" }}
                    alt=""
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "0.5%",
                    width: "50%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={
                        app.raceboard.p1PartyN.length > 10
                          ? "racePartyName"
                          : "racePartyName"
                      }
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p1PartyN]?.high,
                        color:
                          PartyColors[app.raceboard.p1PartyN]?.boardText ||
                          PartyColors[app.raceboard.p1PartyN]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize:
                          app.raceboard.p1PartyN.length > 10 ? "23px" : "23px",
                        lineHeight:
                          app.raceboard.p1PartyN.length > 10 ? "23px" : "23px"
                      }}
                    >
                      {app.raceboard.p1PartyN}
                    </div>
                  </Box>
                  <Box
                    className={"raceTitle"}
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {/*Titles[app.raceboard.p1PartyN + year]
                    ? Titles[app.raceboard.p1PartyN + year]
                    : "Candidatura " + app.raceboard.p1PartyN*/}
                    &nbsp;
                  </Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[app.raceboard.p1PartyN]
                          ? PartyColors[app.raceboard.p1PartyN].high
                          : "#111111")
                    }}
                  >
                    <Box
                      className={"raceName"}
                      sx={{
                        position: "relative",
                        height: "3rem",
                        fontSize: "1.8rem",
                        lineHeight: "1.7rem",
                        width: "98%"
                      }}
                    >
                      <span style={{ position: "absolute", bottom: 3 }}>
                        {Names[app.raceboard.p1PartyN + year]
                          ? Names[app.raceboard.p1PartyN + year].toUpperCase()
                          : app.raceboard.p1Name.toUpperCase()}
                      </span>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNum"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1"
                    }}
                  >
                    {(app.raceboard.p1PercentN &&
                      app.raceboard.p1PercentN < 10) ||
                    app.raceboard.p1PercentN === 0
                      ? "\u00A0\u00A0"
                      : ""}
                    {app.raceboard.p1PercentN || app.raceboard.p1PercentN === 0
                      ? app.raceboard.p1PercentN
                      : ""}
                    <span className={"percent"}>
                      {app.raceboard.p1PercentN ||
                      app.raceboard.p1PercentN === 0
                        ? "%"
                        : ""}
                    </span>
                  </Text>
                  <Text
                    className={"voteNum"}
                    style={{
                      fontFamily: "FranklinGothicHeavy",
                      fontWeight: "normal"
                    }}
                  >
                    {(app.raceboard.p1VoteN || app.raceboard.p1VoteN === 0) && (
                      <FormattedMessage
                        id={"Votes2"}
                        values={{
                          votes: app.raceboard.p1VoteN.toLocaleString("en")
                        }}
                      />
                    )}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : undefined}

        {app.raceboard.p2PartyN ? (
          <Box
            className={"partyBox"}
            sx={{
              display: "flex",
              flex: 1,
              marginTop: "2px",
              backgroundColor: "rgba(255,255,255,0.8)",
              paddingTop: "0.6rem",
              marginBottom: "0.5rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "6%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "94%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[app.raceboard.p2PartyN]?.high,
                  marginRight: "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "top",
                    width: "19%"
                  }}
                >
                  <img
                    src={
                      CandidateImgs[
                        app.raceboard.p2PartyN?.replaceAll("-", "") + year
                      ] || CandidateImgs.Default
                    }
                    style={{ width: "100%" }}
                    alt=""
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "0.5%",
                    width: "50%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={
                        app.raceboard.p2PartyN.length > 10
                          ? "racePartyName"
                          : "racePartyName"
                      }
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p2PartyN]?.high,
                        color:
                          PartyColors[app.raceboard.p2PartyN]?.boardText ||
                          PartyColors[app.raceboard.p2PartyN]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize:
                          app.raceboard.p2PartyN.length > 10 ? "23px" : "23px",
                        lineHeight:
                          app.raceboard.p2PartyN.length > 10 ? "23px" : "23px"
                      }}
                    >
                      {app.raceboard.p2PartyN}
                    </div>
                  </Box>
                  <Box
                    className={"raceTitle"}
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {/*Titles[app.raceboard.p2PartyN + year]
                    ? Titles[app.raceboard.p2PartyN + year]
                    : "Candidatura " + app.raceboard.p2PartyN*/}
                    &nbsp;
                  </Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[app.raceboard.p2PartyN]
                          ? PartyColors[app.raceboard.p2PartyN].high
                          : "#111111")
                    }}
                  >
                    <Box
                      className={"raceName"}
                      sx={{
                        position: "relative",
                        height: "3rem",
                        fontSize: "1.8rem",
                        lineHeight: "1.7rem",
                        width: "98%"
                      }}
                    >
                      <span style={{ position: "absolute", bottom: 3 }}>
                        {Names[app.raceboard.p2PartyN + year]
                          ? Names[app.raceboard.p2PartyN + year].toUpperCase()
                          : app.raceboard.p2Name.toUpperCase()}
                      </span>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNum"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1"
                    }}
                  >
                    {(app.raceboard.p2PercentN &&
                      app.raceboard.p2PercentN < 10) ||
                    app.raceboard.p2PercentN === 0
                      ? "\u00A0\u00A0"
                      : ""}
                    {app.raceboard.p2PercentN || app.raceboard.p2PercentN === 0
                      ? app.raceboard.p2PercentN
                      : ""}
                    <span className={"percent"}>
                      {app.raceboard.p2PercentN ||
                      app.raceboard.p2PercentN === 0
                        ? "%"
                        : ""}
                    </span>
                  </Text>
                  <Text
                    className={"voteNum"}
                    style={{ color: "#555", fontWeight: "normal" }}
                  >
                    {(app.raceboard.p2VoteN || app.raceboard.p2VoteN === 0) && (
                      <FormattedMessage
                        id={"Votes2"}
                        values={{
                          votes: app.raceboard.p2VoteN.toLocaleString("en")
                        }}
                      />
                    )}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : undefined}

        {app.raceboard.p3PartyN ? (
          <Box
            className={"partyBox"}
            sx={{
              display: "flex",
              flex: 1,
              marginTop: "2px",
              backgroundColor: "rgba(255,255,255,0.8)",
              paddingTop: "0.6rem",
              marginBottom: "0.5rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "6%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "94%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[app.raceboard.p3PartyN]?.high,
                  marginRight: "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "top",
                    width: "19%"
                  }}
                >
                  <img
                    src={
                      CandidateImgs[
                        app.raceboard.p3PartyN?.replaceAll("-", "") + year
                      ] || CandidateImgs.Default
                    }
                    style={{ width: "100%" }}
                    alt=""
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "0.5%",
                    width: "50%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={
                        app.raceboard.p3PartyN.length > 10
                          ? "racePartyName"
                          : "racePartyName"
                      }
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p3PartyN]?.high,
                        color:
                          PartyColors[app.raceboard.p3PartyN]?.boardText ||
                          PartyColors[app.raceboard.p3PartyN]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize:
                          app.raceboard.p3PartyN.length > 10 ? "23px" : "23px",
                        lineHeight:
                          app.raceboard.p3PartyN.length > 10 ? "23px" : "23px"
                      }}
                    >
                      {app.raceboard.p3PartyN}
                    </div>
                  </Box>
                  <Box
                    className={"raceTitle"}
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {/*Titles[app.raceboard.p3PartyN + year]
                    ? Titles[app.raceboard.p3PartyN + year]
                    : "Candidatura " + app.raceboard.p3PartyN*/}
                    &nbsp;
                  </Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[app.raceboard.p3PartyN]
                          ? PartyColors[app.raceboard.p3PartyN].high
                          : "#111111")
                    }}
                  >
                    <Box
                      className={"raceName"}
                      sx={{
                        position: "relative",
                        height: "3rem",
                        fontSize: "1.8rem",
                        lineHeight: "1.7rem",
                        width: "98%"
                      }}
                    >
                      <span style={{ position: "absolute", bottom: 3 }}>
                        {Names[app.raceboard.p3PartyN + year]
                          ? Names[app.raceboard.p3PartyN + year].toUpperCase()
                          : app.raceboard.p3Name.toUpperCase()}
                      </span>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNum"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1"
                    }}
                  >
                    {(app.raceboard.p3PercentN &&
                      app.raceboard.p3PercentN < 10) ||
                    app.raceboard.p3PercentN === 0
                      ? "\u00A0\u00A0"
                      : ""}
                    {app.raceboard.p3PercentN || app.raceboard.p3PercentN === 0
                      ? app.raceboard.p3PercentN
                      : ""}
                    <span className={"percent"}>
                      {app.raceboard.p3PercentN ||
                      app.raceboard.p3PercentN === 0
                        ? "%"
                        : ""}
                    </span>
                  </Text>
                  <Text
                    className={"voteNum"}
                    style={{ color: "#555", fontWeight: "normal" }}
                  >
                    {(app.raceboard.p3VoteN || app.raceboard.p3VoteN === 0) && (
                      <FormattedMessage
                        id={"Votes2"}
                        values={{
                          votes: app.raceboard.p3VoteN.toLocaleString("en")
                        }}
                      />
                    )}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : undefined}

        {app.raceboard.p4PartyN ? (
          <Box
            className={"partyBox"}
            sx={{
              display: "flex",
              flex: 1,
              marginTop: "2px",
              backgroundColor: "rgba(255,255,255,0.8)",
              paddingTop: "0.6rem",
              marginBottom: "0.5rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "6%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "94%" }}
            >
              <Box
                className={"partySubBox party4SubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[app.raceboard.p4PartyN]?.high,
                  marginRight: "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "top",
                    width: "19%"
                  }}
                >
                  <img
                    src={
                      CandidateImgs[
                        app.raceboard.p4PartyN?.replaceAll("-", "") + year
                      ] || CandidateImgs.Default
                    }
                    style={{ width: "100%" }}
                    alt=""
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "0.5%",
                    width: "50%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={
                        app.raceboard.p4PartyN.length > 10
                          ? "racePartyName"
                          : "racePartyName"
                      }
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p4PartyN]?.high,
                        color:
                          PartyColors[app.raceboard.p4PartyN]?.boardText ||
                          PartyColors[app.raceboard.p4PartyN]?.high,
                        display: "inline-block",
                        width: "auto",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize:
                          app.raceboard.p4PartyN.length > 10 ? "23px" : "23px",
                        lineHeight:
                          app.raceboard.p4PartyN.length > 10 ? "23px" : "23px"
                      }}
                    >
                      {app.raceboard.p4PartyN}
                    </div>
                  </Box>
                  <Box
                    className={"raceTitle"}
                    sx={{
                      marginTop: "0rem",
                      marginBottom: "10px",
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {/*Titles[app.raceboard.p4PartyN + year]
                    ? Titles[app.raceboard.p4PartyN + year]
                    : "Candidatura " + app.raceboard.p4PartyN*/}
                    &nbsp;
                  </Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[app.raceboard.p4PartyN]
                          ? PartyColors[app.raceboard.p4PartyN].high
                          : "#111111")
                    }}
                  >
                    <Box
                      className={"raceName"}
                      sx={{
                        position: "relative",
                        height: "3rem",
                        fontSize: "1.8rem",
                        lineHeight: "1.7rem",
                        width: "100%"
                      }}
                    >
                      <span style={{ position: "absolute", bottom: 3 }}>
                        {Names[app.raceboard.p4PartyN + year]
                          ? Names[app.raceboard.p4PartyN + year].toUpperCase()
                          : app.raceboard.p4Name.toUpperCase()}
                      </span>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNum"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1"
                    }}
                  >
                    {(app.raceboard.p4PercentN &&
                      app.raceboard.p4PercentN < 10) ||
                    app.raceboard.p4PercentN === 0
                      ? "\u00A0\u00A0"
                      : ""}
                    {app.raceboard.p4PercentN || app.raceboard.p4PercentN === 0
                      ? app.raceboard.p4PercentN
                      : ""}
                    <span className={"percent"}>
                      {app.raceboard.p4PercentN ||
                      app.raceboard.p4PercentN === 0
                        ? "%"
                        : ""}
                    </span>
                  </Text>
                  <Text
                    className={"voteNum"}
                    style={{ color: "#555", fontWeight: "normal" }}
                  >
                    {(app.raceboard.p4VoteN || app.raceboard.p4VoteN === 0) && (
                      <FormattedMessage
                        id={"Votes2"}
                        values={{
                          votes: app.raceboard.p4VoteN.toLocaleString("en")
                        }}
                      />
                    )}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : undefined}
      </Box>
    </Dialog>
  );
};

export default RaceboardNational;
