import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Dialog, Box, Text, TextInput, Select, Checkbox } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import voca from "voca";
import { useIntl } from "react-intl";
import removeAccents from "remove-accents";
import CandidateImgs from "../../../mapbox/candidates";
import CandidateImgs2024 from "../../../mapbox/candidatesGov2024";
import { Names } from "../../../mapbox/titles";
import { PartyColors } from "../../../mapbox/colors";
import { StatesAccents } from "../../../mapbox/states";
import { update as updateYear } from "../../../actions/yearSlice";
import { setPre2024Winners, setPre2024Neutrals } from "../../../redux/app/slice";
import axios from "axios";

const Raceboard = ({ open, onClose, app }) => {
  const year = useSelector((state) => state.year.value);
  const intl = useIntl();
  const dispatch = useDispatch();
  
  const [updatedColor, setUpdatedColor] = useState(false);
  const [editing, setEditing] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [coalitionPartiesArr, setCoalitionPartiesArr] = useState([]);
  const [nameArr, setNameArr] = useState([]);
  const [subTitle, setSubTitle] = useState('');
  const [neutral, setNeutral] = useState(false);
  const [hasWinner, setHasWinner] = useState(false);
  const [winner1, setWinner1] = useState(false);
  const [winner2, setWinner2] = useState(false);
  const [winner3, setWinner3] = useState(false);
  const [winner4, setWinner4] = useState(false);
  const [party1, setParty1] = useState(false);
  const [party2, setParty2] = useState(false);
  const [party3, setParty3] = useState(false);
  const [party4, setParty4] = useState(false);
  const [partyPerc1, setPartyPerc1] = useState(false);
  const [partyPerc2, setPartyPerc2] = useState(false);
  const [partyPerc3, setPartyPerc3] = useState(false);
  const [partyPerc4, setPartyPerc4] = useState(false);
  const [partyVote1, setPartyVote1] = useState(false);
  const [partyVote2, setPartyVote2] = useState(false);
  const [partyVote3, setPartyVote3] = useState(false);
  const [partyVote4, setPartyVote4] = useState(false);
  const [partyOrder1, setPartyOrder1] = useState('1');
  const [partyOrder2, setPartyOrder2] = useState('2');
  const [partyOrder3, setPartyOrder3] = useState('3');
  const [partyOrder4, setPartyOrder4] = useState('4');
  const [showMin1, setShowMin1] = useState(true);
  const [showMin2, setShowMin2] = useState(true);
  const [showMin3, setShowMin3] = useState(true);
  const [showMin4, setShowMin4] = useState(true);
  const [showMax1, setShowMax1] = useState(true);
  const [showMax2, setShowMax2] = useState(true);
  const [showMax3, setShowMax3] = useState(true);
  const [showMax4, setShowMax4] = useState(true);
  const [showMinPerc1, setShowMinPerc1] = useState(true);
  const [showMinPerc2, setShowMinPerc2] = useState(true);
  const [showMinPerc3, setShowMinPerc3] = useState(true);
  const [showMinPerc4, setShowMinPerc4] = useState(true);
  const [showMaxPerc1, setShowMaxPerc1] = useState(true);
  const [showMaxPerc2, setShowMaxPerc2] = useState(true);
  const [showMaxPerc3, setShowMaxPerc3] = useState(true);
  const [showMaxPerc4, setShowMaxPerc4] = useState(true);

  const { partyBorderLeft, partyBorderBottom, raceNameLong } = useMemo(
    () => ({
      partyBorderLeft: 0,
      partyBorderBottom: window.innerWidth > 3000 ? 20 : 10,
      raceNameLong: window.innerWidth > 3000 ? "3.5rem !important" : "1.6rem"
    }),
    []
  );

  const sanitizeName = useCallback(
    (name) =>
      removeAccents(
        name
          ?.trim()
          ?.replaceAll(" ", "_")
          .replaceAll("“", "")
          .replaceAll("”", "") || ""
      ),
    []
  );

  const runAfterRender = useCallback(() => {
    const raceboardG = document.getElementById("raceboardG");
    if (raceboardG) {
      raceboardG.style.display = (app.raceboard.p1Name && app.raceboard.p1Name === "0") ||
      year === "cdmx" ||
      year === "cdmx24" ||
      year === "jal"
        ? "none"
        : "block"
      raceboardG.style.visibility = app.showNationalResult
        ? "hidden"
        : "visible";
    }
  }, [app.showNationalResult, app.raceboard.p1Name, year]);
  
  const getCookie = useCallback((name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }, []);
  
  const isAdmin = (getCookie('email') === 'admin@admin.com');
  
  const updatePartyPerc = useCallback((num, val) => {
    if (num === 1)
      setPartyPerc1(val);
    else if (num === 2)
      setPartyPerc2(val);
    else if (num === 3)
      setPartyPerc3(val);
    else if (num === 4)
      setPartyPerc4(val);   
  }, []);
  
  const updatePartyVote = useCallback((num, val) => {
    if (num === 1)
      setPartyVote1(val);
    else if (num === 2)
      setPartyVote2(val);
    else if (num === 3)
      setPartyVote3(val);
    else if (num === 4)
      setPartyVote4(val);    
  }, []);
  
  const updateShowMin = useCallback((num, val) => {
    if (num === 1)
      setShowMin1(val);
    else if (num === 2)
      setShowMin2(val);
    else if (num === 3)
      setShowMin3(val);
    else if (num === 4)
      setShowMin4(val);    
  }, []);
  
  const updateShowMinPerc = useCallback((num, val) => {
    if (num === 1)
      setShowMinPerc1(val);
    else if (num === 2)
      setShowMinPerc2(val);
    else if (num === 3)
      setShowMinPerc3(val);
    else if (num === 4)
      setShowMinPerc4(val);    
  }, []);
  
  const updateShowMax = useCallback((num, val) => {
    if (num === 1)
      setShowMax1(val);
    else if (num === 2)
      setShowMax2(val);
    else if (num === 3)
      setShowMax3(val);
    else if (num === 4)
      setShowMax4(val);    
  }, []);
  
  const updateShowMaxPerc = useCallback((num, val) => {
    if (num === 1)
      setShowMaxPerc1(val);
    else if (num === 2)
      setShowMaxPerc2(val);
    else if (num === 3)
      setShowMaxPerc3(val);
    else if (num === 4)
      setShowMaxPerc4(val);    
  }, []);
  
  const updatePartyOrder = useCallback((num, val) => {
    if (num === 1)
      setPartyOrder1(val);
    else if (num === 2)
      setPartyOrder2(val);
    else if (num === 3)
      setPartyOrder3(val);
    else if (num === 4)
      setPartyOrder4(val);  
  }, []);
  
  const updateWinner = useCallback((num, val) => {
    if (num === 1)
      setWinner1(val);
    else if (num === 2)
      setWinner2(val);
    else if (num === 3)
      setWinner3(val);
    else if (num === 4)
      setWinner4(val);  
  }, []);

  useEffect(() => {
    runAfterRender();
  }, [app.raceboard.state, runAfterRender]);
  
  useEffect(() => {
    if (!updatedColor) {
      axios.get(process.env.REACT_APP_BASE_URL + '/api/pre2024/getWinners').then((response) => {  
        if (response.data && response.data.data) {
          if (response.data.data.winners && response.data.data.winners.length) {
            let winners = [];
            response.data.data.winners.forEach(function (item, index) {
              //console.log(item, index);
              winners[item.state] = item;
            });          
            dispatch(setPre2024Winners(winners));
          }          
          if (response.data.data.neutrals) {         
            dispatch(setPre2024Neutrals(response.data.data.neutrals));
          }
        }
        setUpdatedColor(true);
      });
    }
  }, [dispatch, year, updatedColor]);
  
  useEffect(() => {
    if (year === 'pre-2024') {
      setLoaded(false); 
      const raceboardG = document.getElementById("raceboardG");
      if (raceboardG) {
        raceboardG.style.display = "none";
        raceboardG.style.visible = "hidden";
      }
      let coalitionPartiesArrTemp = [];
      let p3Party = app.raceboard.p3Party;
      if (app.raceboard.p3Party === 'MC-Morelos Progresa')
        p3Party = 'MC';
      coalitionPartiesArrTemp[app.raceboard.p1Party] = app.raceboard.p1CoalitionParties;
      coalitionPartiesArrTemp[app.raceboard.p2Party] = app.raceboard.p2CoalitionParties;
      coalitionPartiesArrTemp[p3Party] = app.raceboard.p3CoalitionParties;
      coalitionPartiesArrTemp[app.raceboard.p4Party] = app.raceboard.p4CoalitionParties;
      setCoalitionPartiesArr(coalitionPartiesArrTemp);
      let nameArrTemp = [];
      nameArrTemp[app.raceboard.p1Party] = app.raceboard.p1Name;
      nameArrTemp[app.raceboard.p2Party] = app.raceboard.p2Name;
      nameArrTemp[p3Party] = app.raceboard.p3Name;
      nameArrTemp[app.raceboard.p4Party] = app.raceboard.p4Name;
      setNameArr(nameArrTemp);      
      setParty1(app.raceboard.p1Party);
      setParty2(app.raceboard.p2Party);
      setParty3(app.raceboard.p3Party);
      setParty4(app.raceboard.p4Party);
      setPartyPerc1(false);
      setPartyPerc2(false);
      setPartyPerc3(false);
      setPartyPerc4(false);
      setPartyVote1(false);
      setPartyVote2(false);
      setPartyVote3(false);
      setPartyVote4(false);
      setPartyOrder1('1');
      setPartyOrder2('2');
      setPartyOrder3('3');
      setPartyOrder4('4');
      setShowMin1(true);
      setShowMin2(true);
      setShowMin3(true);
      setShowMin4(true);
      setShowMinPerc1(true);
      setShowMinPerc2(true);
      setShowMinPerc3(true);
      setShowMinPerc4(true);
      setShowMax1(true);
      setShowMax2(true);
      setShowMax3(true);
      setShowMax4(true);
      setShowMaxPerc1(true);
      setShowMaxPerc2(true);
      setShowMaxPerc3(true);
      setShowMaxPerc4(true);
      setNeutral(false);
      setHasWinner(false);
      setSubTitle('');
      axios.get(process.env.REACT_APP_BASE_URL + '/api/pre2024/data?state=' + app.raceboard.state).then((response) => {        
        //console.log(response.data.data);
        if (response.data && response.data.data && response.data.data.length) {
          let _hasWinner = false;
          response.data.data.forEach(function (item, index) {
            //console.log(item, index);
            item.showMin = item.showMin === 1 ? true : false;
            item.showMinPerc = item.showMinPerc === 1 ? true : false;
            item.showMax = item.showMax === 1 ? true : false;
            item.showMaxPerc = item.showMaxPerc === 1 ? true : false;
            item.neutralColor = item.neutralColor === 1 ? true : false;
            if (item.winner === 1)
              _hasWinner = true;
            if (item.order === 1) {
              setParty1(item.party);
              setPartyPerc1(item.percent);
              setPartyVote1(item.votes);
              setShowMin1(item.showMin);
              setShowMinPerc1(item.showMinPerc);
              setShowMax1(item.showMax);
              setShowMaxPerc1(item.showMaxPerc);
              setWinner1(item.winner);
              setSubTitle(item.subTitle);
              setNeutral(item.neutralColor);              
            }
            else if (item.order === 2) {
              setParty2(item.party);
              setPartyPerc2(item.percent);
              setPartyVote2(item.votes);
              setShowMin2(item.showMin);
              setShowMinPerc2(item.showMinPerc);
              setShowMax2(item.showMax);
              setShowMaxPerc2(item.showMaxPerc);
              setWinner2(item.winner);
            }
            else if (item.order === 3) {
              setParty3(item.party);
              setPartyPerc3(item.percent);
              setPartyVote3(item.votes);
              setShowMin3(item.showMin);
              setShowMinPerc3(item.showMinPerc);
              setShowMax3(item.showMax);
              setShowMaxPerc3(item.showMaxPerc);
              setWinner3(item.winner);
            }
            else if (item.order === 4) {
              setParty4(item.party);
              setPartyPerc4(item.percent);
              setPartyVote4(item.votes);
              setShowMin4(item.showMin);
              setShowMinPerc4(item.showMinPerc);
              setShowMax4(item.showMax);
              setShowMaxPerc4(item.showMaxPerc);
              setWinner4(item.winner);
            }
          });
          setHasWinner(_hasWinner);
        }        
        setLoaded(true);

        setTimeout(() => { 
          const raceboardG = document.getElementById("raceboardG");
          if (raceboardG) {
            raceboardG.style.display = "block";
            raceboardG.style.visible = "visible";
          }
        }, 100);        
      });
    }
  }, [year, app.raceboard.state, app.raceboard.p1Party, app.raceboard.p2Party, app.raceboard.p3Party, app.raceboard.p4Party, app.raceboard.p1CoalitionParties, app.raceboard.p2CoalitionParties, app.raceboard.p3CoalitionParties, app.raceboard.p4CoalitionParties, app.raceboard.p1Name, app.raceboard.p2Name, app.raceboard.p3Name, app.raceboard.p4Name]);

  const clearInputs = useCallback((num) => {
    updatePartyPerc(num, '');
    updatePartyVote(num, '');
    updateShowMin(num, false);
    updateShowMinPerc(num, false);
    updateShowMax(num, false);
    updateShowMaxPerc(num, false);
    updateWinner(num, false);
  }, [updatePartyPerc, updatePartyVote, updateShowMin, updateShowMinPerc, updateShowMax, updateShowMaxPerc, updateWinner]);

  const renderCandidateBox = useCallback(
    (candidateName, candidateParty, coalitionParties, num) => {   
      if (!loaded) //comment this line after they enter all data in database to prevent flashing!    
        coalitionParties = '';
      let partyPerc = '';
      let partyVote = '';
      let partyOrder = '';
      let showMin = true;
      let showMinPerc = true;
      let showMax = true;
      let showMaxPerc = true;
      let winner = false;
      if (num === 1) {
        partyOrder = partyOrder1;
        if (partyPerc1)
          partyPerc = partyPerc1;
        if (partyVote1)
          partyVote = partyVote1;
        if (party1) {
          candidateParty = party1;
          coalitionParties = coalitionPartiesArr[party1];
          candidateName = nameArr[party1];
        }
        showMin = showMin1;
        showMinPerc = showMinPerc1;
        showMax = showMax1;
        showMaxPerc = showMaxPerc1;
        winner = winner1;
      }
      else if (num === 2) {
        partyOrder = partyOrder2;  
        if (partyPerc2)
          partyPerc = partyPerc2;
        if (partyVote2)
          partyVote = partyVote2;
        if (party2) {
          candidateParty = party2;
          coalitionParties = coalitionPartiesArr[party2];
          candidateName = nameArr[party2];
        }
        showMin = showMin2;
        showMinPerc = showMinPerc2;
        showMax = showMax2;
        showMaxPerc = showMaxPerc2;
        winner = winner2;
      }
      else if (num === 3) {
        partyOrder = partyOrder3;
        if (partyPerc3)
          partyPerc = partyPerc3;
        if (partyVote3)
          partyVote = partyVote3;
        if (party3) {
          let key = party3;
          if (party3 === "MC-Morelos Progresa") {
            key = "MC"
          }
          candidateParty = key;
          coalitionParties = coalitionPartiesArr[key];
          candidateName = nameArr[key];
        }
        showMin = showMin3;
        showMinPerc = showMinPerc3;
        showMax = showMax3;
        showMaxPerc = showMaxPerc3;
        winner = winner3;
      }
      else if (num === 4) {
        partyOrder = partyOrder4;
        if (partyPerc4)
          partyPerc = partyPerc4;
        if (partyVote4)
          partyVote = partyVote4;   
        if (party4) {
          candidateParty = party4;
          coalitionParties = coalitionPartiesArr[party4];
          candidateName = nameArr[party4];
        }
        showMin = showMin4;
        showMinPerc = showMinPerc4;
        showMax = showMax4;
        showMaxPerc = showMaxPerc4;
        winner = winner4;
      }
      
      if (!candidateName || candidateName === "0") return null;
      
      if (hasWinner && winner === 0 && !editing) return null;
      
      const imageName = sanitizeName(candidateName);
      const partyColor = PartyColors[candidateParty]?.high;
      const textColor =
        candidateParty === "PRD" ? "black" :
        PartyColors[candidateParty]?.boardText ||
        PartyColors[candidateParty]?.high;
      const coalitionImages = (coalitionParties || "")
        .split("-")
        .map((img) => {
          const logoName = sanitizeName(img);
          return CandidateImgs[logoName] && logoName !== "Independiente"
            ? CandidateImgs[logoName]
            : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAQAAADYv8WvAAAADklEQVR42mNkAAJGEAEAABkAA50Y5HIAAAAASUVORK5CYII="
          }
        );    
        
      return (
        <Box
          className="partyBox"
          sx={{
            display: "flex",
            flex: 1,
            marginBottom: "0.5rem",
            marginTop: "2px",
            backgroundColor: "rgba(255,255,255,0.8)",
            justifyContent: "center",
            padding: "1.2rem",
            paddingBottom: "0.2rem"
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "94%" }}>
            <Box
              className="partySubBox"
              sx={{
                display: "flex",
                borderBottom: `solid ${partyBorderBottom}px ${partyColor}`,
                paddingBottom: "0rem",
                marginBottom: "0.5rem"
              }}
            >
              {CandidateImgs2024[imageName] && (
                <Box
                  sx={{
                    flexDirection: "column",
                    justifyContent: "top",
                    width: hasWinner && !editing ? "40%" : "19%",
                    display: "flex"
                  }}
                >
                  <img
                    src={CandidateImgs2024[imageName]}
                    style={{ width: window.innerWidth > 3000 ? "96%" : "97%" }}
                    alt=""
                  />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "0.5%",
                  width: hasWinner && !editing ? "59.5%" : "38%",
                  marginTop: "0px"
                }}
              >                
                <Box
                  className="raceCandBox2"
                  sx={{
                    paddingTop: "9px",
                    paddingLeft: "5px",
                    borderLeft: `solid ${partyBorderLeft}px ${partyColor}`
                  }}
                >
                  <Box
                    className="raceName"
                    sx={{
                      position: "relative",
                      height: "3rem",
                      fontSize:
                        candidateName.length > 27 ? raceNameLong : "1.8rem",
                      lineHeight: "1.7rem",
                      width: "98%"
                    }}
                  >
                    <span style={{ position: "absolute", top: -3 }}>
                      {Names[candidateName]
                        ? Names[candidateName].toUpperCase()
                        : candidateName.toUpperCase()}
                    </span>                    
                  </Box>
                  {hasWinner &&!editing && winner ? 
                  (<Box className="winnerCheck" sx={{fontWeight:"bold", fontSize: "5rem", color:"green"}}>
                    &#10003;
                  </Box>) : ''}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <div
                    className="racePartyName2"
                    style={{
                      display: "inline-block",
                      width: "auto",
                      padding: "2px 0px 0px 6px",
                      marginLeft: "0px",
                      overflowWrap: "break-word",
                      lineHeight: "26px"
                    }}
                  >
                    {coalitionImages.map((src, index) => (
                      index === 4 && coalitionImages.length > 6 ? 
                      (<><img
                        key={index}
                        className="raceboardImgGov2024"
                        src={src}
                        style={{
                          width: "2.2rem",
                          height: "2.2rem",
                          marginRight: "0.5rem",
                          display: loaded ? 'inline-block' : 'none'
                        }}
                        alt=""
                      /><br/></>) : 
                      (<img
                        key={index}
                        className="raceboardImgGov2024"
                        src={src}
                        style={{
                          width: "2.2rem",
                          height: "2.2rem",
                          marginRight: "0.5rem",
                          display: loaded ? 'inline-block' : 'none'
                        }}
                        alt=""
                      />)
                    ))}
                  </div>
                </Box>
              </Box>
              <Box
                sx={{
                  display: hasWinner && !editing ? "none" : "flex",
                  flexDirection: "column",
                  textAlign: "right",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  flex: 1,
                  width: "37%",
                }}
              >
                <div className={'Edit'} style={{ display: isAdmin && editing ? 'block' : 'none' }}>
                  <Checkbox sx={{marginBottom: "3%"}} checked={winner} onChange={(event) => { updateWinner(num, event.currentTarget.checked); }} label="Winner" />
                  <Select
                    placeholder="Order"
                    data={['1', '2', '3', '4']}
                    value={partyOrder}
                    onChange={(_value, option) => { updatePartyOrder(num, _value); }}
                  />
                  <TextInput
                    placeholder="Min. Percent"
                    sx={{marginTop: "3%"}}
                    value={partyVote}
                    onChange={(event) => { updatePartyVote(num, event.currentTarget.value); }}
                  />
                  <div style={{display:'flex', marginTop:'3%'}}>
                    <div style={{}}><Checkbox checked={showMin} onChange={(event) => { updateShowMin(num, event.currentTarget.checked); }} label="Show Min" /></div>
                    <div style={{marginLeft:'15%'}}><Checkbox checked={showMinPerc} onChange={(event) => { updateShowMinPerc(num, event.currentTarget.checked); }} label="Show Min %" /></div>
                  </div>
                  <TextInput
                    placeholder="Max. Percent"
                    sx={{marginTop: "3%", marginBottom: "3%"}}
                    value={partyPerc}
                    onChange={(event) => { updatePartyPerc(num, event.currentTarget.value); }}
                  />
                  <div style={{display:'flex', marginTop:'3%', marginBottom:'3%'}}>
                    <div style={{}}><Checkbox checked={showMax} onChange={(event) => { updateShowMax(num, event.currentTarget.checked); }} label="Show Max" /></div>
                    <div style={{marginLeft:'15%'}}><Checkbox checked={showMaxPerc} onChange={(event) => { updateShowMaxPerc(num, event.currentTarget.checked); }} label="Show Max %" /></div>
                  </div>
                  <button sx={{}} onClick={() => clearInputs(num)}>Clear</button> {/* Clear button */}
                </div>
                <Text
                  className={"percentNum2"}
                  sx={{
                    fontFamily: "FranklinGothicMedium",
                    fontSize: "4rem",
                    marginTop: "0rem",
                    lineHeight: "1",
                    display: isAdmin && editing ? 'none' : 'flex',
                    alignItems: "last baseline",
                    justifyContent: "right",
                    position: "relative",
                    color: textColor
                  }}
                >
                  {partyVote && <div className={"percent3"}>{showMin && 'MÍN.'}&nbsp;{partyVote < 10 ? '\u00a0\u00a0' : ''}</div>}
                  <div style={{paddingRight: showMinPerc ? "9%" : "0%"}}>{partyVote < 10 ? '\u00a0\u00a0' : ''}{partyVote}</div>
                  {partyVote && (<div className={"percent2"} style={{top: window.innerWidth > 3000 ? "13%" : "11%", position: "absolute"}}>
                    {showMinPerc && '\u00a0\u00a0%'}
                  </div>)}
                </Text>
                <Text
                  className={partyVote === "" ? "voteNumBig2" : "voteNum2"}
                  style={{
                    fontFamily: "FranklinGothicHeavy",
                    fontWeight: "normal",
                    display: isAdmin && editing ? 'none' : 'flex',
                    alignItems: "last baseline",
                    justifyContent: "right",
                    position: "relative",
                    color: textColor
                  }}
                >                   
                  {partyPerc && <div className={"percent3"}>{showMax && 'MÁX.'}&nbsp;{partyPerc < 10 ? '\u00a0\u00a0' : ''}</div>}
                  <div style={{paddingRight: showMaxPerc && partyVote === "" ? "15%" : showMaxPerc ? "9%" : "0%"}}>{partyPerc < 10 ? '\u00a0\u00a0' : ''}{partyPerc}</div>
                  {partyPerc && (<div className={partyVote === "" ? "percentBig2" : "percent2"} style={{top: window.innerWidth > 3000 ? "-29%" : "-1%", position: "absolute"}}>
                    {showMaxPerc && '\u00a0\u00a0%'}
                  </div>)}
                </Text>
              </Box>              
            </Box>
          </Box>
        </Box>
      );
    },
    [partyBorderLeft, partyBorderBottom, raceNameLong, sanitizeName, isAdmin, editing, loaded, coalitionPartiesArr, nameArr, hasWinner, party1, party2, party3, party4, partyPerc1, partyPerc2, partyPerc3, partyPerc4, partyVote1, partyVote2, partyVote3, partyVote4, partyOrder1, partyOrder2, partyOrder3, partyOrder4, showMin1, showMin2, showMin3, showMin4, showMinPerc1, showMinPerc2, showMinPerc3, showMinPerc4, showMax1, showMax2, showMax3, showMax4, showMaxPerc1, showMaxPerc2, showMaxPerc3, showMaxPerc4, winner1, winner2, winner3, winner4, updatePartyPerc, updatePartyVote, updatePartyOrder, updateShowMin, updateShowMinPerc, updateShowMax, updateShowMaxPerc, updateWinner, clearInputs]
  );

  const setEdit = useCallback(
    () => {  
      setEditing(true);
    },
    []
  );

  const submitEdit = useCallback(
    () => {        
      const pdata = { 
        state: app.raceboard.state,
        party1: party1,
        percent1: partyPerc1,
        vote1: partyVote1,
        order1: partyOrder1,
        showMin1: showMin1,
        showMinPerc1: showMinPerc1,
        showMax1: showMax1,
        showMaxPerc1: showMaxPerc1,
        winner1: winner1,
        party2: party2,
        percent2: partyPerc2,
        vote2: partyVote2,
        order2: partyOrder2,
        showMin2: showMin2,
        showMinPerc2: showMinPerc2,
        showMax2: showMax2,
        showMaxPerc2: showMaxPerc2,
        winner2: winner2,
        party3: party3,
        percent3: partyPerc3,
        vote3: partyVote3,
        order3: partyOrder3,
        showMin3: showMin3,
        showMinPerc3: showMinPerc3,
        showMax3: showMax3,
        showMaxPerc3: showMaxPerc3,
        winner3: winner3,
        party4: party4,
        percent4: partyPerc4,
        vote4: partyVote4,
        order4: partyOrder4,
        showMin4: showMin4,
        showMinPerc4: showMinPerc4,
        showMax4: showMax4,
        showMaxPerc4: showMaxPerc4,
        winner4: winner4,
        subTitle: subTitle,
        neutral: neutral
      };
      
      axios.post(process.env.REACT_APP_BASE_URL + '/api/pre2024/edit', pdata).then((response) => {        
        dispatch(updateYear('post-2024'));       
        setTimeout(() => { document.getElementById('raceboardG').style.display = 'none'; }, 100);
        setUpdatedColor(false);         
        setTimeout(() => { dispatch(updateYear('pre-2024')); setEditing(false); }, 500);
      });      
    },
    [dispatch, app.raceboard.state, party1, partyPerc1, partyVote1, partyOrder1, party2, partyPerc2, partyVote2, partyOrder2, party3, partyPerc3, partyVote3, partyOrder3, party4, partyPerc4, partyVote4, partyOrder4, showMin1, showMin2, showMin3, showMin4, showMinPerc1, showMinPerc2, showMinPerc3, showMinPerc4, showMax1, showMax2, showMax3, showMax4, showMaxPerc1, showMaxPerc2, showMaxPerc3, showMaxPerc4, winner1, winner2, winner3, winner4, subTitle, neutral]
  );

  return (
    <Dialog
      id="raceboardG"
      className={'raceboard2024' + (hasWinner && !editing ? ' raceboard2024Winner' : '')}
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={20}
      sx={{
        width: "700px",
        borderLeft: `solid 0px ${PartyColors[app.raceboard.p1Party]?.high}`,
        padding: "0px !important",
        fontSize: "16px",
        color: "#111111",
        top: 89,
        display: "none",
        visible: "hidden"
      }}
      onLoad={runAfterRender}
    >
      {/* Title Box */}
      <Box
        className="raceboardTitleBoxW"
        sx={{
          paddingLeft: "20px",
          fontWeight: "bold",
          //borderBottom: "solid 3px #111",
          paddingBottom: "4px",
          marginBottom: "6px",
          backgroundColor: "rgba(213,209,250,0.8)",
          borderRadius: "5px"
        }}
      >
        <Box
          className="raceboardTitle"
          sx={{
            fontSize: "39px",
            lineHeight: "39px",
            paddingBottom: "3px",
            paddingTop: "9px",
            textAlign: "center"
          }}
        >
          {app.raceboard.layer === "municipals"
            ? app.raceboard.mun
            : app.raceboard.state === "México"
            ? intl.formatMessage({ id: "MexicoState" })
            : voca.titleCase(
                StatesAccents[app.raceboard.state.toUpperCase()] ||
                  app.raceboard.state.toUpperCase() ||
                  ""
              )}
        </Box>
        <Box
          className="raceboardSubtitle"
          sx={{
            fontSize: "25px",
            lineHeight: "25px",
            paddingBottom: "3px",
            paddingTop: "0px",
            fontWeight: "normal",
            textAlign: "center",
            display: isAdmin && editing ? "none" : "block"
          }}
        >
          {/*app.raceboard.stateRaceYear*/}
          {subTitle}
        </Box>
        <div className="subTitleEdit" style={{display: isAdmin && editing ? "flex" : "none", justifyContent: "center"}}>
          <div style={{width:"70%", marginRight:"5%"}}>
            <TextInput
              placeholder="Sub Title"
              sx={{width:"100%", marginTop: window.innerWidth > 3000 ? "2%" : "0%"}}
              value={subTitle}
              onChange={(event) => { setSubTitle(event.currentTarget.value); }}
            />
          </div>
          <div>
            <Checkbox checked={neutral} onChange={(event) => { setNeutral(event.currentTarget.checked); }} label="Neutral Color" />
          </div>
        </div>
        <div style={{width: "100%", textAlign: "center", marginTop: "1%", marginBottom: "1%", display: isAdmin && !editing ? "block" : "none"}}><input className="govUpdate" type="button" value="Edit" onClick={setEdit} /></div>
        <div style={{width: "100%", textAlign: "center", marginTop: "1%", marginBottom: "1%", display: isAdmin && editing ? "block" : "none"}}><input className="govUpdate" type="button" value="Save" onClick={submitEdit} /></div>
      </Box>

      {/* Candidate Boxes */}
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}
      >
        {renderCandidateBox(
          app.raceboard.p1Name,
          app.raceboard.p1Party,
          app.raceboard.p1CoalitionParties,
          1
        )}
        {renderCandidateBox(
          app.raceboard.p2Name,
          app.raceboard.p2Party,
          app.raceboard.p2CoalitionParties,
          2
        )}
        {renderCandidateBox(
          app.raceboard.p3Name,
          app.raceboard.p3Party,
          app.raceboard.p3CoalitionParties,
          3
        )}
        {renderCandidateBox(
          app.raceboard.p4Name,
          app.raceboard.p4Party,
          app.raceboard.p4CoalitionParties,
          4
        )}
      </Box>
    </Dialog>
  );
};

export default Raceboard;
