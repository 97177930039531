import { Box, Text } from "@mantine/core";
import mx_logo from "../../../assets/img/mexico24.png";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import voca from "voca";

const TopBar = ({ app, goHome }) => {
  const year = useSelector((state) => state.year.value);
  const raceType = useSelector((state) => state.raceType.value);
  const raceTypeTxt = {
    pres: "Presidential",
    gov: "Gubernational",
    cong: "Congressional",
    sen: "Senate"
  };

  let titleId =
    year === "women"
      ? "WomenTitle"
      : year === "nom-2024"
      ? "RegVotersTitle"
      : year === "cdmx" || year === "cdmx24"
      ? "CdmxTitle"
      : year === "jal"
      ? "JalTitle"
      : raceType === "mayor"
      ? year.startsWith("cdmx")
        ? "TopTitleValleDeMexico"
        : year.startsWith("mty")
        ? "TopTitleMonterrey"
        : year.startsWith("gdl")
        ? "TopTitleGuadalajara"
        : "TopTitle" +
          raceTypeTxt[raceType] +
          (year === "post-2023"
            ? "2023"
            : year === "post-2024"
            ? "2024"
            : year === "pre-2024"
            ? "2024"
            : "")
      : "TopTitle" +
        raceTypeTxt[raceType] +
        (year === "post-2023"
          ? "2023"
          : year === "post-2024"
          ? "2024"
          : year === "pre-2024"
          ? "2024"
          : "");

  let yearLabel =
    year === "women" || year === "post-2023" || year === "nom-2024"
      ? ""
      : year === "jal" || year === "cdmx"
      ? "2018"
      : year === "cdmx24"
      ? "2024"
      : voca
          .titleCase(year)
          .replace("Pre-", "")
          .replace("Post-", "")
          .replace("Cdmx-", "")
          .replace("Mty-", "")
          .replace("Gdl-", "");

  return (
    <Box
      className={"topBar"}
      style={{
        fontFmaily: "inherit",
        width: "100%",
        display: "flex",
        position: "absolute",
        left: "0px",
        top: "0px",
        backgroundColor: "#D5D1FA",
        color: "#000",
        zIndex: "201"
        //maxHeight: "10%"
      }}
    >
      {!app.reverse && (
        <Box
          style={{
            width: "8.1%",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer"
          }}
          onClick={() => goHome(true, true)}
        >
          <img
            className={"topLogo"}
            style={{ maxWidth: "100%" }}
            src={mx_logo}
            alt="MX Election 2024"
          />
        </Box>
      )}

      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Text
          className={"topTitleText"}
          sx={{
            marginLeft: "1.2%",
            fontSize: "3rem",
            whiteSpace: "nowrap",
            lineHeight: "79px"
          }}
        >
          {/*<FormattedMessage id="topTitle" values={{raceType: raceTypeTxt[raceType]}} />*/}
          <FormattedMessage id={titleId} /> {yearLabel}
        </Text>
      </Box>
      {app.reverse && (
        <Box
          style={{
            width: "8.1%",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer"
          }}
          onClick={() => goHome(true, true)}
        >
          <img
            className={"topLogo"}
            style={{ maxWidth: "100%" }}
            src={mx_logo}
            alt="MX Election 2024"
          />
        </Box>
      )}
    </Box>
  );
};

export default TopBar;
