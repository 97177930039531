import Clara_Brugada from "../assets/img/candidates/governors/2024/CDMX/Clara Brugada.png";

import Salomon_Chertorivski from "../assets/img/candidates/governors/2024/CDMX/Salomón Chertorivski.png";

import Santiago_Taboada from "../assets/img/candidates/governors/2024/CDMX/Santiago Taboada.png";

import Eduardo_Ramirez_Aguilar from "../assets/img/candidates/governors/2024/Chiapas/Eduardo Ramírez Aguilar.png";

import Olga_Luz_Espinosa_Morales from "../assets/img/candidates/governors/2024/Chiapas/Olga Luz Espinosa Morales.png";

import Alma_Alcaraz_Hernandez from "../assets/img/candidates/governors/2024/Guanajuato/Alma Alcaráz Hernández.png";

import Libia_Dennise_Garcia_Munoz_Ledo from "../assets/img/candidates/governors/2024/Guanajuato/Libia Dennise García Muñoz Ledo.png";

import Yulma_Rocha_Aguilar from "../assets/img/candidates/governors/2024/Guanajuato/Yulma Rocha Aguilar.png";

import Claudia_Delgadillo from "../assets/img/candidates/governors/2024/Jalisco/Claudia Delgadillo.png";

import Laura_Haro_Ramirez from "../assets/img/candidates/governors/2024/Jalisco/Laura Haro Ramírez.png";

import Pablo_Lemus from "../assets/img/candidates/governors/2024/Jalisco/Pablo Lemus.png";

import Jessica_Ortega_de_la_Cruz from "../assets/img/candidates/governors/2024/Morelos/Jessica Ortega de la Cruz.png";

import Margarita_Gonzalez_Saravia from "../assets/img/candidates/governors/2024/Morelos/Margarita González Saravia.png";

import Lucia_Lucy_Meza_Guzman from "../assets/img/candidates/governors/2024/Morelos/Lucía “Lucy” Meza Guzmán.png";

import Alejandro_Armenta from "../assets/img/candidates/governors/2024/Puebla/Alejandro Armenta.png";

import Fernando_Fer_Morales_Martinez from "../assets/img/candidates/governors/2024/Puebla/Fernando “Fer” Morales Martínez.png";

import Eduardo_Lalo_Rivera from "../assets/img/candidates/governors/2024/Puebla/Eduardo “Lalo” Rivera.png";

import Javier_May_Rodriguez from "../assets/img/candidates/governors/2024/Tabasco/Javier May Rodríguez.png";

import Lorena_Beaurregard_de_los_Santos from "../assets/img/candidates/governors/2024/Tabasco/Lorena Beaurregard de los Santos.png";

import Juan_Manuel_Focil from "../assets/img/candidates/governors/2024/Tabasco/Juan Manuel Fócil.png";

import Maria_Ines_Mines_de_la_Fuente_Dagdug from "../assets/img/candidates/governors/2024/Tabasco/María Inés “Minés” de la Fuente Dagdug.png";

import Hipolito_Polo_Deschamps from "../assets/img/candidates/governors/2024/Veracruz/Hipólito “Polo” Deschamps.png";

import Rocio_Nahle_Garcia from "../assets/img/candidates/governors/2024/Veracruz/Rocío Nahle García.png";

import Jose_Francisco_Yunes_Zorrilla from "../assets/img/candidates/governors/2024/Veracruz/José Francisco Yunes Zorrilla.png";

import Joaquin_Huacho_Diaz_Mena from "../assets/img/candidates/governors/2024/Yucatan/Joaquín “Huacho” Díaz Mena.png";

import Vida_Aravari_Gomez_Herrera from "../assets/img/candidates/governors/2024/Yucatan/Vida Aravari Gómez Herrera.png";

import Renan_Barrera_Concha from "../assets/img/candidates/governors/2024/Yucatan/Renan Barrera Concha.png";

// Additional images from the provided list that correspond to existing candidates, imported with a unique identifier

// Chiapas
import Olga_Luz_Espinosa from "../assets/img/candidates/governors/2024/Chiapas/Olga Luz Espinosa.png";
import Karla_Irasema_Munoz from '../assets/img/candidates/governors/2024/Chiapas/Karla Irasema Muñoz.png';
import Eduardo_Ramirez from '../assets/img/candidates/governors/2024/Chiapas/Eduardo Ramírez.png';

// Guanajuato
import Alma_Alcaraz from "../assets/img/candidates/governors/2024/Guanajuato/Alma Alcaráz.png";
import Libia_Dennise_Garcia from "../assets/img/candidates/governors/2024/Guanajuato/Libia Dennise García.png";
import Yulma_Rocha from "../assets/img/candidates/governors/2024/Guanajuato/Yulma Rocha.png";

// Jalisco
import Laura_Haro from "../assets/img/candidates/governors/2024/Jalisco/Laura Haro.png";

// Morelos
import Jessica_Ortega from "../assets/img/candidates/governors/2024/Morelos/Jessica Ortega.png";
import Lucia_Meza from "../assets/img/candidates/governors/2024/Morelos/Lucía Meza.png";
import Margarita_Gonzalez from "../assets/img/candidates/governors/2024/Morelos/Margarita González Saravia.png";

// Puebla
import Eduardo_Rivera from "../assets/img/candidates/governors/2024/Puebla/Eduardo Rivera.png";
import Fernando_Morales from "../assets/img/candidates/governors/2024/Puebla/Fernando Morales.png";

// Tabasco
import Javier_May from "../assets/img/candidates/governors/2024/Tabasco/Javier May.png";
import Lorena_Beaurregard from "../assets/img/candidates/governors/2024/Tabasco/Lorena Beaurregard.png";
import Maria_Ines_Mines_de_la_Fuente from "../assets/img/candidates/governors/2024/Tabasco/María Inés “Minés” de la Fuente.png";

// Yucatan
import Vida_Gomez_Herrera from "../assets/img/candidates/governors/2024/Yucatan/Vida Gómez Herrera.png";
import Jazmin_Lopez_Tina_Tuyub from '../assets/img/candidates/governors/2024/Yucatan/Jazmín López “Tina Tuyub”.png';

const CandidateImgs2024 = {
    Clara_Brugada,
    Salomon_Chertorivski,
    Santiago_Taboada,
    Eduardo_Ramirez_Aguilar,
    Eduardo_Ramirez, // Correctly included now
    Olga_Luz_Espinosa_Morales,
    Olga_Luz_Espinosa, // Additional
    Alma_Alcaraz_Hernandez,
    Alma_Alcaraz, // Additional
    Libia_Dennise_Garcia_Munoz_Ledo,
    Libia_Dennise_Garcia, // Additional
    Yulma_Rocha_Aguilar,
    Yulma_Rocha, // Additional
    Claudia_Delgadillo,
    Laura_Haro_Ramirez,
    Laura_Haro, // Additional
    Pablo_Lemus,
    Jessica_Ortega_de_la_Cruz,
    Jessica_Ortega, // Additional
    Margarita_Gonzalez_Saravia,
    Margarita_Gonzalez, // Additional
    Lucia_Lucy_Meza_Guzman,
    Lucia_Meza, // Additional
    Alejandro_Armenta,
    Fernando_Fer_Morales_Martinez,
    Fernando_Morales, // Additional
    Eduardo_Lalo_Rivera,
    Eduardo_Rivera, // Additional
    Javier_May_Rodriguez,
    Javier_May, // Additional
    Lorena_Beaurregard_de_los_Santos,
    Lorena_Beaurregard, // Additional
    Juan_Manuel_Focil,
    Maria_Ines_Mines_de_la_Fuente_Dagdug,
    Maria_Ines_Mines_de_la_Fuente, // Additional
    Hipolito_Polo_Deschamps,    
    Rocio_Nahle_Garcia,
    Jose_Francisco_Yunes_Zorrilla,
    Joaquin_Huacho_Diaz_Mena,
    Vida_Aravari_Gomez_Herrera,
    Vida_Gomez_Herrera, // Additional
    Renan_Barrera_Concha,
    Karla_Irasema_Munoz, // Previously added
    Jazmin_Lopez_Tina_Tuyub // Previously added
};

export default CandidateImgs2024;
