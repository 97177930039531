import { useEffect, useState } from "react";
import { Dialog, Box, Text } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import Papa from "papaparse";
import { FormattedMessage } from "react-intl";

import RaceData from "../../../mapbox/racechart";
import { PartyColors } from "../../../mapbox/colors";

const RaceboardNationalTurnout = ({ open, onClose, app }) => {
  const dispatch = useDispatch();
  const year = useSelector((state) => state.year.value);
  const raceType = useSelector((state) => state.raceType.value);

  const [turnout, setTurnout] = useState(0);
  const [votes, setVotes] = useState(0);

  useEffect(() => {
    setTurnout(0);
    setVotes(0);

    if (RaceData[`${raceType}${year}`]) {
      Papa.parse(RaceData[`${raceType}${year}`], {
        download: true,
        complete: function (input) {
          let records = input.data;
          if (records[0]) {
            setTurnout(parseFloat(records[0]?.TURNOUT || 0));
            setVotes(parseInt((records[0]?.VALIDATES || records[0]?.VALID_VOTES || records[0]?.LISTA_NOMINAL || "0").replaceAll(",", "")) || 0);
          }
        },
        header: true
      });
    }
  }, [year, raceType, dispatch]);

  return (
    <Dialog
      id={"raceboardNT"}
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={0}
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: "0px !important",
        fontSize: "16px",
        color: "#111111",
        top: window.innerWidth > 3000 ? 236 : 89,
        width: "auto",
        background: "transparent"
      }}
    >
      <Box
        sx={{
          width: window.innerWidth > 3000 ? 960 : 480,
          marginRight: window.innerWidth > 3000 ? 16 : 4
        }}
      >
        <Box className={"raceboardTurnout"}>
          <Box
            className={"raceboardNTTitle"}
            sx={{
              fontSize: "39px",
              textAlign: "center",
              lineHeight: "96px",
              height: "96px"
            }}
          >
            <FormattedMessage id={"NationalResults"} />
          </Box>
        </Box>
        <Box className={"raceboardTurnout"}>
          <Box
            className={"partyBoxNT"}
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              marginTop: "4px",
              backgroundColor: "rgba(255,255,255,0.8)"
            }}
          >
            <Box>
              <Text className={"voteNumTurnout"}>
                <FormattedMessage
                  id={"Votes2"}
                  values={{
                    votes: votes.toLocaleString("en")
                  }}
                />
              </Text>
            </Box>
            <Box
              sx={{
                padding: window.innerWidth > 3000 ? 20 : 10,
                width:
                  window.innerWidth > 3000
                    ? "calc(100% - 40px)"
                    : "calc(100% - 20px)",
                marginTop: window.innerWidth > 3000 ? "2rem" : "1rem"
              }}
            >
              <Text
                className={"percentNumTurnout"}
                sx={{ borderBottom: `solid 10px ${PartyColors.INE.high}` }}
              >
                {Math.round(turnout) || 0}
                <span className={"percent"}>%</span>
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      {app?.raceboard?.title ? (
        <Box sx={{ width: window.innerWidth > 3000 ? 1040 : 520 }}>
          <Box className={"raceboardTurnout"}>
            <Box
              className={"raceboardNTSubtitle"}
              sx={{
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "28px",
                height: "28px"
              }}
            >
              {app.raceboard.subtitle1 || ""}
            </Box>
            <Box
              className={"raceboardNTTitleState"}
              sx={{
                fontSize: "39px",
                textAlign: "center",
                lineHeight: "40px",
                height: "40px"
              }}
            >
              {app.raceboard.title || ""}
            </Box>
            <Box
              className={"raceboardNTSubtitle"}
              sx={{
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "28px",
                height: "28px"
              }}
            >
              {app.raceboard.subtitle2 || ""}
            </Box>
          </Box>
          <Box className={"raceboardTurnout"}>
            <Box
              className={"partyBoxNT"}
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                marginTop: "4px",
                backgroundColor: "rgba(255,255,255,0.8)"
              }}
            >
              <Box>
                <Text className={"voteNumTurnout"}>
                  <FormattedMessage
                    id={"Votes2"}
                    values={{
                      votes: app.raceboard.totalVote.toLocaleString("en")
                    }}
                  />
                </Text>
              </Box>
              <Box
                sx={{
                  padding: window.innerWidth > 3000 ? 20 : 10,
                  width:
                    window.innerWidth > 3000
                      ? "calc(100% - 40px)"
                      : "calc(100% - 20px)",
                  marginTop: window.innerWidth > 3000 ? "2rem" : "1rem"
                }}
              >
                <Text
                  className={"percentNumTurnout"}
                  sx={{ borderBottom: `solid 10px ${PartyColors.INE.high}` }}
                >
                  {Math.round(app.raceboard.turnoutPercent) || 0}
                  <span className={"percent"}>%</span>
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : undefined}
    </Dialog>
  );
};

export default RaceboardNationalTurnout;
