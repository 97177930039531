import { Parties } from "./parties";

export const PartyColors = {
  Tie: { low: "#ffffff", high: "#ffffff", contrast: "#000" },
  "No cómputo": { low: "#000000", high: "#000000", contrast: "#fff" },
  PRI: {
    low: "#FFADB3",
    mid: "#FF7582",
    high: "#FF2E36",
    contrast: "#fff"
  },
  MORENA: {
    low: "#C18689",
    mid: "#CF4952",
    high: "#B01213",
    contrast: "#fff"
  },
  Morena: {
    low: "#C18689",
    mid: "#CF4952",
    high: "#B01213",
    contrast: "#fff"
  },
  PAN: { low: "#80AAEA", mid: "#478DF7", high: "#165FDE", contrast: "#fff" },
  PRD: {
    low: "#FFEC94",
    mid: "#F7DE74",
    high: "#FED500",
    contrast: "#000",
    boardText: "#6F8D9D"
  },
  MC: { low: "#FFC485", mid: "#FFB15E", high: "#FF8F17", contrast: "#fff" },
  PVEM: { low: "#CFFFBF", mid: "#96D681", high: "#4DCF21", contrast: "#fff" },
  PT: { low: "#F2C6C2", mid: "#F2B0AB", high: "#E39288", contrast: "#fff" },
  FXM: { low: "#FFB3DD", mid: "#FF85C9", high: "#FF5EB4", contrast: "#fff" },
  PES: { low: "#B08FC7", mid: "#9B59C7", high: "#822ABD", contrast: "#fff" },
  ES: { low: "#B08FC7", mid: "#9B59C7", high: "#822ABD", contrast: "#fff" },
  PES2015: {
    low: "#B08FC7",
    mid: "#9B59C7",
    high: "#822ABD",
    contrast: "#fff"
  },
  RSP: { low: "#DCDEE6", mid: "#ABADB3", high: "#7D7F82", contrast: "#fff" },
  "PAN-PRI-PRD": {
    low: "#AFE2E6",
    mid: "#62DAE3",
    high: "#00C1CF",
    contrast: "#fff"
  },
  INE: { lowest: "#ffdbf4", low: "#ff99e1", mid: "#ff66d4", high: "#FF0AAB", contrast: "#fff" },
  PANAL: {
    low: "#AFE2E6",
    mid: "#62DAE3",
    high: "#00C1CF",
    contrast: "#fff"
  },
  NA: {
    low: "#AFE2E6",
    mid: "#62DAE3",
    high: "#00C1CF",
    contrast: "#fff"
  },
  "PRI-PVEM": {
    low: "#FFADB3",
    mid: "#FF7582",
    high: "#FF2E36",
    contrast: "#fff"
  },
  "PRI-PVEM-PANAL": {
    low: "#FFADB3",
    mid: "#FF7582",
    high: "#FF2E36",
    contrast: "#fff"
  },
  "PAN-PANAL": {
    low: "#80AAEA",
    mid: "#478DF7",
    high: "#165FDE",
    contrast: "#fff"
  },
  "PAN-PRD-MC": {
    low: "#80AAEA",
    mid: "#478DF7",
    high: "#165FDE",
    contrast: "#fff"
  },
  "PRD-PT": {
    low: "#FFEC94",
    mid: "#F7DE74",
    high: "#FED500",
    contrast: "#000",
    boardText: "#6F8D9D"
  },
  "PRD-PT-MC": {
    low: "#FFEC94",
    mid: "#F7DE74",
    high: "#FED500",
    contrast: "#000",
    boardText: "#6F8D9D"
  },
  "PRD-PT-CONVERGENCIA": {
    low: "#FFEC94",
    mid: "#F7DE74",
    high: "#FED500",
    contrast: "#000",
    boardText: "#6F8D9D"
  },
  "PT-CONVERGENCIA": {
    low: "#F2C6C2",
    mid: "#F2B0AB",
    high: "#E39288",
    contrast: "#fff"
  },
  ASDC: { low: "#DCDEE6", mid: "#ABADB3", high: "#7D7F82", contrast: "#fff" },
  "MORENA-PT-PES": {
    low: "#C18689",
    mid: "#CF4952",
    high: "#B01213",
    contrast: "#fff"
  },
  "MORENA-PT-PVEM": {
    low: "#C18689",
    mid: "#CF4952",
    high: "#B01213",
    contrast: "#fff"
  },
  PSD: { low: "#DCDEE6", mid: "#ABADB3", high: "#7D7F82", contrast: "#fff" },
  PAS: { high: "#340874" },
  Independiente: {
    low: "#DCDEE6",
    mid: "#ABADB3",
    high: "#7D7F82",
    contrast: "#fff"
  },
  PH: { low: "#FFB3DD", mid: "#FF85C9", high: "#FF5EB4", contrast: "#fff" },
  CONVERGENCIA: {
    low: "#FFC485",
    mid: "#FFB15E",
    high: "#FF8F17",
    contrast: "#fff"
  },
  FUTURO: {
    low: "#f25d68",
    mid: "#f25d68",
    high: "#f25d68",
    contrast: "#fff"
  },
  HAGAMOS: {
    low: "#903094",
    mid: "#903094",
    high: "#903094",
    contrast: "#fff"
  },
  SHH: {
    low: "#f25d68",
    mid: "#f25d68",
    high: "#f25d68",
    contrast: "#fff"
  },
  Neutral: {
    low: "#4C515E",
    mid: "#4C515E",
    high: "#4C515E",
    contrast: "#fff"
  },
  TOOEARLY: {
    low: "#4C515E",
    mid: "#4C515E",
    high: "#4C515E",
    contrast: "#fff"
  },
  OTHER: { low: "#DCDEE6", mid: "#ABADB3", high: "#7D7F82", contrast: "#fff" }
};

//export const GeneralFillColors = {
export const GeneralFillColors = (
  raceType,
  winners = [],
  neutrals = [],
  show2023Result = false
) => {
  const gcolors = {
    pres: [
      "case",
      ["==", ["get", "TIE"], "TRUE"],
      "white",
      ["==", ["get", "TIE"], true],
      "white",
      ["==", ["get", "FIRST"], "No cómputo"],
      "black",
      ["==", ["get", "FIRST_PARTY"], "PRI"],
      ["to-color", PartyColors["PRI"].high],
      ["==", ["get", "FIRST_PARTY"], "MORENA"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST_PARTY"], "PAN"],
      ["to-color", PartyColors["PAN"].high],
      ["==", ["get", "FIRST_PARTY"], "PRD"],
      ["to-color", PartyColors["PRD"].high],
      ["==", ["get", "FIRST_PARTY"], "MC"],
      ["to-color", PartyColors["MC"].high],
      ["==", ["get", "FIRST_PARTY"], "PVEM"],
      ["to-color", PartyColors["PVEM"].high],
      ["==", ["get", "FIRST_PARTY"], "PT"],
      ["to-color", PartyColors["PT"].high],
      ["==", ["get", "FIRST_PARTY"], "FXM"],
      ["to-color", PartyColors["FXM"].high],
      ["==", ["get", "FIRST_PARTY"], "PES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_PARTY"], "ES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_PARTY"], "RSP"],
      ["to-color", PartyColors["RSP"].high],
      ["==", ["get", "FIRST_PARTY"], "PAN-PRI-PRD"],
      ["to-color", PartyColors["PAN-PRI-PRD"].high],
      ["==", ["get", "FIRST_PARTY"], "INE"],
      ["to-color", PartyColors["INE"].high],
      ["==", ["get", "FIRST_PARTY"], "PANAL"],
      ["to-color", PartyColors["PANAL"].high],
      ["==", ["get", "FIRST_PARTY"], "PRI-PVEM"],
      ["to-color", PartyColors["PRI-PVEM"].high],
      ["==", ["get", "FIRST_PARTY"], "PRI-PVEM-PANAL"],
      ["to-color", PartyColors["PRI-PVEM-PANAL"].high],
      ["==", ["get", "FIRST_PARTY"], "PAN-PANAL"],
      ["to-color", PartyColors["PAN-PANAL"].high],
      ["==", ["get", "FIRST_PARTY"], "PAN-PRD-MC"],
      ["to-color", PartyColors["PAN-PRD-MC"].high],
      ["==", ["get", "FIRST_PARTY"], "PRD-PT"],
      ["to-color", PartyColors["PRD-PT"].high],
      ["==", ["get", "FIRST_PARTY"], "PRD-PT-MC"],
      ["to-color", PartyColors["PRD-PT-MC"].high],
      ["==", ["get", "FIRST_PARTY"], "PRD-PT-CONVERGENCIA"],
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high],
      ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PES"],
      ["to-color", PartyColors["MORENA-PT-PES"].high],
      ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PVEM"],
      ["to-color", PartyColors["MORENA-PT-PVEM"].high],
      "black"
    ],
    sen: [
      "case",
      ["==", ["get", "TIE"], "TRUE"],
      "white",
      ["==", ["get", "TIE"], true],
      "white",
      ["==", ["get", "FIRST"], "No cómputo"],
      "black",
      ["==", ["get", "FIRST_PARTIES"], "PRI"],
      ["to-color", PartyColors["PRI"].high],
      ["==", ["get", "FIRST_PARTIES"], "MORENA"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST_PARTIES"], "PAN"],
      ["to-color", PartyColors["PAN"].high],
      ["==", ["get", "FIRST_PARTIES"], "PRD"],
      ["to-color", PartyColors["PRD"].high],
      ["==", ["get", "FIRST_PARTIES"], "MC"],
      ["to-color", PartyColors["MC"].high],
      ["==", ["get", "FIRST_PARTIES"], "PVEM"],
      ["to-color", PartyColors["PVEM"].high],
      ["==", ["get", "FIRST_PARTIES"], "PT"],
      ["to-color", PartyColors["PT"].high],
      ["==", ["get", "FIRST_PARTIES"], "FXM"],
      ["to-color", PartyColors["FXM"].high],
      ["==", ["get", "FIRST_PARTIES"], "PES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_PARTIES"], "ES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_PARTIES"], "RSP"],
      ["to-color", PartyColors["RSP"].high],
      ["==", ["get", "FIRST_PARTIES"], "PAN-PRI-PRD"],
      ["to-color", PartyColors["PAN-PRI-PRD"].high],
      ["==", ["get", "FIRST_PARTIES"], "INE"],
      ["to-color", PartyColors["INE"].high],
      ["==", ["get", "FIRST_PARTIES"], "PANAL"],
      ["to-color", PartyColors["PANAL"].high],
      ["==", ["get", "FIRST_PARTIES"], "PRI-PVEM"],
      ["to-color", PartyColors["PRI-PVEM"].high],
      ["==", ["get", "FIRST_PARTIES"], "PRI-PVEM-PANAL"],
      ["to-color", PartyColors["PRI-PVEM-PANAL"].high],
      ["==", ["get", "FIRST_PARTIES"], "PAN-PANAL"],
      ["to-color", PartyColors["PAN-PANAL"].high],
      ["==", ["get", "FIRST_PARTIES"], "PAN-PRD-MC"],
      ["to-color", PartyColors["PAN-PRD-MC"].high],
      ["==", ["get", "FIRST_PARTIES"], "PRD-PT"],
      ["to-color", PartyColors["PRD-PT"].high],
      ["==", ["get", "FIRST_PARTIES"], "PRD-PT-MC"],
      ["to-color", PartyColors["PRD-PT-MC"].high],
      ["==", ["get", "FIRST_PARTIES"], "PRD-PT-CONVERGENCIA"],
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high],
      ["==", ["get", "FIRST_PARTIES"], "MORENA-PT-PES"],
      ["to-color", PartyColors["MORENA-PT-PES"].high],
      ["==", ["get", "FIRST_PARTIES"], "MORENA-PT-PVEM"],
      ["to-color", PartyColors["MORENA-PT-PVEM"].high],
      ["to-color", PartyColors.Independiente.high]
    ],
    cong: [
      "case",
      ["==", ["get", "TIE"], true],
      "white",
      ["==", ["get", "TIE"], "TRUE"],
      "white",
      ["==", ["get", "FIRST"], "No cómputo"],
      "black",
      ["==", ["get", "FIRST"], "PRI"],
      ["to-color", PartyColors["PRI"].high],
      ["==", ["get", "FIRST"], "MORENA"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST"], "Morena"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST"], "PAN"],
      ["to-color", PartyColors["PAN"].high],
      ["==", ["get", "FIRST"], "PRD"],
      ["to-color", PartyColors["PRD"].high],
      ["==", ["get", "FIRST"], "MC"],
      ["to-color", PartyColors["MC"].high],
      ["==", ["get", "FIRST"], "PVEM"],
      ["to-color", PartyColors["PVEM"].high],
      ["==", ["get", "FIRST"], "PT"],
      ["to-color", PartyColors["PT"].high],
      ["==", ["get", "FIRST"], "FXM"],
      ["to-color", PartyColors["FXM"].high],
      ["==", ["get", "FIRST"], "PES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST"], "ES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST"], "RSP"],
      ["to-color", PartyColors["RSP"].high],
      ["==", ["get", "FIRST"], "PAN-PRI-PRD"],
      ["to-color", PartyColors["PAN-PRI-PRD"].high],
      ["==", ["get", "FIRST"], "INE"],
      ["to-color", PartyColors["INE"].high],
      ["==", ["get", "FIRST"], "PANAL"],
      ["to-color", PartyColors["PANAL"].high],
      ["==", ["get", "FIRST"], "PRI-PVEM"],
      ["to-color", PartyColors["PRI-PVEM"].high],
      ["==", ["get", "FIRST"], "PRI-PVEM-PANAL"],
      ["to-color", PartyColors["PRI-PVEM-PANAL"].high],
      ["==", ["get", "FIRST"], "PAN-PANAL"],
      ["to-color", PartyColors["PAN-PANAL"].high],
      ["==", ["get", "FIRST"], "PAN-PRD-MC"],
      ["to-color", PartyColors["PAN-PRD-MC"].high],
      ["==", ["get", "FIRST"], "PRD-PT"],
      ["to-color", PartyColors["PRD-PT"].high],
      ["==", ["get", "FIRST"], "PRD-PT-MC"],
      ["to-color", PartyColors["PRD-PT-MC"].high],
      ["==", ["get", "FIRST"], "PRD-PT-CONVERGENCIA"],
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high],
      ["==", ["get", "FIRST"], "MORENA-PT-PES"],
      ["to-color", PartyColors["MORENA-PT-PES"].high],
      ["==", ["get", "FIRST"], "MORENA-PT-PVEM"],
      ["to-color", PartyColors["MORENA-PT-PVEM"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PRI"],
      ["to-color", PartyColors["PRI"].high],
      ["==", ["get", "FIRST_DISPLAY"], "MORENA"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST_DISPLAY"], "Morena"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PAN"],
      ["to-color", PartyColors["PAN"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PRD"],
      ["to-color", PartyColors["PRD"].high],
      ["==", ["get", "FIRST_DISPLAY"], "MC"],
      ["to-color", PartyColors["MC"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PVEM"],
      ["to-color", PartyColors["PVEM"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PT"],
      ["to-color", PartyColors["PT"].high],
      ["==", ["get", "FIRST_DISPLAY"], "FXM"],
      ["to-color", PartyColors["FXM"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_DISPLAY"], "ES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_DISPLAY"], "RSP"],
      ["to-color", PartyColors["RSP"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PAN-PANAL"],
      ["to-color", PartyColors["PAN-PANAL"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PAN-PRI-PRD"],
      ["to-color", PartyColors["PAN-PRI-PRD"].high],
      ["==", ["get", "FIRST_DISPLAY"], "INE"],
      ["to-color", PartyColors["INE"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PANAL"],
      ["to-color", PartyColors["PANAL"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PRI-PVEM"],
      ["to-color", PartyColors["PRI-PVEM"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PRI-PVEM-PANAL"],
      ["to-color", PartyColors["PRI-PVEM-PANAL"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PAN-PRD-MC"],
      ["to-color", PartyColors["PAN-PRD-MC"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PRD-PT"],
      ["to-color", PartyColors["PRD-PT"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PRD-PT-MC"],
      ["to-color", PartyColors["PRD-PT-MC"].high],
      ["==", ["get", "FIRST_DISPLAY"], "PRD-PT-CONVERGENCIA"],
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high],
      ["==", ["get", "FIRST_DISPLAY"], "MORENA-PT-PES"],
      ["to-color", PartyColors["MORENA-PT-PES"].high],
      ["==", ["get", "FIRST_DISPLAY"], "MORENA-PT-PVEM"],
      ["to-color", PartyColors["MORENA-PT-PVEM"].high],
      ["==", ["get", "FIRST"], "PT-CONVERGENCIA"],
      ["to-color", PartyColors["PT-CONVERGENCIA"].high],
      ["==", ["get", "FIRST"], "PH"],
      ["to-color", PartyColors["PH"].high],
      ["to-color", PartyColors.Independiente.high]
    ],
    gov: [
      "case",
      ["==", ["get", "TIE"], "TRUE"],
      "white",
      ["==", ["get", "TIE"], true],
      "white",
      ["==", ["get", "FIRST"], "No cómputo"],
      "black",
      ["==", ["get", "STATE_DISPLAY"], "PRI"],
      ["to-color", PartyColors["PRI"].high],
      ["==", ["get", "STATE_DISPLAY"], "MORENA"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "STATE_DISPLAY"], "Morena"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "STATE_DISPLAY"], "PAN"],
      ["to-color", PartyColors["PAN"].high],
      ["==", ["get", "STATE_DISPLAY"], "PRD"],
      ["to-color", PartyColors["PRD"].high],
      ["==", ["get", "STATE_DISPLAY"], "MC"],
      ["to-color", PartyColors["MC"].high],
      ["==", ["get", "STATE_DISPLAY"], "PVEM"],
      ["to-color", PartyColors["PVEM"].high],
      ["==", ["get", "STATE_DISPLAY"], "PT"],
      ["to-color", PartyColors["PT"].high],
      ["==", ["get", "STATE_DISPLAY"], "FXM"],
      ["to-color", PartyColors["FXM"].high],
      ["==", ["get", "STATE_DISPLAY"], "PES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "STATE_DISPLAY"], "ES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "STATE_DISPLAY"], "RSP"],
      ["to-color", PartyColors["RSP"].high],
      ["==", ["get", "STATE_DISPLAY"], "PAN-PRI-PRD"],
      ["to-color", PartyColors["PAN-PRI-PRD"].high],
      ["==", ["get", "STATE_DISPLAY"], "INE"],
      ["to-color", PartyColors["INE"].high],
      ["==", ["get", "STATE_DISPLAY"], "PANAL"],
      ["to-color", PartyColors["PANAL"].high],
      ["==", ["get", "STATE_DISPLAY"], "PRI-PVEM"],
      ["to-color", PartyColors["PRI-PVEM"].high],
      ["==", ["get", "STATE_DISPLAY"], "PRI-PVEM-PANAL"],
      ["to-color", PartyColors["PRI-PVEM-PANAL"].high],
      ["==", ["get", "STATE_DISPLAY"], "PAN-PANAL"],
      ["to-color", PartyColors["PAN-PANAL"].high],
      ["==", ["get", "STATE_DISPLAY"], "PAN-PRD-MC"],
      ["to-color", PartyColors["PAN-PRD-MC"].high],
      ["==", ["get", "STATE_DISPLAY"], "PRD-PT"],
      ["to-color", PartyColors["PRD-PT"].high],
      ["==", ["get", "STATE_DISPLAY"], "PRD-PT-MC"],
      ["to-color", PartyColors["PRD-PT-MC"].high],
      ["==", ["get", "STATE_DISPLAY"], "PRD-PT-CONVERGENCIA"],
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high],
      ["==", ["get", "STATE_DISPLAY"], "MORENA-PT-PES"],
      ["to-color", PartyColors["MORENA-PT-PES"].high],
      ["==", ["get", "STATE_DISPLAY"], "MORENA-PT-PVEM"],
      ["to-color", PartyColors["MORENA-PT-PVEM"].high],
      ["==", ["get", "FIRST_PARTY"], "PRI"],
      ["to-color", PartyColors["PRI"].high],
      ["==", ["get", "FIRST_PARTY"], "MORENA"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST_PARTY"], "Morena"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST_PARTY"], "PAN"],
      ["to-color", PartyColors["PAN"].high],
      ["==", ["get", "FIRST_PARTY"], "PRD"],
      ["to-color", PartyColors["PRD"].high],
      ["==", ["get", "FIRST_PARTY"], "MC"],
      ["to-color", PartyColors["MC"].high],
      ["==", ["get", "FIRST_PARTY"], "PVEM"],
      ["to-color", PartyColors["PVEM"].high],
      ["==", ["get", "FIRST_PARTY"], "PT"],
      ["to-color", PartyColors["PT"].high],
      ["==", ["get", "FIRST_PARTY"], "FXM"],
      ["to-color", PartyColors["FXM"].high],
      ["==", ["get", "FIRST_PARTY"], "PES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_PARTY"], "ES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_PARTY"], "RSP"],
      ["to-color", PartyColors["RSP"].high],
      ["==", ["get", "FIRST_PARTY"], "PAN-PRI-PRD"],
      ["to-color", PartyColors["PAN-PRI-PRD"].high],
      ["==", ["get", "FIRST_PARTY"], "INE"],
      ["to-color", PartyColors["INE"].high],
      ["==", ["get", "FIRST_PARTY"], "PANAL"],
      ["to-color", PartyColors["PANAL"].high],
      ["==", ["get", "FIRST_PARTY"], "PRI-PVEM"],
      ["to-color", PartyColors["PRI-PVEM"].high],
      ["==", ["get", "FIRST_PARTY"], "PRI-PVEM-PANAL"],
      ["to-color", PartyColors["PRI-PVEM-PANAL"].high],
      ["==", ["get", "FIRST_PARTY"], "PAN-PANAL"],
      ["to-color", PartyColors["PAN-PANAL"].high],
      ["==", ["get", "FIRST_PARTY"], "PAN-PRD-MC"],
      ["to-color", PartyColors["PAN-PRD-MC"].high],
      ["==", ["get", "FIRST_PARTY"], "PRD-PT"],
      ["to-color", PartyColors["PRD-PT"].high],
      ["==", ["get", "FIRST_PARTY"], "PRD-PT-MC"],
      ["to-color", PartyColors["PRD-PT-MC"].high],
      ["==", ["get", "FIRST_PARTY"], "PRD-PT-CONVERGENCIA"],
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high],
      ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PES"],
      ["to-color", PartyColors["MORENA-PT-PES"].high],
      ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PVEM"],
      ["to-color", PartyColors["MORENA-PT-PVEM"].high],
      ["==", ["get", "FIRST_PARTY"], "CONVERGENCIA"],
      ["to-color", PartyColors["CONVERGENCIA"].high],
      ["to-color", PartyColors.Independiente.high]
    ],
    gov_2024: [
      "case",
      ["==", ["get", "TIE"], "TRUE"],
      "white",
      ["==", ["get", "TIE"], true],
      "white",
      ["==", ["get", "FIRST"], "No cómputo"],
      "black",
      show2023Result
        ? ["==", ["get", "NOMBRE"], "CHIHUAHUA"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["PAN"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "SONORA"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "BAJA CALIFORNIA"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "BAJA CALIFORNIA SUR"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "SINALOA"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "NAYARIT"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "ZACATECAS"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "TAMAULIPAS"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "COLIMA"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "MICHOACAN"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "MEXICO"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "HIDALGO"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "TLAXCALA"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "GUERRERO"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "OAXACA"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "CAMPECHE"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "QUINTANA ROO"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MORENA"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "DURANGO"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["PRI"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "COAHUILA"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["PRI"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "AGUASCALIENTES"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["PAN"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "QUERETARO"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["PAN"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "NUEVO LEON"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["MC"].high]
        : ["to-color", "#fff"],
      show2023Result
        ? ["==", ["get", "NOMBRE"], "SAN LUIS POTOSI"]
        : ["==", ["get", "NOMBRE"], false],
      show2023Result
        ? ["to-color", PartyColors["PVEM"].high]
        : ["to-color", "#fff"],
      neutrals["Jalisco"] || winners["Jalisco"]
        ? ["==", ["get", "NOMBRE"], "JALISCO"]
        : ["==", ["get", "NOMBRE"], false],
      neutrals["Jalisco"]
        ? ["to-color", "#4C515E"]
        : winners["Jalisco"]
        ? ["to-color", PartyColors[winners["Jalisco"].party].high]
        : ["to-color", "#fff"],
      neutrals["Guanajuato"] || winners["Guanajuato"]
        ? ["==", ["get", "NOMBRE"], "GUANAJUATO"]
        : ["==", ["get", "NOMBRE"], false],
      neutrals["Guanajuato"]
        ? ["to-color", "#4C515E"]
        : winners["Guanajuato"]
        ? ["to-color", PartyColors[winners["Guanajuato"].party].high]
        : ["to-color", "#fff"],
      neutrals["Veracruz"] || winners["Veracruz"]
        ? ["==", ["get", "NOMBRE"], "VERACRUZ"]
        : ["==", ["get", "NOMBRE"], false],
      neutrals["Veracruz"]
        ? ["to-color", "#4C515E"]
        : winners["Veracruz"]
        ? ["to-color", PartyColors[winners["Veracruz"].party].high]
        : ["to-color", "#fff"],
      neutrals["Puebla"] || winners["Puebla"]
        ? ["==", ["get", "NOMBRE"], "PUEBLA"]
        : ["==", ["get", "NOMBRE"], false],
      neutrals["Puebla"]
        ? ["to-color", "#4C515E"]
        : winners["Puebla"]
        ? ["to-color", PartyColors[winners["Puebla"].party].high]
        : ["to-color", "#fff"],
      neutrals["Ciudad De Mexico"] || winners["Ciudad De Mexico"]
        ? ["==", ["get", "NOMBRE"], "CIUDAD DE MEXICO"]
        : ["==", ["get", "NOMBRE"], false],
      neutrals["Ciudad De Mexico"]
        ? ["to-color", "#4C515E"]
        : winners["Ciudad De Mexico"]
        ? ["to-color", PartyColors[winners["Ciudad De Mexico"].party].high]
        : ["to-color", "#fff"],
      neutrals["Morelos"] || winners["Morelos"]
        ? ["==", ["get", "NOMBRE"], "MORELOS"]
        : ["==", ["get", "NOMBRE"], false],
      neutrals["Morelos"]
        ? ["to-color", "#4C515E"]
        : winners["Morelos"]
        ? ["to-color", PartyColors[winners["Morelos"].party].high]
        : ["to-color", "#fff"],
      neutrals["Tabasco"] || winners["Tabasco"]
        ? ["==", ["get", "NOMBRE"], "TABASCO"]
        : ["==", ["get", "NOMBRE"], false],
      neutrals["Tabasco"]
        ? ["to-color", "#4C515E"]
        : winners["Tabasco"]
        ? ["to-color", PartyColors[winners["Tabasco"].party].high]
        : ["to-color", "#fff"],
      neutrals["Chiapas"] || winners["Chiapas"]
        ? ["==", ["get", "NOMBRE"], "CHIAPAS"]
        : ["==", ["get", "NOMBRE"], false],
      neutrals["Chiapas"]
        ? ["to-color", "#4C515E"]
        : winners["Chiapas"]
        ? ["to-color", PartyColors[winners["Chiapas"].party].high]
        : ["to-color", "#fff"],
      neutrals["Yucatan"] || winners["Yucatan"]
        ? ["==", ["get", "NOMBRE"], "YUCATAN"]
        : ["==", ["get", "NOMBRE"], false],
      neutrals["Yucatan"]
        ? ["to-color", "#4C515E"]
        : winners["Yucatan"]
        ? ["to-color", PartyColors[winners["Yucatan"].party].high]
        : ["to-color", "#fff"],
      ["==", ["get", "STATE_DISPLAY"], "PRI"],
      ["to-color", PartyColors["PRI"].high],
      ["==", ["get", "STATE_DISPLAY"], "MORENA"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "STATE_DISPLAY"], "Morena"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "STATE_DISPLAY"], "PAN"],
      ["to-color", PartyColors["PAN"].high],
      ["==", ["get", "STATE_DISPLAY"], "PRD"],
      ["to-color", PartyColors["PRD"].high],
      ["==", ["get", "STATE_DISPLAY"], "MC"],
      ["to-color", PartyColors["MC"].high],
      ["==", ["get", "STATE_DISPLAY"], "PVEM"],
      ["to-color", PartyColors["PVEM"].high],
      ["==", ["get", "STATE_DISPLAY"], "PT"],
      ["to-color", PartyColors["PT"].high],
      ["==", ["get", "STATE_DISPLAY"], "FXM"],
      ["to-color", PartyColors["FXM"].high],
      ["==", ["get", "STATE_DISPLAY"], "PES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "STATE_DISPLAY"], "ES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "STATE_DISPLAY"], "RSP"],
      ["to-color", PartyColors["RSP"].high],
      ["==", ["get", "STATE_DISPLAY"], "PAN-PRI-PRD"],
      ["to-color", PartyColors["PAN-PRI-PRD"].high],
      ["==", ["get", "STATE_DISPLAY"], "INE"],
      ["to-color", PartyColors["INE"].high],
      ["==", ["get", "STATE_DISPLAY"], "PANAL"],
      ["to-color", PartyColors["PANAL"].high],
      ["==", ["get", "STATE_DISPLAY"], "PRI-PVEM"],
      ["to-color", PartyColors["PRI-PVEM"].high],
      ["==", ["get", "STATE_DISPLAY"], "PRI-PVEM-PANAL"],
      ["to-color", PartyColors["PRI-PVEM-PANAL"].high],
      ["==", ["get", "STATE_DISPLAY"], "PAN-PANAL"],
      ["to-color", PartyColors["PAN-PANAL"].high],
      ["==", ["get", "STATE_DISPLAY"], "PAN-PRD-MC"],
      ["to-color", PartyColors["PAN-PRD-MC"].high],
      ["==", ["get", "STATE_DISPLAY"], "PRD-PT"],
      ["to-color", PartyColors["PRD-PT"].high],
      ["==", ["get", "STATE_DISPLAY"], "PRD-PT-MC"],
      ["to-color", PartyColors["PRD-PT-MC"].high],
      ["==", ["get", "STATE_DISPLAY"], "PRD-PT-CONVERGENCIA"],
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high],
      ["==", ["get", "STATE_DISPLAY"], "MORENA-PT-PES"],
      ["to-color", PartyColors["MORENA-PT-PES"].high],
      ["==", ["get", "STATE_DISPLAY"], "MORENA-PT-PVEM"],
      ["to-color", PartyColors["MORENA-PT-PVEM"].high],
      ["==", ["get", "FIRST_PARTY"], "PRI"],
      ["to-color", PartyColors["PRI"].high],
      ["==", ["get", "FIRST_PARTY"], "MORENA"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST_PARTY"], "Morena"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST_PARTY"], "PAN"],
      ["to-color", PartyColors["PAN"].high],
      ["==", ["get", "FIRST_PARTY"], "PRD"],
      ["to-color", PartyColors["PRD"].high],
      ["==", ["get", "FIRST_PARTY"], "MC"],
      ["to-color", PartyColors["MC"].high],
      ["==", ["get", "FIRST_PARTY"], "PVEM"],
      ["to-color", PartyColors["PVEM"].high],
      ["==", ["get", "FIRST_PARTY"], "PT"],
      ["to-color", PartyColors["PT"].high],
      ["==", ["get", "FIRST_PARTY"], "FXM"],
      ["to-color", PartyColors["FXM"].high],
      ["==", ["get", "FIRST_PARTY"], "PES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_PARTY"], "ES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_PARTY"], "RSP"],
      ["to-color", PartyColors["RSP"].high],
      ["==", ["get", "FIRST_PARTY"], "PAN-PRI-PRD"],
      ["to-color", PartyColors["PAN-PRI-PRD"].high],
      ["==", ["get", "FIRST_PARTY"], "INE"],
      ["to-color", PartyColors["INE"].high],
      ["==", ["get", "FIRST_PARTY"], "PANAL"],
      ["to-color", PartyColors["PANAL"].high],
      ["==", ["get", "FIRST_PARTY"], "PRI-PVEM"],
      ["to-color", PartyColors["PRI-PVEM"].high],
      ["==", ["get", "FIRST_PARTY"], "PRI-PVEM-PANAL"],
      ["to-color", PartyColors["PRI-PVEM-PANAL"].high],
      ["==", ["get", "FIRST_PARTY"], "PAN-PANAL"],
      ["to-color", PartyColors["PAN-PANAL"].high],
      ["==", ["get", "FIRST_PARTY"], "PAN-PRD-MC"],
      ["to-color", PartyColors["PAN-PRD-MC"].high],
      ["==", ["get", "FIRST_PARTY"], "PRD-PT"],
      ["to-color", PartyColors["PRD-PT"].high],
      ["==", ["get", "FIRST_PARTY"], "PRD-PT-MC"],
      ["to-color", PartyColors["PRD-PT-MC"].high],
      ["==", ["get", "FIRST_PARTY"], "PRD-PT-CONVERGENCIA"],
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high],
      ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PES"],
      ["to-color", PartyColors["MORENA-PT-PES"].high],
      ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PVEM"],
      ["to-color", PartyColors["MORENA-PT-PVEM"].high],
      ["==", ["get", "FIRST_PARTY"], "CONVERGENCIA"],
      ["to-color", PartyColors["CONVERGENCIA"].high],
      ["to-color", PartyColors.Independiente.high]
    ],
    mayor: [
      "case",
      ["==", ["get", "TIE"], "TRUE"],
      "white",
      ["==", ["get", "TIE"], true],
      "white",
      ["==", ["get", "FIRST"], "No cómputo"],
      "black",
      ["==", ["get", "FIRST_AFFILIATION"], "PRI"],
      ["to-color", PartyColors["PRI"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "MORENA"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "Morena"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PAN"],
      ["to-color", PartyColors["PAN"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PRD"],
      ["to-color", PartyColors["PRD"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "MC"],
      ["to-color", PartyColors["MC"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PVEM"],
      ["to-color", PartyColors["PVEM"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PT"],
      ["to-color", PartyColors["PT"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "FXM"],
      ["to-color", PartyColors["FXM"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "ES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "RSP"],
      ["to-color", PartyColors["RSP"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PAN-PRI-PRD"],
      ["to-color", PartyColors["PAN-PRI-PRD"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "INE"],
      ["to-color", PartyColors["INE"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PANAL"],
      ["to-color", PartyColors["PANAL"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PRI-PVEM"],
      ["to-color", PartyColors["PRI-PVEM"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PRI-PVEM-PANAL"],
      ["to-color", PartyColors["PRI-PVEM-PANAL"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PAN-PANAL"],
      ["to-color", PartyColors["PAN-PANAL"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PAN-PRD-MC"],
      ["to-color", PartyColors["PAN-PRD-MC"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PRD-PT"],
      ["to-color", PartyColors["PRD-PT"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PRD-PT-MC"],
      ["to-color", PartyColors["PRD-PT-MC"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "PRD-PT-CONVERGENCIA"],
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "MORENA-PT-PES"],
      ["to-color", PartyColors["MORENA-PT-PES"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "MORENA-PT-PVEM"],
      ["to-color", PartyColors["MORENA-PT-PVEM"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "FUTURO"],
      ["to-color", PartyColors["FUTURO"].high],
      ["==", ["get", "FIRST_AFFILIATION"], "HAGAMOS"],
      ["to-color", PartyColors["HAGAMOS"].high],
      ["to-color", PartyColors.Independiente.high]
    ],
    mayor2024: [
      "case",
      ["==", ["get", "TIE"], "TRUE"],
      "white",
      ["==", ["get", "TIE"], true],
      "white",
      ["==", ["get", "FIRST"], "No cómputo"],
      "black",
      ["==", ["get", "FIRST_PARTIES"], "PRI"],
      ["to-color", PartyColors["PRI"].high],
      ["==", ["get", "FIRST_PARTIES"], "MORENA"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST_PARTIES"], "Morena"],
      ["to-color", PartyColors["MORENA"].high],
      ["==", ["get", "FIRST_PARTIES"], "PAN"],
      ["to-color", PartyColors["PAN"].high],
      ["==", ["get", "FIRST_PARTIES"], "PRD"],
      ["to-color", PartyColors["PRD"].high],
      ["==", ["get", "FIRST_PARTIES"], "MC"],
      ["to-color", PartyColors["MC"].high],
      ["==", ["get", "FIRST_PARTIES"], "PVEM"],
      ["to-color", PartyColors["PVEM"].high],
      ["==", ["get", "FIRST_PARTIES"], "PT"],
      ["to-color", PartyColors["PT"].high],
      ["==", ["get", "FIRST_PARTIES"], "FXM"],
      ["to-color", PartyColors["FXM"].high],
      ["==", ["get", "FIRST_PARTIES"], "PES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_PARTIES"], "ES"],
      ["to-color", PartyColors["PES"].high],
      ["==", ["get", "FIRST_PARTIES"], "RSP"],
      ["to-color", PartyColors["RSP"].high],
      ["==", ["get", "FIRST_PARTIES"], "PAN-PRI-PRD"],
      ["to-color", PartyColors["PAN-PRI-PRD"].high],
      ["==", ["get", "FIRST_PARTIES"], "INE"],
      ["to-color", PartyColors["INE"].high],
      ["==", ["get", "FIRST_PARTIES"], "PANAL"],
      ["to-color", PartyColors["PANAL"].high],
      ["==", ["get", "FIRST_PARTIES"], "PRI-PVEM"],
      ["to-color", PartyColors["PRI-PVEM"].high],
      ["==", ["get", "FIRST_PARTIES"], "PRI-PVEM-PANAL"],
      ["to-color", PartyColors["PRI-PVEM-PANAL"].high],
      ["==", ["get", "FIRST_PARTIES"], "PAN-PANAL"],
      ["to-color", PartyColors["PAN-PANAL"].high],
      ["==", ["get", "FIRST_PARTIES"], "PAN-PRD-MC"],
      ["to-color", PartyColors["PAN-PRD-MC"].high],
      ["==", ["get", "FIRST_PARTIES"], "PRD-PT"],
      ["to-color", PartyColors["PRD-PT"].high],
      ["==", ["get", "FIRST_PARTIES"], "PRD-PT-MC"],
      ["to-color", PartyColors["PRD-PT-MC"].high],
      ["==", ["get", "FIRST_PARTIES"], "PRD-PT-CONVERGENCIA"],
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high],
      ["==", ["get", "FIRST_PARTIES"], "MORENA-PT-PES"],
      ["to-color", PartyColors["MORENA-PT-PES"].high],
      ["==", ["get", "FIRST_PARTIES"], "MORENA-PT-PVEM"],
      ["to-color", PartyColors["MORENA-PT-PVEM"].high],
      ["==", ["get", "FIRST_PARTIES"], "MORENA-PT-PVEM-HAGAMOS-FUTURO"],
      ["to-color", PartyColors["HAGAMOS"].high],
      ["to-color", PartyColors.Independiente.high]
    ]
  };

  return gcolors[raceType];
};

// export const MarginFillColors = {
//   "pres-2012": [
//     "case",
//     ["==", ["get", "TIE"], "TRUE"],
//     "white",
//     ["==", ["get", "FIRST"], "No cómputo"],
//     "black",
//     ["==", ["get", "FIRST_PARTY"], "PRI-PVEM"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors["PRI-PVEM"].low],
//       100,
//       ["to-color", PartyColors["PRI-PVEM"].high]
//     ],
//     ["==", ["get", "FIRST_PARTY"], "PRD-PT-MC"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors["PRD-PT-MC"].low],
//       100,
//       ["to-color", PartyColors["PRD-PT-MC"].high]
//     ],
//     ["==", ["get", "FIRST_PARTY"], "PAN"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors.PAN.low],
//       100,
//       ["to-color", PartyColors.PAN.high]
//     ],
//     ["==", ["get", "FIRST_PARTY"], "PANAL"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors.PANAL.low],
//       100,
//       ["to-color", PartyColors.PANAL.high]
//     ],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors.Independiente.low],
//       100,
//       ["to-color", PartyColors.Independiente.high]
//     ]
//   ],
//   "pres-2018": [
//     "case",
//     ["==", ["get", "TIE"], "TRUE"],
//     "white",
//     ["==", ["get", "FIRST"], "No cómputo"],
//     "black",
//     ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PES"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors["MORENA-PT-PES"].low],
//       100,
//       ["to-color", PartyColors["MORENA-PT-PES"].high]
//     ],
//     ["==", ["get", "FIRST_PARTY"], "PAN-PRD-MC"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors["PAN-PRD-MC"].low],
//       100,
//       ["to-color", PartyColors["PAN-PRD-MC"].high]
//     ],
//     ["==", ["get", "FIRST_PARTY"], "PRI-PVEM-PANAL"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors["PRI-PVEM-PANAL"].low],
//       100,
//       ["to-color", PartyColors["PRI-PVEM-PANAL"].high]
//     ],
//     ["==", ["get", "FIRST_PARTY"], "Independiente"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors.Independiente.low],
//       100,
//       ["to-color", PartyColors.Independiente.high]
//     ],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors.Independiente.low],
//       100,
//       ["to-color", PartyColors.Independiente.high]
//     ]
//   ],
//   "cong-2021": [
//     "case",
//     ["==", ["get", "TIE"], "TRUE"],
//     "white",
//     ["==", ["get", "FIRST"], "No cómputo"],
//     "black",
//     ["==", ["get", "FIRST"], "MORENA"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors.MORENA.low],
//       100,
//       ["to-color", PartyColors.MORENA.high]
//     ],
//     ["==", ["get", "FIRST"], "PRI"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors["PRI"].low],
//       100,
//       ["to-color", PartyColors["PRI"].high]
//     ],
//     ["==", ["get", "FIRST"], "PAN-PRI-PRD"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors["PAN-PRI-PRD"].low],
//       100,
//       ["to-color", PartyColors["PAN-PRI-PRD"].high]
//     ],
//     ["==", ["get", "FIRST"], "PVEM"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors.PVEM.low],
//       100,
//       ["to-color", PartyColors.PVEM.high]
//     ],
//     ["==", ["get", "FIRST"], "MC"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors.MC.low],
//       100,
//       ["to-color", PartyColors.MC.high]
//     ],
//     ["==", ["get", "FIRST"], "MORENA-PT-PVEM"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors["MORENA-PT-PVEM"].low],
//       100,
//       ["to-color", PartyColors["MORENA-PT-PVEM"].high]
//     ],
//     ["==", ["get", "FIRST"], "PT"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors.PT.low],
//       100,
//       ["to-color", PartyColors.PT.high]
//     ],
//     ["==", ["get", "FIRST"], "PAN"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors.PAN.low],
//       100,
//       ["to-color", PartyColors.PAN.high]
//     ],
//     ["==", ["get", "FIRST"], "PRD"],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors.PRD.low],
//       100,
//       ["to-color", PartyColors.PRD.high]
//     ],
//     [
//       "interpolate",
//       ["linear"],
//       ["to-number", ["get", "MARGIN"], 0],
//       0,
//       ["to-color", PartyColors.Independiente.low],
//       100,
//       ["to-color", PartyColors.Independiente.high]
//     ]
//   ]
// };

export const MarginFillColors = (
  year,
  level = "",
  winners = [],
  neutrals = [],
  show2023Result = false
) => {
  let margin = [];

  margin["2006"] = [];
  margin["2006"]["states"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST_PARTY"], "PAN"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN"].low],
      10.2,
      ["to-color", PartyColors["PAN"].mid],
      18.8,
      ["to-color", PartyColors["PAN"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PRD-PT-CONVERGENCIA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].low],
      10.2,
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].mid],
      18.8,
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PRI-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI-PVEM"].low],
      10.2,
      ["to-color", PartyColors["PRI-PVEM"].mid],
      18.8,
      ["to-color", PartyColors["PRI-PVEM"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "ASDC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["ASDC"].low],
      10.2,
      ["to-color", PartyColors["ASDC"].mid],
      18.8,
      ["to-color", PartyColors["ASDC"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PANAL"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PANAL"].low],
      10.2,
      ["to-color", PartyColors["PANAL"].mid],
      18.8,
      ["to-color", PartyColors["PANAL"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2006"]["municipals"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST_PARTY"], "PAN"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN"].low],
      10.2,
      ["to-color", PartyColors["PAN"].mid],
      18.8,
      ["to-color", PartyColors["PAN"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PRD-PT-CONVERGENCIA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].low],
      10.2,
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].mid],
      18.8,
      ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PRI-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI-PVEM"].low],
      10.2,
      ["to-color", PartyColors["PRI-PVEM"].mid],
      18.8,
      ["to-color", PartyColors["PRI-PVEM"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "ASDC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["ASDC"].low],
      10.2,
      ["to-color", PartyColors["ASDC"].mid],
      18.8,
      ["to-color", PartyColors["ASDC"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PANAL"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PANAL"].low],
      10.2,
      ["to-color", PartyColors["PANAL"].mid],
      18.8,
      ["to-color", PartyColors["PANAL"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2009"] = [];
  margin["2009"]["states"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST"], "PAN"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN"].low],
      12.95,
      ["to-color", PartyColors["PAN"].mid],
      25.31,
      ["to-color", PartyColors["PAN"].high]
    ],
    ["==", ["get", "FIRST"], "PT-CONVERGENCIA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PT-CONVERGENCIA"].low],
      12.95,
      ["to-color", PartyColors["PT-CONVERGENCIA"].mid],
      25.31,
      ["to-color", PartyColors["PT-CONVERGENCIA"].high]
    ],
    ["==", ["get", "FIRST"], "PRI-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI-PVEM"].low],
      12.95,
      ["to-color", PartyColors["PRI-PVEM"].mid],
      25.31,
      ["to-color", PartyColors["PRI-PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "PRD"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRD"].low],
      12.95,
      ["to-color", PartyColors["PRD"].mid],
      25.31,
      ["to-color", PartyColors["PRD"].high]
    ],
    ["==", ["get", "FIRST"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2009"]["districts"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST"], "PAN"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN"].low],
      12.95,
      ["to-color", PartyColors["PAN"].mid],
      25.31,
      ["to-color", PartyColors["PAN"].high]
    ],
    ["==", ["get", "FIRST"], "PT-CONVERGENCIA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PT-CONVERGENCIA"].low],
      12.95,
      ["to-color", PartyColors["PT-CONVERGENCIA"].mid],
      25.31,
      ["to-color", PartyColors["PT-CONVERGENCIA"].high]
    ],
    ["==", ["get", "FIRST"], "PRI-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI-PVEM"].low],
      12.95,
      ["to-color", PartyColors["PRI-PVEM"].mid],
      25.31,
      ["to-color", PartyColors["PRI-PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "PRI"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI"].low],
      12.95,
      ["to-color", PartyColors["PRI"].mid],
      25.31,
      ["to-color", PartyColors["PRI"].high]
    ],
    ["==", ["get", "FIRST"], "PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PVEM"].low],
      12.95,
      ["to-color", PartyColors["PVEM"].mid],
      25.31,
      ["to-color", PartyColors["PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "PRD"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRD"].low],
      12.95,
      ["to-color", PartyColors["PRD"].mid],
      25.31,
      ["to-color", PartyColors["PRD"].high]
    ],
    ["==", ["get", "FIRST"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      12.95,
      ["to-color", PartyColors["NA"].mid],
      25.31,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2009"]["municipals"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST"], "PAN"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN"].low],
      15,
      ["to-color", PartyColors["PAN"].mid],
      35,
      ["to-color", PartyColors["PAN"].high]
    ],
    ["==", ["get", "FIRST"], "PT-CONVERGENCIA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PT-CONVERGENCIA"].low],
      15,
      ["to-color", PartyColors["PT-CONVERGENCIA"].mid],
      35,
      ["to-color", PartyColors["PT-CONVERGENCIA"].high]
    ],
    ["==", ["get", "FIRST"], "PRI-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI-PVEM"].low],
      15,
      ["to-color", PartyColors["PRI-PVEM"].mid],
      35,
      ["to-color", PartyColors["PRI-PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "PRD"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRD"].low],
      15,
      ["to-color", PartyColors["PRD"].mid],
      35,
      ["to-color", PartyColors["PRD"].high]
    ],
    ["==", ["get", "FIRST"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2012"] = [];
  margin["2012"]["states"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST_PARTY"], "PAN"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN"].low],
      5.9,
      ["to-color", PartyColors["PAN"].mid],
      16.5,
      ["to-color", PartyColors["PAN"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PRD-PT-MC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRD-PT-MC"].low],
      5.9,
      ["to-color", PartyColors["PRD-PT-MC"].mid],
      16.5,
      ["to-color", PartyColors["PRD-PT-MC"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PRI-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI-PVEM"].low],
      5.9,
      ["to-color", PartyColors["PRI-PVEM"].mid],
      16.5,
      ["to-color", PartyColors["PRI-PVEM"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PANAL"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PANAL"].low],
      5.9,
      ["to-color", PartyColors["PANAL"].mid],
      16.5,
      ["to-color", PartyColors["PANAL"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2012"]["municipals"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST_PARTY"], "PAN"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN"].low],
      15,
      ["to-color", PartyColors["PAN"].mid],
      35,
      ["to-color", PartyColors["PAN"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PRD-PT-MC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRD-PT-MC"].low],
      15,
      ["to-color", PartyColors["PRD-PT-MC"].mid],
      35,
      ["to-color", PartyColors["PRD-PT-MC"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PRI-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI-PVEM"].low],
      15,
      ["to-color", PartyColors["PRI-PVEM"].mid],
      35,
      ["to-color", PartyColors["PRI-PVEM"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PANAL"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PANAL"].low],
      15,
      ["to-color", PartyColors["PANAL"].mid],
      35,
      ["to-color", PartyColors["PANAL"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2015"] = [];
  margin["2015"]["states"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST"], "PAN"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN"].low],
      12.17,
      ["to-color", PartyColors["PAN"].mid],
      33.85,
      ["to-color", PartyColors["PAN"].high]
    ],
    ["==", ["get", "FIRST"], "PRD-PT"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRD-PT"].low],
      12.17,
      ["to-color", PartyColors["PRD-PT"].mid],
      33.85,
      ["to-color", PartyColors["PRD-PT"].high]
    ],
    ["==", ["get", "FIRST"], "MC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MC"].low],
      12.17,
      ["to-color", PartyColors["MC"].mid],
      33.85,
      ["to-color", PartyColors["MC"].high]
    ],
    ["==", ["get", "FIRST"], "PRI-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI-PVEM"].low],
      12.17,
      ["to-color", PartyColors["PRI-PVEM"].mid],
      33.85,
      ["to-color", PartyColors["PRI-PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "MORENA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MORENA"].low],
      12.17,
      ["to-color", PartyColors["MORENA"].mid],
      33.85,
      ["to-color", PartyColors["MORENA"].high]
    ],
    ["==", ["get", "FIRST"], "PANAL"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PANAL"].low],
      12.17,
      ["to-color", PartyColors["PANAL"].mid],
      33.85,
      ["to-color", PartyColors["PANAL"].high]
    ],
    ["==", ["get", "FIRST"], "PES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      12.17,
      ["to-color", PartyColors["PES"].mid],
      33.85,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "ES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      12.17,
      ["to-color", PartyColors["PES"].mid],
      33.85,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2015"]["districts"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST"], "PAN"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN"].low],
      16.66,
      ["to-color", PartyColors["PAN"].mid],
      43.15,
      ["to-color", PartyColors["PAN"].high]
    ],
    ["==", ["get", "FIRST"], "PRD-PT"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRD-PT"].low],
      16.66,
      ["to-color", PartyColors["PRD-PT"].mid],
      43.15,
      ["to-color", PartyColors["PRD-PT"].high]
    ],
    ["==", ["get", "FIRST"], "PRD"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRD"].low],
      16.66,
      ["to-color", PartyColors["PRD"].mid],
      43.15,
      ["to-color", PartyColors["PRD"].high]
    ],
    ["==", ["get", "FIRST"], "PT"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PT"].low],
      16.66,
      ["to-color", PartyColors["PT"].mid],
      43.15,
      ["to-color", PartyColors["PT"].high]
    ],
    ["==", ["get", "FIRST"], "MC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MC"].low],
      16.66,
      ["to-color", PartyColors["MC"].mid],
      43.15,
      ["to-color", PartyColors["MC"].high]
    ],
    ["==", ["get", "FIRST"], "PRI-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI-PVEM"].low],
      16.66,
      ["to-color", PartyColors["PRI-PVEM"].mid],
      43.15,
      ["to-color", PartyColors["PRI-PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "PRI"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI"].low],
      16.66,
      ["to-color", PartyColors["PRI"].mid],
      43.15,
      ["to-color", PartyColors["PRI"].high]
    ],
    ["==", ["get", "FIRST"], "PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PVEM"].low],
      16.66,
      ["to-color", PartyColors["PVEM"].mid],
      43.15,
      ["to-color", PartyColors["PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "MORENA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MORENA"].low],
      16.66,
      ["to-color", PartyColors["MORENA"].mid],
      43.15,
      ["to-color", PartyColors["MORENA"].high]
    ],
    ["==", ["get", "FIRST"], "PANAL"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PANAL"].low],
      16.66,
      ["to-color", PartyColors["PANAL"].mid],
      43.15,
      ["to-color", PartyColors["PANAL"].high]
    ],
    ["==", ["get", "FIRST"], "PES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      16.66,
      ["to-color", PartyColors["PES"].mid],
      43.15,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "ES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      16.66,
      ["to-color", PartyColors["PES"].mid],
      43.15,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2015"]["municipals"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST"], "PAN"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN"].low],
      15,
      ["to-color", PartyColors["PAN"].mid],
      35,
      ["to-color", PartyColors["PAN"].high]
    ],
    ["==", ["get", "FIRST"], "PRD-PT"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRD-PT"].low],
      15,
      ["to-color", PartyColors["PRD-PT"].mid],
      35,
      ["to-color", PartyColors["PRD-PT"].high]
    ],
    ["==", ["get", "FIRST"], "MC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MC"].low],
      15,
      ["to-color", PartyColors["MC"].mid],
      35,
      ["to-color", PartyColors["MC"].high]
    ],
    ["==", ["get", "FIRST"], "PRI-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI-PVEM"].low],
      15,
      ["to-color", PartyColors["PRI-PVEM"].mid],
      35,
      ["to-color", PartyColors["PRI-PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "MORENA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MORENA"].low],
      15,
      ["to-color", PartyColors["MORENA"].mid],
      35,
      ["to-color", PartyColors["MORENA"].high]
    ],
    ["==", ["get", "FIRST"], "PANAL"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PANAL"].low],
      15,
      ["to-color", PartyColors["PANAL"].mid],
      35,
      ["to-color", PartyColors["PANAL"].high]
    ],
    ["==", ["get", "FIRST"], "PES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      15,
      ["to-color", PartyColors["PES"].mid],
      35,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "ES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      15,
      ["to-color", PartyColors["PES"].mid],
      35,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2018"] = [];
  margin["2018"]["states"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST_PARTY"], "PAN-PRD-MC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN-PRD-MC"].low],
      21.28,
      ["to-color", PartyColors["PAN-PRD-MC"].mid],
      40.83,
      ["to-color", PartyColors["PAN-PRD-MC"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MORENA-PT-PES"].low],
      21.28,
      ["to-color", PartyColors["MORENA-PT-PES"].mid],
      40.83,
      ["to-color", PartyColors["MORENA-PT-PES"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PRI-PVEM-PANAL"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI-PVEM-PANAL"].low],
      21.28,
      ["to-color", PartyColors["PRI-PVEM-PANAL"].mid],
      40.83,
      ["to-color", PartyColors["PRI-PVEM-PANAL"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      21.28,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      40.83,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2018"]["municipals"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST_PARTY"], "PAN-PRD-MC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN-PRD-MC"].low],
      15,
      ["to-color", PartyColors["PAN-PRD-MC"].mid],
      35,
      ["to-color", PartyColors["PAN-PRD-MC"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MORENA-PT-PES"].low],
      15,
      ["to-color", PartyColors["MORENA-PT-PES"].mid],
      35,
      ["to-color", PartyColors["MORENA-PT-PES"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PRI-PVEM-PANAL"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI-PVEM-PANAL"].low],
      15,
      ["to-color", PartyColors["PRI-PVEM-PANAL"].mid],
      35,
      ["to-color", PartyColors["PRI-PVEM-PANAL"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2021"] = [];
  margin["2021"]["states"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST"], "PAN-PRI-PRD"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN-PRI-PRD"].low],
      12.25,
      ["to-color", PartyColors["PAN-PRI-PRD"].mid],
      30.31,
      ["to-color", PartyColors["PAN-PRI-PRD"].high]
    ],
    ["==", ["get", "FIRST"], "MC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MC"].low],
      12.25,
      ["to-color", PartyColors["MC"].mid],
      30.31,
      ["to-color", PartyColors["MC"].high]
    ],
    ["==", ["get", "FIRST"], "MORENA-PT-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MORENA-PT-PVEM"].low],
      12.25,
      ["to-color", PartyColors["MORENA-PT-PVEM"].mid],
      30.31,
      ["to-color", PartyColors["MORENA-PT-PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "PES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      12.25,
      ["to-color", PartyColors["PES"].mid],
      30.31,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "ES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      12.25,
      ["to-color", PartyColors["PES"].mid],
      30.31,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "FXM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["FXM"].low],
      12.25,
      ["to-color", PartyColors["FXM"].mid],
      30.31,
      ["to-color", PartyColors["FXM"].high]
    ],
    ["==", ["get", "FIRST"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2021"]["districts"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST"], "PAN-PRI-PRD"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN-PRI-PRD"].low],
      15,
      ["to-color", PartyColors["PAN-PRI-PRD"].mid],
      33,
      ["to-color", PartyColors["PAN-PRI-PRD"].high]
    ],
    ["==", ["get", "FIRST"], "PRI"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI"].low],
      15,
      ["to-color", PartyColors["PRI"].mid],
      33,
      ["to-color", PartyColors["PRI"].high]
    ],
    ["==", ["get", "FIRST"], "PRD"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRD"].low],
      15,
      ["to-color", PartyColors["PRD"].mid],
      33,
      ["to-color", PartyColors["PRD"].high]
    ],
    ["==", ["get", "FIRST"], "PAN"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN"].low],
      15,
      ["to-color", PartyColors["PAN"].mid],
      33,
      ["to-color", PartyColors["PAN"].high]
    ],
    ["==", ["get", "FIRST"], "MC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MC"].low],
      15,
      ["to-color", PartyColors["MC"].mid],
      33,
      ["to-color", PartyColors["MC"].high]
    ],
    ["==", ["get", "FIRST"], "MORENA-PT-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MORENA-PT-PVEM"].low],
      15,
      ["to-color", PartyColors["MORENA-PT-PVEM"].mid],
      33,
      ["to-color", PartyColors["MORENA-PT-PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "MORENA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MORENA"].low],
      15,
      ["to-color", PartyColors["MORENA"].mid],
      33,
      ["to-color", PartyColors["MORENA"].high]
    ],
    ["==", ["get", "FIRST"], "PT"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PT"].low],
      15,
      ["to-color", PartyColors["PT"].mid],
      33,
      ["to-color", PartyColors["PT"].high]
    ],
    ["==", ["get", "FIRST"], "PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PVEM"].low],
      15,
      ["to-color", PartyColors["PVEM"].mid],
      33,
      ["to-color", PartyColors["PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "PES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      15,
      ["to-color", PartyColors["PES"].mid],
      33,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "ES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      15,
      ["to-color", PartyColors["PES"].mid],
      33,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "FXM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["FXM"].low],
      15,
      ["to-color", PartyColors["FXM"].mid],
      33,
      ["to-color", PartyColors["FXM"].high]
    ],
    ["==", ["get", "FIRST"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2021"]["municipals"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST"], "PAN-PRI-PRD"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN-PRI-PRD"].low],
      15,
      ["to-color", PartyColors["PAN-PRI-PRD"].mid],
      35,
      ["to-color", PartyColors["PAN-PRI-PRD"].high]
    ],
    ["==", ["get", "FIRST"], "MC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MC"].low],
      15,
      ["to-color", PartyColors["MC"].mid],
      35,
      ["to-color", PartyColors["MC"].high]
    ],
    ["==", ["get", "FIRST"], "MORENA-PT-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MORENA-PT-PVEM"].low],
      15,
      ["to-color", PartyColors["MORENA-PT-PVEM"].mid],
      35,
      ["to-color", PartyColors["MORENA-PT-PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "PES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      15,
      ["to-color", PartyColors["PES"].mid],
      35,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "ES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      15,
      ["to-color", PartyColors["PES"].mid],
      35,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "FXM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["FXM"].low],
      15,
      ["to-color", PartyColors["FXM"].mid],
      35,
      ["to-color", PartyColors["FXM"].high]
    ],
    ["==", ["get", "FIRST"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2024"] = [];
  margin["2024"]["states"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST_PARTY"], "PAN-PRI-PRD"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN-PRI-PRD"].low],
      10,
      ["to-color", PartyColors["PAN-PRI-PRD"].mid],
      20,
      ["to-color", PartyColors["PAN-PRI-PRD"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "MC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MC"].low],
      10,
      ["to-color", PartyColors["MC"].mid],
      20,
      ["to-color", PartyColors["MC"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MORENA-PT-PVEM"].low],
      10,
      ["to-color", PartyColors["MORENA-PT-PVEM"].mid],
      20,
      ["to-color", PartyColors["MORENA-PT-PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "PES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      12.25,
      ["to-color", PartyColors["PES"].mid],
      30.31,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "ES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      12.25,
      ["to-color", PartyColors["PES"].mid],
      30.31,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "FXM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["FXM"].low],
      12.25,
      ["to-color", PartyColors["FXM"].mid],
      30.31,
      ["to-color", PartyColors["FXM"].high]
    ],
    ["==", ["get", "FIRST"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["2024"]["municipals"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST_PARTY"], "PAN-PRI-PRD"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN-PRI-PRD"].low],
      15,
      ["to-color", PartyColors["PAN-PRI-PRD"].mid],
      35,
      ["to-color", PartyColors["PAN-PRI-PRD"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "MC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MC"].low],
      15,
      ["to-color", PartyColors["MC"].mid],
      35,
      ["to-color", PartyColors["MC"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MORENA-PT-PVEM"].low],
      15,
      ["to-color", PartyColors["MORENA-PT-PVEM"].mid],
      35,
      ["to-color", PartyColors["MORENA-PT-PVEM"].high]
    ],
    ["==", ["get", "FIRST"], "PES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      15,
      ["to-color", PartyColors["PES"].mid],
      35,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "ES"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PES"].low],
      15,
      ["to-color", PartyColors["PES"].mid],
      35,
      ["to-color", PartyColors["PES"].high]
    ],
    ["==", ["get", "FIRST"], "FXM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["FXM"].low],
      15,
      ["to-color", PartyColors["FXM"].mid],
      35,
      ["to-color", PartyColors["FXM"].high]
    ],
    ["==", ["get", "FIRST"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      15,
      ["to-color", PartyColors["NA"].mid],
      35,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      15,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      35,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["jal"] = [];
  margin["jal"]["municipals"] = [
    "case",
    ["==", ["get", "TIE"], "TRUE"],
    "white",
    ["==", ["get", "FIRST_PARTY"], "No cómputo"],
    "black",
    ["==", ["get", "FIRST_PARTY"], "PRI"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PRI"].low],
      10.76,
      ["to-color", PartyColors["PRI"].mid],
      25.6,
      ["to-color", PartyColors["PRI"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "MC"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MC"].low],
      10.76,
      ["to-color", PartyColors["MC"].mid],
      25.6,
      ["to-color", PartyColors["MC"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["MORENA-PT-PVEM"].low],
      10.76,
      ["to-color", PartyColors["MORENA-PT-PVEM"].mid],
      25.6,
      ["to-color", PartyColors["MORENA-PT-PVEM"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PAN"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PAN"].low],
      10.76,
      ["to-color", PartyColors["PAN"].mid],
      25.6,
      ["to-color", PartyColors["PAN"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "PVEM"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["PVEM"].low],
      10.76,
      ["to-color", PartyColors["PVEM"].mid],
      25.6,
      ["to-color", PartyColors["PVEM"].high]
    ],
    ["==", ["get", "FIRST_PARTY"], "NA"],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      ["to-color", PartyColors["NA"].low],
      10.76,
      ["to-color", PartyColors["NA"].mid],
      25.6,
      ["to-color", PartyColors["NA"].high]
    ],
    [
      "step",
      ["to-number", ["get", "MARGIN"], 0],
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.low2018
          : PartyColors.Independiente.low
      ],
      10.76,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.mid2018
          : PartyColors.Independiente.mid
      ],
      25.6,
      [
        "to-color",
        year === 2018
          ? PartyColors.Independiente.high2018
          : PartyColors.Independiente.high
      ]
    ]
  ];

  margin["cdmx-2024"] = [];
  margin["cdmx-2024"]["states"] = [
    "case",
    ["==", ["get", "FIRST_PARTIES"], "PAN-PRI-PRD"],
    [
      "step",
      ["to-number", ["get", "margin"], 0],
      ["to-color", "#d8f4f5"],
      4.6,
      ["to-color", "#9de3e6"],
      11.4,
      ["to-color", "#79dde0"],
      23.4,
      ["to-color", "#00c1c7"]
    ],
    ["==", ["get", "FIRST_PARTIES"], "MORENA-PT-PVEM"],
    [
      "step",
      ["to-number", ["get", "margin"], 0],
      ["to-color", "#e6cfd0"],
      4.6,
      ["to-color", "#C18689"],
      11.4,
      ["to-color", "#CF4952"],
      23.4,
      ["to-color", "#B01213"]
    ],
    ["==", ["get", "FIRST_PARTIES"], "MORENA"],
    [
      "step",
      ["to-number", ["get", "margin"], 0],
      ["to-color", "#e6cfd0"],
      4.6,
      ["to-color", "#C18689"],
      11.4,
      ["to-color", "#CF4952"],
      23.4,
      ["to-color", "#B01213"]
    ],
    "white"
  ];

  if (margin[year] && margin[year][level]) {
    return margin[year][level];
  } else {
    return {
      pres: [
        "case",
        ["==", ["get", "TIE"], "TRUE"],
        "white",
        ["==", ["get", "FIRST"], "No cómputo"],
        "black",
        ["==", ["get", "FIRST_PARTY"], "PRI"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI"].low],
          15,
          ["to-color", PartyColors["PRI"].mid],
          35,
          ["to-color", PartyColors["PRI"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MORENA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA"].low2018
              : PartyColors["MORENA"].low
          ],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "Morena"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA"].low2018
              : PartyColors["MORENA"].low
          ],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PAN"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN"].low],
          15,
          ["to-color", PartyColors["PAN"].mid],
          35,
          ["to-color", PartyColors["PAN"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD"].low],
          15,
          ["to-color", PartyColors["PRD"].mid],
          35,
          ["to-color", PartyColors["PRD"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["MC"].low],
          15,
          ["to-color", PartyColors["MC"].mid],
          35,
          ["to-color", PartyColors["MC"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PVEM"].low],
          15,
          ["to-color", PartyColors["PVEM"].mid],
          35,
          ["to-color", PartyColors["PVEM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PT"].low],
          15,
          ["to-color", PartyColors["PT"].mid],
          35,
          ["to-color", PartyColors["PT"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "FXM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["FXM"].low],
          15,
          ["to-color", PartyColors["FXM"].mid],
          35,
          ["to-color", PartyColors["FXM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "ES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "RSP"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["RSP"].low],
          15,
          ["to-color", PartyColors["RSP"].mid],
          35,
          ["to-color", PartyColors["RSP"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PAN-PRI-PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRI-PRD"].low],
          15,
          ["to-color", PartyColors["PAN-PRI-PRD"].mid],
          35,
          ["to-color", PartyColors["PAN-PRI-PRD"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "INE"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["INE"].low],
          15,
          ["to-color", PartyColors["INE"].mid],
          35,
          ["to-color", PartyColors["INE"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors.PANAL.low],
          15,
          ["to-color", PartyColors.PANAL.mid],
          35,
          ["to-color", PartyColors.PANAL.high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRI-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM"].low],
          15,
          ["to-color", PartyColors["PRI-PVEM"].mid],
          35,
          ["to-color", PartyColors["PRI-PVEM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRI-PVEM-PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM-PANAL"].low],
          15,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].mid],
          35,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PAN-PRD-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRD-MC"].low],
          15,
          ["to-color", PartyColors["PAN-PRD-MC"].mid],
          35,
          ["to-color", PartyColors["PAN-PRD-MC"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD-PT-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-MC"].low],
          15,
          ["to-color", PartyColors["PRD-PT-MC"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-MC"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD-PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT"].low],
          15,
          ["to-color", PartyColors["PRD-PT"].mid],
          35,
          ["to-color", PartyColors["PRD-PT"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD-PT-CONVERGENCIA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].low],
          15,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA-PT-PES"].low2018
              : PartyColors["MORENA-PT-PES"].low
          ],
          15,
          ["to-color", PartyColors["MORENA-PT-PES"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PES"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA-PT-PVEM"].low2018
              : PartyColors["MORENA-PT-PVEM"].low
          ],
          15,
          ["to-color", PartyColors["MORENA-PT-PVEM"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PVEM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "NA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["NA"].low],
          15,
          ["to-color", PartyColors["NA"].mid],
          35,
          ["to-color", PartyColors["NA"].high]
        ],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors.Independiente.low2018
              : PartyColors.Independiente.low
          ],
          15,
          [
            "to-color",
            year === 2018
              ? PartyColors.Independiente.mid2018
              : PartyColors.Independiente.mid
          ],
          35,
          [
            "to-color",
            year === 2018
              ? PartyColors.Independiente.high2018
              : PartyColors.Independiente.high
          ]
        ]
      ],
      cong: [
        "case",
        ["==", ["get", "TIE"], "TRUE"],
        "white",
        ["==", ["get", "FIRST"], "No cómputo"],
        "black",
        ["==", ["get", "FIRST"], "PRI"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI"].low],
          15,
          ["to-color", PartyColors["PRI"].mid],
          35,
          ["to-color", PartyColors["PRI"].high]
        ],
        ["==", ["get", "FIRST"], "MORENA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["MORENA"].low],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "FIRST"], "Morena"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["MORENA"].low],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "FIRST"], "PAN"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN"].low],
          15,
          ["to-color", PartyColors["PAN"].mid],
          35,
          ["to-color", PartyColors["PAN"].high]
        ],
        ["==", ["get", "FIRST"], "PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD"].low],
          15,
          ["to-color", PartyColors["PRD"].mid],
          35,
          ["to-color", PartyColors["PRD"].high]
        ],
        ["==", ["get", "FIRST"], "MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["MC"].low],
          15,
          ["to-color", PartyColors["MC"].mid],
          35,
          ["to-color", PartyColors["MC"].high]
        ],
        ["==", ["get", "FIRST"], "PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PVEM"].low],
          15,
          ["to-color", PartyColors["PVEM"].mid],
          35,
          ["to-color", PartyColors["PVEM"].high]
        ],
        ["==", ["get", "FIRST"], "PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PT"].low],
          15,
          ["to-color", PartyColors["PT"].mid],
          35,
          ["to-color", PartyColors["PT"].high]
        ],
        ["==", ["get", "FIRST"], "FXM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["FXM"].low],
          15,
          ["to-color", PartyColors["FXM"].mid],
          35,
          ["to-color", PartyColors["FXM"].high]
        ],
        ["==", ["get", "FIRST"], "PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "FIRST"], "ES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "FIRST"], "RSP"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["RSP"].low],
          15,
          ["to-color", PartyColors["RSP"].mid],
          35,
          ["to-color", PartyColors["RSP"].high]
        ],
        ["==", ["get", "FIRST"], "PAN-PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PANAL"].low],
          15,
          ["to-color", PartyColors["PAN-PANAL"].mid],
          35,
          ["to-color", PartyColors["PAN-PANAL"].high]
        ],
        ["==", ["get", "FIRST"], "PAN-PRI-PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRI-PRD"].low],
          15,
          ["to-color", PartyColors["PAN-PRI-PRD"].mid],
          35,
          ["to-color", PartyColors["PAN-PRI-PRD"].high]
        ],
        ["==", ["get", "FIRST"], "INE"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["INE"].low],
          15,
          ["to-color", PartyColors["INE"].mid],
          35,
          ["to-color", PartyColors["INE"].high]
        ],
        ["==", ["get", "FIRST"], "PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors.PANAL.low],
          15,
          ["to-color", PartyColors.PANAL.mid],
          35,
          ["to-color", PartyColors.PANAL.high]
        ],
        ["==", ["get", "FIRST"], "PRI-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM"].low],
          year === 2015 && level === "districts" ? 16.66 : 15,
          ["to-color", PartyColors["PRI-PVEM"].mid],
          year === 2015 && level === "districts"
            ? 43.15
            : year === 2021 && level === "districts"
            ? 33
            : 35,
          ["to-color", PartyColors["PRI-PVEM"].high]
        ],
        ["==", ["get", "FIRST"], "PRI-PVEM-PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM-PANAL"].low],
          15,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].mid],
          35,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].high]
        ],
        ["==", ["get", "FIRST"], "PAN-PRD-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRD-MC"].low],
          15,
          ["to-color", PartyColors["PAN-PRD-MC"].mid],
          35,
          ["to-color", PartyColors["PAN-PRD-MC"].high]
        ],
        ["==", ["get", "FIRST"], "PRD-PT-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-MC"].low],
          15,
          ["to-color", PartyColors["PRD-PT-MC"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-MC"].high]
        ],
        ["==", ["get", "FIRST"], "PRD-PT-CONVERGENCIA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].low],
          15,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD-PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT"].low],
          15,
          ["to-color", PartyColors["PRD-PT"].mid],
          35,
          ["to-color", PartyColors["PRD-PT"].high]
        ],
        ["==", ["get", "FIRST"], "MORENA-PT-PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["MORENA-PT-PES"].low],
          15,
          ["to-color", PartyColors["MORENA-PT-PES"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PES"].high]
        ],
        ["==", ["get", "FIRST"], "MORENA-PT-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["MORENA-PT-PVEM"].low],
          15,
          ["to-color", PartyColors["MORENA-PT-PVEM"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PVEM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "NA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["NA"].low],
          15,
          ["to-color", PartyColors["NA"].mid],
          35,
          ["to-color", PartyColors["NA"].high]
        ],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors.Independiente.low2018
              : PartyColors.Independiente.low
          ],
          15,
          [
            "to-color",
            year === 2018
              ? PartyColors.Independiente.mid2018
              : PartyColors.Independiente.mid
          ],
          35,
          [
            "to-color",
            year === 2018
              ? PartyColors.Independiente.high2018
              : PartyColors.Independiente.high
          ]
        ]
      ],
      gov: [
        "case",
        ["==", ["get", "TIE"], "TRUE"],
        "white",
        ["==", ["get", "FIRST"], "No cómputo"],
        "black",
        ["==", ["get", "STATE_DISPLAY"], "PRI"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI"].low],
          15,
          ["to-color", PartyColors["PRI"].mid],
          35,
          ["to-color", PartyColors["PRI"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "MORENA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA"].low2018
              : PartyColors["MORENA"].low
          ],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "Morena"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA"].low2018
              : PartyColors["MORENA"].low
          ],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PAN"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN"].low],
          15,
          ["to-color", PartyColors["PAN"].mid],
          35,
          ["to-color", PartyColors["PAN"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD"].low],
          15,
          ["to-color", PartyColors["PRD"].mid],
          35,
          ["to-color", PartyColors["PRD"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["MC"].low],
          15,
          ["to-color", PartyColors["MC"].mid],
          35,
          ["to-color", PartyColors["MC"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PVEM"].low],
          15,
          ["to-color", PartyColors["PVEM"].mid],
          35,
          ["to-color", PartyColors["PVEM"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PT"].low],
          15,
          ["to-color", PartyColors["PT"].mid],
          35,
          ["to-color", PartyColors["PT"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "FXM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["FXM"].low],
          15,
          ["to-color", PartyColors["FXM"].mid],
          35,
          ["to-color", PartyColors["FXM"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "ES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "RSP"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["RSP"].low],
          15,
          ["to-color", PartyColors["RSP"].mid],
          35,
          ["to-color", PartyColors["RSP"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PAN-PRI-PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRI-PRD"].low],
          15,
          ["to-color", PartyColors["PAN-PRI-PRD"].mid],
          35,
          ["to-color", PartyColors["PAN-PRI-PRD"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "INE"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["INE"].low],
          15,
          ["to-color", PartyColors["INE"].mid],
          35,
          ["to-color", PartyColors["INE"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors.PANAL.low],
          15,
          ["to-color", PartyColors.PANAL.mid],
          35,
          ["to-color", PartyColors.PANAL.high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PRI-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM"].low],
          15,
          ["to-color", PartyColors["PRI-PVEM"].mid],
          35,
          ["to-color", PartyColors["PRI-PVEM"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PRI-PVEM-PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM-PANAL"].low],
          15,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].mid],
          35,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PAN-PRD-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRD-MC"].low],
          15,
          ["to-color", PartyColors["PAN-PRD-MC"].mid],
          35,
          ["to-color", PartyColors["PAN-PRD-MC"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PRD-PT-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-MC"].low],
          15,
          ["to-color", PartyColors["PRD-PT-MC"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-MC"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PRD-PT-CONVERGENCIA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].low],
          15,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PRD-PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT"].low],
          15,
          ["to-color", PartyColors["PRD-PT"].mid],
          35,
          ["to-color", PartyColors["PRD-PT"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "MORENA-PT-PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA-PT-PES"].low2018
              : PartyColors["MORENA-PT-PES"].low
          ],
          15,
          ["to-color", PartyColors["MORENA-PT-PES"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PES"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "MORENA-PT-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA-PT-PVEM"].low2018
              : PartyColors["MORENA-PT-PVEM"].low
          ],
          15,
          ["to-color", PartyColors["MORENA-PT-PVEM"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PVEM"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "NA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["NA"].low],
          15,
          ["to-color", PartyColors["NA"].mid],
          35,
          ["to-color", PartyColors["NA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRI"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI"].low],
          15,
          ["to-color", PartyColors["PRI"].mid],
          35,
          ["to-color", PartyColors["PRI"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MORENA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA"].low2018
              : PartyColors["MORENA"].low
          ],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "Morena"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA"].low2018
              : PartyColors["MORENA"].low
          ],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PAN"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN"].low],
          15,
          ["to-color", PartyColors["PAN"].mid],
          35,
          ["to-color", PartyColors["PAN"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD"].low],
          15,
          ["to-color", PartyColors["PRD"].mid],
          35,
          ["to-color", PartyColors["PRD"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["MC"].low],
          15,
          ["to-color", PartyColors["MC"].mid],
          35,
          ["to-color", PartyColors["MC"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PVEM"].low],
          15,
          ["to-color", PartyColors["PVEM"].mid],
          35,
          ["to-color", PartyColors["PVEM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PT"].low],
          15,
          ["to-color", PartyColors["PT"].mid],
          35,
          ["to-color", PartyColors["PT"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "FXM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["FXM"].low],
          15,
          ["to-color", PartyColors["FXM"].mid],
          35,
          ["to-color", PartyColors["FXM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "ES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "RSP"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["RSP"].low],
          15,
          ["to-color", PartyColors["RSP"].mid],
          35,
          ["to-color", PartyColors["RSP"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PAN-PRI-PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRI-PRD"].low],
          15,
          ["to-color", PartyColors["PAN-PRI-PRD"].mid],
          35,
          ["to-color", PartyColors["PAN-PRI-PRD"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "INE"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["INE"].low],
          15,
          ["to-color", PartyColors["INE"].mid],
          35,
          ["to-color", PartyColors["INE"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors.PANAL.low],
          15,
          ["to-color", PartyColors.PANAL.mid],
          35,
          ["to-color", PartyColors.PANAL.high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRI-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM"].low],
          15,
          ["to-color", PartyColors["PRI-PVEM"].mid],
          35,
          ["to-color", PartyColors["PRI-PVEM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRI-PVEM-PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM-PANAL"].low],
          15,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].mid],
          35,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PAN-PRD-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRD-MC"].low],
          15,
          ["to-color", PartyColors["PAN-PRD-MC"].mid],
          35,
          ["to-color", PartyColors["PAN-PRD-MC"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD-PT-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-MC"].low],
          15,
          ["to-color", PartyColors["PRD-PT-MC"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-MC"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD-PT-CONVERGENCIA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].low],
          15,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD-PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT"].low],
          15,
          ["to-color", PartyColors["PRD-PT"].mid],
          35,
          ["to-color", PartyColors["PRD-PT"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA-PT-PES"].low2018
              : PartyColors["MORENA-PT-PES"].low
          ],
          15,
          ["to-color", PartyColors["MORENA-PT-PES"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PES"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA-PT-PVEM"].low2018
              : PartyColors["MORENA-PT-PVEM"].low
          ],
          15,
          ["to-color", PartyColors["MORENA-PT-PVEM"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PVEM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "NA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["NA"].low],
          15,
          ["to-color", PartyColors["NA"].mid],
          35,
          ["to-color", PartyColors["NA"].high]
        ],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors.Independiente.low2018
              : PartyColors.Independiente.low
          ],
          15,
          [
            "to-color",
            year === 2018
              ? PartyColors.Independiente.mid2018
              : PartyColors.Independiente.mid
          ],
          35,
          [
            "to-color",
            year === 2018
              ? PartyColors.Independiente.high2018
              : PartyColors.Independiente.high
          ]
        ]
      ],
      gov_2024: [
        "case",
        ["==", ["get", "TIE"], "TRUE"],
        "white",
        ["==", ["get", "FIRST"], "No cómputo"],
        "black",
        show2023Result
          ? ["==", ["get", "NOMBRE"], "CHIHUAHUA"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["PAN"].low],
              15,
              ["to-color", PartyColors["PAN"].mid],
              35,
              ["to-color", PartyColors["PAN"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "SONORA"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "BAJA CALIFORNIA"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "BAJA CALIFORNIA SUR"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "SINALOA"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "NAYARIT"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "ZACATECAS"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "TAMAULIPAS"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "COLIMA"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "MICHOACAN"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "MEXICO"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "HIDALGO"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "TLAXCALA"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "GUERRERO"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "OAXACA"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "CAMPECHE"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "QUINTANA ROO"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MORENA"].low],
              15,
              ["to-color", PartyColors["MORENA"].mid],
              35,
              ["to-color", PartyColors["MORENA"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "DURANGO"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["PRI"].low],
              15,
              ["to-color", PartyColors["PRI"].mid],
              35,
              ["to-color", PartyColors["PRI"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "COAHUILA"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["PRI"].low],
              15,
              ["to-color", PartyColors["PRI"].mid],
              35,
              ["to-color", PartyColors["PRI"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "AGUASCALIENTES"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["PAN"].low],
              15,
              ["to-color", PartyColors["PAN"].mid],
              35,
              ["to-color", PartyColors["PAN"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "QUERETARO"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["PAN"].low],
              15,
              ["to-color", PartyColors["PAN"].mid],
              35,
              ["to-color", PartyColors["PAN"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "NUEVO LEON"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["MC"].low],
              15,
              ["to-color", PartyColors["MC"].mid],
              35,
              ["to-color", PartyColors["MC"].high]
            ]
          : ["to-color", "#fff"],
        show2023Result
          ? ["==", ["get", "NOMBRE"], "SAN LUIS POTOSI"]
          : ["==", ["get", "NOMBRE"], false],
        show2023Result
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors["PVEM"].low],
              15,
              ["to-color", PartyColors["PVEM"].mid],
              35,
              ["to-color", PartyColors["PVEM"].high]
            ]
          : ["to-color", "#fff"],
        neutrals["Jalisco"] || winners["Jalisco"]
          ? ["==", ["get", "NOMBRE"], "JALISCO"]
          : ["==", ["get", "NOMBRE"], false],
        neutrals["Jalisco"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors.Neutral.low],
              15,
              ["to-color", PartyColors.Neutral.mid],
              35,
              ["to-color", PartyColors.Neutral.high]
            ]
          : winners["Jalisco"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors[winners["Jalisco"].party].low],
              15,
              ["to-color", PartyColors[winners["Jalisco"].party].mid],
              35,
              ["to-color", PartyColors[winners["Jalisco"].party].high]
            ]
          : ["to-color", "#fff"],
        neutrals["Guanajuato"] || winners["Guanajuato"]
          ? ["==", ["get", "NOMBRE"], "GUANAJUATO"]
          : ["==", ["get", "NOMBRE"], false],
        neutrals["Guanajuato"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors.Neutral.low],
              15,
              ["to-color", PartyColors.Neutral.mid],
              35,
              ["to-color", PartyColors.Neutral.high]
            ]
          : winners["Guanajuato"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors[winners["Guanajuato"].party].low],
              15,
              ["to-color", PartyColors[winners["Guanajuato"].party].mid],
              35,
              ["to-color", PartyColors[winners["Guanajuato"].party].high]
            ]
          : ["to-color", "#fff"],
        neutrals["Veracruz"] || winners["Veracruz"]
          ? ["==", ["get", "NOMBRE"], "VERACRUZ"]
          : ["==", ["get", "NOMBRE"], false],
        neutrals["Veracruz"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors.Neutral.low],
              15,
              ["to-color", PartyColors.Neutral.mid],
              35,
              ["to-color", PartyColors.Neutral.high]
            ]
          : winners["Veracruz"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors[winners["Veracruz"].party].low],
              15,
              ["to-color", PartyColors[winners["Veracruz"].party].mid],
              35,
              ["to-color", PartyColors[winners["Veracruz"].party].high]
            ]
          : ["to-color", "#fff"],
        neutrals["Puebla"] || winners["Puebla"]
          ? ["==", ["get", "NOMBRE"], "PUEBLA"]
          : ["==", ["get", "NOMBRE"], false],
        neutrals["Puebla"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors.Neutral.low],
              15,
              ["to-color", PartyColors.Neutral.mid],
              35,
              ["to-color", PartyColors.Neutral.high]
            ]
          : winners["Puebla"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors[winners["Puebla"].party].low],
              15,
              ["to-color", PartyColors[winners["Puebla"].party].mid],
              35,
              ["to-color", PartyColors[winners["Puebla"].party].high]
            ]
          : ["to-color", "#fff"],
        neutrals["Ciudad De Mexico"] || winners["Ciudad De Mexico"]
          ? ["==", ["get", "NOMBRE"], "CIUDAD DE MEXICO"]
          : ["==", ["get", "NOMBRE"], false],
        neutrals["Ciudad De Mexico"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors.Neutral.low],
              15,
              ["to-color", PartyColors.Neutral.mid],
              35,
              ["to-color", PartyColors.Neutral.high]
            ]
          : winners["Ciudad De Mexico"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors[winners["Ciudad De Mexico"].party].low],
              15,
              ["to-color", PartyColors[winners["Ciudad De Mexico"].party].mid],
              35,
              ["to-color", PartyColors[winners["Ciudad De Mexico"].party].high]
            ]
          : ["to-color", "#fff"],
        neutrals["Morelos"] || winners["Morelos"]
          ? ["==", ["get", "NOMBRE"], "MORELOS"]
          : ["==", ["get", "NOMBRE"], false],
        neutrals["Morelos"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors.Neutral.low],
              15,
              ["to-color", PartyColors.Neutral.mid],
              35,
              ["to-color", PartyColors.Neutral.high]
            ]
          : winners["Morelos"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors[winners["Morelos"].party].low],
              15,
              ["to-color", PartyColors[winners["Morelos"].party].mid],
              35,
              ["to-color", PartyColors[winners["Morelos"].party].high]
            ]
          : ["to-color", "#fff"],
        neutrals["Tabasco"] || winners["Tabasco"]
          ? ["==", ["get", "NOMBRE"], "TABASCO"]
          : ["==", ["get", "NOMBRE"], false],
        neutrals["Tabasco"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors.Neutral.low],
              15,
              ["to-color", PartyColors.Neutral.mid],
              35,
              ["to-color", PartyColors.Neutral.high]
            ]
          : winners["Tabasco"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors[winners["Tabasco"].party].low],
              15,
              ["to-color", PartyColors[winners["Tabasco"].party].mid],
              35,
              ["to-color", PartyColors[winners["Tabasco"].party].high]
            ]
          : ["to-color", "#fff"],
        neutrals["Chiapas"] || winners["Chiapas"]
          ? ["==", ["get", "NOMBRE"], "CHIAPAS"]
          : ["==", ["get", "NOMBRE"], false],
        neutrals["Chiapas"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors.Neutral.low],
              15,
              ["to-color", PartyColors.Neutral.mid],
              35,
              ["to-color", PartyColors.Neutral.high]
            ]
          : winners["Chiapas"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors[winners["Chiapas"].party].low],
              15,
              ["to-color", PartyColors[winners["Chiapas"].party].mid],
              35,
              ["to-color", PartyColors[winners["Chiapas"].party].high]
            ]
          : ["to-color", "#fff"],
        neutrals["Yucatan"] || winners["Yucatan"]
          ? ["==", ["get", "NOMBRE"], "YUCATAN"]
          : ["==", ["get", "NOMBRE"], false],
        neutrals["Yucatan"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors.Neutral.low],
              15,
              ["to-color", PartyColors.Neutral.mid],
              35,
              ["to-color", PartyColors.Neutral.high]
            ]
          : winners["Yucatan"]
          ? [
              "step",
              ["to-number", ["get", "MARGIN"], 0],
              ["to-color", PartyColors[winners["Yucatan"].party].low],
              15,
              ["to-color", PartyColors[winners["Yucatan"].party].mid],
              35,
              ["to-color", PartyColors[winners["Yucatan"].party].high]
            ]
          : ["to-color", "#fff"],
        ["==", ["get", "STATE_DISPLAY"], "PRI"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI"].low],
          15,
          ["to-color", PartyColors["PRI"].mid],
          35,
          ["to-color", PartyColors["PRI"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "MORENA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA"].low2018
              : PartyColors["MORENA"].low
          ],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "Morena"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA"].low2018
              : PartyColors["MORENA"].low
          ],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PAN"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN"].low],
          15,
          ["to-color", PartyColors["PAN"].mid],
          35,
          ["to-color", PartyColors["PAN"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD"].low],
          15,
          ["to-color", PartyColors["PRD"].mid],
          35,
          ["to-color", PartyColors["PRD"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["MC"].low],
          15,
          ["to-color", PartyColors["MC"].mid],
          35,
          ["to-color", PartyColors["MC"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PVEM"].low],
          15,
          ["to-color", PartyColors["PVEM"].mid],
          35,
          ["to-color", PartyColors["PVEM"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PT"].low],
          15,
          ["to-color", PartyColors["PT"].mid],
          35,
          ["to-color", PartyColors["PT"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "FXM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["FXM"].low],
          15,
          ["to-color", PartyColors["FXM"].mid],
          35,
          ["to-color", PartyColors["FXM"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "ES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "RSP"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["RSP"].low],
          15,
          ["to-color", PartyColors["RSP"].mid],
          35,
          ["to-color", PartyColors["RSP"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PAN-PRI-PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRI-PRD"].low],
          15,
          ["to-color", PartyColors["PAN-PRI-PRD"].mid],
          35,
          ["to-color", PartyColors["PAN-PRI-PRD"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "INE"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["INE"].low],
          15,
          ["to-color", PartyColors["INE"].mid],
          35,
          ["to-color", PartyColors["INE"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors.PANAL.low],
          15,
          ["to-color", PartyColors.PANAL.mid],
          35,
          ["to-color", PartyColors.PANAL.high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PRI-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM"].low],
          15,
          ["to-color", PartyColors["PRI-PVEM"].mid],
          35,
          ["to-color", PartyColors["PRI-PVEM"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PRI-PVEM-PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM-PANAL"].low],
          15,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].mid],
          35,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PAN-PRD-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRD-MC"].low],
          15,
          ["to-color", PartyColors["PAN-PRD-MC"].mid],
          35,
          ["to-color", PartyColors["PAN-PRD-MC"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PRD-PT-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-MC"].low],
          15,
          ["to-color", PartyColors["PRD-PT-MC"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-MC"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PRD-PT-CONVERGENCIA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].low],
          15,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "PRD-PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT"].low],
          15,
          ["to-color", PartyColors["PRD-PT"].mid],
          35,
          ["to-color", PartyColors["PRD-PT"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "MORENA-PT-PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA-PT-PES"].low2018
              : PartyColors["MORENA-PT-PES"].low
          ],
          15,
          ["to-color", PartyColors["MORENA-PT-PES"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PES"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "MORENA-PT-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA-PT-PVEM"].low2018
              : PartyColors["MORENA-PT-PVEM"].low
          ],
          15,
          ["to-color", PartyColors["MORENA-PT-PVEM"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PVEM"].high]
        ],
        ["==", ["get", "STATE_DISPLAY"], "NA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["NA"].low],
          15,
          ["to-color", PartyColors["NA"].mid],
          35,
          ["to-color", PartyColors["NA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRI"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI"].low],
          15,
          ["to-color", PartyColors["PRI"].mid],
          35,
          ["to-color", PartyColors["PRI"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MORENA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA"].low2018
              : PartyColors["MORENA"].low
          ],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "Morena"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA"].low2018
              : PartyColors["MORENA"].low
          ],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PAN"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN"].low],
          15,
          ["to-color", PartyColors["PAN"].mid],
          35,
          ["to-color", PartyColors["PAN"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD"].low],
          15,
          ["to-color", PartyColors["PRD"].mid],
          35,
          ["to-color", PartyColors["PRD"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["MC"].low],
          15,
          ["to-color", PartyColors["MC"].mid],
          35,
          ["to-color", PartyColors["MC"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PVEM"].low],
          15,
          ["to-color", PartyColors["PVEM"].mid],
          35,
          ["to-color", PartyColors["PVEM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PT"].low],
          15,
          ["to-color", PartyColors["PT"].mid],
          35,
          ["to-color", PartyColors["PT"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "FXM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["FXM"].low],
          15,
          ["to-color", PartyColors["FXM"].mid],
          35,
          ["to-color", PartyColors["FXM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "ES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "RSP"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["RSP"].low],
          15,
          ["to-color", PartyColors["RSP"].mid],
          35,
          ["to-color", PartyColors["RSP"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PAN-PRI-PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRI-PRD"].low],
          15,
          ["to-color", PartyColors["PAN-PRI-PRD"].mid],
          35,
          ["to-color", PartyColors["PAN-PRI-PRD"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "INE"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["INE"].low],
          15,
          ["to-color", PartyColors["INE"].mid],
          35,
          ["to-color", PartyColors["INE"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors.PANAL.low],
          15,
          ["to-color", PartyColors.PANAL.mid],
          35,
          ["to-color", PartyColors.PANAL.high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRI-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM"].low],
          15,
          ["to-color", PartyColors["PRI-PVEM"].mid],
          35,
          ["to-color", PartyColors["PRI-PVEM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRI-PVEM-PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM-PANAL"].low],
          15,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].mid],
          35,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PAN-PRD-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRD-MC"].low],
          15,
          ["to-color", PartyColors["PAN-PRD-MC"].mid],
          35,
          ["to-color", PartyColors["PAN-PRD-MC"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD-PT-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-MC"].low],
          15,
          ["to-color", PartyColors["PRD-PT-MC"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-MC"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD-PT-CONVERGENCIA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].low],
          15,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD-PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT"].low],
          15,
          ["to-color", PartyColors["PRD-PT"].mid],
          35,
          ["to-color", PartyColors["PRD-PT"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA-PT-PES"].low2018
              : PartyColors["MORENA-PT-PES"].low
          ],
          15,
          ["to-color", PartyColors["MORENA-PT-PES"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PES"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA-PT-PVEM"].low2018
              : PartyColors["MORENA-PT-PVEM"].low
          ],
          15,
          ["to-color", PartyColors["MORENA-PT-PVEM"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PVEM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "NA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["NA"].low],
          15,
          ["to-color", PartyColors["NA"].mid],
          35,
          ["to-color", PartyColors["NA"].high]
        ],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018 ? PartyColors.Independiente.low2018 : "#7d7f82"
          ],
          15,
          [
            "to-color",
            year === 2018 ? PartyColors.Independiente.mid2018 : "#7d7f82"
          ],
          35,
          [
            "to-color",
            year === 2018 ? PartyColors.Independiente.high2018 : "#7d7f82"
          ]
        ]
      ],
      mayor: [
        "case",
        ["==", ["get", "TIE"], "TRUE"],
        "white",
        ["==", ["get", "FIRST"], "No cómputo"],
        "black",
        ["==", ["get", "FIRST_PARTY"], "PRI"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI"].low],
          15,
          ["to-color", PartyColors["PRI"].mid],
          35,
          ["to-color", PartyColors["PRI"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MORENA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA"].low2018
              : PartyColors["MORENA"].low
          ],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "Morena"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA"].low2018
              : PartyColors["MORENA"].low
          ],
          15,
          ["to-color", PartyColors["MORENA"].mid],
          35,
          ["to-color", PartyColors["MORENA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PAN"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN"].low],
          15,
          ["to-color", PartyColors["PAN"].mid],
          35,
          ["to-color", PartyColors["PAN"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD"].low],
          15,
          ["to-color", PartyColors["PRD"].mid],
          35,
          ["to-color", PartyColors["PRD"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["MC"].low],
          15,
          ["to-color", PartyColors["MC"].mid],
          35,
          ["to-color", PartyColors["MC"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PVEM"].low],
          15,
          ["to-color", PartyColors["PVEM"].mid],
          35,
          ["to-color", PartyColors["PVEM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PT"].low],
          15,
          ["to-color", PartyColors["PT"].mid],
          35,
          ["to-color", PartyColors["PT"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "FXM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["FXM"].low],
          15,
          ["to-color", PartyColors["FXM"].mid],
          35,
          ["to-color", PartyColors["FXM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "ES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PES"].low],
          15,
          ["to-color", PartyColors["PES"].mid],
          35,
          ["to-color", PartyColors["PES"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "RSP"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["RSP"].low],
          15,
          ["to-color", PartyColors["RSP"].mid],
          35,
          ["to-color", PartyColors["RSP"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PAN-PRI-PRD"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRI-PRD"].low],
          15,
          ["to-color", PartyColors["PAN-PRI-PRD"].mid],
          35,
          ["to-color", PartyColors["PAN-PRI-PRD"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "INE"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["INE"].low],
          15,
          ["to-color", PartyColors["INE"].mid],
          35,
          ["to-color", PartyColors["INE"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors.PANAL.low],
          15,
          ["to-color", PartyColors.PANAL.mid],
          35,
          ["to-color", PartyColors.PANAL.high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRI-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM"].low],
          15,
          ["to-color", PartyColors["PRI-PVEM"].mid],
          35,
          ["to-color", PartyColors["PRI-PVEM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRI-PVEM-PANAL"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRI-PVEM-PANAL"].low],
          15,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].mid],
          35,
          ["to-color", PartyColors["PRI-PVEM-PANAL"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PAN-PRD-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PAN-PRD-MC"].low],
          15,
          ["to-color", PartyColors["PAN-PRD-MC"].mid],
          35,
          ["to-color", PartyColors["PAN-PRD-MC"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD-PT-MC"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-MC"].low],
          15,
          ["to-color", PartyColors["PRD-PT-MC"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-MC"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD-PT-CONVERGENCIA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].low],
          15,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].mid],
          35,
          ["to-color", PartyColors["PRD-PT-CONVERGENCIA"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "PRD-PT"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["PRD-PT"].low],
          15,
          ["to-color", PartyColors["PRD-PT"].mid],
          35,
          ["to-color", PartyColors["PRD-PT"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PES"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA-PT-PES"].low2018
              : PartyColors["MORENA-PT-PES"].low
          ],
          15,
          ["to-color", PartyColors["MORENA-PT-PES"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PES"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "MORENA-PT-PVEM"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors["MORENA-PT-PVEM"].low2018
              : PartyColors["MORENA-PT-PVEM"].low
          ],
          15,
          ["to-color", PartyColors["MORENA-PT-PVEM"].mid],
          35,
          ["to-color", PartyColors["MORENA-PT-PVEM"].high]
        ],
        ["==", ["get", "FIRST_PARTY"], "NA"],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          ["to-color", PartyColors["NA"].low],
          15,
          ["to-color", PartyColors["NA"].mid],
          35,
          ["to-color", PartyColors["NA"].high]
        ],
        [
          "step",
          ["to-number", ["get", "MARGIN"], 0],
          [
            "to-color",
            year === 2018
              ? PartyColors.Independiente.low2018
              : PartyColors.Independiente.low
          ],
          15,
          [
            "to-color",
            year === 2018
              ? PartyColors.Independiente.mid2018
              : PartyColors.Independiente.mid
          ],
          35,
          [
            "to-color",
            year === 2018
              ? PartyColors.Independiente.high2018
              : PartyColors.Independiente.high
          ]
        ]
      ]
    };
  }
};

export const PartyStrFillColors = (raceType, year, party) => {
  const partyField = Parties[`${raceType}-${year}`][party];

  let partystr = [];
  partystr["2006"] = [];
  partystr["2006"]["PAN"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    20,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    38,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2006"]["PRD-PT-CONVERGENCIA"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    27,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    46,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2006"]["PRI-PVEM"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    29,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    46,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2006"]["ASDC"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    1.9,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    7.9,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2006"]["PANAL"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    1.9,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    7,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2009"] = [];
  partystr["2009"]["PAN"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    19,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    36,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2009"]["PRI-PVEM"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    41,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    58,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2009"]["PRD"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    13,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    32,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2009"]["PT-CONVERGENCIA"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    6.4,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    17,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2012"] = [];
  partystr["2012"]["PRI-PVEM"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    37,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    51,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2012"]["PRD-PT-MC"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    23,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    42,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2012"]["PAN"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    19,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    34,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2012"]["PANAL"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    1.9,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    6.4,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2015"] = [];
  partystr["2015"]["PAN"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    15,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    33,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2015"]["PRI-PVEM"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    37,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    58,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2015"]["PRD-PT"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    15,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    35,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2015"]["MC"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    7.1,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    21.4,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2015"]["MORENA"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    9.5,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    25.9,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2015"]["PANAL"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    7.5,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    21.9,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2015"]["PES"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    4.5,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    19.6,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2015"]["ES"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    4.5,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    19.6,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2018"] = [];
  partystr["2018"]["PAN-PRD-MC"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    18,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    33,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2018"]["MORENA-PT-PES"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    42,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    60,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2018"]["PRI-PVEM-PANAL"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    20,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    34,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2018"]["Independiente"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    3.3,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    9.8,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2021"] = [];
  partystr["2021"]["PAN-PRI-PRD"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    30,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    45,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2021"]["MC"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    8.6,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    24.1,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2021"]["MORENA-PT-PVEM"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    38,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    60,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2021"]["PES"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    5.4,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    17.3,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2021"]["ES"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    5.4,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    17.3,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2021"]["FXM"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    4.3,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    14.3,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2024"] = [];
  partystr["2024"]["PAN-PRI-PRD"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    18.22,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    33.71,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2024"]["MC"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    8.3,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    18.25,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  partystr["2024"]["MORENA-PT-PVEM"] = [
    "step",
    ["to-number", ["get", partyField], 0],
    ["to-color", PartyColors[party].low, "#ffffff"],
    54.26,
    ["to-color", PartyColors[party].mid, "#ffffff"],
    73.95,
    ["to-color", PartyColors[party].high, "#888888"]
  ];

  if (partystr[year] && partystr[year][party]) {
    return partystr[year][party];
  } else {
    return [
      "interpolate",
      ["linear"],
      ["to-number", ["get", partyField], 0],
      0,
      ["to-color", PartyColors[party].low, "#ffffff"],
      100,
      ["to-color", PartyColors[party].high, "#888888"]
    ];
  }
};

export const WomenColors = [
  "case",
  ["==", ["get", "THIRD_ENTRY"], 1],
  ["to-color", "#8c4b8b"],
  ["==", ["get", "SECOND_ENTRY"], 1],
  ["to-color", "#aa7eab"],
  ["==", ["get", "GOVERNOR_WOMAN"], 1],
  ["to-color", "#c8b1c6"],
  ["to-color", "#e6e4e3"]
];

export const NomColor = (useVoteCircle, level = "") => {
  if (useVoteCircle) {
    return "#ff00a8";
  }
  if (level === "municipals") {
    return [
      "step",
      ["to-number", ["get", "LISTA_NOMINAL"], 0],
      "#ffccef",
      80000,
      "#ff99e1",
      200000,
      "#ff66d4",
      450000,
      "#ff00a8"
    ];
  } else {
    return [
      "step",
      ["to-number", ["get", "LISTA_NOMINAL"], 0],
      "#ffccef",
      1899054,
      "#ff99e1",
      4003328,
      "#ff66d4",
      7918514,
      "#ff00a8"
    ];
  }
};

export const TurnoutColor = (year = "", level = "") => {
  let turnout = [];

  turnout["2006"] = [];
  turnout["2006"]["states"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    52.01,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    60.12,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];
  turnout["2006"]["municipals"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    49.93,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    64.42,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];

  turnout["2009"] = [];
  turnout["2009"]["states"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    38.26,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    47.91,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];
  turnout["2009"]["districts"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    40.45,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    51.37,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];
  turnout["2009"]["municipals"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    40.5,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    56.12,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];

  turnout["2012"] = [];
  turnout["2012"]["states"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    60.67,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    67.32,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];
  turnout["2012"]["municipals"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    57.5,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    71.43,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];

  turnout["2015"] = [];
  turnout["2015"]["states"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    39.5,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    50.28,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];
  turnout["2015"]["districts"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    41.27,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    53.21,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];
  turnout["2015"]["municipals"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    41.33,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    61.4,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];

  turnout["2018"] = [];
  turnout["2018"]["states"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    60.56,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    67.28,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];
  turnout["2018"]["municipals"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    59.59,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    72.87,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];

  turnout["2021"] = [];
  turnout["2021"]["states"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    46.75,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    54.3,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];
  turnout["2021"]["districts"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    48.87,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    59.71,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];
  turnout["2021"]["municipals"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    54.19,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    68.28,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];

  turnout["2024"] = [];
  turnout["2024"]["states"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.low, "#ffffff"],
    50,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    60,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];
  turnout["2024"]["municipals"] = [
    "step",
    ["to-number", ["get", "TURNOUT"], 0],
    ["to-color", PartyColors.INE.lowest, "#ffffff"],
    30,
    ["to-color", PartyColors.INE.low, "#ffffff"],
    47,
    ["to-color", PartyColors.INE.mid, "#ffffff"],
    64,
    ["to-color", PartyColors.INE.high, "#888888"]
  ];

  if (turnout[year] && turnout[year][level]) {
    return turnout[year][level];
  } else {
    return [
      "interpolate",
      ["linear"],
      ["to-number", ["get", "TURNOUT"], 0],
      year === "2021"
        ? level === "districts"
          ? 48.87
          : 40
        : year === "2015"
        ? level === "districts"
          ? 41.27
          : 40
        : 40,
      ["to-color", PartyColors.INE.low, "#ffffff"],
      year === "2021"
        ? level === "districts"
          ? 59.71
          : 80
        : year === "2015"
        ? level === "districts"
          ? 53.21
          : 80
        : 80,
      ["to-color", PartyColors.INE.high, "#888888"]
    ];
  }
};

export const StateOlColors = [
  "case",
  ["==", ["get", "FIRST_PARTY"], "PRD"],
  "#2654A5",
  ["==", ["get", "FIRST_PARTY"], "PRD-PT-CONVERGENCIA"],
  "#2654A5",
  ["==", ["get", "FIRST_PARTY"], "PRD-PT"],
  "#2654A5",
  ["==", ["get", "FIRST_PARTY"], "PRD-PT-MC"],
  "#2654A5",
  ["==", ["get", "FIRST"], "PRD"],
  "#2654A5",
  ["==", ["get", "FIRST"], "PRD-PT-CONVERGENCIA"],
  "#2654A5",
  ["==", ["get", "FIRST"], "PRD-PT"],
  "#2654A5",
  ["==", ["get", "FIRST"], "PRD-PT-MC"],
  "#2654A5",
  "white"
];
