import { useEffect, useState } from "react";
import Papa from "papaparse";
import { Box, Text } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { appSelector } from "../../../redux/app/selectors";
import RaceData from "../../../mapbox/racechart";
import { PartyColors } from "../../../mapbox/colors";
import {
  setRaceNameN,
  setRacePartyN,
  setRacePercentN,
  setRaceVoteN
} from "../../../redux/app/slice";

const RaceChart = () => {
  const dispatch = useDispatch();
  const app = useSelector(appSelector);
  const year = useSelector((state) => state.year.value);
  const raceType = useSelector((state) => state.raceType.value);
  /*const raceTypeTxt = {
    pres: "Presidential",
    gov: "Gubernational",
    cong: "Congressional",
    mayor: "Mayoral"
  };*/

  let raceboardWidth = "0px";
  if (window.innerWidth > 3000) {
    raceboardWidth = "0px";
  }

  const [partyDisp1, setPartyDisp1] = useState("");
  const [partyDisp2, setPartyDisp2] = useState("");
  const [partyDisp3, setPartyDisp3] = useState("");
  const [partyDisp4, setPartyDisp4] = useState("");
  const [partyDisp5, setPartyDisp5] = useState("");
  const [party1, setParty1] = useState("");
  const [party2, setParty2] = useState("");
  const [party3, setParty3] = useState("");
  const [party4, setParty4] = useState("");
  const [party5, setParty5] = useState("");
  const [party6, setParty6] = useState("");
  const [party7, setParty7] = useState("");
  const [party8, setParty8] = useState("");
  const [party9, setParty9] = useState("");
  const [party10, setParty10] = useState("");
  const [party11, setParty11] = useState("");
  const [partyPerc1, setPartyPerc1] = useState(0);
  const [partyPerc2, setPartyPerc2] = useState(0);
  const [partyPerc3, setPartyPerc3] = useState(0);
  const [partyPerc4, setPartyPerc4] = useState(0);
  const [partyPerc5, setPartyPerc5] = useState(0);
  const [partyPerc6, setPartyPerc6] = useState(0);
  const [partyPerc7, setPartyPerc7] = useState(0);
  const [partyPerc8, setPartyPerc8] = useState(0);
  const [partyPerc9, setPartyPerc9] = useState(0);
  const [partyPerc10, setPartyPerc10] = useState(0);
  const [partyPerc11, setPartyPerc11] = useState(0);
  const [congParty1, setCongParty1] = useState("");
  const [congParty2, setCongParty2] = useState("");
  const [congParty3, setCongParty3] = useState("");
  const [congParty4, setCongParty4] = useState("");
  const [congParty5, setCongParty5] = useState("");
  const [congParty6, setCongParty6] = useState("");
  const [congParty7, setCongParty7] = useState("");
  const [congParty8, setCongParty8] = useState("");
  const [congParty9, setCongParty9] = useState("");
  const [congParty10, setCongParty10] = useState("");
  const [congParty11, setCongParty11] = useState("");
  const [congPartyPerc1, setCongPartyPerc1] = useState(0);
  const [congPartyPerc2, setCongPartyPerc2] = useState(0);
  const [congPartyPerc3, setCongPartyPerc3] = useState(0);
  const [congPartyPerc4, setCongPartyPerc4] = useState(0);
  const [congPartyPerc5, setCongPartyPerc5] = useState(0);
  const [congPartyPerc6, setCongPartyPerc6] = useState(0);
  const [congPartyPerc7, setCongPartyPerc7] = useState(0);
  const [congPartyPerc8, setCongPartyPerc8] = useState(0);
  const [congPartyPerc9, setCongPartyPerc9] = useState(0);
  const [congPartyPerc10, setCongPartyPerc10] = useState(0);
  const [congPartyPerc11, setCongPartyPerc11] = useState(0);
  const [congPartySeat1, setCongPartySeat1] = useState(0);
  const [congPartySeat2, setCongPartySeat2] = useState(0);
  const [congPartySeat3, setCongPartySeat3] = useState(0);
  const [congPartySeat4, setCongPartySeat4] = useState(0);
  const [congPartySeat5, setCongPartySeat5] = useState(0);
  const [congPartySeat6, setCongPartySeat6] = useState(0);
  const [congPartySeat7, setCongPartySeat7] = useState(0);
  const [congPartySeat8, setCongPartySeat8] = useState(0);
  const [congPartySeat9, setCongPartySeat9] = useState(0);
  const [congPartySeat10, setCongPartySeat10] = useState(0);
  const [congPartySeat11, setCongPartySeat11] = useState(0);
  const [govPartiesJal2018, setGovPartiesJal2018] = useState({
    seats: {},
    names: {}
  });
  const [govPartiesJal2021, setGovPartiesJal2021] = useState({
    seats: {},
    names: {}
  });
  const [govPartiesCdmx2018, setGovPartiesCdmx2018] = useState({
    seats: {},
    names: {}
  });
  const [govPartiesCdmx2021, setGovPartiesCdmx2021] = useState({
    seats: {},
    names: {}
  });
  const [senParties, setSenParties] = useState({
    seats: {},
    names: {}
  });


  const updateGovPartyJal2018 = (index, seats, perc, name) => {
    setGovPartiesJal2018((prev) => ({
      ...prev,
      seats: { ...prev.seats, [index]: seats },
      names: { ...prev.names, [index]: name },
      percs: { ...prev.percs, [index]: perc }
    }));
  };
  const updateGovPartyJal2021 = (index, seats, perc, name) => {
    setGovPartiesJal2021((prev) => ({
      ...prev,
      seats: { ...prev.seats, [index]: seats },
      names: { ...prev.names, [index]: name },
      percs: { ...prev.percs, [index]: perc }
    }));
  };

  const updateGovPartyCdmx2018 = (index, seats, perc, name) => {
    setGovPartiesCdmx2018((prev) => ({
      ...prev,
      seats: { ...prev.seats, [index]: seats },
      names: { ...prev.names, [index]: name },
      percs: { ...prev.percs, [index]: perc }
    }));
  };
  const updateGovPartyCdmx2021 = (index, seats, perc, name) => {
    setGovPartiesCdmx2021((prev) => ({
      ...prev,
      seats: { ...prev.seats, [index]: seats },
      names: { ...prev.names, [index]: name },
      percs: { ...prev.percs, [index]: perc }
    }));
  };

  const updateSenParty = (index, seats, perc, name) => {
    setSenParties((prev) => ({
      ...prev,
      seats: { ...prev.seats, [index]: seats },
      names: { ...prev.names, [index]: name },
      percs: { ...prev.percs, [index]: perc }
    }));
  };

  useEffect(() => {
    if (
      ["pres", "cong", "sen"].includes(raceType) ||
      (raceType === "gov" && (year === "jal" || year === "cdmx" || year === "cdmx24"))
    ) {
      setParty5("");
      setPartyPerc5(0);
      setCongParty5("");
      setCongPartyPerc5(0);
      setCongPartySeat5(0);
      setParty6("");
      setPartyPerc6(0);
      setCongParty6("");
      setCongPartyPerc6(0);
      setCongPartySeat6(0);
      setParty7("");
      setPartyPerc7(0);
      setCongParty7("");
      setCongPartyPerc7(0);
      setCongPartySeat7(0);
      setParty8("");
      setPartyPerc8(0);
      setCongParty8("");
      setCongPartyPerc8(0);
      setCongPartySeat8(0);
      setParty9("");
      setPartyPerc9(0);
      setCongParty9("");
      setCongPartyPerc9(0);
      setCongPartySeat9(0);
      setParty10("");
      setPartyPerc10(0);
      setCongParty10("");
      setCongPartyPerc10(0);
      setCongPartySeat10(0);
      setParty11("");
      setPartyPerc11(0);
      setCongParty11("");
      setCongPartyPerc11(0);
      setCongPartySeat11(0);
      setGovPartiesJal2018({
        seats: {},
        names: {},
        percs: {}
      });
      setGovPartiesJal2021({
        seats: {},
        names: {},
        percs: {}
      });
      setGovPartiesCdmx2018({
        seats: {},
        names: {},
        percs: {}
      });
      setGovPartiesCdmx2021({
        seats: {},
        names: {},
        percs: {}
      });

      if (raceType === "pres" && year !== "nom-2024") {
        Papa.parse(RaceData["pres" + year], {
          download: true,
          complete: function (input) {
            let records = input.data;

            // Initialize state values to default
            setPartyDisp1("");
            setParty1("");
            setPartyPerc1(0);
            dispatch(setRacePartyN({ payload: "", i: 1 }));
            dispatch(setRacePercentN({ payload: 0, i: 1 }));
            dispatch(setRaceVoteN({ payload: 0, i: 1 }));

            setPartyDisp2("");
            setParty2("");
            setPartyPerc2(0);
            dispatch(setRacePartyN({ payload: "", i: 2 }));
            dispatch(setRacePercentN({ payload: 0, i: 2 }));
            dispatch(setRaceVoteN({ payload: 0, i: 2 }));

            setPartyDisp3("");
            setParty3("");
            setPartyPerc3(0);
            dispatch(setRacePartyN({ payload: "", i: 3 }));
            dispatch(setRacePercentN({ payload: 0, i: 3 }));
            dispatch(setRaceVoteN({ payload: 0, i: 3 }));

            setPartyDisp4("");
            setParty4("");
            setPartyPerc4(0);
            dispatch(setRacePartyN({ payload: "", i: 4 }));
            dispatch(setRacePercentN({ payload: 0, i: 4 }));
            dispatch(setRaceVoteN({ payload: 0, i: 4 }));

            setPartyDisp5("");
            setParty5("");
            setPartyPerc5(0);
            dispatch(setRacePartyN({ payload: "", i: 5 }));
            dispatch(setRacePercentN({ payload: 0, i: 5 }));
            dispatch(setRaceVoteN({ payload: 0, i: 5 }));

            records.forEach(function (data, i) {
              if (i === 1) {
                setPartyDisp1(data[2]);
                setParty1(data[3]);
                setPartyPerc1(Math.round(data[6]));
                dispatch(setRacePartyN({ payload: data[3], i: 1 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[6]), i: 1 })
                );
                dispatch(setRaceVoteN({ payload: data[5], i: 1 }));
              } else if (i === 2) {
                setPartyDisp2(data[2]);
                setParty2(data[3]);
                setPartyPerc2(Math.round(data[6]));
                dispatch(setRacePartyN({ payload: data[3], i: 2 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[6]), i: 2 })
                );
                dispatch(setRaceVoteN({ payload: data[5], i: 2 }));
              } else if (i === 3) {
                setPartyDisp3(data[2]);
                setParty3(data[3]);
                setPartyPerc3(Math.round(data[6]));
                dispatch(setRacePartyN({ payload: data[3], i: 3 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[6]), i: 3 })
                );
                dispatch(setRaceVoteN({ payload: data[5], i: 3 }));
              } else if (i === 4) {
                setPartyDisp4(data[2]);
                setParty4(data[3]);
                setPartyPerc4(Math.round(data[6]));
                dispatch(setRacePartyN({ payload: data[3], i: 4 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[6]), i: 4 })
                );
                dispatch(setRaceVoteN({ payload: data[5], i: 4 }));
              } else if (i === 5) {
                setPartyDisp5(data[2]);
                setParty5(data[3]);
                setPartyPerc5(Math.round(data[6]));
                dispatch(setRacePartyN({ payload: data[3], i: 5 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[6]), i: 5 })
                );
                dispatch(setRaceVoteN({ payload: data[5], i: 5 }));
              }
            });
          }
        });
      } else if (raceType === "cong") {
        Papa.parse(RaceData["congN" + year], {
          download: true,
          complete: function (input) {
            let records = input.data;
            const raceRecords = records.sort(function (a, b) {
              if (b[3] === a[3]) return b[2] - a[2];
              else return b[3] - a[3];
            });
            raceRecords.forEach(function (data, i) {
              if (i >= 1 && i <= 4) {
                dispatch(setRacePartyN({ payload: data[1], i: i }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[3]), i: i })
                );
                dispatch(setRaceVoteN({ payload: data[2], i: i }));
              }
            });
          }
        });
      } else if (raceType === "gov" && year === "jal") {
        Papa.parse(RaceData["govJalisco"], {
          download: true,
          complete: function (input) {
            let records = input.data;
            records.forEach(function (data, i) {
              if (i === 1) {
                dispatch(setRacePartyN({ payload: data[2], i: 1 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 1 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 1 }));
                dispatch(setRaceNameN({ payload: data[1], i: 1 }));
              } else if (i === 2) {
                dispatch(setRacePartyN({ payload: data[2], i: 2 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 2 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 2 }));
                dispatch(setRaceNameN({ payload: data[1], i: 2 }));
              } else if (i === 3) {
                dispatch(setRacePartyN({ payload: data[2], i: 3 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 3 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 3 }));
                dispatch(setRaceNameN({ payload: data[1], i: 3 }));
              } else if (i === 4) {
                dispatch(setRacePartyN({ payload: data[2], i: 4 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 4 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 4 }));
                dispatch(setRaceNameN({ payload: data[1], i: 4 }));
              } else if (i === 5) {
                dispatch(setRacePartyN({ payload: data[2], i: 5 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 5 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 5 }));
                dispatch(setRaceNameN({ payload: data[1], i: 5 }));
              }
            });
          }
        });

        Papa.parse(RaceData["govJaliscoRC2018"], {
          download: true,
          complete: function (input) {
            let records = input.data;
            records.forEach(function (data, i) {
              if (i > 0) {
                updateGovPartyJal2018(i, data[4], Math.round(data[3]), data[1]);
              }
            });
          }
        });

        Papa.parse(RaceData["govJaliscoRC2021"], {
          download: true,
          complete: function (input) {
            let records = input.data;
            records.forEach(function (data, i) {
              if (i > 0) {
                updateGovPartyJal2021(i, data[4], Math.round(data[3]), data[1]);
              }
            });
          }
        });
      } else if (raceType === "gov" && year === "cdmx") {
        Papa.parse(RaceData["govCdmx2018"], {
          download: true,
          complete: function (input) {
            let records = input.data;
            records.forEach(function (data, i) {
              if (i === 1) {
                dispatch(setRacePartyN({ payload: data[2], i: 1 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 1 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 1 }));
                dispatch(setRaceNameN({ payload: data[1], i: 1 }));
              } else if (i === 2) {
                dispatch(setRacePartyN({ payload: data[2], i: 2 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 2 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 2 }));
                dispatch(setRaceNameN({ payload: data[1], i: 2 }));
              } else if (i === 3) {
                dispatch(setRacePartyN({ payload: data[2], i: 3 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 3 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 3 }));
                dispatch(setRaceNameN({ payload: data[1], i: 3 }));
              } else if (i === 4) {
                dispatch(setRacePartyN({ payload: data[2], i: 4 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 4 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 4 }));
                dispatch(setRaceNameN({ payload: data[1], i: 4 }));
              } else if (i === 5) {
                dispatch(setRacePartyN({ payload: data[2], i: 5 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 5 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 5 }));
                dispatch(setRaceNameN({ payload: data[1], i: 5 }));
              }
            });
          }
        });

        Papa.parse(RaceData["govCdmxRC2018"], {
          download: true,
          complete: function (input) {
            let records = input.data;
            records.forEach(function (data, i) {
              if (i > 0) {
                updateGovPartyCdmx2018(
                  i,
                  data[4],
                  Math.round(data[3]),
                  data[1]
                );
              }
            });
          }
        });

        Papa.parse(RaceData["govCdmxRC2021"], {
          download: true,
          complete: function (input) {
            let records = input.data;
            records.forEach(function (data, i) {
              if (i > 0) {
                updateGovPartyCdmx2021(
                  i,
                  data[4],
                  Math.round(data[3]),
                  data[1]
                );
              }
            });
          }
        });
      } else if (raceType === "gov" && year === "cdmx24") {
        Papa.parse(RaceData["govCdmx2024"], {
          download: true,
          complete: function (input) {
            let records = input.data;
            records.forEach(function (data, i) {
              if (i === 1) {
                dispatch(setRacePartyN({ payload: data[2], i: 1 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 1 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 1 }));
                dispatch(setRaceNameN({ payload: data[1], i: 1 }));
              } else if (i === 2) {
                dispatch(setRacePartyN({ payload: data[2], i: 2 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 2 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 2 }));
                dispatch(setRaceNameN({ payload: data[1], i: 2 }));
              } else if (i === 3) {
                dispatch(setRacePartyN({ payload: data[2], i: 3 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 3 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 3 }));
                dispatch(setRaceNameN({ payload: data[1], i: 3 }));
              } else if (i === 4) {
                dispatch(setRacePartyN({ payload: data[2], i: 4 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 4 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 4 }));
                dispatch(setRaceNameN({ payload: data[1], i: 4 }));
              } else if (i === 5) {
                dispatch(setRacePartyN({ payload: data[2], i: 5 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 5 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 5 }));
                dispatch(setRaceNameN({ payload: data[1], i: 5 }));
              }
            });
          }
        });

        Papa.parse(RaceData["govCdmxRC2018"], {
          download: true,
          complete: function (input) {
            let records = input.data;
            records.forEach(function (data, i) {
              if (i > 0) {
                updateGovPartyCdmx2018(
                  i,
                  data[4],
                  Math.round(data[3]),
                  data[1]
                );
              }
            });
          }
        });

        Papa.parse(RaceData["govCdmxRC2021"], {
          download: true,
          complete: function (input) {
            let records = input.data;
            records.forEach(function (data, i) {
              if (i > 0) {
                updateGovPartyCdmx2021(
                  i,
                  data[4],
                  Math.round(data[3]),
                  data[1]
                );
              }
            });
          }
        });
      } else if (raceType === "sen") {
        Papa.parse(RaceData["sen"], {
          download: true,
          complete: function (input) {
            let records = input.data;
            records.forEach(function (data, i) {
              if (i === 1) {
                dispatch(setRacePartyN({ payload: data[2], i: 1 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 1 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 1 }));
                dispatch(setRaceNameN({ payload: data[1], i: 1 }));
              } else if (i === 2) {
                dispatch(setRacePartyN({ payload: data[2], i: 2 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 2 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 2 }));
                dispatch(setRaceNameN({ payload: data[1], i: 2 }));
              } else if (i === 3) {
                dispatch(setRacePartyN({ payload: data[2], i: 3 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 3 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 3 }));
                dispatch(setRaceNameN({ payload: data[1], i: 3 }));
              } else if (i === 4) {
                dispatch(setRacePartyN({ payload: data[2], i: 4 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 4 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 4 }));
                dispatch(setRaceNameN({ payload: data[1], i: 4 }));
              } else if (i === 5) {
                dispatch(setRacePartyN({ payload: data[2], i: 5 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 5 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 5 }));
                dispatch(setRaceNameN({ payload: data[1], i: 5 }));
              }
              else if (i === 6) {
                dispatch(setRacePartyN({ payload: data[2], i: 6 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 6 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 6 }));
                dispatch(setRaceNameN({ payload: data[1], i: 6 }));
              }
              else if (i === 7) {
                dispatch(setRacePartyN({ payload: data[2], i: 7 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 7 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 7 }));
                dispatch(setRaceNameN({ payload: data[1], i: 7 }));
              }
              else if (i === 8) {
                dispatch(setRacePartyN({ payload: data[2], i: 8 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 8 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 8 }));
                dispatch(setRaceNameN({ payload: data[1], i: 8 }));
              }
              else if (i === 9) {
                dispatch(setRacePartyN({ payload: data[2], i: 9 }));
                dispatch(
                  setRacePercentN({ payload: Math.round(data[5]), i: 9 })
                );
                dispatch(setRaceVoteN({ payload: data[4], i: 9 }));
                dispatch(setRaceNameN({ payload: data[1], i: 9 }));
              }
            });
          }
        });

        Papa.parse(RaceData["senRC"], {
          download: true,
          complete: function (input) {
            let records = input.data;
            records.forEach(function (data, i) {
              if (i > 0) {
                updateSenParty(
                  i,
                  Math.round(data[4]),
                  `${parseFloat(data[7] || 0.0).toFixed(1)}%`,
                  data[1]
                );
              }
            });
          }
        });
      }

      if (
        raceType !== "gov" &&
        raceType !== "sen" &&
        year !== "jal" &&
        year !== "cdmx" &&
        year !== "cdmx24" &&
        year !== "nom-2024" &&
        !(year === "2024" && raceType === "pres")
      ) {
        Papa.parse(RaceData["cong" + year], {
          download: true,
          complete: function (input) {
            let records = input.data;
            const seatRecords = records.sort(function (a, b) {
              if (b[4] === a[4]) return b[3] - a[3];
              else return b[4] - a[4];
            });
            /*let other_seats = 0;
              records.forEach(function (data, i) {
                if (i >= 4)
                  other_seats += parseInt(data[4]);
              });*/
            seatRecords.forEach(function (data, i) {
              if (i === 1) {
                setCongParty1(data[1]);
                setCongPartyPerc1(Math.round(data[3]));
                setCongPartySeat1(data[4]);
              } else if (i === 2) {
                setCongParty2(data[1]);
                setCongPartyPerc2(Math.round(data[3]));
                setCongPartySeat2(data[4]);
              } else if (i === 3) {
                setCongParty3(data[1]);
                setCongPartyPerc3(Math.round(data[3]));
                setCongPartySeat3(data[4]);
              } else if (i === 4) {
                setCongParty4(data[1]);
                setCongPartyPerc4(Math.round(data[3]));
                setCongPartySeat4(data[4]);
              } else if (i === 5) {
                setCongParty5(data[1]);
                setCongPartyPerc5(Math.round(data[3]));
                setCongPartySeat5(data[4]);
              } else if (i === 6) {
                setCongParty6(data[1]);
                setCongPartyPerc6(Math.round(data[3]));
                setCongPartySeat6(data[4]);
              } else if (i === 7) {
                setCongParty7(data[1]);
                setCongPartyPerc7(Math.round(data[3]));
                setCongPartySeat7(data[4]);
              } else if (i === 8) {
                setCongParty8(data[1]);
                setCongPartyPerc8(Math.round(data[3]));
                setCongPartySeat8(data[4]);
              } else if (i === 9) {
                setCongParty9(data[1]);
                setCongPartyPerc9(Math.round(data[3]));
                setCongPartySeat9(data[4]);
              } else if (i === 10) {
                setCongParty10(data[1]);
                setCongPartyPerc10(Math.round(data[3]));
                setCongPartySeat10(data[4]);
              } else if (i === 11) {
                setCongParty11(data[1]);
                setCongPartyPerc11(Math.round(data[3]));
                setCongPartySeat11(data[4]);
              }
            });

            if (raceType === "cong") {
              const percRecords = records.sort(function (a, b) {
                if (b[3] === "-") b[3] = 0;
                if (a[3] === "-") a[3] = 0;
                return b[3] - a[3];
              });
              percRecords.forEach(function (data, i) {
                if (i === 1) {
                  setParty1(data[1]);
                  setPartyPerc1(Math.round(data[3]));
                  //dispatch(setRacePartyN({ payload: data[1], i: 1 }));
                  //dispatch(setRacePercentN({ payload: Math.round(data[3]), i: 1 }));
                  //dispatch(setRaceVoteN({ payload: data[2], i: 1 }));
                } else if (i === 2) {
                  setParty2(data[1]);
                  setPartyPerc2(Math.round(data[3]));
                  //dispatch(setRacePartyN({ payload: data[1], i: 2 }));
                  //dispatch(setRacePercentN({ payload: Math.round(data[3]), i: 2 }));
                  //dispatch(setRaceVoteN({ payload: data[2], i: 2 }));
                } else if (i === 3) {
                  setParty3(data[1]);
                  setPartyPerc3(Math.round(data[3]));
                  //dispatch(setRacePartyN({ payload: data[1], i: 3 }));
                  //dispatch(setRacePercentN({ payload: Math.round(data[3]), i: 3 }));
                  //dispatch(setRaceVoteN({ payload: data[2], i: 3 }));
                } else if (i === 4) {
                  setParty4(data[1]);
                  setPartyPerc4(Math.round(data[3]));
                  //dispatch(setRacePartyN({ payload: data[1], i: 4 }));
                  //dispatch(setRacePercentN({ payload: Math.round(data[3]), i: 4 }));
                  //dispatch(setRaceVoteN({ payload: data[2], i: 4 }));
                } else if (i === 5) {
                  setParty5(data[1]);
                  setPartyPerc5(Math.round(data[3]) ? Math.round(data[3]) : 0);
                } else if (i === 6) {
                  setParty6(data[1]);
                  setPartyPerc6(Math.round(data[3]) ? Math.round(data[3]) : 0);
                } else if (i === 7) {
                  setParty7(data[1]);
                  setPartyPerc7(Math.round(data[3]) ? Math.round(data[3]) : 0);
                } else if (i === 8) {
                  setParty8(data[1]);
                  setPartyPerc8(Math.round(data[3]) ? Math.round(data[3]) : 0);
                } else if (i === 9) {
                  setParty9(data[1]);
                  setPartyPerc9(Math.round(data[3]) ? Math.round(data[3]) : 0);
                } else if (i === 10) {
                  setParty10(data[1]);
                  setPartyPerc10(Math.round(data[3]) ? Math.round(data[3]) : 0);
                } else if (i === 11) {
                  setParty11(data[1]);
                  setPartyPerc11(Math.round(data[3]) ? Math.round(data[3]) : 0);
                }
              });
            }
          }
        });
      }
    }
  }, [year, raceType, dispatch]);

  useEffect(() => {
    if (!app.showRaceboard) {
      setTimeout(() => {
        if (document.getElementById("racechart"))
          document.getElementById("racechart").classList.add("board-opened");
      }, 0);
    } else {
      setTimeout(() => {
        if (document.getElementById("racechart"))
          document.getElementById("racechart").classList.remove("board-opened");
      }, 0);
    }
  }, [app.showRaceboard]);

  return (
    <Box
      id={"racechart"}
      className={"raceChart"}
      style={{
        fontFmaily: "inherit",
        width: "calc(100% - 7.5% - " + raceboardWidth + " - 0%)",
        position: "absolute",
        left: app.reverse
          ? app.showRaceboard
            ? raceboardWidth
            : "0px"
          : "7.5%",
        bottom: "2.5rem",
        backgroundColor: "transparent",
        color: "#000",
        zIndex: "201",
        display: app.showRaceChart ? "flex" : "none",
        paddingLeft: "0%",
        paddingRight: "0%"
      }}
    >
      <Box
        style={{
          display: ["pres", "cong"].includes(raceType) ? "flex" : "none",
          flexDirection: "column",
          width: year === "2024" ? "100%" : "48%",
          paddingLeft: "1%",
          paddingRight: "1%",
          marginRight: "0.3%",
          border: "solid 0px",
          backgroundColor: "#D5D1FA",
          borderRadius: "5px"
        }}
      >
        <Text
          className={"raceChartTitle"}
          sx={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            whiteSpace: "nowrap",
            marginTop: "0.4rem",
            marginBottom: "0.3rem"
          }}
        >
          <FormattedMessage id={"ValidVote"} />{" "}
          {raceType === "pres" ? (
            <FormattedMessage id={"Presidential"} />
          ) : undefined}{" "}
          {year}
        </Text>
        <Box style={{ display: "flex", width: "100%" }}>
          <Box
            style={{
              width: "calc(" + partyPerc1 + "% - 0.5%)",
              minWidth: "8%",
              marginRight: "0.5%",
              display: "flex",
              alignItems: "end"
            }}
          >
            <Text
              className={"raceChartParty"}
              sx={{
                fontWeight: "bold",
                color:
                  PartyColors[party1]?.boardText || PartyColors[party1]?.high,
                fontSize: "0.8rem",
                lineHeight: "1.55",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              {raceType === "pres" ? partyDisp1 : party1}
            </Text>
          </Box>
          <Box
            style={{
              width: "calc(" + partyPerc2 + "% - 0.5%)",
              minWidth: "8%",
              marginRight: "0.5%",
              display: "flex",
              alignItems: "end"
            }}
          >
            <Text
              className={"raceChartParty"}
              sx={{
                fontWeight: "bold",
                color:
                  PartyColors[party2]?.boardText || PartyColors[party2]?.high,
                fontSize: "0.8rem",
                lineHeight: "1.55",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              {raceType === "pres" ? partyDisp2 : party2}
            </Text>
          </Box>
          {party3 && (
            <Box
              style={{
                width: "calc(" + partyPerc3 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[party3]?.boardText || PartyColors[party3]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >
                {raceType === "pres" ? partyDisp3 : party3}
              </Text>
            </Box>
          )}
          {party4 && (
            <Box
              style={{
                width: "calc(" + partyPerc4 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[party4]?.boardText || PartyColors[party4]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible" /*, textOverflow: "ellipsis"*/
                }}
              >
                {raceType === "pres" ? partyDisp4 : party4.substring(0, 5)}
              </Text>
            </Box>
          )}
          {party5 && (
            <Box
              style={{
                width: "calc(" + partyPerc5 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[party5]?.boardText || PartyColors[party5]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible"
                }}
              >
                {raceType === "pres" ? partyDisp5 : party5.substring(0, 5)}
              </Text>
            </Box>
          )}
          {party6 && (
            <Box
              style={{
                width: "calc(" + partyPerc6 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[party6]?.boardText || PartyColors[party6]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible"
                }}
              >
                {party6.substring(0, 5)}
              </Text>
            </Box>
          )}
          {party7 && (
            <Box
              style={{
                width: "calc(" + partyPerc7 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[party7]?.boardText || PartyColors[party7]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible"
                }}
              >
                {party7.substring(0, 5)}
              </Text>
            </Box>
          )}
          {party8 && (
            <Box
              style={{
                width: "calc(" + partyPerc8 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[party8]?.boardText || PartyColors[party8]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible"
                }}
              >
                {party8.substring(0, 5)}
              </Text>
            </Box>
          )}
          {party9 && (
            <Box
              style={{
                width: "calc(" + partyPerc9 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[party9]?.boardText || PartyColors[party9]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible"
                }}
              >
                {party9.substring(0, 5)}
              </Text>
            </Box>
          )}
          {party10 && (
            <Box
              style={{
                width: "calc(" + partyPerc10 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[party10]?.boardText ||
                    PartyColors[party10]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible"
                }}
              >
                {party10.substring(0, 5)}
              </Text>
            </Box>
          )}
          {party11 && (
            <Box
              style={{
                width: "calc(" + partyPerc11 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[party11]?.boardText ||
                    PartyColors[party11]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible"
                }}
              >
                {party11.substring(0, 5)}
              </Text>
            </Box>
          )}
        </Box>
        <Box
          className={"raceChartBar"}
          style={{ display: "flex", width: "100%", marginBottom: "0.6rem" }}
        >
          <Box
            style={{
              width: "calc(" + partyPerc1 + "% - 0.5%)",
              minWidth: "8%",
              backgroundColor: PartyColors[party1]?.high,
              marginRight: "0.5%"
            }}
          >
            <Text
              className={"raceChartNum"}
              sx={{
                fontWeight: "bold",
                color: PartyColors[party1]?.contrast || "white",
                margin: "0.1rem 0.5rem",
                fontSize:
                  "1.2rem" /*, overflow: "hidden", textOverflow: "ellipsis"*/
              }}
            >
              {partyPerc1}%
            </Text>
          </Box>
          <Box
            style={{
              width: "calc(" + partyPerc2 + "% - 0.5%)",
              minWidth: "8%",
              backgroundColor: PartyColors[party2]?.high,
              marginRight: "0.5%"
            }}
          >
            <Text
              className={"raceChartNum"}
              sx={{
                fontWeight: "bold",
                color: PartyColors[party2]?.contrast || "white",
                margin: "0.1rem 0.5rem",
                fontSize:
                  "1.2rem" /*, overflow: "hidden", textOverflow: "ellipsis"*/
              }}
            >
              {partyPerc2}%
            </Text>
          </Box>
          {party3 && (
            <Box
              style={{
                width: "calc(" + partyPerc3 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[party3]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[party3]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize:
                    "1.2rem" /*, overflow: "hidden", textOverflow: "ellipsis"*/
                }}
              >
                {partyPerc3}%
              </Text>
            </Box>
          )}
          {party4 && (
            <Box
              style={{
                width: "calc(" + partyPerc4 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[party4]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[party4]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {partyPerc4}%
              </Text>
            </Box>
          )}
          {party5 && (
            <Box
              style={{
                width: "calc(" + partyPerc5 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[party5]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[party5]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {partyPerc5}%
              </Text>
            </Box>
          )}
          {party6 && (
            <Box
              style={{
                width: "calc(" + partyPerc6 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[party6]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[party6]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {partyPerc6}%
              </Text>
            </Box>
          )}
          {party7 && (
            <Box
              style={{
                width: "calc(" + partyPerc7 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[party7]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[party7]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {partyPerc7}%
              </Text>
            </Box>
          )}
          {party8 && (
            <Box
              style={{
                width: "calc(" + partyPerc8 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[party8]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[party8]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {year === "2021" ? "2.9" : partyPerc8}%
              </Text>
            </Box>
          )}
          {party9 && (
            <Box
              style={{
                width: "calc(" + partyPerc9 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[party9]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[party9]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {year === "2021" ? "2.6" : partyPerc9}%
              </Text>
            </Box>
          )}
          {party10 && (
            <Box
              style={{
                width: "calc(" + partyPerc10 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[party10]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[party10]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {year === "2021" ? "1.8" : partyPerc10}%
              </Text>
            </Box>
          )}
          {party11 && (
            <Box
              style={{
                width: "calc(" + partyPerc11 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[party11]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[party11]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {partyPerc11}%
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        style={{
          display:
            raceType !== "gov" &&
            year !== "jal" &&
            year !== "cdmx" &&
            year !== "cdmx24" &&
            year !== "2024"
              ? "flex"
              : "none",
          flexDirection: "column",
          width: ["pres", "cong"].includes(raceType) ? "48%" : "100%",
          marginLeft: ["pres", "cong"].includes(raceType) ? "0%" : "0%",
          paddingLeft: "1%",
          paddingRight: "1%",
          marginRight: "0%",
          border: "solid 0px",
          backgroundColor: "#D5D1FA",
          borderRadius: "5px"
        }}
      >
        <Text
          className={"raceChartTitle"}
          sx={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            whiteSpace: "nowrap",
            marginTop: "0.4rem",
            marginBottom: "0.3rem"
          }}
        >
          <FormattedMessage id={"Seats"} /> {year}
        </Text>
        <Box style={{ display: "flex", width: "100%" }}>
          <Box
            style={{
              width: "calc(" + congPartyPerc1 + "% - 0.5%)",
              minWidth: "8%",
              marginRight: "0.5%",
              display: "flex",
              alignItems: "end"
            }}
          >
            <Text
              className={"raceChartParty"}
              sx={{
                fontWeight: "bold",
                color:
                  PartyColors[congParty1]?.boardText ||
                  PartyColors[congParty1]?.high,
                fontSize: "0.8rem",
                lineHeight: "1.55",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              {congParty1}
            </Text>
          </Box>
          <Box
            style={{
              width: "calc(" + congPartyPerc2 + "% - 0.5%)",
              minWidth: "8%",
              marginRight: "0.5%",
              display: "flex",
              alignItems: "end"
            }}
          >
            <Text
              className={"raceChartParty"}
              sx={{
                fontWeight: "bold",
                color:
                  PartyColors[congParty2]?.boardText ||
                  PartyColors[congParty2]?.high,
                fontSize: "0.8rem",
                lineHeight: "1.55",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              {congParty2}
            </Text>
          </Box>
          <Box
            style={{
              width: "calc(" + congPartyPerc3 + "% - 0.5%)",
              minWidth: "8%",
              marginRight: "0.5%",
              display: "flex",
              alignItems: "end"
            }}
          >
            <Text
              className={"raceChartParty"}
              sx={{
                fontWeight: "bold",
                color:
                  PartyColors[congParty3]?.boardText ||
                  PartyColors[congParty3]?.high,
                fontSize: "0.8rem",
                lineHeight: "1.55",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              {congParty3}
            </Text>
          </Box>
          <Box
            style={{
              width: "calc(" + congPartyPerc4 + "% - 0.5%)",
              minWidth: "8%",
              marginRight: "0.5%",
              display: "flex",
              alignItems: "end"
            }}
          >
            <Text
              className={"raceChartParty"}
              sx={{
                fontWeight: "bold",
                color:
                  PartyColors[congParty4]?.boardText ||
                  PartyColors[congParty4]?.high,
                fontSize: "0.8rem",
                lineHeight: "1.55",
                overflow: "visible" /*, textOverflow: "ellipsis"*/
              }}
            >
              {congParty4.substring(0, 5)}
            </Text>
          </Box>
          {congParty5 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc5 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[congParty5]?.boardText ||
                    PartyColors[congParty5]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible" /*, textOverflow: "ellipsis"*/
                }}
              >
                {congParty5.substring(0, 5)}
              </Text>
            </Box>
          )}
          {congParty6 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc6 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[congParty6]?.boardText ||
                    PartyColors[congParty6]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible" /*, textOverflow: "ellipsis"*/
                }}
              >
                {congParty6.substring(0, 5)}
              </Text>
            </Box>
          )}
          {congParty7 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc7 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[congParty7]?.boardText ||
                    PartyColors[congParty7]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible" /*, textOverflow: "ellipsis"*/
                }}
              >
                {congParty7.substring(0, 5)}
              </Text>
            </Box>
          )}
          {congParty8 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc8 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[congParty8]?.boardText ||
                    PartyColors[congParty8]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible" /*, textOverflow: "ellipsis"*/
                }}
              >
                {congParty8.substring(0, 5)}
              </Text>
            </Box>
          )}
          {congParty9 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc9 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[congParty9]?.boardText ||
                    PartyColors[congParty9]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible" /*, textOverflow: "ellipsis"*/
                }}
              >
                {congParty9.substring(0, 5)}
              </Text>
            </Box>
          )}
          {congParty10 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc10 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[congParty10]?.boardText ||
                    PartyColors[congParty10]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible" /*, textOverflow: "ellipsis"*/
                }}
              >
                {congParty10.substring(0, 5)}
              </Text>
            </Box>
          )}
          {congParty11 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc11 + "% - 0.5%)",
                minWidth: "8%",
                marginRight: "0.5%",
                display: "flex",
                alignItems: "end"
              }}
            >
              <Text
                className={"raceChartParty"}
                sx={{
                  fontWeight: "bold",
                  color:
                    PartyColors[congParty11]?.boardText ||
                    PartyColors[congParty11]?.high,
                  fontSize: "0.8rem",
                  lineHeight: "1.55",
                  overflow: "visible" /*, textOverflow: "ellipsis"*/
                }}
              >
                {congParty11.substring(0, 5)}
              </Text>
            </Box>
          )}
        </Box>
        <Box
          className={"raceChartBar"}
          style={{ display: "flex", width: "100%", marginBottom: "0.6rem" }}
        >
          <Box
            style={{
              width: "calc(" + congPartyPerc1 + "% - 0.5%)",
              minWidth: "8%",
              backgroundColor: PartyColors[congParty1]?.high,
              marginRight: "0.5%"
            }}
          >
            <Text
              className={"raceChartNum"}
              sx={{
                fontWeight: "bold",
                color: PartyColors[congParty1]?.contrast || "white",
                margin: "0.1rem 0.5rem",
                fontSize:
                  "1.2rem" /*, overflow: "hidden", textOverflow: "ellipsis"*/
              }}
            >
              {congPartySeat1}
            </Text>
          </Box>
          <Box
            style={{
              width: "calc(" + congPartyPerc2 + "% - 0.5%)",
              minWidth: "8%",
              backgroundColor: PartyColors[congParty2]?.high,
              marginRight: "0.5%"
            }}
          >
            <Text
              className={"raceChartNum"}
              sx={{
                fontWeight: "bold",
                color: PartyColors[congParty2]?.contrast || "white",
                margin: "0.1rem 0.5rem",
                fontSize:
                  "1.2rem" /*, overflow: "hidden", textOverflow: "ellipsis"*/
              }}
            >
              {congPartySeat2}
            </Text>
          </Box>
          <Box
            style={{
              width: "calc(" + congPartyPerc3 + "% - 0.5%)",
              minWidth: "8%",
              backgroundColor: PartyColors[congParty3]?.high,
              marginRight: "0.5%"
            }}
          >
            <Text
              className={"raceChartNum"}
              sx={{
                fontWeight: "bold",
                color: PartyColors[congParty3]?.contrast || "white",
                margin: "0.1rem 0.5rem",
                fontSize:
                  "1.2rem" /*, overflow: "hidden", textOverflow: "ellipsis"*/
              }}
            >
              {congPartySeat3}
            </Text>
          </Box>
          <Box
            style={{
              width: "calc(" + congPartyPerc4 + "% - 0.5%)",
              minWidth: "8%",
              backgroundColor: PartyColors[congParty4]?.high,
              marginRight: "0.5%"
            }}
          >
            <Text
              className={"raceChartNum"}
              sx={{
                fontWeight: "bold",
                color: PartyColors[congParty4]?.contrast || "white",
                margin: "0.1rem 0.5rem",
                fontSize: "1.2rem",
                overflow: "visible"
              }}
            >
              {congPartySeat4}
            </Text>
          </Box>
          {congParty5 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc5 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[congParty5]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[congParty5]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {congPartySeat5}
              </Text>
            </Box>
          )}
          {congParty6 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc6 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[congParty6]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[congParty6]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {congPartySeat6}
              </Text>
            </Box>
          )}
          {congParty7 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc7 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[congParty7]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[congParty7]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {congPartySeat7}
              </Text>
            </Box>
          )}
          {congParty8 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc8 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[congParty8]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[congParty8]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {congPartySeat8}
              </Text>
            </Box>
          )}
          {congParty9 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc9 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[congParty9]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[congParty9]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {congPartySeat9}
              </Text>
            </Box>
          )}
          {congParty10 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc10 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[congParty10]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[congParty10]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {congPartySeat10}
              </Text>
            </Box>
          )}
          {congParty11 && (
            <Box
              style={{
                width: "calc(" + congPartyPerc11 + "% - 0.5%)",
                minWidth: "8%",
                backgroundColor: PartyColors[congParty11]?.high,
                marginRight: "0.5%"
              }}
            >
              <Text
                className={"raceChartNum"}
                sx={{
                  fontWeight: "bold",
                  color: PartyColors[congParty11]?.contrast || "white",
                  margin: "0.1rem 0.5rem",
                  fontSize: "1.2rem",
                  overflow: "visible"
                }}
              >
                {congPartySeat11}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        style={{
          display: raceType === "gov" && year === "jal" ? "flex" : "none",
          flexDirection: "column",
          width: "48%",
          paddingLeft: "1%",
          paddingRight: "1%",
          marginRight: "0.3%",
          border: "solid 0px",
          backgroundColor: "#D5D1FA",
          borderRadius: "5px"
        }}
      >
        <Text
          className={"raceChartTitle"}
          sx={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            whiteSpace: "nowrap",
            marginTop: "0.4rem",
            marginBottom: "0.3rem"
          }}
        >
          <FormattedMessage id={"JalRaceChart"} />
          {" 2018"}
        </Text>
        <Box style={{ display: "flex", width: "100%" }}>
          {Object.keys(govPartiesJal2018.names)
            .filter((key) => parseInt(govPartiesJal2018.seats[key]) > 0)
            .map((key) => (
              <Box
                key={key}
                style={{
                  width: `calc(${
                    (govPartiesJal2018.seats[key] * 100.0) / 38.0
                  }% - 0.5%)`,
                  minWidth: "8%",
                  marginRight: "0.5%",
                  display: "flex",
                  alignItems: "end"
                }}
              >
                <Text
                  className={"raceChartParty"}
                  sx={{
                    fontWeight: "bold",
                    color:
                      PartyColors[govPartiesJal2018.names[key]]?.boardText ||
                      PartyColors[govPartiesJal2018.names[key]]?.high,
                    fontSize: "0.8rem",
                    lineHeight: "1.55",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                  }}
                >
                  {govPartiesJal2018.names[key]}
                </Text>
              </Box>
            ))}
        </Box>
        <Box
          className={"raceChartBar"}
          style={{ display: "flex", width: "100%", marginBottom: "0.6rem" }}
        >
          {Object.keys(govPartiesJal2018.names)
            .filter((key) => parseInt(govPartiesJal2018.seats[key]) > 0)
            .map((key) => (
              <Box
                style={{
                  width: `calc(${
                    (govPartiesJal2018.seats[key] * 100.0) / 38.0
                  }% - 0.5%)`,
                  minWidth: "8%",
                  backgroundColor:
                    PartyColors[govPartiesJal2018.names[key]]?.high,
                  marginRight: "0.5%"
                }}
              >
                <Text
                  className={"raceChartNum"}
                  sx={{
                    fontWeight: "bold",
                    color:
                      PartyColors[govPartiesJal2018.names[key]]?.contrast ||
                      "white",
                    margin: "0.1rem 0.5rem",
                    fontSize:
                      "1.2rem" /*, overflow: "hidden", textOverflow: "ellipsis"*/
                  }}
                >
                  {govPartiesJal2018.seats[key]}
                </Text>
              </Box>
            ))}
        </Box>
      </Box>
      <Box
        style={{
          display: raceType === "gov" && year === "jal" ? "flex" : "none",
          flexDirection: "column",
          width: "48%",
          paddingLeft: "1%",
          paddingRight: "1%",
          marginRight: "0%",
          border: "solid 0px",
          backgroundColor: "#D5D1FA",
          borderRadius: "5px"
        }}
      >
        <Text
          className={"raceChartTitle"}
          sx={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            whiteSpace: "nowrap",
            marginTop: "0.4rem",
            marginBottom: "0.3rem"
          }}
        >
          <FormattedMessage id={"JalRaceChart"} />
          {" 2021"}
        </Text>
        <Box style={{ display: "flex", width: "100%" }}>
          {Object.keys(govPartiesJal2021.names)
            .filter((key) => parseInt(govPartiesJal2021.seats[key]) > 0)
            .map((key) => (
              <Box
                key={key}
                style={{
                  width: `calc(${
                    (govPartiesJal2021.seats[key] * 100.0) / 38.0
                  }% - 0.5%)`,
                  minWidth: "8%",
                  marginRight: "0.5%",
                  display: "flex",
                  alignItems: "end"
                }}
              >
                <Text
                  className={"raceChartParty"}
                  sx={{
                    fontWeight: "bold",
                    color:
                      PartyColors[govPartiesJal2021.names[key]]?.boardText ||
                      PartyColors[govPartiesJal2021.names[key]]?.high,
                    fontSize: "0.8rem",
                    lineHeight: "1.55",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                  }}
                >
                  {govPartiesJal2021.names[key]}
                </Text>
              </Box>
            ))}
        </Box>
        <Box
          className={"raceChartBar"}
          style={{ display: "flex", width: "100%", marginBottom: "0.6rem" }}
        >
          {Object.keys(govPartiesJal2021.names)
            .filter((key) => parseInt(govPartiesJal2021.seats[key]) > 0)
            .map((key) => (
              <Box
                style={{
                  width: `calc(${
                    (govPartiesJal2021.seats[key] * 100.0) / 38.0
                  }% - 0.5%)`,
                  minWidth: "8%",
                  backgroundColor:
                    PartyColors[govPartiesJal2021.names[key]]?.high,
                  marginRight: "0.5%"
                }}
              >
                <Text
                  className={"raceChartNum"}
                  sx={{
                    fontWeight: "bold",
                    color:
                      PartyColors[govPartiesJal2021.names[key]]?.contrast ||
                      "white",
                    margin: "0.1rem 0.5rem",
                    fontSize:
                      "1.2rem" /*, overflow: "hidden", textOverflow: "ellipsis"*/
                  }}
                >
                  {govPartiesJal2021.seats[key]}
                </Text>
              </Box>
            ))}
        </Box>
      </Box>
      <Box
        style={{
          display: raceType === "gov" && (year === "cdmx" || year === "cdmx24") ? "flex" : "none",
          flexDirection: "column",
          width: "48%",
          paddingLeft: "1%",
          paddingRight: "1%",
          marginRight: "0.3%",
          border: "solid 0px",
          backgroundColor: "#D5D1FA",
          borderRadius: "5px"
        }}
      >
        <Text
          className={"raceChartTitle"}
          sx={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            whiteSpace: "nowrap",
            marginTop: "0.4rem",
            marginBottom: "0.3rem"
          }}
        >
          <FormattedMessage id={"CdmxRaceChart"} />
          {" 2018"}
        </Text>
        <Box style={{ display: "flex", width: "100%" }}>
          {Object.keys(govPartiesCdmx2018.names)
            .filter((key) => parseInt(govPartiesCdmx2018.seats[key]) > 0)
            .map((key) => (
              <Box
                key={key}
                style={{
                  width: `calc(${
                    (govPartiesCdmx2018.seats[key] * 100.0) / 38.0
                  }% - 0.5%)`,
                  minWidth: "8%",
                  marginRight: "0.5%",
                  display: "flex",
                  alignItems: "end"
                }}
              >
                <Text
                  className={"raceChartParty"}
                  sx={{
                    fontWeight: "bold",
                    color:
                      PartyColors[govPartiesCdmx2018.names[key]]?.boardText ||
                      PartyColors[govPartiesCdmx2018.names[key]]?.high,
                    fontSize: "0.8rem",
                    lineHeight: "1.55",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                  }}
                >
                  {govPartiesCdmx2018.names[key]}
                </Text>
              </Box>
            ))}
        </Box>
        <Box
          className={"raceChartBar"}
          style={{ display: "flex", width: "100%", marginBottom: "0.6rem" }}
        >
          {Object.keys(govPartiesCdmx2018.names)
            .filter((key) => parseInt(govPartiesCdmx2018.seats[key]) > 0)
            .map((key) => (
              <Box
                style={{
                  width: `calc(${
                    (govPartiesCdmx2018.seats[key] * 100.0) / 38.0
                  }% - 0.5%)`,
                  minWidth: "8%",
                  backgroundColor:
                    PartyColors[govPartiesCdmx2018.names[key]]?.high,
                  marginRight: "0.5%"
                }}
              >
                <Text
                  className={"raceChartNum"}
                  sx={{
                    fontWeight: "bold",
                    color:
                      PartyColors[govPartiesCdmx2018.names[key]]?.contrast ||
                      "white",
                    margin: "0.1rem 0.5rem",
                    fontSize:
                      "1.2rem" /*, overflow: "hidden", textOverflow: "ellipsis"*/
                  }}
                >
                  {govPartiesCdmx2018.seats[key]}
                </Text>
              </Box>
            ))}
        </Box>
      </Box>
      <Box
        style={{
          display: raceType === "gov" && (year === "cdmx" || year === "cdmx24") ? "flex" : "none",
          flexDirection: "column",
          width: "48%",
          paddingLeft: "1%",
          paddingRight: "1%",
          marginRight: "0%",
          border: "solid 0px",
          backgroundColor: "#D5D1FA",
          borderRadius: "5px"
        }}
      >
        <Text
          className={"raceChartTitle"}
          sx={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            whiteSpace: "nowrap",
            marginTop: "0.4rem",
            marginBottom: "0.3rem"
          }}
        >
          <FormattedMessage id={"CdmxRaceChart"} />
          {" 2021"}
        </Text>
        <Box style={{ display: "flex", width: "100%" }}>
          {Object.keys(govPartiesCdmx2021.names)
            .filter((key) => parseInt(govPartiesCdmx2021.seats[key]) > 0)
            .map((key) => (
              <Box
                key={key}
                style={{
                  width: `calc(${
                    (govPartiesCdmx2021.seats[key] * 100.0) / 38.0
                  }% - 0.5%)`,
                  minWidth: "8%",
                  marginRight: "0.5%",
                  display: "flex",
                  alignItems: "end"
                }}
              >
                <Text
                  className={"raceChartParty"}
                  sx={{
                    fontWeight: "bold",
                    color:
                      PartyColors[govPartiesCdmx2021.names[key]]?.boardText ||
                      PartyColors[govPartiesCdmx2021.names[key]]?.high,
                    fontSize: "0.8rem",
                    lineHeight: "1.55",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                  }}
                >
                  {govPartiesCdmx2021.names[key]}
                </Text>
              </Box>
            ))}
        </Box>
        <Box
          className={"raceChartBar"}
          style={{ display: "flex", width: "100%", marginBottom: "0.6rem" }}
        >
          {Object.keys(govPartiesCdmx2021.names)
            .filter((key) => parseInt(govPartiesCdmx2021.seats[key]) > 0)
            .map((key) => (
              <Box
                style={{
                  width: `calc(${
                    (govPartiesCdmx2021.seats[key] * 100.0) / 38.0
                  }% - 0.5%)`,
                  minWidth: "8%",
                  backgroundColor:
                    PartyColors[govPartiesCdmx2021.names[key]]?.high,
                  marginRight: "0.5%"
                }}
              >
                <Text
                  className={"raceChartNum"}
                  sx={{
                    fontWeight: "bold",
                    color:
                      PartyColors[govPartiesCdmx2021.names[key]]?.contrast ||
                      "white",
                    margin: "0.1rem 0.5rem",
                    fontSize:
                      "1.2rem" /*, overflow: "hidden", textOverflow: "ellipsis"*/
                  }}
                >
                  {govPartiesCdmx2021.seats[key]}
                </Text>
              </Box>
            ))}
        </Box>
      </Box>
      <Box
        style={{
          display: raceType === "sen" ? "flex" : "none",
          flexDirection: "column",
          width: "100%",
          paddingLeft: "1%",
          paddingRight: "1%",
          marginRight: "0%",
          border: "solid 0px",
          backgroundColor: "#D5D1FA",
          borderRadius: "5px"
        }}
      >
        <Text
          className={"raceChartTitle"}
          sx={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            whiteSpace: "nowrap",
            marginTop: "0.4rem",
            marginBottom: "0.3rem"
          }}
        >
          <FormattedMessage id={"SenRaceChart"} />
        </Text>
        <Box style={{ display: "flex", width: "100%" }}>
          {Object.keys(senParties.names)
            .filter((key) => parseInt(senParties.seats[key]) > 0)
            .map((key) => (
              <Box
                key={key}
                style={{
                  width: `calc(${
                    (senParties.seats[key] * 100.0) / 126.0
                  }% - 0.5%)`,
                  minWidth: "8%",
                  marginRight: "0.5%",
                  display: "flex",
                  alignItems: "end"
                }}
              >
                <Text
                  className={"raceChartParty"}
                  sx={{
                    fontWeight: "bold",
                    color:
                      PartyColors[senParties.names[key]]?.boardText ||
                      PartyColors[senParties.names[key]]?.high,
                    fontSize: "0.8rem",
                    lineHeight: "1.55",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                  }}
                >
                  {senParties.names[key]}
                </Text>
              </Box>
            ))}
        </Box>
        <Box
          className={"raceChartBar"}
          style={{ display: "flex", width: "100%", marginBottom: "0.6rem" }}
        >
          {Object.keys(senParties.names)
            .filter((key) => parseInt(senParties.seats[key]) > 0)
            .map((key) => (
              <Box
                style={{
                  width: `calc(${
                    (senParties.seats[key] * 100.0) / 126.0
                  }% - 0.5%)`,
                  minWidth: "8%",
                  backgroundColor:
                    PartyColors[senParties.names[key]]?.high,
                  marginRight: "0.5%"
                }}
              >
                <Text
                  className={"raceChartNum"}
                  sx={{
                    fontWeight: "bold",
                    color:
                      PartyColors[senParties.names[key]]?.contrast ||
                      "white",
                    margin: "0.1rem 0.5rem",
                    fontSize:
                      "1.2rem" /*, overflow: "hidden", textOverflow: "ellipsis"*/
                  }}
                >
                  {senParties.percs[key]}
                </Text>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default RaceChart;
