import PRDPTCONVERGENCIA2006 from '../assets/img/candidates/PRD-PT-CONVERGENCIA2006.png';
import PAN2006 from '../assets/img/candidates/PAN2006.png';
import PRIPVEM2006 from '../assets/img/candidates/PRI-PVEM2006.png';
import PSD2006 from '../assets/img/candidates/PSD2006.png';
import ASDC2006 from '../assets/img/candidates/ASDC2006.png';
import PANAL2006 from '../assets/img/candidates/PANAL2006.png';
import PAN2012 from '../assets/img/candidates/PAN2012.png';
import PANAL2012 from '../assets/img/candidates/PANAL2012.png';
import PRDPTMC2012 from '../assets/img/candidates/PRD-PT-MC2012.png';
import PRIPVEM2012 from '../assets/img/candidates/PRI-PVEM2012.png';
import MORENAPTPES2018 from '../assets/img/candidates/MORENA-PT-PES2018.png';
import PRIPVEMPANAL2018 from '../assets/img/candidates/PRI-PVEM-PANAL2018.png';
import PANPRDMC2018 from '../assets/img/candidates/PAN-PRD-MC2018.png';
import Independiente2018 from '../assets/img/candidates/Independiente2018.png';
import PANPRIPRD2021 from '../assets/img/candidates/PAN-PRI-PRD2021.jpg';
import PANPRIPRD2024 from '../assets/img/candidates/PAN-PRI-PRD2024.png';
import PVEM2021 from '../assets/img/candidates/PVEM2021.png';
import PT2021 from '../assets/img/candidates/PT2021.png';
import PRI2021 from '../assets/img/candidates/PRI2021.png';
import PES2021 from '../assets/img/candidates/PES2021.png';
import PES2015 from '../assets/img/candidates/PES2015.png';
import PAN2021 from '../assets/img/candidates/PAN2021.png';
import PRD2021 from '../assets/img/candidates/PRD2021.png';
import RSP2021 from '../assets/img/candidates/RSP2021.png';
import MORENA2021 from '../assets/img/candidates/MORENA2021.png';
import MC2021 from '../assets/img/candidates/MC2021.png';
import MC2024 from '../assets/img/candidates/MC2024.png';
import FXM2021 from '../assets/img/candidates/FXM2021.png';
import MORENAPTPVEM2021 from '../assets/img/candidates/MORENA-PT-PVEM2021.png';
import MORENAPTPVEM2024 from '../assets/img/candidates/MORENA-PT-PVEM2024.png';
import PVEM from '../assets/img/candidates/PVEM2021.png';
import PT from '../assets/img/candidates/PT2021.png';
import PRI from '../assets/img/candidates/PRI2021.png';
import PES from '../assets/img/candidates/PES2021.png';
import PAN from '../assets/img/candidates/PAN2021.png';
import PRD from '../assets/img/candidates/PRD2021.png';
import PSD from '../assets/img/candidates/PSD2009.png';
import PAS from '../assets/img/candidates/PAS.png';
import PH from '../assets/img/candidates/PH2021.png';
import RSP from '../assets/img/candidates/RSP2021.png';
import MORENA from '../assets/img/candidates/MORENA2021.png';
import Morena from '../assets/img/candidates/MORENA2021.png';
import MC from '../assets/img/candidates/MC2021.png';
import Independiente from '../assets/img/candidates/IND2018.png';
import FXM from '../assets/img/candidates/FXM2021.png';
import PPCH from '../assets/img/candidates/PPCH.png';
import PANAL from '../assets/img/candidates/PANAL.png';
import CONVERGENCIA from '../assets/img/candidates/CONVERGENCIA.png';
import Futuro from '../assets/img/candidates/FUTURO.png';
import Hagamos from '../assets/img/candidates/HAGAMOS.png';
import FUTURO from '../assets/img/candidates/FUTURO.png';
import HAGAMOS from '../assets/img/candidates/HAGAMOS.png';
import MAS from '../assets/img/candidates/MAS.png';
import PSI from '../assets/img/candidates/PSI.png';
import PCHU from '../assets/img/candidates/PCHU.png';
import PMCH from '../assets/img/candidates/PMCH.png';
import Morelos_Progresa from '../assets/img/candidates/Morelos Progresa.png';
import TOOEARLY from '../assets/img/candidates/TOO-EARLY.png';
import IND20151 from '../assets/img/candidates/Ind20151.png';
import IND20155 from '../assets/img/candidates/Ind20155.png';
import IND20156 from '../assets/img/candidates/Ind20156.png';
import IND20157 from '../assets/img/candidates/Ind20157.png';
import IND20158 from '../assets/img/candidates/Ind20158.png';
import Default from '../assets/img/candidates/default.png';

const CandidateImgs = {    
    PRDPTCONVERGENCIA2006,
    PAN2006,
    PRIPVEM2006,
    PSD2006,
    ASDC2006,
    PANAL2006,
    PAN2012,
    PANAL2012,
    PRDPTMC2012,
    PRIPVEM2012,
    MORENAPTPES2018,
    PRIPVEMPANAL2018,
    PANPRDMC2018,
    Independiente2018,
    PVEM2021,
    PT2021,
    PRI2021,
    PES2021,
    PES2015,
    PAN2021,
    PRD2021,
    RSP2021,
    MORENA2021,
    Morena,
    MC2021,
    MC2024,
    FXM2021,
    MORENAPTPVEM2021,
    MORENAPTPVEM2024,
    PANPRIPRD2021,
    PANPRIPRD2024,
    PVEM,
    PT,
    PRI,
    PES,
    PAN,
    PRD,
    PSD,
    PAS,
    PH,
    RSP,
    MORENA,
    MC,
    FXM,
    PPCH,
    PANAL,
    CONVERGENCIA,
    Futuro,
    Hagamos,
    FUTURO,
    HAGAMOS,
    MAS,
    PSI,
    PCHU,
    PMCH,
    Morelos_Progresa,
    TOOEARLY,
    Independiente,
    IND20151,
    IND20155,
    IND20156,
    IND20157,
    IND20158,
    Default
}

export default CandidateImgs;