import ALEJANDRABARRALESMAGDALENO from "../assets/img/candidates/governors/cdmx/Alejandra Barrales.png";
import MARIANABOYTAMBORRELL from "../assets/img/candidates/governors/cdmx/Mariana Boy.png";
import CLAUDIASHEINBAUMPARDO from "../assets/img/candidates/governors/cdmx/Claudia Sheinbaum.png";
import MIKELARRIOLAPENALOSA from "../assets/img/candidates/governors/cdmx/Mikel Arriola.png";

import CLARABRUGADA from "../assets/img/candidates/governors/2024/CDMX/Clara Brugada.png";
import SALOMONCHERTORIVSKI from "../assets/img/candidates/governors/2024/CDMX/Salomón Chertorivski.png";
import SANTIAGOTABOADA from "../assets/img/candidates/governors/2024/CDMX/Santiago Taboada.png";

const GovCdmx = {
  ALEJANDRABARRALESMAGDALENO,
  MARIANABOYTAMBORRELL,
  CLAUDIASHEINBAUMPARDO,
  MIKELARRIOLAPENALOSA,
  CLARABRUGADA,
  SALOMONCHERTORIVSKI,
  SANTIAGOTABOADA
};

export default GovCdmx;
