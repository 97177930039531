import React, { useRef, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Box, AppShell, Navbar, Header } from "@mantine/core";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import L from "leaflet";
import voca from "voca";
import { useIntl } from "react-intl";
import removeAccents from "remove-accents";
import * as turf from "@turf/turf";
//import axios from "axios";

import {
  GeneralFillColors,
  TurnoutColor,
  NomColor,
  WomenColors,
  MarginFillColors,
  PartyStrFillColors,
  PartyColors,
  StateOlColors
} from "./mapbox/colors";
import { Urls } from "./mapbox/urls";
import { StatesAccents } from "./mapbox/states";
//import { Titles } from "./mapbox/titles";
import {
  StateOlWidth,
  MunFillOpacity,
  MunDisFillOpacity,
  DisFillOpacity,
  StateFillOpacity,
  ForceMunFillOpacity,
  ForceDisFillOpacity,
  MunOutlineColor,
  DisOutlineColor,
  StateOutlineColor,
  PresMunCircleRadius,
  CongMunCircleRadius,
  NomMunCircleRadius,
  StateFilter,
  StateOpacity,
  StateLabelLayers
} from "./mapbox/rules";

import NavbarNested from "./NavBar/Navbar";
import Login from "./components/Auth/LogIn";
import AuthorizedComponent from "./components/AuthorizedComponent";
//import BookmarksListing from "./components/Widgets/Bookmarks/Listing/BookmarksListing";
import CreateBookmarkModal from "./components/Widgets/Bookmarks/Create/CreateBookmarkModal";
import Raceboard from "./components/Widgets/Raceboard/Raceboard";
import RaceboardNational from "./components/Widgets/Raceboard/RaceboardNational";
import RaceboardSenate from "./components/Widgets/Raceboard/RaceboardSenate";
import RaceboardSenNational from "./components/Widgets/Raceboard/RaceboardSenNational";
import RaceboardCong from "./components/Widgets/Raceboard/RaceboardCong";
import RaceboardCongNational from "./components/Widgets/Raceboard/RaceboardCongNational";
import RaceboardGov from "./components/Widgets/Raceboard/RaceboardGov";
import RaceboardGov2024 from "./components/Widgets/Raceboard/RaceboardGov2024";
import RaceboardGovPre2024 from "./components/Widgets/Raceboard/RaceboardGovPre2024";
import RaceboardWomen from "./components/Widgets/Raceboard/RaceboardWomen";
import RaceboardGovNational from "./components/Widgets/Raceboard/RaceboardGovNational";
import RaceboardNationalTurnout from "./components/Widgets/Raceboard/RaceboardNationalTurnout";
import RaceboardNationalReg from "./components/Widgets/Raceboard/RaceboardNationalReg";
import RaceboardNationalWomen from "./components/Widgets/Raceboard/RaceboardNationalWomen";
import RaceboardMayor from "./components/Widgets/Raceboard/RaceboardMayor";
import RaceboardGovState from "./components/Widgets/Raceboard/RaceboardGovState";
import TopBar from "./components/Widgets/TopBar/TopBar";
import Telestrator from "./components/Widgets/Telestrator/Telestrator";
import RaceChart from "./components/Widgets/RaceChart/RaceChart";
import { appSelector, getOpenModal } from "./redux/app/selectors";
import {
  setOpenModal,
  setShowRaceboard,
  setShowNationalResult,
  setShowBookmarks,
  setRaceLayer,
  setRaceState,
  setRaceStateId,
  setRaceStateYear,
  setRaceMun,
  setRaceMunId,
  setRaceReg,
  setColorFirstParty,
  setRaceParty,
  setRaceCoalition,
  setRaceCoalitionParties,
  setRaceInterina,
  //setRaceColor,
  setRaceName,
  setRaceTitle,
  setRaceTotalVote,
  setRaceTurnoutPercent,
  setRaceVote,
  setRacePercent,
  setRaceTie,
  setReelected,
  setClickedMarker,
  setStateLayerId,
  setMetroLayerId,
  setMunLayerId,
  setDisLayerId,
  setMarkerLng,
  setMarkerLat,
  setRaceSubtitle1,
  setRaceSubtitle2,
  setShowRaceChart
} from "./redux/app/slice";
import { update as updateParty } from "./actions/partySlice";
import { update as updateTurnout } from "./actions/turnoutSlice";
import { update as updateMargin } from "./actions/marginSlice";
import { update as updateFeature } from "./actions/featureSlice";
import { update as updateForceDis } from "./actions/forceDisSlice";
import { update as updateYear } from "./actions/yearSlice";
import { update as updateRaceType } from "./actions/raceTypeSlice";
import { update as updateVoteCircle } from "./actions/voteCircleSlice";
import {
  DefaultLng,
  DefaultLat,
  DefaultZoom,
  MaxBounds
} from "./mapbox/settings";
import { ZOOM_DIR_ENUM } from "./enums/zoomDir";
//import LOGO from "./assets/img/televisa.png";
import COVER from "./assets/img/PortadaMG.png";
//import GeoJSONData from "./mapbox/geojson";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const statesToOffset = ["31", "23", "4", "27", "7"];
const statesToOffsetReverse = ["2", "3"];

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const Home = () => {
  const dispatch = useDispatch();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const leafletMapContainer = useRef(null);
  const leafletMap = useRef(null);
  const marker = useRef(null);
  const popup = useRef(null);
  const murderPopup = useRef(null);
  const canvas = useRef(null);
  const firstLayerId = useRef(null);
  const stateSourceId = useRef(null);
  const stateLayerId = useRef(null);
  const stateOlSourceId = useRef(null);
  const stateOlLayerId = useRef(null);
  const stateFillSourceId = useRef(null);
  const stateFillLayerId = useRef(null);
  const disSourceId = useRef(null);
  const disLayerId = useRef(null);
  const munSourceId = useRef(null);
  const munLayerId = useRef(null);
  const metroSourceId = useRef(null);
  const metroLayerId = useRef(null);
  const metroInvertSourceId = useRef(null);
  const metroInvertLayerId = useRef(null);
  const stateName = useRef(null);
  const lastStateFeature = useRef(null);
  const lastDisFeature = useRef(null);
  const lastMunFeature = useRef(null);
  const currentLayer = useRef("states");
  const stateColorExpression = useRef(null);

  const [lng /*, setLng*/] = useState(DefaultLng);
  const [lat /*, setLat*/] = useState(DefaultLat);
  const [zoom /*, setZoom*/] = useState(DefaultZoom);
  const [mapReady, setMapReady] = useState(false);

  const year = useSelector((state) => state.year.value);
  const raceType = useSelector((state) => state.raceType.value);
  const party = useSelector((state) => state.party.value);
  const forceMun = useSelector((state) => state.forceMun.value);
  const forceDis = useSelector((state) => state.forceDis.value);
  const voteCircle = useSelector((state) => state.voteCircle.value);
  const turnout = useSelector((state) => state.turnout.value);
  const margin = useSelector((state) => state.margin.value);
  const lastFeature = useSelector((state) => state.feature.value);

  const modal = useSelector(getOpenModal);
  const app = useSelector(appSelector);

  const prevForceMun = usePrevious(forceMun);
  const prevForceDis = usePrevious(forceDis);
  const prevVoteCircle = usePrevious(voteCircle);
  const prevYear = usePrevious(year);

  const intl = useIntl();

  const onCloseModal = useCallback(() => {
    dispatch(setOpenModal(false));
  }, [dispatch]);

  const onCloseRaceboard = useCallback(() => {
    dispatch(setShowRaceboard(false));
  }, [dispatch]);

  const onCloseRaceboardNational = useCallback(() => {
    if (document.getElementById("raceboard"))
      document.getElementById("raceboard").style.visibility = "visible";
    if (document.getElementById("raceboardG"))
      document.getElementById("raceboardG").style.visibility = "visible";
    dispatch(setShowNationalResult(false));
  }, [dispatch]);

  const onCloseRaceboardNationalTurnout = useCallback(() => {
    if (document.getElementById("raceboardNT"))
      document.getElementById("raceboardNT").style.visibility = "visible";
    dispatch(setShowNationalResult(false));
  }, [dispatch]);

  const onCloseRaceboardNationalWomen = useCallback(() => {
    if (document.getElementById("raceboardNW"))
      document.getElementById("raceboardNW").style.visibility = "visible";
    dispatch(setShowNationalResult(false));
  }, [dispatch]);

  const getMapboxUrl = (raceType, year, voteCircle, level) => {
    if (Urls[`${raceType}-${year}`]) {
      if (
        Urls[`${raceType}-${year}`][
          `${level}${voteCircle && level !== "state" ? "-centroids" : ""}`
        ]
      ) {
        return Urls[`${raceType}-${year}`][
          `${level}${voteCircle && level !== "state" ? "-centroids" : ""}`
        ];
      }
    }
    return "";
  };

  const loadRaceBoard = useCallback(
    (feature, overrideYear) => {
      const newYear = overrideYear || year;
      if (feature.sourceLayer === "states") {
        dispatch(setColorFirstParty(false));
        dispatch(setRaceMun(""));
        dispatch(setRaceMunId(""));
        dispatch(
          setRaceStateId(
            feature.properties.ENTIDAD
              ? feature.properties.ENTIDAD
              : feature.properties.ID_ESTADO
          )
        );
        dispatch(
          setRaceStateYear(
            feature.properties.STATE_RACE_YR
              ? feature.properties.STATE_RACE_YR
              : ""
          )
        );
        dispatch(setRaceReg(feature.properties.LISTA_NOMINAL || 0));
      } else if (feature.sourceLayer === "municipals") {
        dispatch(setColorFirstParty(false));
        dispatch(setRaceState(""));
        dispatch(setRaceStateId(""));
        dispatch(setRaceMun(voca.titleCase(feature.properties.MUNICIPIO || feature.properties.ALCALDIA)));
        dispatch(setRaceMunId(voca.titleCase(feature.properties.UNIQUE_ID)));
        dispatch(setRaceState(voca.titleCase(feature.properties.STATE_NAME)));
        dispatch(setRaceReg(feature.properties.LISTA_NOMINAL || 0));
      } else if (feature.sourceLayer === "districts") {
        dispatch(setColorFirstParty(feature.properties.FIRST));
        dispatch(
          setRaceMun(voca.titleCase(feature.properties.DISTRICT_DISPLAY))
        );
        dispatch(
          setRaceMunId(voca.titleCase(feature.properties.ID_DISTRITO_FEDERAL))
        );
      } else if (feature.sourceLayer === "neighborhoods") {
        dispatch(setColorFirstParty(false));
        dispatch(setRaceMun(""));
        dispatch(setRaceMunId(""));
        dispatch(setRaceStateId(feature.properties.NOMBRE_MUN));
        dispatch(
          setRaceStateYear(newYear.split("-")[1] ? newYear.split("-")[1] : newYear)
        );
      } else return false;

      dispatch(
        setRaceTie(
          feature.properties.TIE === true || feature.properties.TIE === "TRUE"
        )
      );

      let reelected = 0;
      if (feature.properties.reelected)
        reelected = feature.properties.reelected;
      dispatch(setReelected(reelected));

      let first_party = 
      newYear === "cdmx-2024" ? feature.properties.FIRST_PARTIES || feature.properties.FIRST_AFFILIATION || feature.properties.FIRST_AFILIATION || "" :
        feature.properties.FIRST_DISPLAY ||
        feature.properties.FIRST_PARTY ||
        feature.properties.FIRST_AFFILIATION ||
        feature.properties.FIRST_AFILIATION ||
        feature.properties.FIRST ||
        feature.properties.GOVERNOR_PARTY ||
        "";
      if (first_party === "INDEPENDIENTE") first_party = "Independiente";
      dispatch(setRaceParty({ payload: first_party, i: 1 }));
      dispatch(
        setRaceCoalitionParties({
          payload:
            feature.properties.FIRST_COALITION_PARTIES &&
            feature.properties.FIRST_COALITION_PARTIES !== "0"
              ? feature.properties.FIRST_COALITION_PARTIES
              : feature.properties.FIRST_PARTY ||
                feature.properties.FIRST_PARTIES ||
                feature.properties.FIRST || "",
          i: 1
        })
      );
      dispatch(
        setRaceCoalition({
          payload:
            feature.properties.FIRST_COALITION_NAME &&
            feature.properties.FIRST_COALITION_NAME !== "0"
              ? feature.properties.FIRST_COALITION_NAME
              : feature.properties.TRANSITION_YEAR || "",
          i: 1
        })
      );
      dispatch(
        setRaceInterina({
          payload: feature.properties.GOVERNOR_TYPE || "",
          i: 1
        })
      );

      //dispatch(setRaceColor({payload: PartyColors[feature.properties.FIRST_PARTY]?.high, i: 1}));
      const p1Name =
        feature.properties.FIRST_NAME ||
        feature.properties.ELECTED_CANDIDATE ||
        feature.properties.CANDIDATO ||
        feature.properties.FIRST ||
        feature.properties.FIRST_DISPLAY ||
        feature.properties.FIRST_CANDIDATE_NAME ||
        feature.properties.GOVERNOR_NAME ||
        "";
      dispatch(setRaceName({ payload: p1Name, i: 1 }));
      //dispatch(setRaceTitle({payload: Titles[feature.properties.FIRST] ? Titles[feature.properties.FIRST] : 'Candidatura '+feature.properties.FIRST_PARTY, i: 1}));

      const total_votes =
        feature.properties.TOTAL_VOTOS_CALCULADOS ||
        feature.properties.TOTAL_PERSONAS_VOTARON ||
        feature.properties.TOTAL_VOTES ||
        feature.properties.TOTAL_VOTOS ||
        feature.properties.TOTAL_VALID_VOTES ||
        "";
      dispatch(setRaceTotalVote(total_votes));

      const turnout_percent = feature.properties.TURNOUT || "";
      dispatch(setRaceTurnoutPercent(turnout_percent));

      let first_votes = "";
      if (typeof feature.properties.FIRST_votes !== "undefined")
        first_votes = feature.properties.FIRST_votes;
      else if (typeof feature.properties.FIRST_VOTES !== "undefined")
        first_votes = feature.properties.FIRST_VOTES;
      else if (
        typeof feature.properties[feature.properties.FIRST] !== "undefined"
      )
        first_votes = feature.properties[feature.properties.FIRST];
      else if (
        typeof feature.properties[feature.properties.SECOND] !== "undefined"
      ) {
        //Tie
        first_votes = feature.properties[feature.properties.SECOND];
        for (const [key, value] of Object.entries(feature.properties)) {
          //console.log(`${key}: ${value}`);
          if (value === first_votes && feature.properties.SECOND !== key)
            dispatch(setRaceName({ payload: key, i: 1 }));
        }
      }
      dispatch(setRaceVote({ payload: first_votes, i: 1 }));
      dispatch(
        setRacePercent({
          payload: Math.round(
            feature.properties.FIRST_Sh ||
              feature.properties.winner_Sh ||
              parseFloat(feature.properties.FIRST_PERCENTAGE)
          ),
          i: 1
        })
      );

      let second_party =
      newYear === "cdmx-2024" ? feature.properties.SECOND_PARTIES || feature.properties.SECOND_AFFILIATION || "" :
        feature.properties.SECOND_DISPLAY ||
        feature.properties.SECOND_PARTY ||
        feature.properties.SECOND_AFFILIATION ||
        feature.properties.SECOND ||
        feature.properties.SECOND_ENTRY_PARTY ||
        "";
      if (second_party === "INDEPENDIENTE") second_party = "Independiente";
      dispatch(setRaceParty({ payload: second_party, i: 2 }));
      dispatch(
        setRaceCoalitionParties({
          payload:
            feature.properties.SECOND_COALITION_PARTIES &&
            feature.properties.SECOND_COALITION_PARTIES !== "0"
              ? feature.properties.SECOND_COALITION_PARTIES
              : feature.properties.SECOND_PARTY ||
                feature.properties.SECOND_PARTIES ||
                feature.properties.SECOND || "",
          i: 2
        })
      );
      dispatch(
        setRaceCoalition({
          payload:
            feature.properties.SECOND_COALITION_NAME &&
            feature.properties.SECOND_COALITION_NAME !== "0"
              ? feature.properties.SECOND_COALITION_NAME
              : feature.properties.SECOND_ENTRY_YEAR,
          i: 2
        })
      );
      dispatch(
        setRaceInterina({
          payload: feature.properties.SECOND_GOVERNOR_TYPE || "",
          i: 2
        })
      );
      //dispatch(setRaceColor({payload: PartyColors[feature.properties.SECOND_PARTY]?.high, i: 2}));
      const p2Name =
        feature.properties.SECOND_NAME ||  
        feature.properties.SECOND ||
        feature.properties.SECOND_DISPLAY ||
        feature.properties.SECOND_CANDIDATE_NAME ||
        feature.properties.SECOND_ENTRY_NAME ||        
        "";
      dispatch(setRaceName({ payload: p2Name, i: 2 }));
      //dispatch(setRaceTitle({payload: Titles[feature.properties.SECOND] ? Titles[feature.properties.SECOND] : 'Candidatura '+feature.properties.SECOND_PARTY, i: 2}));
      let second_votes = "";
      if (typeof feature.properties.SECOND_votes !== "undefined")
        second_votes = feature.properties.SECOND_votes;
      else if (typeof feature.properties.SECOND_VOTES !== "undefined")
        second_votes = feature.properties.SECOND_VOTES;
      else if (
        typeof feature.properties[feature.properties.SECOND] !== "undefined"
      )
        second_votes = feature.properties[feature.properties.SECOND];
      dispatch(setRaceVote({ payload: second_votes, i: 2 }));
      dispatch(
        setRacePercent({
          payload: Math.round(
            feature.properties.SECOND_Sh ||
              feature.properties.second_Sh ||
              parseFloat(feature.properties.SECOND_PERCENTAGE)
          ),
          i: 2
        })
      );

      let third_party =
      newYear === "cdmx-2024" ? feature.properties.THIRD_PARTIES || feature.properties.THIRD_AFFILIATION || "" :
        feature.properties.THIRD_DISPLAY ||
        feature.properties.THIRD_PARTY ||
        feature.properties.THIRD_AFFILIATION ||
        feature.properties.THIRD ||
        feature.properties.THIRD_ENTRY_PARTY ||
        "";
      if (third_party === "INDEPENDIENTE") third_party = "Independiente";
      dispatch(setRaceParty({ payload: third_party, i: 3 }));
      dispatch(
        setRaceCoalitionParties({
          payload:
            feature.properties.THIRD_COALITION_PARTIES &&
            feature.properties.THIRD_COALITION_PARTIES !== "0"
              ? feature.properties.THIRD_COALITION_PARTIES
              : feature.properties.THIRD_PARTY ||
                feature.properties.THIRD_PARTIES ||
                feature.properties.THIRD || "",
          i: 3
        })
      );
      dispatch(
        setRaceCoalition({
          payload:
            feature.properties.THIRD_COALITION_NAME &&
            feature.properties.THIRD_COALITION_NAME !== "0"
              ? feature.properties.THIRD_COALITION_NAME
              : "",
          i: 3
        })
      );
      dispatch(
        setRaceInterina({
          payload: feature.properties.THIRD_GOVERNOR_TYPE || "",
          i: 3
        })
      );
      //dispatch(setRaceColor({payload: PartyColors[feature.properties.THIRD_PARTY]?.high, i: 3}));
      const p3Name =
        feature.properties.THIRD_NAME ||
        feature.properties.THIRD ||
        feature.properties.THIRD_DISPLAY ||
        feature.properties.THIRD_CANDIDATE_NAME ||
        feature.properties.THIRD_ENTRY_NAME ||        
        "";
      dispatch(setRaceName({ payload: p3Name, i: 3 }));
      //dispatch(setRaceTitle({payload: Titles[feature.properties.THIRD] ? Titles[feature.properties.THIRD] : 'Candidatura '+feature.properties.THIRD_PARTY, i: 3}));
      let third_votes = "";
      if (typeof feature.properties.THIRD_votes !== "undefined")
        third_votes = feature.properties.THIRD_votes;
      else if (typeof feature.properties.THIRD_VOTES !== "undefined")
        third_votes = feature.properties.THIRD_VOTES;
      else if (
        typeof feature.properties[feature.properties.THIRD] !== "undefined"
      )
        third_votes = feature.properties[feature.properties.THIRD];
      dispatch(setRaceVote({ payload: third_votes, i: 3 }));
      dispatch(
        setRacePercent({
          payload: Math.round(
            feature.properties.THIRD_Sh ||
              feature.properties.third_Sh ||
              parseFloat(feature.properties.THIRD_PERCENTAGE)
          ),
          i: 3
        })
      );

      let fourth_party =
      newYear === "cdmx-2024" ? feature.properties.FOURTH_PARTIES || feature.properties.FOURTH_AFFILIATION || "" :
        feature.properties.FOURTH_DISPLAY ||
        feature.properties.FOURTH_PARTY ||
        feature.properties.FOURTH_AFFILIATION ||
        feature.properties.FOURTH ||
        feature.properties.FOURTH_ENTRY_PARTY ||
        "";
      if (fourth_party === "INDEPENDIENTE") fourth_party = "Independiente";
      dispatch(setRaceParty({ payload: fourth_party, i: 4 }));
      dispatch(
        setRaceCoalitionParties({
          payload:
            feature.properties.FOURTH_COALITION_PARTIES &&
            feature.properties.FOURTH_COALITION_PARTIES !== "0"
              ? feature.properties.FOURTH_COALITION_PARTIES
              : feature.properties.FOURTH_PARTY ||
                feature.properties.FOURTH_PARTIES || "",
          i: 4
        })
      );
      dispatch(
        setRaceCoalition({
          payload:
            feature.properties.FOURTH_COALITION_NAME &&
            feature.properties.FOURTH_COALITION_NAME !== "0"
              ? feature.properties.FOURTH_COALITION_NAME
              : "",
          i: 4
        })
      );
      dispatch(
        setRaceInterina({
          payload: feature.properties.FOURTH_GOVERNOR_TYPE || "",
          i: 4
        })
      );
      //dispatch(setRaceColor({payload: PartyColors[feature.properties.THIRD_PARTY]?.high, i: 3}));
      const p4Name =
        feature.properties.FOURTH_NAME ||
        feature.properties.FOURTH ||
        feature.properties.FOURTH_DISPLAY ||
        feature.properties.FOURTH_CANDIDATE_NAME ||
        feature.properties.FOURTH_ENTRY_NAME ||
        "";
      dispatch(setRaceName({ payload: p4Name, i: 4 }));
      //dispatch(setRaceTitle({payload: Titles[feature.properties.THIRD] ? Titles[feature.properties.THIRD] : 'Candidatura '+feature.properties.THIRD_PARTY, i: 3}));
      let fourth_votes = "";
      if (typeof feature.properties.FOURTH_votes !== "undefined")
        fourth_votes = feature.properties.FOURTH_votes;
      else if (typeof feature.properties.FOURTH_VOTES !== "undefined")
        fourth_votes = feature.properties.FOURTH_VOTES;
      else if (
        typeof feature.properties[feature.properties.FOURTH] !== "undefined"
      )
        fourth_votes = feature.properties[feature.properties.FOURTH];
      dispatch(setRaceVote({ payload: fourth_votes, i: 4 }));
      dispatch(
        setRacePercent({
          payload: Math.round(
            feature.properties.FOURTH_Sh ||
              feature.properties.FOURTH_Sh ||
              parseFloat(feature.properties.FOURTH_PERCENTAGE)
          ),
          i: 4
        })
      );

      dispatch(setShowRaceboard(true));
    },
    [dispatch, year]
  );

  const hideLayer = useCallback(
    (layerId, ms = 250) => {
      if (
        voteCircle &&
        !party &&
        !margin &&
        !turnout &&
        layerId.endsWith("-mun")
      ) {
        map.current.setPaintProperty(layerId, "circle-opacity", 0, {
          duration: ms,
          easing: "ease-in-out"
        });
      } else {
        if (map.current.getLayer(layerId)) {
          map.current.setPaintProperty(layerId, "fill-opacity", 0, {
            duration: ms,
            easing: "ease-in-out"
          });
        }
      }

      if (map.current.getLayer(layerId)) {
        map.current.setLayoutProperty(layerId, "visibility", "none");
      }
    },
    [voteCircle, party, margin, turnout]
  );

  const showLayer = useCallback(
    (layerId, ms = 250) => {
      map.current.setLayoutProperty(layerId, "visibility", "visible");

      if (
        voteCircle &&
        !party &&
        !margin &&
        !turnout &&
        layerId.endsWith("-mun")
      ) {
        map.current.setPaintProperty(layerId, "circle-opacity", 0.5, {
          duration: ms,
          easing: "ease-in-out"
        });
      } else {
        if (stateLayerId?.current === layerId) {
          const stateNameStr = `${stateName?.current || ""}`;
          const stateName1 =
            StatesAccents[stateNameStr.toUpperCase()] ||
            stateNameStr.toUpperCase();
          const stateName2 = removeAccents(stateNameStr.toUpperCase());

          if (raceType !== "mayor") {
            map.current.setPaintProperty(
              layerId,
              "fill-opacity",
              raceType === "gov" || raceType === "sen" ? 1 : StateOpacity(stateName1, stateName2),
              {
                duration: ms,
                easing: "ease-in-out"
              }
            );
          }
        } else {
          if (raceType !== "mayor") {
            map.current.setPaintProperty(layerId, "fill-opacity", 1, {
              duration: ms,
              easing: "ease-in-out"
            });
          }
        }
      }
    },
    [voteCircle, party, margin, turnout, stateLayerId, stateName, raceType]
  );

  const zoomToFeat = useCallback((features, padding = 100) => {
    if (features[0]?.geometry?.type === "Point") {
      map.current.flyTo({
        center: features[0].geometry.coordinates
      });
    } else {
      const featureCollection = {
        type: "FeatureCollection",
        features: features
      };
      const bounds = turf.bbox(featureCollection);
      const leafletBounds = L.latLngBounds([
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]]
      ]);
      const newZoomLevel = leafletMap.current.getBoundsZoom(
        leafletBounds,
        false
      );

      map.current.easeTo({
        center: turf.center(
          turf.points([
            [bounds[0], bounds[1]],
            [bounds[2], bounds[3]]
          ])
        ).geometry.coordinates,
        zoom: newZoomLevel * 0.8,
        duration: 1000
      });
    }
  }, []);

  const addFilter = useCallback(
    (stateNameStr) => {
      stateNameStr = `${stateNameStr}`;
      if (!stateNameStr) {
        stateNameStr = `${stateName.current}`;
        if (!stateNameStr) {
          return;
        }
      }

      if (stateName.current) {
        const lastStateNameStr = removeAccents(
          `${stateName.current}`.toUpperCase()
        );

        if (StateLabelLayers[lastStateNameStr]) {
          map.current.setPaintProperty(
            StateLabelLayers[lastStateNameStr],
            "text-opacity",
            0,
            {
              duration: 50,
              easing: "ease-in-out"
            }
          );
        } else {
          console.log(`Label layer for ${lastStateNameStr} not found`);
        }
      }

      const stateName1 =
        StatesAccents[stateNameStr.toUpperCase()] || stateNameStr.toUpperCase();
      const stateName2 = removeAccents(stateNameStr.toUpperCase());

      if (StateLabelLayers[stateName2]) {
        map.current.setPaintProperty(
          StateLabelLayers[stateName2],
          "text-opacity",
          raceType === "gov" || raceType === "sen" ? 0 : 1,
          {
            duration: 50,
            easing: "ease-in-out"
          }
        );
      } else {
        console.log(`Label layer for ${stateName2} not found`);
      }

      if (StateLabelLayers[stateName2]) {
        map.current.setPaintProperty(
          StateLabelLayers[stateName2],
          "text-opacity",
          raceType === "gov" || raceType === "sen" ? 0 : 1,
          {
            duration: 50,
            easing: "ease-in-out"
          }
        );
      } else {
        console.log(`Label layer for ${stateName2} not found`);
      }

      if (raceType === "gov" || raceType === "sen") return;

      if (map.current.getLayer(munLayerId.current)) {
        map.current.setFilter(
          munLayerId.current,
          StateFilter(stateName1, stateName2)
        );
      }

      if (map.current.getLayer(disLayerId.current)) {
        map.current.setFilter(
          disLayerId.current,
          StateFilter(stateName1, stateName2)
        );
      }

      if (map.current.getLayer(metroLayerId.current)) {
        map.current.setFilter(
          metroLayerId.current,
          StateFilter(stateName1, stateName2)
        );
      }

      if (map.current.getLayer(stateLayerId.current)) {
        if (raceType !== "mayor") {
          map.current.setPaintProperty(
            stateLayerId.current,
            "fill-opacity",
            (stateName1 || stateName2) && raceType !== "gov" && raceType !== "sen"
              ? StateOpacity(stateName1, stateName2)
              : 1
          );
        }

        //   map.current.setFilter(stateLayerId.current, [
        //     "any",
        //     ["==", ["upcase", ["get", "STATE_NAME"]], stateName1],
        //     ["==", ["upcase", ["get", "NOMBRE_ESTADO"]], stateName1],
        //     ["==", ["upcase", ["get", "NOMBRE"]], stateName1],
        //     ["==", ["upcase", ["get", "ESTADO"]], stateName1],
        //     ["==", ["upcase", ["get", "STATE_NAME"]], stateName2],
        //     ["==", ["upcase", ["get", "NOMBRE_ESTADO"]], stateName2],
        //     ["==", ["upcase", ["get", "NOMBRE"]], stateName2],
        //     ["==", ["upcase", ["get", "ESTADO"]], stateName2]
        //   ]);
      }

      if (map.current.getLayer(stateOlLayerId.current)) {
        map.current.setPaintProperty(
          stateOlLayerId.current,
          "line-color",
          stateName1 || stateName2 ? "white" : StateOlColors
        );
      }
    },
    [stateName, raceType]
  );

  const removeFilter = useCallback(() => {
    if (map.current.getLayer(munLayerId.current)) {
      map.current.setFilter(munLayerId.current, null);
      if (!forceMun && !party && !voteCircle) {
        hideLayer(munLayerId.current);
      }
    }

    if (map.current.getLayer(disLayerId.current)) {
      map.current.setFilter(disLayerId.current, null);
      if (!forceDis && !voteCircle) {
        hideLayer(disLayerId.current);
      }
    }

    if (map.current.getLayer(stateLayerId.current)) {
      map.current.setFilter(stateLayerId.current, null);
      if (raceType !== "mayor") {
        map.current.setPaintProperty(stateLayerId.current, "fill-opacity", 1);
      }
      if (!forceMun && !forceDis && !party && !voteCircle) {
        showLayer(stateLayerId.current);
      }
    }

    if (map.current.getLayer(metroLayerId.current)) {
      map.current.setFilter(metroLayerId.current, null);
    }

    if (map.current.getLayer(stateOlLayerId.current)) {
      map.current.setPaintProperty(
        stateOlLayerId.current,
        "line-color",
        StateOlColors
      );
    }

    if (stateName.current) {
      const stateNameStr = removeAccents(`${stateName.current}`.toUpperCase());

      if (StateLabelLayers[stateNameStr]) {
        map.current.setPaintProperty(
          StateLabelLayers[stateNameStr],
          "text-opacity",
          0,
          {
            duration: 50,
            easing: "ease-in-out"
          }
        );
      } else {
        console.log(`Label layer for ${stateNameStr} not found`);
      }
    }

    stateName.current = null;
    dispatch(setRaceTitle(""));
  }, [
    dispatch,
    showLayer,
    hideLayer,
    forceMun,
    forceDis,
    party,
    voteCircle,
    raceType
  ]);

  const revealLayer = useCallback(
    (features, dir = ZOOM_DIR_ENUM.FORWARD) => {
      if (forceDis || forceMun) return;

      const feature = features[0];
      if (dir === ZOOM_DIR_ENUM.FORWARD) {
        if (feature.sourceLayer === "states") {
          hideLayer(stateLayerId.current);
          lastStateFeature.current = features;

          if (raceType === "cong") {
            if (munLayerId.current) {
              hideLayer(munLayerId.current);
            }

            if (disLayerId.current) {
              showLayer(disLayerId.current);
              currentLayer.current = "districts";
            }
          } else {
            if (munLayerId.current) {
              showLayer(munLayerId.current);
              currentLayer.current = "municipals";
            }

            if (disLayerId.current) {
              hideLayer(disLayerId.current);
            }
          }
        } else if (feature.sourceLayer === "districts") {
          if (disLayerId.current) {
            hideLayer(disLayerId.current);
            lastDisFeature.current = features;
          }

          if (munLayerId.current) {
            showLayer(munLayerId.current);
            currentLayer.current = "municipals";
          }
        } else if (feature.sourceLayer === "municipals") {
          lastMunFeature.current = features;
        }
      } else if (dir === ZOOM_DIR_ENUM.BACKWARD) {
        if (feature.sourceLayer === "states") {
          showLayer(stateLayerId.current);
          lastStateFeature.current = feature;
          currentLayer.current = "states";
          // removeFilter();

          if (munLayerId.current) {
            hideLayer(munLayerId.current);
          }

          if (disLayerId.current) {
            hideLayer(disLayerId.current);
          }
        } else if (feature.sourceLayer === "districts") {
          if (disLayerId.current) {
            showLayer(disLayerId.current);
            lastDisFeature.current = features;
            currentLayer.current = "districts";
          }

          if (munLayerId.current) {
            hideLayer(munLayerId.current);
          }
        } else if (feature.sourceLayer === "municipals") {
          lastMunFeature.current = features;
        }
      }
    },
    [raceType, showLayer, hideLayer, forceDis, forceMun]
  );

  const zoomAndReveal = useCallback(
    (features) => {
      dispatch(setClickedMarker(true));
      zoomToFeat(features);
      if (
        !((raceType === "gov" || raceType === "sen") && year !== "jal" && year !== "cdmx" && year !== "cdmx24") &&
        !voteCircle
      ) {
        revealLayer(features);
      }
    },
    [revealLayer, zoomToFeat, raceType, year, voteCircle, dispatch]
  );

  const loadMarker = useCallback(
    (features, lng, lat) => {
      dispatch(setMarkerLng(lng));
      dispatch(setMarkerLat(lat));

      dispatch(setRaceSubtitle1(""));
      dispatch(setRaceSubtitle2(""));

      if ((!lng || !lat) && marker.current) {
        const lngLat = marker.current.getLngLat();
        lng = lngLat.lng;
        lat = lngLat.lat;
      }

      let color_first_party = false;
      if (features[0].sourceLayer === "districts") {
        color_first_party = features[0].properties.FIRST;
      }

      let first_party =
        year === "cdmx-2024" ? features[0].properties.FIRST_PARTIES || features[0].properties.FIRST_AFFILIATION || "" :
        features[0].properties.STATE_DISPLAY ||
        features[0].properties.FIRST_PARTIES ||
        features[0].properties.FIRST_AFFILIATION ||
        features[0].properties.FIRST_DISPLAY ||
        features[0].properties.FIRST_PARTY ||
        features[0].properties.FIRST ||
        "";
      if (first_party === "INDEPENDIENTE") first_party = "Independiente";

      let markerColor = "#111111";
      let textColor = "#fff";
      if (turnout || year === "nom-2024") markerColor = PartyColors["INE"].high;
      else if (
        features[0]?.properties?.TIE === "TRUE" ||
        features[0]?.properties?.TIE === true
      ) {
        markerColor = "#B4B4B4";
      } else if (color_first_party && PartyColors[color_first_party]) {
        markerColor = PartyColors[color_first_party].high;
        textColor = PartyColors[color_first_party].contrast;
      } else if (PartyColors[first_party]) {
        if (year === "pre-2024") {
          let vstate = voca.titleCase(features[0].properties.NOMBRE);
          markerColor = app.pre2024Neutrals[vstate] ? PartyColors.Neutral.high : app.pre2024Winners[vstate]
            ? PartyColors[app.pre2024Winners[vstate].party].high
            : PartyColors[first_party].high;
        } else markerColor = PartyColors[first_party].high;
        textColor = PartyColors[first_party].contrast;
      }

      if (marker.current) {
        marker.current.remove();
        marker.current
          .getElement()
          .removeEventListener("click", zoomAndReveal.bind(this, features));
      }

      marker.current = new mapboxgl.Marker({
        color: markerColor,
        draggable: false
      })
        .setLngLat([lng, lat])
        .addTo(map.current);

      marker.current
        .getElement()
        .addEventListener("click", zoomAndReveal.bind(this, features));

      if (popup.current) popup.current.remove();

      if (
        year === "women" ||
        year === "post-2024" ||
        year === "pre-2024"
      )
        return;

      let popup_txt = '<div class="mapPopup">';
      let subtitle = intl.formatMessage({ id: "WinnerPopup" });

      if (features[0].sourceLayer === "states") {
        if (features[0].properties.ESTADO)
          subtitle = voca.titleCase(
            StatesAccents[features[0].properties.ESTADO.toUpperCase()] ||
              features[0].properties.ESTADO
          );
        else if (features[0].properties.NOMBRE_ESTADO)
          subtitle = voca.titleCase(
            StatesAccents[features[0].properties.NOMBRE_ESTADO.toUpperCase()] ||
              features[0].properties.NOMBRE_ESTADO
          );
        else if (features[0].properties.STATE_NAME)
          subtitle = voca.titleCase(
            StatesAccents[features[0].properties.STATE_NAME.toUpperCase()] ||
              features[0].properties.STATE_NAME
          );
        else if (features[0].properties.NOMBRE)
          subtitle = voca.titleCase(
            StatesAccents[features[0].properties.NOMBRE.toUpperCase()] ||
              features[0].properties.NOMBRE
          );
        else if (features[0].properties.NOMBRE_MUN)
          subtitle = voca.titleCase(
            StatesAccents[features[0].properties.NOMBRE_MUN.toUpperCase()] ||
              features[0].properties.NOMBRE_MUN
          );
        else if (features[0].properties.ENTIDAD && typeof features[0].properties.ENTIDAD === "string")
          subtitle = voca.titleCase(
            StatesAccents[features[0].properties.ENTIDAD.toUpperCase()] ||
              features[0].properties.ENTIDAD
          );

        if (subtitle === "México") {
          subtitle = intl.formatMessage({ id: "MexicoState" });
        }
      } else {
        if (typeof features[0].properties.DISTRICT_DISPLAY !== "undefined")
          subtitle = voca.titleCase(features[0].properties.DISTRICT_DISPLAY);
        else subtitle = voca.titleCase(features[0].properties.MUNICIPIO || features[0].properties.ALCALDIA);

        if (subtitle === "Torreon") {
          subtitle = "Torreón";
        }
      }

      const regex = /^(.*?) \((.*?)\)$/;
      let match = null;
      if (regex.test(subtitle)) {
        match = regex.exec(subtitle);
      }

      if (features[0].sourceLayer === "districts" && match) {
        dispatch(setRaceSubtitle1(match[1]));
        popup_txt += `<div class="mapPopupDist" style="color:#000;font-weight:bold;">${match[1]}</div>`;
      }

      popup_txt += '<div class="mapPopupTitle" style="color:#000;">';
      if (features[0].sourceLayer === "states") {
        let stateName = "";
        if (features[0].properties.ESTADO)
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.ESTADO.toUpperCase()] ||
              features[0].properties.ESTADO
          );
        else if (features[0].properties.NOMBRE_ESTADO)
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.NOMBRE_ESTADO.toUpperCase()] ||
              features[0].properties.NOMBRE_ESTADO
          );
        else if (features[0].properties.STATE_NAME)
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.STATE_NAME.toUpperCase()] ||
              features[0].properties.STATE_NAME
          );
        else if (features[0].properties.NOMBRE_MUN)
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.NOMBRE_MUN.toUpperCase()] ||
              features[0].properties.NOMBRE_MUN
          );
        else if (features[0].properties.ENTIDAD && typeof features[0].properties.ENTIDAD === "string")
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.ENTIDAD.toUpperCase()] ||
              features[0].properties.ENTIDAD
          );

        if (stateName === "México") {
          stateName = intl.formatMessage({ id: "MexicoState" });
        }

        dispatch(setRaceTitle(stateName));

        popup_txt += stateName;
      } else if (features[0].sourceLayer === "districts" && match) {
        dispatch(setRaceTitle(match[2]));

        popup_txt += match[2];
      } else {
        let stateName = "";
        if (features[0].properties.ESTADO)
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.ESTADO.toUpperCase()] ||
              features[0].properties.ESTADO
          );
        else if (features[0].properties.NOMBRE_ESTADO)
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.NOMBRE_ESTADO.toUpperCase()] ||
              features[0].properties.NOMBRE_ESTADO
          );
        else if (features[0].properties.STATE_NAME)
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.STATE_NAME.toUpperCase()] ||
              features[0].properties.STATE_NAME
          );
        else if (features[0].properties.NOMBRE_MUN)
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.NOMBRE_MUN.toUpperCase()] ||
              features[0].properties.NOMBRE_MUN
          );
        else if (features[0].properties.MUNICIPIO)
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.MUNICIPIO.toUpperCase()] ||
              features[0].properties.MUNICIPIO
          );
        else if (features[0].properties.ALCALDIA)
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.ALCALDIA.toUpperCase()] ||
              features[0].properties.ALCALDIA
          );
        else if (features[0].properties.ENTIDAD && typeof features[0].properties.ENTIDAD === "string")
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.ENTIDAD.toUpperCase()] ||
              features[0].properties.ENTIDAD
          );

        if (stateName === "México") {
          stateName = intl.formatMessage({ id: "MexicoState" });
        }

        dispatch(setRaceTitle(stateName));

        popup_txt += stateName;
      }
      popup_txt += "</div>";

      if (features[0].sourceLayer === "districts" && match) {
        popup_txt += `<div class="mapPopupLocation" style="color:#000;">`;
        let stateName = "";
        if (features[0].properties.ESTADO)
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.ESTADO.toUpperCase()] ||
              features[0].properties.ESTADO
          );
        else if (features[0].properties.NOMBRE_ESTADO)
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.NOMBRE_ESTADO.toUpperCase()] ||
              features[0].properties.NOMBRE_ESTADO
          );
        else if (features[0].properties.STATE_NAME)
          stateName = voca.titleCase(
            StatesAccents[features[0].properties.STATE_NAME.toUpperCase()] ||
              features[0].properties.STATE_NAME
          );

        if (stateName === "México") {
          stateName = intl.formatMessage({ id: "MexicoState" });
        }

        popup_txt += stateName;
        popup_txt += `</div>`;

        dispatch(setRaceSubtitle2(stateName));
      }

      if (features[0].sourceLayer === "municipals") {
        popup_txt += `<div class="mapPopupLocation" style="color:#000;">${voca.titleCase(
          subtitle
        )}</div>`;

        dispatch(setRaceSubtitle2(subtitle));
      }

      if (
        features[0]?.properties?.TIE === "TRUE" ||
        features[0]?.properties?.TIE === true
      ) {
        popup_txt += `<div class="mapPopupParty" style="background-color:#B4B4B4;color:#fff">`;
        popup_txt += intl.formatMessage({ id: "Tie" });
      } else {
        popup_txt += `<div class="mapPopupParty" style="background-color:${markerColor};color:${textColor};">`;
        if (turnout) {
          popup_txt += Math.round(features[0].properties.TURNOUT) + "%";
        } else if (year === "nom-2024") {
          const reg_msg = intl.formatMessage(
            { id: "RegVoters" },
            {
              voters: Math.round(features[0].properties.LISTA_NOMINAL).toLocaleString(
                //intl.locale
                "en"
              )
            }
          );
          popup_txt += reg_msg;
        } else {
          if (first_party === "0") {
            popup_txt += `${intl.formatMessage({ id: "Regent" })}`;
          } else {
            popup_txt += `${first_party.toUpperCase()}`;
          }
        }
      }
      popup_txt += `</div>`;

      const totalVotes =
        features[0].properties.TOTAL_VOTOS_CALCULADOS ||
        features[0].properties.TOTAL_PERSONAS_VOTARON ||
        features[0].properties.TOTAL_VOTES ||
        features[0].properties.TOTAL_VOTOS ||
        features[0].properties.TOTAL_VALID_VOTES ||
        0;

      if (totalVotes) {
        const vote_msg = intl.formatMessage(
          { id: "Votes" },
          {
            votes: totalVotes.toLocaleString(
              //intl.locale
              "en"
            )
          }
        );
        popup_txt += '<div class="mapPopupVotes">' + vote_msg + "</div>";
        if (
          !turnout &&
          features[0].sourceLayer === "states" &&
          typeof features[0].properties.TURNOUT !== "undefined"
        )
          popup_txt +=
            '<div class="mapPopupTurnout">' +
            Math.round(features[0].properties.TURNOUT) +
            "% " +
            intl.formatMessage({ id: "Turnout" }) +
            "</div>";
      }

      popup_txt += "</div>";

      if (year === "nom-2024" && app.showNationalResult) return;

      if (1 || !["districts"].includes(features[0].sourceLayer)) {
        popup.current = new mapboxgl.Popup({
          offset: window.innerWidth > 3000 ? 120 : 45
        })
          .setLngLat([lng, lat])
          .setHTML(popup_txt)
          .addTo(map.current);
      }
    },
    [intl, zoomAndReveal, turnout, dispatch, year, app.pre2024Winners, app.pre2024Neutrals]
  );

  const selectFeature = useCallback(
    (
      features,
      lng,
      lat,
      shouldReveal = false,
      shouldRevealDir = ZOOM_DIR_ENUM.BACKWARD,
      shouldZoom = false,
      overrideYear = ""
    ) => {
      if (!features || !features[0]) return;

      if (document.getElementById("raceboard")) {
        document.getElementById("raceboard").classList.remove("board-opened");
        setTimeout(
          () =>
            document.getElementById("raceboard").classList.add("board-opened"),
          0
        );
      }

      dispatch(setRaceLayer(features[0].sourceLayer));
      dispatch(updateFeature(features[0]));

      let newStateName = "";

      if (!(year === "women" && !features[0].properties.GOVERNOR_NAME)) {
        if (features[0].properties.ESTADO) {
          dispatch(setRaceState(voca.titleCase(features[0].properties.ESTADO)));
          newStateName = features[0].properties.ESTADO;
        } else if (features[0].properties.NOMBRE_ESTADO) {
          dispatch(
            setRaceState(voca.titleCase(features[0].properties.NOMBRE_ESTADO))
          );
          newStateName = features[0].properties.NOMBRE_ESTADO;
        } else if (features[0].properties.STATE_NAME) {
          dispatch(
            setRaceState(voca.titleCase(features[0].properties.STATE_NAME))
          );
          newStateName = features[0].properties.STATE_NAME;
        } else if (features[0].properties.NOMBRE) {
          dispatch(setRaceState(voca.titleCase(features[0].properties.NOMBRE)));
          newStateName = features[0].properties.NOMBRE;
        } else if (features[0].properties.STATE_ID) {
          dispatch(
            setRaceState(voca.titleCase(features[0].properties.NOMBRE_MUN))
          );
          newStateName = features[0].properties.STATE_ID;
        } else if (features[0].properties.NOMBRE_MUN) {
          dispatch(
            setRaceState(voca.titleCase(features[0].properties.NOMBRE_MUN))
          );
          //newStateName = features[0].properties.NOMBRE_MUN
        } else if (features[0].properties.ENTIDAD) {
          dispatch(
            setRaceState(voca.titleCase(features[0].properties.ENTIDAD))
          );
          newStateName = features[0].properties.ENTIDAD;
        } else {
          dispatch(setRaceState(""));
          newStateName = "";
        }
      }

      if (newStateName) {
        addFilter(newStateName);

        if (shouldReveal) {
          revealLayer(features, shouldRevealDir);
        }

        if (shouldZoom) {
          zoomToFeat(features);
        }
      }

      if (!(year === "women" && !features[0].properties.GOVERNOR_NAME)) {
        stateName.current = newStateName;
        loadRaceBoard(features[0], overrideYear);
        loadMarker(features, lng, lat);

        if (
          (["pres", "cong"].includes(raceType) &&
            (turnout || year === "nom-2024") &&
            app.showNationalResult) ||
          year === "post-2024" ||
          year === "pre-2024"
        ) {
          if (popup.current) popup.current.remove();
        }
      }
    },
    [
      dispatch,
      loadRaceBoard,
      loadMarker,
      addFilter,
      zoomToFeat,
      revealLayer,
      raceType,
      year,
      turnout
    ]
  );

  const selectFeatureEvent = useCallback(
    (e) => {
      let features = [];

      // check if click was on murder circle
      if (map.current.getLayer("mx_murders_2024-circle")) {
        features = map.current.queryRenderedFeatures(e.point, {
          layers: ["mx_murders_2024-circle"] 
        });
      }

      if (features.length > 0) return;

      console.log("Select Event", e);
      console.log("Selected Feature", e.features);

      if (voteCircle && !e?.features[0]?.sourceLayer)
        e.features[0].sourceLayer = "municipals";

      selectFeature(
        e.features,
        e.lngLat.lng,
        e.lngLat.lat,
        false,
        ZOOM_DIR_ENUM.FORWARD,
        false
      );

      if (e?.features[0]?.layer?.id?.endsWith("state-fill")) return;
      if (e?.features[0]?.layer?.id?.endsWith("state-outline")) return;

      // if (
      //   raceType === "mayor" &&
      //   e.features[0].sourceLayer === "neighborhoods"
      // ) {
      //   //Getting geometory from map method (Old)
      //   //(less detailed geometry coordinates, very ugly)
      //   /*const mayorFeatures = map.current.querySourceFeatures('mayor-mx-' + year + '-metro', {
      //     sourceLayer: 'neighborhoods',
      //   });

      //   //const renderFeatures = map.current.queryRenderedFeatures({ layers: ['mayor-mx-' + year + '-metro'] });
      //   //console.log(renderFeatures);

      //   console.log(mayorFeatures);

      //   const featureCollection = {
      //     type: "FeatureCollection",
      //     features: mayorFeatures
      //   };

      //   //get geometry with leaflet
      //   const lg = L.geoJson(mayorFeatures, {
      //   });

      //   console.log(lg);

      //   let multipolyCoords = [];
      //   lg.eachLayer((l)=>{
      //     if(l instanceof L.Polygon){
      //       if (l.getLatLngs().length === 1) {
      //         console.log(l.getLatLngs());
      //         multipolyCoords.push(l.getLatLngs())
      //       }
      //     }
      //   });

      //   console.log(multipolyCoords);

      //   let poly = L.polygon(multipolyCoords);

      //   console.log(poly);
      //   console.log(poly.toGeoJSON(15));

      //   poly = poly.toGeoJSON(15);

      //   const pcoor = poly.geometry.coordinates;

      //   //For Geometry Invert Background
      //   poly.geometry.type = 'MultiPolygon';
      //   poly.geometry.coordinates = [[
      //     [[0, 90], [180, 90], [180, -90], [0, -90], [-180, -90], [-180, 0], [-180, 90], [0, 90]],
      //     //poly.geometry.coordinates[0][0]
      //   ]];

      //   pcoor.forEach((pc, ind) => {
      //     //console.log(ind);
      //     //console.log(pc[0]);
      //     if (ind <= 5)
      //       poly.geometry.coordinates[0].push(pc[0]);
      //   });

      //   const invertFeatures = [poly];
      //   console.log(invertFeatures);*/

      //   //test invert data
      //   /*let test = [{
      //       "type": "Feature",
      //       "properties": {"party": "Republican"},
      //       "geometry": {
      //           "type": "MultiPolygon",
      //           "coordinates": [[
      //               [[0, 90], [180, 90], [180, -90], [0, -90], [-180, -90], [-180, 0], [-180, 90], [0, 90]],
      //               [[-100.2667236328125, 25.770213848960253],
      //               [-100.20355224609375,  25.723209559418265],
      //               [-100.25848388671875,  25.70093788144426],
      //               [-100.30242919921875, 25.715786129968635],
      //               [-100.33813476562499, 25.762793355586624],
      //               [-100.2667236328125, 25.770213848960253]],
      //               [[-100.426025390625, 25.713311550538407],
      //               [-100.39306640625, 25.67123582857704],
      //               [-100.34637451171875, 25.661333498952686],
      //               [-100.31341552734375, 25.644002443615022],
      //               [-100.35186767578125, 25.594471277418847],
      //               [-100.426025390625, 25.636574077877043],
      //               [-100.426025390625, 25.713311550538407]]
      //           ]]
      //       }
      //   }];*/

      //   /*const invert_lg = L.geoJson(invertFeatures, {
      //   });

      //   console.log(invert_lg);

      //   const invertBGFeatures = invert_lg._layers[Object.keys(invert_lg._layers)[0]].feature;

      //   const invertFeatureCollection = {
      //     type: "FeatureCollection",
      //     features: [invertBGFeatures]
      //   };

      //   console.log(invertFeatureCollection);
      //   console.log(featureCollection);

      //   const msrc = 'mayor_invert'

      //   //map.current.getSource(msrc).setData(featureCollection);
      //   map.current.getSource(msrc).setData(invertFeatureCollection);*/
      //   //End of Getting geometry from map method

      //   //Getting geometry from GeoJSON file instead (New)
      //   //(more detailed geometry coordinates)
      //   //19 for Nuevo Leon, 14 for Jalisco, 15 for Mexico State, 9 for Mexico City
      //   const selectedState = e.features[0].properties.ID_ESTADO
      //     ? e.features[0].properties.ID_ESTADO
      //     : "19";
      //   let selectedStates = [selectedState];
      //   if (["15", "9"].includes(selectedState)) selectedStates = ["15", "9"];
      //   //const geoJsonFile = GeoJSONData['mayor'+year];
      //   const geoJsonFile = GeoJSONData["states"];
      //   axios.get(geoJsonFile).then((response) => {
      //     const jsonFeatures = response.data.features;

      //     const jsonFeatureCollection = {
      //       type: "FeatureCollection",
      //       features: jsonFeatures
      //     };

      //     //get geometry with leaflet
      //     const jlg = L.geoJson(jsonFeatures, {});

      //     console.log(jlg);

      //     let jsonMultipolyCoords = [];
      //     jlg.eachLayer((l) => {
      //       if (
      //         l instanceof L.Polygon &&
      //         selectedStates.includes(l.feature.properties.ID_ESTADO)
      //       ) {
      //         //console.log(l.getLatLngs().length);
      //         //console.log(l.getLatLngs());
      //         if (l.getLatLngs().length === 1) {
      //           //jsonMultipolyCoords.push(l.getLatLngs());  //for mayor2021.GeoJSON
      //           jsonMultipolyCoords.push(l.getLatLngs()[0]); //for states.GeoJSON
      //         } else {
      //           l.getLatLngs().forEach((latlngs) => {
      //             //console.log(latlngs);
      //             //jsonMultipolyCoords.push([latlngs]); //for mayor2021.GeoJSON
      //             if (latlngs.length === 1)
      //               //for states.GeoJSON
      //               jsonMultipolyCoords.push(latlngs);
      //           });
      //         }
      //       }
      //     });

      //     console.log(jsonMultipolyCoords);

      //     let jpoly = L.polygon(jsonMultipolyCoords);

      //     console.log(jpoly);
      //     console.log(jpoly.toGeoJSON(15));

      //     jpoly = jpoly.toGeoJSON(15);

      //     const jpcoor = jpoly.geometry.coordinates;

      //     //For Geometry Invert Background
      //     jpoly.geometry.type = "MultiPolygon";
      //     jpoly.geometry.coordinates = [
      //       [
      //         [
      //           [0, 90],
      //           [180, 90],
      //           [180, -90],
      //           [0, -90],
      //           [-180, -90],
      //           [-180, 0],
      //           [-180, 90],
      //           [0, 90]
      //         ]
      //         //poly.geometry.coordinates[0][0]
      //       ]
      //     ];

      //     jpcoor.forEach((pc, ind) => {
      //       //console.log(ind);
      //       //console.log(pc[0]);
      //       if (ind <= 1) jpoly.geometry.coordinates[0].push(pc[0]);
      //     });

      //     const jsonInvertFeatures = [jpoly];
      //     console.log(jsonInvertFeatures);

      //     const invert_jlg = L.geoJson(jsonInvertFeatures, {});

      //     console.log(invert_jlg);

      //     const invertJsonBGFeatures =
      //       invert_jlg._layers[Object.keys(invert_jlg._layers)[0]].feature;

      //     const invertJsonFeatureCollection = {
      //       type: "FeatureCollection",
      //       features: [invertJsonBGFeatures]
      //     };

      //     console.log(invertJsonFeatureCollection);
      //     console.log(jsonFeatureCollection);

      //     const jmsrc = "mayor_invert";

      //     //map.current.getSource(jmsrc).setData(jsonFeatureCollection);
      //     map.current.getSource(jmsrc).setData(invertJsonFeatureCollection);

      //     if (selectedState === "19") {
      //       //Nuevo Leon
      //       map.current.flyTo({
      //         center: [-100.27390592571065, 25.790487294911887],
      //         zoom: window.innerWidth > 3000 ? 9.7 : 8.6,
      //         duration: 1000
      //       });
      //     } else if (selectedState === "14") {
      //       //Jalisco
      //       map.current.flyTo({
      //         center: [-103.67073492578699, 20.69959154892672],
      //         zoom: window.innerWidth > 3000 ? 8.1 : 7,
      //         duration: 1000
      //       });
      //     } else if (["15", "9"].includes(selectedState)) {
      //       //15 for Mexico State, 9 for Mexico City
      //       map.current.flyTo({
      //         center: [-99.59237455141704, 19.363312547702748],
      //         zoom: window.innerWidth > 3000 ? 9 : 7.9,
      //         duration: 1000
      //       });
      //     }
      //   });
      // } else

      if (app.reverse) {
        if (
          (statesToOffsetReverse.includes(e.features[0].properties.ID_ESTADO) ||
            statesToOffsetReverse.includes(e.features[0].properties.ESTADO) ||
            statesToOffsetReverse.includes(e.features[0].properties.ENTIDAD)) &&
          e.features[0].sourceLayer === "states"
        ) {
          zoomToFeat(e.features);
        }
      } else {
        if (
          (statesToOffset.includes(e.features[0].properties.ID_ESTADO) ||
            statesToOffset.includes(e.features[0].properties.ESTADO) ||
            statesToOffset.includes(e.features[0].properties.ENTIDAD)) &&
          e.features[0].sourceLayer === "states"
        ) {
          zoomToFeat(e.features);
        }
      }
    },
    [selectFeature, zoomToFeat, app.reverse, voteCircle]
  );

  const unselectFeature = useCallback(() => {
    dispatch(setShowRaceboard(false));
    dispatch(setClickedMarker(false));
    dispatch(updateFeature(null));
    dispatch(setRaceTitle(""));
  }, [dispatch]);

  const removeLayers = useCallback(() => {
    if (map.current) {
      if (map.current.getLayer(munLayerId.current)) {
        map.current.off("click", munLayerId.current, selectFeatureEvent);
        map.current.removeLayer(munLayerId.current);
      }

      if (map.current.getLayer(disLayerId.current)) {
        map.current.off("click", disLayerId.current, selectFeatureEvent);
        map.current.removeLayer(disLayerId.current);
      }

      if (map.current.getLayer(stateLayerId.current)) {
        map.current.off("click", stateLayerId.current, selectFeatureEvent);
        map.current.removeLayer(stateLayerId.current);
      }

      if (map.current.getLayer(stateOlLayerId.current)) {
        map.current.off("click", stateOlLayerId.current, selectFeatureEvent);
        map.current.removeLayer(stateOlLayerId.current);
      }

      if (map.current.getLayer(stateFillLayerId.current)) {
        map.current.off("click", stateFillLayerId.current, selectFeatureEvent);
        map.current.removeLayer(stateFillLayerId.current);
      }

      if (map.current.getLayer(metroLayerId.current)) {
        map.current.off("click", metroLayerId.current, selectFeatureEvent);
        map.current.removeLayer(metroLayerId.current);
      }

      if (map.current.getLayer(metroInvertLayerId.current)) {
        map.current.off(
          "click",
          metroInvertLayerId.current,
          selectFeatureEvent
        );
        map.current.removeLayer(metroInvertLayerId.current);
      }

      if (map.current.getSource(stateOlSourceId.current)) {
        map.current.removeSource(stateOlSourceId.current);
      }

      if (map.current.getSource(stateFillSourceId.current)) {
        map.current.removeSource(stateFillSourceId.current);
      }

      if (map.current.getSource(munSourceId.current)) {
        map.current.removeSource(munSourceId.current);
      }

      if (map.current.getSource(disSourceId.current)) {
        map.current.removeSource(disSourceId.current);
      }

      if (map.current.getSource(stateSourceId.current)) {
        map.current.removeSource(stateSourceId.current);
      }

      if (map.current.getSource(metroSourceId.current)) {
        map.current.removeSource(metroSourceId.current);
      }

      if (map.current.getSource(metroInvertSourceId.current)) {
        map.current.removeSource(metroInvertSourceId.current);
      }
    }
  }, [selectFeatureEvent]);

  const loadSingleLayer = useCallback(
    (src, vectorId, layerName, dataType, url, drawType, options) => {
      // const c_options = structuredClone(options);

      if (!map.current.getSource(src)) {
        map.current.addSource(src, {
          type: "vector",
          url: url
        });

        //correct center point of municipals with islands
        // if (drawType === "circle") {
        //   options["paint"]["circle-radius"] = 0;

        //   setTimeout(
        //     () => {
        //       const munFeatures = map.current.querySourceFeatures(
        //         `${raceType}-mx-${year}-mun`,
        //         {
        //           sourceLayer: "municipals"
        //         }
        //       );
        //       if (!munFeatures.length) return false;

        //       if (map.current.getLayer(munLayerId.current)) {
        //         map.current.off(
        //           "click",
        //           munLayerId.current,
        //           selectFeatureEvent
        //         );
        //         map.current.removeLayer(munLayerId.current);
        //       }
        //       if (map.current.getSource(munSourceId.current)) {
        //         map.current.removeSource(munSourceId.current);
        //       }

        //       //let c_munFeatures = structuredClone(munFeatures);
        //       //get geometry with leaflet
        //       const lg = L.geoJson(munFeatures, {});

        //       let n_munFeatures = [];
        //       lg.eachLayer((l) => {
        //         if (l.feature.properties.MUNICIPIO === "Manzanillo") {
        //           if (["2021", "2015"].includes(year))
        //             l.feature._geometry.coordinates[0] =
        //               l.feature._geometry.coordinates[0] + 0.8;
        //           else if (["2018"].includes(year))
        //             l.feature._geometry.coordinates[0] =
        //               l.feature._geometry.coordinates[0] + 2.3;
        //           else if (["2009"].includes(year))
        //             l.feature._geometry.coordinates[0] =
        //               l.feature._geometry.coordinates[0] + 2.2;
        //           else if (["2006"].includes(year))
        //             l.feature._geometry.coordinates[0] =
        //               l.feature._geometry.coordinates[0] + 0.9;
        //           else
        //             l.feature._geometry.coordinates[0] =
        //               l.feature._geometry.coordinates[0] + 0.8;
        //           l.feature._geometry.coordinates[1] =
        //             l.feature._geometry.coordinates[1] - 0;
        //         } else if (l.feature.properties.MUNICIPIO === "San Blas") {
        //           l.feature._geometry.coordinates[0] =
        //             l.feature._geometry.coordinates[0] + 0.56;
        //         }
        //         n_munFeatures.push(l.feature);
        //       });

        //       const featureCollection = {
        //         type: "FeatureCollection",
        //         features: n_munFeatures
        //       };

        //       map.current.addSource(src, {
        //         type: "geojson",
        //         // Use a URL for the value for the `data` property.
        //         data: featureCollection
        //       });

        //       const vectorLayerOutline = {
        //         id: vectorId,
        //         source: src,
        //         //"source-layer": layerName,
        //         sourceLayer: layerName,
        //         type: drawType,
        //         ...c_options
        //       };

        //       map.current.addLayer(vectorLayerOutline, firstLayerId.current);
        //       map.current.on(
        //         "click",
        //         vectorLayerOutline.id,
        //         selectFeatureEvent
        //       );

        //       map.current.on("mouseenter", vectorLayerOutline.id, () => {
        //         map.current.getCanvas().style.cursor = "pointer";
        //       });
        //       map.current.on("mouseleave", vectorLayerOutline.id, () => {
        //         map.current.getCanvas().style.cursor = "";
        //       });

        //       munSourceId.current = src;
        //       munLayerId.current = vectorLayerOutline.id;
        //       dispatch(setMunLayerId(munLayerId.current));

        //       if (stateName?.current) {
        //         addFilter(stateName.current);
        //       }
        //     },
        //     app.preloaded ? 400 : 1000
        //   );
        // }
      }

      const vectorLayerOutline = {
        id: vectorId,
        source: src,
        "source-layer": layerName,
        type: drawType,
        ...options
      };
      map.current.addLayer(vectorLayerOutline, firstLayerId.current);

      if (drawType !== "line") {
        map.current.on("click", vectorLayerOutline.id, selectFeatureEvent);

        map.current.on("mouseenter", vectorLayerOutline.id, () => {
          map.current.getCanvas().style.cursor = "pointer";
        });
        map.current.on("mouseleave", vectorLayerOutline.id, () => {
          map.current.getCanvas().style.cursor = "";
        });
      }

      switch (dataType) {
        case "district":
          disSourceId.current = src;
          disLayerId.current = vectorLayerOutline.id;
          dispatch(setDisLayerId(disLayerId.current));
          break;
        case "municipal":
          munSourceId.current = src;
          munLayerId.current = vectorLayerOutline.id;
          dispatch(setMunLayerId(munLayerId.current));
          break;
        case "state-outline":
          stateOlSourceId.current = src;
          stateOlLayerId.current = vectorLayerOutline.id;
          break;
        case "state-fill":
          stateFillSourceId.current = src;
          stateFillLayerId.current = vectorLayerOutline.id;
          break;
        case "state":
          stateSourceId.current = src;
          stateLayerId.current = vectorLayerOutline.id;
          dispatch(setStateLayerId(stateLayerId.current));
          break;
        case "metro":
          metroSourceId.current = src;
          metroLayerId.current = vectorLayerOutline.id;
          dispatch(setMetroLayerId(metroLayerId.current));
          break;
        default:
          break;
      }

      return vectorLayerOutline.id;
    },
    [selectFeatureEvent, dispatch]
  );

  const getColorExpression = useCallback(
    (showPartyStr, raceType, year, party, turnout, margin, useVoteCircle = false, level = "") => {
      if (turnout) {
        const turnoutColor = TurnoutColor(year, level);
        return turnoutColor;
      } else if (year === "nom-2024") {
        const nomColor = NomColor(useVoteCircle, level);
        return nomColor;
      } else if (year === "women") {
        return WomenColors;
      } else if (showPartyStr && party) {
        //return ["concat", "#", ["get", Parties[`${raceType}-${year}`][party]]];
        return PartyStrFillColors(raceType, year, party);
      } else if (margin) {
        const marginFillColors = MarginFillColors(
          year,
          level,
          app.pre2024Winners,
          app.pre2024Neutrals,
          app.show2023Result
        );
        if (year === "pre-2024") {
          return marginFillColors["gov_2024"];
        } else if (marginFillColors[`${raceType}`]) {
          return marginFillColors[`${raceType}`];
        } else {
          return marginFillColors;
        }
        //return ["to-color", ["concat", "#", ["get", "MARGIN_HEX"]], "#000000"];
      } else {
        if (year === "pre-2024") {
          return GeneralFillColors("gov_2024", app.pre2024Winners, app.pre2024Neutrals, app.show2023Result);
        } else {
          return GeneralFillColors(`${raceType}${year === "cdmx-2024" && raceType === "mayor" ? "2024" : ""}`);
        }
      }
    },
    [app.pre2024Winners, app.pre2024Neutrals, app.show2023Result]
  );

  const loadLayers = useCallback(
    (
      raceType,
      year,
      party,
      forceMun,
      forceDis,
      voteCircle,
      turnout,
      margin
    ) => {
      let shouldShowDisLayer = false;
      let shouldShowMunLayer = false;
      let isSameMetro = false;
      let isSameGovMun = false;

      const layers = map.current.getStyle().layers;
      // Iterate through each layer in the style
      for (let layer of layers) {
        if (layer.id === "states-fill") {
          map.current.setPaintProperty(layer.id, "fill-color", voteCircle && year === "nom-2024" ? "#bcc1d9" : "#8f97bf", {
            duration: 50,
            easing: "ease-in-out"
          });
        }
      }

      if (metroLayerId?.current && raceType === "mayor") {
        let currentLayerParts = metroLayerId.current.split("-");
        let newLayerParts = `${raceType}-mx-${year}-metro`.split("-");
        let yearParts = `${year}`.split("-");
        let prevYearParts = `${prevYear}`.split("-");

        isSameMetro =
          yearParts.length > 1 && prevYearParts.length > 1 &&
          year[0] === prevYear[0] &&
          currentLayerParts[0] === newLayerParts[0] &&
          currentLayerParts[1] === newLayerParts[1] &&
          currentLayerParts[2] === newLayerParts[2];
      }

      if (munLayerId?.current && raceType === "gov" && (year === "cdmx24" || year === "cdmx")) {
        let currentLayerParts = munLayerId.current.split("-");
        let newLayerParts = `${raceType}-mx-${year}-mun`.split("-");

        isSameGovMun =
          currentLayerParts[0] === newLayerParts[0] &&
          currentLayerParts[1] === newLayerParts[1] &&
          currentLayerParts[2].substring(0, 3) === newLayerParts[2].substring(0, 3) &&
          currentLayerParts[3] === newLayerParts[3];
      }

      if (
        Urls[`${raceType}-${year}`] &&
        (!isSameMetro || raceType !== "mayor") &&
        (!isSameGovMun || raceType !== "gov")
      ) {
        const src = Urls[`${raceType}-${year}`];
        if (src?.center) {
          map.current.flyTo({
            center: src.center,
            zoom: src.zoom,
            duration: 1000,
            essential: true
          });
        }
      }

      if (year === "nom-2024" || year === "cdmx24") {
        dispatch(updateTurnout(false));
        dispatch(setShowRaceChart(false));
      }

      if (raceType === "gov") {
        dispatch(updateTurnout(false));
      }

      if (raceType === "mayor") {
        dispatch(updateVoteCircle(false));
        dispatch(setShowRaceChart(false));

        const mayorColorExpression = getColorExpression(
          true,
          raceType,
          year,
          party,
          turnout,
          margin,
          voteCircle,
          "states"
        );

        const mayorPaint = {
          "fill-color": mayorColorExpression,
          "fill-opacity": StateFillOpacity,
          "fill-outline-color": StateOutlineColor(mayorColorExpression)
        };

        const mayorNeighborhoodsUrl = getMapboxUrl(
          raceType,
          year,
          voteCircle,
          "neighborhoods"
        );

        const mayorStateUrl = getMapboxUrl(raceType, year, voteCircle, "state");

        if (mayorNeighborhoodsUrl || mayorStateUrl) removeLayers();

        if (mayorNeighborhoodsUrl) {
          loadSingleLayer(
            `${raceType}-mx-${year}-metro`,
            `${raceType}-mx-${year}-metro`,
            "neighborhoods",
            "metro",
            mayorNeighborhoodsUrl,
            "fill",
            {
              minzoom: 1,
              maxzoom: 22,
              paint: mayorPaint
            }
          );
        }

        if (mayorStateUrl) {
          loadSingleLayer(
            `${raceType}-mx-${year}-state`,
            `${raceType}-mx-${year}-state`,
            "states",
            "state",
            mayorStateUrl,
            "line",
            {
              minzoom: 1,
              maxzoom: 22,
              paint: {
                "line-color": "white",
                "line-width": 3
              }
            }
          );
        }

        // const featureCollection = {
        //   type: "FeatureCollection",
        //   features: []
        // };

        // const msrc = "mayor_invert";

        // map.current.addSource(msrc, {
        //   type: "geojson",
        //   // Use a URL for the value for the `data` property.
        //   data: featureCollection
        // });

        // metroInvertSourceId.current = msrc;

        // const mlayer = "mayor-invert";

        // map.current.addLayer({
        //   id: mlayer,
        //   type: "fill",
        //   source: msrc, // reference the data source
        //   layout: {},
        //   paint: {
        //     "fill-color": "#959fb0", // blue color fill
        //     "fill-opacity": 1
        //   }
        // });

        // metroInvertLayerId.current = mlayer;

        return;
      }

      removeLayers();

      const stateUrl = getMapboxUrl(raceType, year, false, "state");

      const stateFillPaint = {
        "fill-color": "white",
        "fill-opacity": 0.1
      };

      if (year !== "jal" && year !== "cdmx" && year !== "cdmx24") {
        loadSingleLayer(
          `${raceType}-mx-${year}-state`,
          `${raceType}-mx-${year}-state-fill`,
          "states",
          "state-fill",
          stateUrl,
          "fill",
          {
            minzoom: 1,
            maxzoom: 22,
            paint: stateFillPaint
          }
        );
      }

      // if force district is enabled
      // and race type is not congressional
      // disable force district
      if (raceType !== "cong" && forceDis) {
        dispatch(updateForceDis(false));
      }

      // if race type is congressional
      // and force municipal is ehabled or force district is enabled
      // and vote circle is disabled
      // load districts
      if (raceType === "cong" && (!forceMun || forceDis) && !party) {
        // if force district is enabled
        // and vote circle is enabled
        // disable vote circle
        if (forceDis) {
          dispatch(updateVoteCircle(false));
        }

        const disColorExpression = getColorExpression(
          true,
          raceType,
          year,
          party,
          turnout,
          margin,
          voteCircle,
          "districts"
        );

        const disPaint = {
          "fill-color": disColorExpression,
          "fill-opacity": forceDis
            ? ForceDisFillOpacity(1.0)
            : DisFillOpacity(1.0),
          "fill-outline-color": DisOutlineColor(disColorExpression)
          // "fill-extrusion-color": disColorExpression,
          // "fill-extrusion-opacity": 1.0,
          // "fill-extrusion-height": 5000
        };
        const disUrl = getMapboxUrl(raceType, year, false, "dis");

        loadSingleLayer(
          `${raceType}-mx-${year}-dis`,
          `${raceType}-mx-${year}-dis`,
          "districts",
          "district",
          disUrl,
          "fill",
          {
            minzoom: 1,
            maxzoom: 22,
            paint: disPaint
          }
        );

        // if force district
        // or layer is districts
        // show districts
        shouldShowDisLayer = forceDis || currentLayer.current === "districts";

        if (shouldShowDisLayer) {
          showLayer(`${raceType}-mx-${year}-dis`);
          currentLayer.current = "districts";
        } else {
          hideLayer(`${raceType}-mx-${year}-dis`);
        }
      }

      // if force district is not enabled
      // load municipals
      if (!forceDis || party) {
        const munColorExpression = getColorExpression(
          true,
          raceType,
          year,
          party,
          turnout,
          margin,
          voteCircle,
          "municipals"
        );

        const useVoteCircle = voteCircle && !margin && !party && !turnout;

        const munPaint = useVoteCircle
          ? {
              "circle-color": munColorExpression,
              "circle-radius":
                year === "nom-2024" ? NomMunCircleRadius : raceType === "cong" ? CongMunCircleRadius : PresMunCircleRadius,
              "circle-opacity":
                raceType === "cong"
                  ? MunDisFillOpacity(0.5)
                  : MunFillOpacity(0.5)
              //Not Working as expected...
              /*"circle-translate": [
			    'match',
                ['get', 'MUNICIPIO'],
				'Manzanillo',
				[100,100],
				[0, 0]
		      ]*/
            }
          : {
              "fill-color": munColorExpression,
              "fill-opacity":
                forceMun || party
                  ? ForceMunFillOpacity(1.0)
                  : raceType === "cong"
                  ? MunDisFillOpacity(1.0)
                  : MunFillOpacity(1.0),
              "fill-outline-color": MunOutlineColor(munColorExpression)
              // "fill-extrusion-color": munColorExpression,
              // "fill-extrusion-opacity": 1.0,
              // "fill-extrusion-height": 5000
            };
        const munUrl = getMapboxUrl(raceType, year, useVoteCircle, "mun");

        if (munUrl) {
          loadSingleLayer(
            `${raceType}-mx-${year}-mun`,
            `${raceType}-mx-${year}-mun`,
            "municipals",
            "municipal",
            munUrl,
            useVoteCircle ? "circle" : "fill",
            {
              minzoom: 1,
              maxzoom: 22,
              paint: munPaint
            }
          );

          // if force municipal is enabled
          // or vote circle is enabled
          // or party strength is enabled
          // show municipals
          shouldShowMunLayer =
            forceMun ||
            useVoteCircle ||
            party ||
            currentLayer.current === "municipals" ||
            year === "jal" ||
            year === "cdmx" || 
            year === "cdmx24";

          if (shouldShowMunLayer && !shouldShowDisLayer) {
            showLayer(`${raceType}-mx-${year}-mun`);
            currentLayer.current = "municipals";
          } else {
            hideLayer(`${raceType}-mx-${year}-mun`);
          }
        }
      }

      // if vote circle is disabled
      // load state
      if (!voteCircle) {
        stateColorExpression.current = getColorExpression(
          false,
          raceType,
          year,
          party,
          turnout,
          margin,
          voteCircle,
          "states"
        );

        const statePaint = {
          "fill-color": stateColorExpression.current,
          "fill-opacity": 1,
          "fill-outline-color": StateOutlineColor(stateColorExpression.current)
        };

        loadSingleLayer(
          `${raceType}-mx-${year}-state`,
          `${raceType}-mx-${year}-state`,
          "states",
          "state",
          stateUrl,
          "fill",
          {
            minzoom: 1,
            maxzoom: 22,
            paint: statePaint
          }
        );

        const shouldHideStateLayer =
          forceMun ||
          forceDis ||
          voteCircle ||
          party ||
          currentLayer.current !== "states";

        const shouldShowStateLayer =
          ((raceType === "gov" || raceType === "sen") && year !== "jal" && year !== "cdmx" && year !== "cdmx24") ||
          (prevForceDis &&
            !forceDis &&
            (!stateName.current || stateName.current)) ||
          (prevForceMun &&
            !forceMun &&
            (!stateName.current || stateName.current)) ||
          (prevVoteCircle &&
            !voteCircle &&
            !stateName.current &&
            !forceMun &&
            !forceDis) ||
          !shouldHideStateLayer;

        if (shouldShowStateLayer) {
          showLayer(`${raceType}-mx-${year}-state`);
          currentLayer.current = "states";
        } else {
          hideLayer(`${raceType}-mx-${year}-state`);
        }
      }

      const stateOlPaint = {
        "line-color": stateName?.current
          ? "white"
          : year === "2006" && !party && !voteCircle && !turnout
          ? StateOlColors
          : "white",
        "line-width": StateOlWidth
      };

      loadSingleLayer(
        `${raceType}-mx-${year}-state`,
        `${raceType}-mx-${year}-state-ol`,
        "states",
        "state-outline",
        stateUrl,
        "line",
        {
          minzoom: 1,
          maxzoom: 22,
          paint: stateOlPaint
        }
      );

      if (stateName?.current) {
        addFilter(stateName.current);
      }
    },
    [
      dispatch,
      removeLayers,
      loadSingleLayer,
      showLayer,
      hideLayer,
      addFilter,
      getColorExpression,
      prevForceDis,
      prevForceMun,
      prevVoteCircle,
      prevYear,
      stateName
    ]
  );

  const getStateFeatures = useCallback(
    (stateId) => {
      let source = `${raceType}-mx-${year}-state`;
      let filter = [
        "any",
        ["==", ["to-number", ["get", "ID_ESTADO"], 0], parseInt(stateId)],
        ["==", ["to-number", ["get", "ENTIDAD"], 0], parseInt(stateId)]
      ];
      let sourceLayer = "states";

      let stateFeatures = map.current.querySourceFeatures(source, {
        sourceLayer: sourceLayer,
        filter: filter
      });

      if (stateFeatures.length > 0) {
        stateFeatures[0].sourceLayer = sourceLayer;
      }

      return stateFeatures;
    },
    [raceType, year]
  );

  const getParallelFeatures = useCallback(
    (raceType, year) => {
      let source = `${raceType}-mx-${year}-`;
      let filter = [];
      let newFeatures = [];
      let sourceLayer = "states";

      if (year === "jal"/* || year === "cdmx" || year === "cdmx24"*/) return newFeatures;

      if (lastFeature) {
        if (raceType === "mayor" && lastFeature.sourceLayer !== "neighborhoods")
          return newFeatures;

        switch (lastFeature.sourceLayer) {
          case "states":
            source += "state";
            filter = [
              "any",
              [
                "==",
                ["to-number", ["get", "ID_ESTADO"], 0],
                parseInt(lastFeature.properties.ID_ESTADO)
              ],
              [
                "==",
                ["to-number", ["get", "ENTIDAD"], 0],
                parseInt(lastFeature.properties.ID_ESTADO)
              ],
              [
                "==",
                ["to-number", ["get", "ID_ESTADO"], 0],
                parseInt(lastFeature.properties.ENTIDAD)
              ],
              [
                "==",
                ["to-number", ["get", "ENTIDAD"], 0],
                parseInt(lastFeature.properties.ENTIDAD)
              ]
            ];
            sourceLayer = "states";
            break;
          case "municipals":
            source += "mun";
            filter = [
              "==",
              ["to-number", ["get", "UNIQUE_ID"], 0],
              parseInt(lastFeature.properties.UNIQUE_ID)
            ];
            sourceLayer = "municipals";
            break;
          case "districts":
            if (raceType !== "cong") {
              source += "state";
              filter = [
                "any",
                [
                  "==",
                  ["to-number", ["get", "ID_ESTADO"], 0],
                  parseInt(lastFeature.properties.ID_ESTADO)
                ],
                [
                  "==",
                  ["to-number", ["get", "ENTIDAD"], 0],
                  parseInt(lastFeature.properties.ID_ESTADO)
                ],
                [
                  "==",
                  ["to-number", ["get", "ID_ESTADO"], 0],
                  parseInt(lastFeature.properties.ENTIDAD)
                ],
                [
                  "==",
                  ["to-number", ["get", "ENTIDAD"], 0],
                  parseInt(lastFeature.properties.ENTIDAD)
                ]
              ];
              sourceLayer = "states";
            } else {
              source += "dis";
              filter = [
                "all",
                [
                  "==",
                  ["to-number", ["get", "ID_DISTRITO_FEDERAL"], 0],
                  parseInt(lastFeature.properties.ID_DISTRITO_FEDERAL)
                ],
                [
                  "==",
                  ["to-number", ["get", "ID_ESTADO"], 0],
                  parseInt(lastFeature.properties.ID_ESTADO)
                ]
              ];
              sourceLayer = "districts";
            }
            break;
          case "neighborhoods":
            source += "metro";
            filter = [
              "==",
              ["get", "NOMBRE_MUN"],
              lastFeature.properties.NOMBRE_MUN
            ];
            sourceLayer = "neighborhoods";
            break;
          default:
            break;
        }

        newFeatures = map.current.querySourceFeatures(source, {
          sourceLayer: sourceLayer,
          filter: filter
        });

        if (newFeatures.length > 0) {
          newFeatures[0].sourceLayer = sourceLayer;
          newFeatures[0].source = source;
        }
      }

      return newFeatures;
    },
    [lastFeature]
  );

  const removeCover = useCallback(() => {
    document.getElementById("cover").classList.add("fade-out");
  }, []);

  const goHome = useCallback(
    (shouldRemoveFilter = true, showCover = false) => {
      if (showCover) {
        document.getElementById("cover").classList.remove("fade-out");
      }

      setTimeout(() => {
        map.current.flyTo({
          center: [DefaultLng, DefaultLat],
          zoom: DefaultZoom,
          duration: 2000,
          essential: true
        });
      }, 1);

      unselectFeature();
      dispatch(updateParty(""));
      dispatch(updateTurnout(false));
      dispatch(updateMargin(false));
      dispatch(setRaceTitle(""));

      if (!forceMun && !forceDis && !voteCircle) {
        if (stateLayerId.current) {
          showLayer(stateLayerId.current);
        }

        if (munLayerId.current) {
          hideLayer(munLayerId.current);
        }

        if (disLayerId.current) {
          hideLayer(disLayerId.current);
        }

        currentLayer.current = "states";
        lastStateFeature.current = null;
        lastMunFeature.current = null;
        lastDisFeature.current = null;
      }

      if (shouldRemoveFilter) {
        removeFilter();
      }

      loadLayers(
        raceType,
        year,
        party,
        forceMun,
        forceDis,
        voteCircle,
        turnout,
        margin
      );
    },
    [
      dispatch,
      removeFilter,
      hideLayer,
      showLayer,
      unselectFeature,
      forceMun,
      forceDis,
      voteCircle,
      loadLayers,
      margin,
      party,
      raceType,
      turnout,
      year
    ]
  );

  const goBack = useCallback(() => {
    console.log(lastFeature);
    if (!lastFeature) {
      if (Urls[`${raceType}-${year}`]) {
        const src = Urls[`${raceType}-${year}`];
        if (src?.center) {
          map.current.flyTo({
            center: src.center,
            zoom: src.zoom,
            duration: 1000,
            essential: true
          });
        } else {
          map.current.flyTo({
            center: [DefaultLng, DefaultLat],
            zoom: DefaultZoom,
            duration: 1000,
            essential: true
          });
        }
      } else {
        map.current.flyTo({
          center: [DefaultLng, DefaultLat],
          zoom: DefaultZoom,
          duration: 1000,
          essential: true
        });
      }

      unselectFeature();

      if (!forceMun && !forceDis && !party && !voteCircle) {
        if (stateLayerId.current) {
          showLayer(stateLayerId.current);
        }

        if (munLayerId.current) {
          hideLayer(munLayerId.current);
        }

        if (disLayerId.current) {
          hideLayer(disLayerId.current);
        }

        currentLayer.current = "states";
        lastStateFeature.current = null;
        lastMunFeature.current = null;
        lastDisFeature.current = null;
      }

      removeFilter();
      return;
    }

    const stateFeatures = getStateFeatures(
      lastFeature.properties.ID_ESTADO || lastFeature.properties.ENTIDAD
    );

    if (forceMun || forceDis || voteCircle || party) {
      const featureCollection = {
        type: "FeatureCollection",
        features: stateFeatures
      };
      const bounds = turf.bbox(featureCollection);
      map.current.fitBounds(bounds, {
        padding: 100,
        center: turf.center(
          turf.points([
            [bounds[0], bounds[1]],
            [bounds[2], bounds[3]]
          ])
        ).geometry.coordinates
      });
      unselectFeature();
    } else {
      switch (lastFeature.sourceLayer) {
        case "municipals":
          if (
            raceType === "cong" &&
            lastDisFeature &&
            lastDisFeature.current &&
            (lastDisFeature.current[0].properties.ID_ESTADO !==
              lastFeature.properties.ID_ESTADO ||
              lastDisFeature.current[0].properties.ENTIDAD !==
                lastFeature.properties.ID_ESTADO)
          ) {
            selectFeature(
              lastDisFeature.current,
              null,
              null,
              true,
              ZOOM_DIR_ENUM.BACKWARD,
              true
            );
          } else {
            selectFeature(
              stateFeatures,
              null,
              null,
              true,
              ZOOM_DIR_ENUM.BACKWARD,
              true
            );
          }
          break;
        case "districts":
          selectFeature(
            stateFeatures,
            null,
            null,
            true,
            ZOOM_DIR_ENUM.BACKWARD,
            true
          );
          break;
        case "neighborhoods":
          unselectFeature();
          removeFilter();

          break;
        default:
          unselectFeature();
          removeFilter();
          break;
      }
    }
  }, [
    lastStateFeature,
    lastDisFeature,
    lastFeature,
    raceType,
    forceMun,
    forceDis,
    party,
    voteCircle,
    year,
    getStateFeatures,
    selectFeature,
    unselectFeature,
    hideLayer,
    showLayer,
    removeFilter
  ]);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: process.env.REACT_APP_MAPBOX_STYLE_URL,
      center: [lng, lat],
      zoom: zoom,
      projection: {
        name: "mercator"
      },
      maxBounds: [MaxBounds.sw, MaxBounds.ne],
      minZoom: 3,
      maxZoom: 16,
      renderWorldCopies: true
    });

    leafletMap.current = L.map(leafletMapContainer.current, {
      center: [40, -74.5],
      zoom: 9,
      zoomControl: false,
      attributionControl: false
    });

    map.current.on("load", () => {
      firstLayerId.current = "states";

      // map.current.on("move", () => {
      // const bounds = map.current.getBounds();
      // const mapPosition = {
      //   center: map.current.getCenter(),
      //   bounds: {
      //     sw: bounds.getSouthWest(),
      //     ne: bounds.getNorthEast()
      //   },
      //   zoom: map.current.getZoom()
      // };
      //console.log("Map Position", mapPosition);

      //   if (map.current.getZoom() > 6.5) {
      //     map.current.setPaintProperty(
      //       "settlement-major-label copy",
      //       "text-opacity",
      //       1,
      //       {
      //         duration: 50,
      //         easing: "ease-in-out"
      //       }
      //     );
      //   } else {
      //     if (!stateName.current) {
      //       map.current.setPaintProperty(
      //         "settlement-major-label copy",
      //         "text-opacity",
      //         0,
      //         {
      //           duration: 50,
      //           easing: "ease-in-out"
      //         }
      //       );
      //     }
      //   }
      // });

      map.current.on("click", function (e) {
        // Prevent the map's default behavior of zooming
        e.preventDefault();
      });

      map.current.on("dblclick", function (e) {
        // Prevent the map's default behavior of zooming
        e.preventDefault();
      });

      // Disable tilting (pitching)
      map.current.dragRotate.disable();
      map.current.touchPitch.disable();
      map.current.touchZoomRotate.disableRotation();
      map.current.doubleClickZoom.disable();

      loadLayers(raceType, year, party, forceMun, forceDis);
      setMapReady(true);

      // size all text up by 150% if ~4k
      if (window.innerWidth >= 3000) {
        const layers = map.current.getStyle().layers;
        for (const layer of layers) {
          if (
            layer.type === "symbol" &&
            layer.layout &&
            "text-size" in layer.layout
          ) {
            const currentSize = map.current.getLayoutProperty(
              layer.id,
              "text-size"
            );
            // Scale up text size by a factor (e.g., 1.5)
            const newSize =
              (typeof currentSize === "number" ? currentSize : 12) * 4;
            map.current.setLayoutProperty(layer.id, "text-size", newSize);
          }
        }
      }

      const layers = map.current.getStyle().layers;

      // Iterate through each layer in the style
      for (let layer of layers) {
        // Check if the layer is a symbol layer
        if (layer.type === "symbol") {
          map.current.setPaintProperty(layer.id, "text-opacity", 0, {
            duration: 50,
            easing: "ease-in-out"
          });
        }
      }

      map.current.resize();

      // Cleanup
      return () => {
        map.current.remove();
        leafletMap.current.remove();
      };
    });
  });

  useEffect(() => {
    if (!app.showRaceboard) {
      if (marker.current) marker.current.remove();
      if (popup.current) popup.current.remove();
    }
  }, [app.showRaceboard]);

  useEffect(() => {
    if (app.show2024Murders) {
      if (!map.current.getSource("mx_murders_2024_v4-bs6eol")) {
        map.current.addSource("mx_murders_2024_v4-bs6eol", {
          type: "vector",
          url: "mapbox://wilsonli.bdqrpuww"
        });
      }

      if (!map.current.getLayer("mx_murders_2024-circle")) {
        map.current.addLayer({
          id: "mx_murders_2024-circle",
          type: "circle",
          source: "mx_murders_2024_v4-bs6eol",
          "source-layer": "mx_murders_2024_v4-bs6eol",
          paint: {
            "circle-color": "#000",
            "circle-opacity": 1,
            "circle-radius": window.innerWidth > 3000 ? 15 : 7.5
          }
        });

        // move to top
        map.current.moveLayer("mx_murders_2024-circle");

        map.current.on("click", "mx_murders_2024-circle", function (e) {
          if (murderPopup.current) murderPopup.current.remove();

          if (!e.features.length) return;

          const coordinates = e.features[0].geometry.coordinates.slice();
          const name = e.features[0].properties?.name;
          let party = e.features[0].properties?.party;
          if (party === "PV") party = "PVEM";

          const markerColor = PartyColors[party]?.high || "#000";
          const textColor = PartyColors[party]?.contrast || "#fff";

          murderPopup.current = new mapboxgl.Popup({
            offset: window.innerWidth > 3000 ? 120 : 45
          })
            .setLngLat(coordinates)
            .setHTML(`<div class="mapPopup"><div class="mapPopupTitle" style="color:#000;">${name}</div>
            <div class="mapPopupParty" style="background-color:${markerColor};color:${textColor};">${party}</div></div>`)
            .addTo(map.current);

            e.originalEvent.stopPropagation();
        });
      }

      // if (!map.current.getLayer("mx_murders_2024-label")) {
      //   map.current.addLayer({
      //     id: "mx_murders_2024-label",
      //     type: "symbol",
      //     source: "mx_murders_2024_v3-2zous5",
      //     "source-layer": "mx_murders_2024_v3-2zous5",
      //     layout: {
      //       "text-field": ["get", "name"],
      //       "text-size": window.innerWidth > 3000 ? 36 : 18,
      //       "text-anchor": "top",
      //       "text-offset": [0, -2]
      //     },
      //     paint: {
      //       "text-color": "#FFFFFF", // White text fill color
      //       "text-halo-color": "#000000", // Black text outline color
      //       "text-halo-width": 1 // Adjust the outline width as needed
      //     }
      //   });
      // }
    } else {
      if (murderPopup.current) {
        murderPopup.current.remove();
      }

      if (map.current.getLayer("mx_murders_2024-circle")) {
        map.current.removeLayer("mx_murders_2024-circle");
      }

      if (map.current.getLayer("mx_murders_2024-label")) {
        map.current.removeLayer("mx_murders_2024-label");
      }

      if (map.current.getSource("mx_murders_2024")) {
        map.current.removeSource("mx_murders_2024");
      }
    }
  }, [app.show2024Murders]);

  useEffect(() => {
    if (!map.current || !mapReady) return;
    loadLayers(
      raceType,
      year,
      party,
      forceMun,
      forceDis,
      voteCircle,
      turnout,
      margin
    );
  }, [
    year,
    raceType,
    party,
    forceMun,
    forceDis,
    voteCircle,
    turnout,
    margin,
    mapReady,
    loadLayers
  ]);

  useEffect(() => {
    if (app.showRaceboard && lastFeature) {
      let features = [];
      features[0] = lastFeature;
      loadMarker(features, app.markerLng, app.markerLat);

      if (
        (["pres", "cong"].includes(raceType) &&
          turnout &&
          app.showNationalResult) ||
        year === "post-2024" ||
        year === "pre-2024"
      ) {
        if (popup.current) popup.current.remove();
      }
    }
  }, [
    turnout,
    lastFeature,
    raceType,
    year,
    loadMarker,
    app.markerLng,
    app.markerLat,
    app.showRaceboard,
  ]);

  useEffect(() => {
    if (document.getElementById("react-sketch-canvas__stroke-group-0"))
      document
        .getElementById("react-sketch-canvas__stroke-group-0")
        .setAttribute("mask", "");
  }, []);

  const isJsonString = useCallback((str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }, []);

  const connection = useRef(null);

  useEffect(() => {
    if (process.env.REACT_APP_WS) {
      try {
        const socket = new WebSocket(process.env.REACT_APP_WS);

        // Connection opened
        socket.addEventListener("open", (event) => {
          //socket.send("Connection established")
        });

        // Listen for messages
        socket.addEventListener("message", (event) => {
          console.log("Message from server: ", event.data);
          if (isJsonString(event.data)) {
            let msg = JSON.parse(event.data);
            console.log(msg);
            if (
              msg.action === "set_racetype_year" &&
              msg.racetype &&
              msg.year
            ) {
              dispatch(updateYear(msg.year));
              dispatch(updateRaceType(msg.racetype));
              removeCover();
            } else if (msg.action === "set_bookmark" && msg.bookmark) {
              dispatch(setShowBookmarks(true));
              const bm = "bm_" + msg.bookmark.replace(/[\W_]+/g, "_") + "_go";
              setTimeout(() => {
                if (document.getElementById(bm))
                  document.getElementById(bm).click();
              }, 200);
              removeCover();
            }
          } else {
            console.log("Invalid JSON Format.");
          }
        });

        connection.current = socket;
      } catch (ex) {
        console.log("WS Error", ex);
      }
    }

    return () => {
      if (process.env.REACT_APP_WS) {
        try {
          connection.current.close();
        } catch (ex) {
          console.log("WS Error", ex);
        }
      }
    };
  }, [dispatch, isJsonString, removeCover]);

  return (
    <AppShell
      padding={0}
      layout="alt"
      navbar={
        <Navbar
          width={{ base: 300 }}
          sx={{
            backgroundColor: "transparent",
            right: app.reverse ? "0px" : "auto",
            left: app.reverse ? "auto" : "0px"
          }}
        >
          <NavbarNested
            mapRef={map}
            markerRef={marker}
            popupRef={popup}
            canvasRef={canvas}
            removeCover={removeCover}
            goHome={goHome}
            goBack={goBack}
            addFilter={addFilter}
            selectFeature={selectFeature}
            unselectFeature={unselectFeature}
            zoomAndReveal={zoomAndReveal}
            hideLayer={hideLayer}
            showLayer={showLayer}
            getParallelFeatures={getParallelFeatures}
            currentLayer={currentLayer}
          />
        </Navbar>
      }
      header={
        <Header height={70} p="xs">
          {/* Header content */}
        </Header>
      }
    >
      <TopBar app={app} goHome={goHome} />
      <Box
        className="map-root"
        style={{
          width: "100%",
          display: "flex",
          position: "absolute",
          left: "0px",
          overflow: "hidden"
        }}
      >
        <div ref={mapContainer} className="map-container" />
        <div
          ref={leafletMapContainer}
          style={{
            width: "100%",
            height: "100%",
            visibility: "hidden",
            position: "absolute",
            zIndex: -1
          }}
        />
      </Box>
      <div
        id={"cover"}
        style={{
          width: "calc(100% - 7.5%)",
          height: "100%",
          position: "absolute",
          backgroundColor: "#fff",
          zIndex: 999,
          left: app.reverse ? "auto" : "7.5%",
          right: app.reverse ? "7.5%" : "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          top: "0px",
          overflow: "hidden"
        }}
      >
        <img src={COVER} alt="" style={{ height: "100%", width: "100%" }} />
      </div>
      <CreateBookmarkModal
        mapRef={map}
        markerRef={marker}
        open={modal === "MODAL_CREATE_BOOKMARK"}
        onClose={onCloseModal}
      />
      <Raceboard
        open={app.showRaceboard && raceType === "pres" && year !== "nom-2024"}
        onClose={onCloseRaceboard}
        app={app}
      />
      <RaceboardSenate
        open={app.showRaceboard && raceType === "sen"}
        onClose={onCloseRaceboard}
        app={app}
      />
      <RaceboardNational
        open={app.showNationalResult && raceType === "pres" && !turnout && year !== "nom-2024"}
        onClose={onCloseRaceboardNational}
        app={app}
      />
      <RaceboardNationalTurnout
        open={app.showNationalResult && turnout}
        onClose={onCloseRaceboardNationalTurnout}
        app={app}
      />
      <RaceboardNationalReg
        open={app.showNationalResult && year === "nom-2024"}
        onClose={onCloseRaceboardNationalTurnout}
        app={app}
      />
      <RaceboardCong
        open={app.showRaceboard && raceType === "cong"}
        onClose={onCloseRaceboard}
        app={app}
      />
      <RaceboardCongNational
        open={app.showNationalResult && raceType === "cong" && !turnout}
        onClose={onCloseRaceboardNational}
        app={app}
      />
      <RaceboardGov
        open={
          app.showRaceboard &&
          raceType === "gov"  &&
          year !== "women" &&
          year !== "post-2024" &&
          year !== "pre-2024" &&
          year !== "jal" &&
          year !== "cdmx" &&
          year !== "cdmx24"
        }
        onClose={onCloseRaceboard}
        app={app}
      />
      <RaceboardGov2024
        open={app.showRaceboard && !app.showNationalResult && raceType === "gov" && year === "post-2024"}
        onClose={onCloseRaceboard}
        app={app}
      />
      <RaceboardGovPre2024
        open={app.showRaceboard && !app.showNationalResult && raceType === "gov" && year === "pre-2024"}
        onClose={onCloseRaceboard}
        app={app}
      />
      <RaceboardWomen
        open={
          app.showRaceboard &&
          raceType === "gov" &&
          year === "women" &&
          !app.showNationalResult
        }
        onClose={onCloseRaceboard}
        app={app}
      />
      <RaceboardNationalWomen
        open={app.showNationalResult && raceType === "gov" && year === "women"}
        onClose={onCloseRaceboardNationalWomen}
        app={app}
      />
      <RaceboardGovNational
        open={
          app.showNationalResult &&
          raceType === "gov" &&
          year !== "women" &&
          year !== "jal" &&
          year !== "cdmx" &&
          year !== "cdmx24" &&
          !turnout
        }
        onClose={onCloseRaceboardNational}
        app={app}
      />
      <RaceboardSenNational
        open={
          app.showNationalResult &&
          raceType === "sen" &&
          !turnout
        }
        onClose={onCloseRaceboardNational}
        app={app}
      />
      <RaceboardMayor
        open={app.showRaceboard && raceType === "mayor"}
        onClose={onCloseRaceboard}
        app={app}
      />
      <RaceboardGovState
        open={
          app.showNationalResult &&
          raceType === "gov" &&
          (year === "jal" || year === "cdmx" || year === "cdmx24")
        }
        onClose={onCloseRaceboardNational}
        app={app}
      />
      <RaceboardGovState
        open={
          app.showRaceboard &&
          app.raceboard.mun &&
          raceType === "gov" &&
          (year === "jal" || year === "cdmx" || year === "cdmx24") &&
          currentLayer?.current !== "states"
        }
        onClose={onCloseRaceboard}
        app={app}
      />
      <Telestrator innerRef={canvas} />
      <RaceChart />
    </AppShell>
  );
};

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<AuthorizedComponent />}>
        <Route path="/" element={<Home />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}
