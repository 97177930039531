import React from "react";
import { Dialog, Box, Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import voca from "voca";
import removeAccents from "remove-accents";

import { /*Titles, */ Names } from "../../../mapbox/titles";
import { PartyColors } from "../../../mapbox/colors";
import SenateImgs from "../../../mapbox/candidatesSen2024";
import CandidateImgs from "../../../mapbox/candidates";
import { StatesAccents } from "../../../mapbox/states";
//import variables from "../../../_variables.scss";
import { FormattedMessage, useIntl } from "react-intl";

const RaceboardSenate = ({ open, onClose, app }) => {
  const year = useSelector((state) => state.year.value);
  const intl = useIntl();

  let checkSize = 20;
  let checkStroke = 3;
  let partyBorderLeft = 0;
  let partyBorderBottom = 10;
  if (window.innerWidth > 3000) {
    //checkSize = 60;
    //checkStroke = 3;
    partyBorderLeft = 0;
    partyBorderBottom = 20;
  }

  const runAfterRender = () => {
    if (document.getElementById("raceboard"))
      if (app.showNationalResult)
        document.getElementById("raceboard").style.visibility = "hidden";
      else document.getElementById("raceboard").style.visibility = "visible";
  };

  return (
    <Dialog
      id={"raceboard"}
      className={"raceboardP"}
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={20}
      sx={{
        width: "530px",
        borderLeft: "solid 0px " + PartyColors[app.raceboard.p1CoalitionParties]?.high,
        padding: "0px !important",
        fontSize: "16px",
        color: "#111111",
        top: 89
      }}
      onLoad={runAfterRender}
    >
      <Box
        className={"raceboardTitleBox"}
        sx={{
          marginLeft: "5%",
          fontWeight: "bold",
          //borderBottom: "solid 3px #111",
          paddingBottom: "4px",
          marginBottom: "10px"
        }}
      >
        <Box
          className={"raceboardTitle"}
          sx={{
            fontSize: "39px",
            lineHeight: "39px",
            paddingBottom: "3px",
            paddingTop: "9px",
            textAlign: "center"
          }}
        >
          {app.raceboard.layer === "municipals"
            ? app.raceboard.mun === "Torreon"
              ? "Torreón"
              : app.raceboard.mun
            : app.raceboard.state === "México"
            ? intl.formatMessage({ id: "MexicoState" })
            : voca.titleCase(
                StatesAccents[app.raceboard.state.toUpperCase()] ||
                  app.raceboard.state.toUpperCase() ||
                  ""
              )}
        </Box>
        <Box
          className={"raceboardSubtitle"}
          sx={{
            fontSize: "25px",
            lineHeight: "25px",
            paddingBottom: "3px",
            paddingTop: "0px",
            fontWeight: "normal",
            textAlign: "center"
          }}
        >
          &nbsp;
          {app.raceboard.layer === "municipals"
            ? app.raceboard.state === "México"
              ? intl.formatMessage({ id: "MexicoState" })
              : voca.titleCase(
                  StatesAccents[app.raceboard.state.toUpperCase()] ||
                    app.raceboard.state.toUpperCase() ||
                    ""
                )
            : app.raceboard.mun || ""}
          &nbsp;
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}
      >
        {app.raceboard.p1Name || app.raceboard.p2Name ? (
          <Box
            className={"partyBox"}
            sx={{
              display: "flex",
              flex: 1,
              backgroundColor: "rgba(255,255,255,0.8)",
              paddingTop: "0.6rem",
              marginBottom: "0.5rem"
            }}
          >
            {app.raceboard.tie ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "6%",
                  marginRight: "0%"
                }}
              ></Box>
            ) : (
              <Box
                id={"raceboardCheckBox"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0px 0px 0px 0px",
                  width: "5%",
                  marginRight: "1%",
                  marginBottom: "1.4%",
                  backgroundColor: !app.raceboard.tie
                    ? PartyColors[app.raceboard.p1CoalitionParties]?.high
                    : "transparent",
                  color: "#fff",
                  fontWeight: "bold",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <IconCheck
                  className={"raceCheck"}
                  strokeWidth={checkStroke}
                  size={checkSize}
                  style={{
                    marginLeft: "0px",
                    display: !app.raceboard.tie ? "block" : "none"
                  }}
                />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%"
                  }}
                >
                  <Box
                    className={"partySubBox"}
                    sx={{
                      display: "flex",
                      marginRight: "4%",
                      paddingBottom: "0rem",
                      marginBottom: "0.5rem",
                      alignItems: "flex-end"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "top",
                        width: "19%"
                      }}
                    >
                      <img
                        id={"raceboardImg1"}
                        src={
                          SenateImgs[
                            `${removeAccents(
                              app.raceboard.state
                                .toUpperCase()
                                .replaceAll(" ", "_")
                            )}_FIRST`
                          ] || SenateImgs.Default
                        }
                        style={{ width: "100%" }}
                        alt=""
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "0.5%",
                        width: "50%",
                        marginTop: "0px"
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <div
                          className={
                            app.raceboard.p1Party.length > 10
                              ? "racePartyName"
                              : "racePartyName"
                          }
                          style={{
                            //backgroundColor: PartyColors[app.raceboard.p1Party]?.high,
                            color:
                              PartyColors[app.raceboard.p1CoalitionParties]?.boardText ||
                              PartyColors[app.raceboard.p1CoalitionParties]?.high,
                            display: "inline-block",
                            padding: "2px 0px 0px 6px",
                            marginLeft: "0px",
                            //whiteSpace: "nowrap",
                            overflowWrap: "break-word",
                            fontSize:
                              app.raceboard.p1Party.length > 10
                                ? "23px"
                                : "23px",
                            lineHeight:
                              app.raceboard.p1Party.length > 10
                                ? "23px"
                                : "23px",
                            width:
                              window.innerWidth > 3000 ? "51rem" : "25.5rem"
                          }}
                        >
                          <div style={{marginBottom: "0.2rem"}}>{app.raceboard.p1CoalitionParties.toUpperCase()}</div>
                          {(app.raceboard.p1Party?.includes("_")
                            ? app.raceboard.p1Party.split("_")
                            : app.raceboard.p1Party.split("-")
                          )
                            .map((img) => {
                              if (img === "PES" && year === "2015")
                                img = "PES2015";
                              return CandidateImgs[img] &&
                                img !== "Independiente"
                                ? CandidateImgs[img]
                                : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAQAAADYv8WvAAAADklEQVR42mNkAAJGEAEAABkAA50Y5HIAAAAASUVORK5CYII=";
                            })
                            .map((src, index) => (
                              <img
                                key={index}
                                className="raceboardImgGov2024"
                                src={src}
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                  marginRight: "0.5rem",
                                  marginBottom:
                                    window.innerWidth > 3000
                                      ? "2.5rem"
                                      : "-0.5rem"
                                }}
                                alt=""
                              />
                            ))}
                        </div>
                      </Box>
                      <Box
                        sx={{
                          marginLeft: "6px",
                          fontSize: "12px",
                          color: "#777",
                          whiteSpace: "nowrap"
                        }}
                      >
                        &nbsp;
                      </Box>
                      <Box
                        className={"raceCandBox"}
                        sx={{
                          paddingTop: "9px",
                          paddingLeft: "5px",
                          borderLeft:
                            "solid " +
                            partyBorderLeft +
                            "px " +
                            (PartyColors[app.raceboard.p1CoalitionParties]
                              ? PartyColors[app.raceboard.p1CoalitionParties].high
                              : "#111111")
                        }}
                      >
                        <Box
                          className={"raceName"}
                          sx={{
                            position: "relative",
                            height: "3rem",
                            fontSize: "1.8rem",
                            lineHeight: "1.7rem",
                            width: "98%"
                          }}
                        >
                          <span style={{ position: "absolute", bottom: 3 }}>
                            {Names[app.raceboard.p1Name]
                              ? Names[app.raceboard.p1Name].toUpperCase()
                              : app.raceboard.p1Name.toUpperCase()}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "right",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        flex: 1
                      }}
                    >
                      <Text
                        className={"percentNum"}
                        sx={{
                          fontFamily: "FranklinGothicMedium",
                          fontSize: "4.5rem",
                          marginTop: "0rem",
                          lineHeight: "1",
                          marginRight: "14%"
                        }}
                      >
                        {(app.raceboard.p1Percent &&
                          app.raceboard.p1Percent < 10) ||
                        app.raceboard.p1Percent === 0
                          ? "\u00A0\u00A0"
                          : ""}
                        {app.raceboard.p1Percent ||
                        app.raceboard.p1Percent === 0
                          ? app.raceboard.p1Percent
                          : ""}
                        <span className={"percent"}>
                          {app.raceboard.p1Percent ||
                          app.raceboard.p1Percent === 0
                            ? "%"
                            : ""}
                        </span>
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "6%",
                    marginRight: "0%"
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "96%"
                  }}
                >
                  <Box
                    className={"partySubBox"}
                    sx={{
                      display: "flex",
                      borderBottom:
                        "solid " +
                        partyBorderBottom +
                        "px " +
                        PartyColors[app.raceboard.p1CoalitionParties]?.high,
                      marginRight: "4%",
                      paddingBottom: "0rem",
                      marginBottom: "0.5rem"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "top",
                        width: "19%"
                      }}
                    >
                      <img
                        src={
                          SenateImgs[
                            `${removeAccents(
                              app.raceboard.state
                                .toUpperCase()
                                .replaceAll(" ", "_")
                            )}_SECOND`
                          ] || SenateImgs.Default
                        }
                        style={{ width: "100%" }}
                        alt=""
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "0.5%",
                        width: "50%",
                        marginTop: "0px"
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <div
                          className={
                            app.raceboard.p2Party.length > 10
                              ? "racePartyName"
                              : "racePartyName"
                          }
                          style={{
                            //backgroundColor: PartyColors[app.raceboard.p2Party]?.high,
                            color:
                              PartyColors[app.raceboard.p1CoalitionParties]?.boardText ||
                              PartyColors[app.raceboard.p1CoalitionParties]?.high,
                            display: "inline-block",
                            width: "auto",
                            padding: "2px 0px 0px 6px",
                            marginLeft: "0px",
                            //whiteSpace: "nowrap",
                            overflowWrap: "break-word",
                            fontSize:
                              app.raceboard.p1CoalitionParties.length > 10
                                ? "23px"
                                : "23px",
                            lineHeight:
                              app.raceboard.p1CoalitionParties.length > 10
                                ? "23px"
                                : "23px"
                          }}
                        >
                          {
                            (app.raceboard.p2Party?.includes("_")
                              ? app.raceboard.p2Party.split("_")
                              : app.raceboard.p2Party.split("-")
                            ).map((img) => {
                              if (img === "PES" && year === "2015")
                                img = "PES2015";
                              return CandidateImgs[img] &&
                                img !== "Independiente"
                                ? CandidateImgs[img]
                                : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAQAAADYv8WvAAAADklEQVR42mNkAAJGEAEAABkAA50Y5HIAAAAASUVORK5CYII=";
                            })
                            .map((src, index) => (
                              <img
                                key={index}
                                className="raceboardImgGov2024"
                                src={src}
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                  marginRight: "0.5rem",
                                  marginBottom:
                                    window.innerWidth > 3000
                                      ? "2.5rem"
                                      : "-0.5rem"
                                }}
                                alt=""
                              />
                            ))}
                        </div>
                      </Box>
                      <Box
                        className={"raceTitle"}
                        sx={{
                          marginTop: "0rem",
                          marginBottom: "10px",
                          marginLeft: "6px",
                          fontSize: "12px",
                          color: "#777",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {/*Titles[app.raceboard.p2Name + year]
                        ? Titles[app.raceboard.p2Name + year]
                        : "Candidatura " + app.raceboard.p2Party*/}
                        &nbsp;
                      </Box>
                      <Box
                        className={"raceCandBox"}
                        sx={{
                          paddingTop: "9px",
                          paddingLeft: "5px",
                          borderLeft:
                            "solid " +
                            partyBorderLeft +
                            "px " +
                            (PartyColors[app.raceboard.p1CoalitionParties]
                              ? PartyColors[app.raceboard.p1CoalitionParties].high
                              : "#111111")
                        }}
                      >
                        <Box
                          className={"raceName"}
                          sx={{
                            position: "relative",
                            height: "3rem",
                            fontSize: "1.8rem",
                            lineHeight: "1.7rem",
                            width: "98%"
                          }}
                        >
                          <span style={{ position: "absolute", bottom: 3 }}>
                            {Names[app.raceboard.p2Name]
                              ? Names[app.raceboard.p2Name].toUpperCase()
                              : app.raceboard.p2Name.toUpperCase()}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "right",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        flex: 1
                      }}
                    >
                      <Text
                        className={"voteNum"}
                        style={{
                          fontFamily: "FranklinGothicHeavy",
                          fontWeight: "normal"
                        }}
                      >
                        {(app.raceboard.p1Vote ||
                          app.raceboard.p1Vote === 0) && (
                          <FormattedMessage
                            id={"Votes2"}
                            values={{
                              votes: app.raceboard.p1Vote.toLocaleString("en")
                            }}
                          />
                        )}
                      </Text>
                      <Text
                        className={"percentNum"}
                        sx={{
                          fontFamily: "FranklinGothicMedium",
                          fontSize: "4.5rem",
                          marginTop: "0rem",
                          lineHeight: "1"
                        }}
                      ></Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : undefined}

        {app.raceboard.p3Name ? (
          <Box
            className={"partyBox"}
            sx={{
              display: "flex",
              flex: 1,
              marginTop: "2px",
              backgroundColor: "rgba(255,255,255,0.8)",
              paddingTop: "0.6rem",
              marginBottom: "0.5rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "6%",
                marginRight: "0%"
              }}
            ></Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "94%" }}
            >
              <Box
                className={"partySubBox"}
                sx={{
                  display: "flex",
                  borderBottom:
                    "solid " +
                    partyBorderBottom +
                    "px " +
                    PartyColors[app.raceboard.p2CoalitionParties]?.high,
                  marginRight: "4%",
                  paddingBottom: "0rem",
                  marginBottom: "0.5rem"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "top",
                    width: "19%"
                  }}
                >
                  <img
                    src={
                      SenateImgs[
                        `${removeAccents(
                          app.raceboard.state.toUpperCase().replaceAll(" ", "_")
                        )}_THIRD`
                      ] || SenateImgs.Default
                    }
                    style={{ width: "100%" }}
                    alt=""
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "0.5%",
                    width: "50%",
                    marginTop: "0px"
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <div
                      className={
                        app.raceboard.p3Party.length > 10
                          ? "racePartyName"
                          : "racePartyName"
                      }
                      style={{
                        //backgroundColor: PartyColors[app.raceboard.p3Party]?.high,
                        color:
                          PartyColors[app.raceboard.p2CoalitionParties]?.boardText ||
                          PartyColors[app.raceboard.p2CoalitionParties]?.high,
                        display: "inline-block",
                        padding: "2px 0px 0px 6px",
                        marginLeft: "0px",
                        //whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        fontSize:
                          app.raceboard.p3Party.length > 10 ? "23px" : "23px",
                        lineHeight:
                          app.raceboard.p3Party.length > 10 ? "23px" : "23px",
                        width: window.innerWidth > 3000 ? "51rem" : "25.5rem"
                      }}
                    >
                      <div style={{marginBottom: "0.2rem"}}>{app.raceboard.p2CoalitionParties.toUpperCase()}</div>
                      {(app.raceboard.p3Party.includes("_")
                        ? app.raceboard.p3Party.split("_")
                        : app.raceboard.p3Party.split("-")
                      )
                        .map((img) => {
                          if (img === "PES" && year === "2015") img = "PES2015";
                          return CandidateImgs[img] && img !== "Independiente"
                            ? CandidateImgs[img]
                            : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAQAAADYv8WvAAAADklEQVR42mNkAAJGEAEAABkAA50Y5HIAAAAASUVORK5CYII=";
                        })
                        .map((src, index) => (
                          <img
                            key={index}
                            className="raceboardImgGov2024"
                            src={src}
                            style={{
                              width: "2rem",
                              height: "2rem",
                              marginRight: "0.5rem",
                              marginBottom:
                                window.innerWidth > 3000 ? "2.5rem" : "-0.5rem"
                            }}
                            alt=""
                          />
                        ))}{" "}
                    </div>
                  </Box>
                  <Box
                    className={"raceTitle"}
                    sx={{
                      marginLeft: "6px",
                      fontSize: "12px",
                      color: "#777",
                      whiteSpace: "nowrap"
                    }}
                  >
                  </Box>
                  <Box
                    className={"raceCandBox"}
                    sx={{
                      paddingTop: "9px",
                      paddingLeft: "5px",
                      borderLeft:
                        "solid " +
                        partyBorderLeft +
                        "px " +
                        (PartyColors[app.raceboard.p2CoalitionParties]
                          ? PartyColors[app.raceboard.p2CoalitionParties].high
                          : "#111111")
                    }}
                  >
                    <Box
                      className={"raceName"}
                      sx={{
                        position: "relative",
                        height: "3rem",
                        fontSize: "1.8rem",
                        lineHeight: "1.7rem",
                        width: "98%"
                      }}
                    >
                      <span style={{ position: "absolute", bottom: 3 }}>
                        {Names[app.raceboard.p3Name]
                          ? Names[app.raceboard.p3Name].toUpperCase()
                          : app.raceboard.p3Name.toUpperCase()}
                      </span>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    flex: 1
                  }}
                >
                  <Text
                    className={"percentNum"}
                    sx={{
                      fontFamily: "FranklinGothicMedium",
                      fontSize: "4.5rem",
                      marginTop: "0rem",
                      lineHeight: "1"
                    }}
                  >
                    {(app.raceboard.p2Percent &&
                      app.raceboard.p2Percent < 10) ||
                    app.raceboard.p2Percent === 0
                      ? "\u00A0\u00A0"
                      : ""}
                    {app.raceboard.p2Percent || app.raceboard.p2Percent === 0
                      ? app.raceboard.p2Percent
                      : ""}
                    <span className={"percent"}>
                      {app.raceboard.p2Percent || app.raceboard.p2Percent === 0
                        ? "%"
                        : ""}
                    </span>
                  </Text>
                  <Text
                    className={"voteNum"}
                    style={{ color: "#555", fontWeight: "normal" }}
                  >
                    {(app.raceboard.p2Vote || app.raceboard.p2Vote === 0) && (
                      <FormattedMessage
                        id={"Votes2"}
                        values={{
                          votes: app.raceboard.p2Vote.toLocaleString("en")
                        }}
                      />
                    )}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : undefined}
      </Box>
    </Dialog>
  );
};

export default RaceboardSenate;
