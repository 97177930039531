export const StatesAccents = {
  AGUASCALIENTES: "AGUASCALIENTES",
  "BAJA CALIFORNIA": "BAJA CALIFORNIA",
  "BAJA CALIFORNIA SUR": "BAJA CALIFORNIA SUR",
  CAMPECHE: "CAMPECHE",
  CHIAPAS: "CHIAPAS",
  CHIHUAHUA: "CHIHUAHUA",
  COAHUILA: "COAHUILA",
  COLIMA: "COLIMA",
  "CIUDAD DE MEXICO": "CIUDAD DE MÉXICO",
  "DISTRITO FEDERAL": "DISTRITO FEDERAL",
  DURANGO: "DURANGO",
  GUANAJUATO: "GUANAJUATO",
  GUERRERO: "GUERRERO",
  HIDALGO: "HIDALGO",
  JALISCO: "JALISCO",
  MEXICO: "MÉXICO",
  MICHOACAN: "MICHOACÁN",
  MORELOS: "MORELOS",
  NAYARIT: "NAYARIT",
  "NUEVO LEON": "NUEVO LEÓN",
  OAXACA: "OAXACA",
  PUEBLA: "PUEBLA",
  QUERETARO: "QUERÉTARO",
  "QUINTANA ROO": "QUINTANA ROO",
  "SAN LUIS POTOSI": "SAN LUIS POTOSÍ",
  SINALOA: "SINALOA",
  SONORA: "SONORA",
  TABASCO: "TABASCO",
  TAMAULIPAS: "TAMAULIPAS",
  TLAXCALA: "TLAXCALA",
  VERACRUZ: "VERACRUZ",
  YUCATAN: "YUCATÁN",
  ZACATECAS: "ZACATECAS",
  "IXTLAHUACAN DE LOS MEMBRILLOS": "IXTLAHUACÁN DE LOS MEMBRILLOS",
  JUANACATLAN: "JUANACATLÁN",
  TONALA: "TONALÁ",
  "TLAJOMULCO DE ZUNIGA": "TLAJOMULCO DE ZÚÑIGA",
  "ACATLAN DE JUAREZ": "ACATLÁN DE JUÁREZ"
};

export const States = [
  "AGUASCALIENTES",
  "BAJA CALIFORNIA",
  "BAJA CALIFORNIA SUR",
  "CAMPECHE",
  "CHIAPAS",
  "CHIHUAHUA",
  "COAHUILA",
  "COLIMA",
  "DISTRITO FEDERAL",
  "DURANGO",
  "GUANAJUATO",
  "GUERRERO",
  "HIDALGO",
  "JALISCO",
  "MEXICO",
  "MICHOACAN",
  "MORELOS",
  "NAYARIT",
  "NUEVO LEON",
  "OAXACA",
  "PUEBLA",
  "QUERETARO",
  "QUINTANA ROO",
  "SAN LUIS POTOSI",
  "SINALOA",
  "SONORA",
  "TABASCO",
  "TAMAULIPAS",
  "TLAXCALA",
  "VERACRUZ",
  "YUCATAN",
  "ZACATECAS"
];
