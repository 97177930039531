import { useEffect } from "react";
import { Dialog, Box } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import Papa from "papaparse";
import { FormattedMessage } from "react-intl";

import RaceData from "../../../mapbox/racechart";

const RaceboardNationalWomen = ({ open, onClose, app }) => {
  const dispatch = useDispatch();
  const year = useSelector((state) => state.year.value);
  const raceType = useSelector((state) => state.raceType.value);

  // const [turnout, setTurnout] = useState(0);
  // const [votes, setVotes] = useState(0);

  useEffect(() => {
    // setTurnout(0);
    // setVotes(0);

    if (RaceData[`${raceType}${year}`]) {
      Papa.parse(RaceData[`${raceType}${year}`], {
        download: true,
        complete: function (input) {
          let records = input.data;
          if (records[0]) {
            // setTurnout(records[0]?.TURNOUT || 0);
            // setVotes(records[0]?.VALID_VOTES || 0);
          }
        },
        header: true
      });
    }
  }, [year, raceType, dispatch]);

  return (
    <Dialog
      id={"raceboardNW"}
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={0}
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: "0px !important",
        fontSize: "16px",
        color: "#111111",
        top: window.innerWidth > 3000 ? 236 : 89,
        width: "auto",
        background: "transparent"
      }}
    >
      <Box
        sx={{
          width: window.innerWidth > 3000 ? 960 : 480,
          marginRight: window.innerWidth > 3000 ? 16 : 4
        }}
      >
        <Box className={"raceboardNWomen"}>
          <Box
            className={"raceboardNTTitle"}
            sx={{
              fontSize: "39px",
              textAlign: "center",
              lineHeight: "96px",
              height: "96px",
              width: "100%"
            }}
          >
            <FormattedMessage id={"States"} />
          </Box>
        </Box>
        <Box className={"raceboardNWomen"}>
          <Box
            className={"partyBoxNW"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "4px",
              backgroundColor: "rgba(255,255,255,0.8)",
              width: "100%"
            }}
          >
            <Box
              className={"raceboardNWomenRow"}
              sx={{
                width: "100%",
                height: "80px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <Box
                className={"raceboardNWomenLabel"}
                sx={{
                  flex: 3,
                  fontSize: "39px",
                  lineHeight: "39px",
                  fontWeight: "bold"
                }}
              >
                <FormattedMessage
                  id={"Governors"}
                  values={{
                    total: 0
                  }}
                />
              </Box>
              <Box
                className={"raceboardNWomenWins"}
                sx={{
                  flex: 1,
                  backgroundColor: "#E5E4E3",
                  fontSize: "80px",
                  lineHeight: "80px",
                  borderRadius: "4px"
                }}
              >
                18
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={"raceboardNWomen"}>
          <Box
            className={"partyBoxNW"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "4px",
              backgroundColor: "rgba(255,255,255,0.8)",
              width: "100%"
            }}
          >
            <Box
              className={"raceboardNWomenRow"}
              sx={{
                width: "100%",
                height: "80px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <Box
                className={"raceboardNWomenLabel"}
                sx={{
                  flex: 3,
                  fontSize: "39px",
                  lineHeight: "39px",
                  fontWeight: "bold"
                }}
              >
                <FormattedMessage
                  id={"Governor"}
                  values={{
                    total: 1
                  }}
                />
              </Box>
              <Box
                className={"raceboardNWomenWins"}
                sx={{
                  flex: 1,
                  backgroundColor: "#C8B1C5",
                  fontSize: "80px",
                  lineHeight: "80px",
                  borderRadius: "4px"
                }}
              >
                10
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={"raceboardNWomen"}>
          <Box
            className={"partyBoxNW"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "4px",
              backgroundColor: "rgba(255,255,255,0.8)",
              width: "100%"
            }}
          >
            <Box
              className={"raceboardNWomenRow"}
              sx={{
                width: "100%",
                height: "80px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textAlign: "center"
              }}
            >
              <Box
                className={"raceboardNWomenLabel"}
                sx={{
                  flex: 3,
                  fontSize: "39px",
                  lineHeight: "39px",
                  fontWeight: "bold"
                }}
              >
                <FormattedMessage
                  id={"Governors"}
                  values={{
                    total: 2
                  }}
                />
              </Box>
              <Box
                className={"raceboardNWomenWins"}
                sx={{
                  flex: 1,
                  backgroundColor: "#A87EA8",
                  fontSize: "80px",
                  lineHeight: "80px",
                  borderRadius: "4px"
                }}
              >
                4
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RaceboardNationalWomen;
