import { useEffect, useState } from "react";
import { Dialog, Box, Text } from "@mantine/core";
import { useDispatch } from "react-redux";
import Papa from "papaparse";
import { FormattedMessage } from "react-intl";

import RaceData from "../../../mapbox/racechart";
import { PartyColors } from "../../../mapbox/colors";

const RaceboardNationalReg = ({ open, onClose, app }) => {
  const dispatch = useDispatch();
  const [reg, setReg] = useState(0);

  useEffect(() => {
    setReg(0);

    Papa.parse(RaceData["pres2024"], {
      download: true,
      complete: function (input) {
        let records = input.data;
        if (records[0]) {
          setReg(records[0]?.LISTA_NOMINAL || 0);
        }
      },
      header: true
    });
  }, [dispatch]);

  return (
    <Dialog
      id={"raceboardNT"}
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={0}
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: "0px !important",
        fontSize: "16px",
        color: "#111111",
        top: window.innerWidth > 3000 ? 236 : 89,
        width: "auto",
        background: "transparent"
      }}
    >
      <Box
        sx={{
          width: window.innerWidth > 3000 ? 960 : 480,
          marginRight: window.innerWidth > 3000 ? 16 : 4
        }}
      >
        <Box className={"raceboardTurnout"}>
          <Box
            className={"raceboardNTTitle"}
            sx={{
              fontSize: "39px",
              textAlign: "center",
              lineHeight: "96px",
              height: "96px"
            }}
          >
            <FormattedMessage id={"RegVotersTitle"} />
          </Box>
        </Box>
        <Box className={"raceboardTurnout"}>
          <Box
            className={"partyBoxNT"}
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              marginTop: "4px",
              backgroundColor: "rgba(255,255,255,0.8)"
            }}
          >
            <Box
              sx={{
                padding: window.innerWidth > 3000 ? 20 : 10,
                width:
                  window.innerWidth > 3000
                    ? "calc(100% - 40px)"
                    : "calc(100% - 20px)",
                marginTop: window.innerWidth > 3000 ? "2rem" : "1rem"
              }}
            >
              <Text
                className={"regNum"}
                sx={{ borderBottom: `solid 10px ${PartyColors.INE.high}` }}
              >
                <FormattedMessage
                  id={"RegVoters"}
                  values={{
                    voters: parseInt(reg).toLocaleString("en") || 0
                  }}
                />
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      {app?.raceboard?.title ? (
        <Box sx={{ width: window.innerWidth > 3000 ? 1040 : 520 }}>
          <Box className={"raceboardTurnout"}>
            <Box
              className={"raceboardNTSubtitle"}
              sx={{
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "28px",
                height: "28px"
              }}
            >
              {app.raceboard.subtitle1 || ""}
            </Box>
            <Box
              className={"raceboardNTTitleState"}
              sx={{
                fontSize: "39px",
                textAlign: "center",
                lineHeight: "40px",
                height: "40px"
              }}
            >
              {app.raceboard.title || ""}
            </Box>
            <Box
              className={"raceboardNTSubtitle"}
              sx={{
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "28px",
                height: "28px"
              }}
            >
              {app.raceboard.subtitle2 || ""}
            </Box>
          </Box>
          <Box className={"raceboardTurnout"}>
            <Box
              className={"partyBoxNT"}
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                marginTop: "4px",
                backgroundColor: "rgba(255,255,255,0.8)"
              }}
            >
              <Box
                sx={{
                  padding: window.innerWidth > 3000 ? 20 : 10,
                  width:
                    window.innerWidth > 3000
                      ? "calc(100% - 40px)"
                      : "calc(100% - 20px)",
                  marginTop: window.innerWidth > 3000 ? "2rem" : "1rem"
                }}
              >
                <Text
                  className={"regNum"}
                  sx={{ borderBottom: `solid 10px ${PartyColors.INE.high}` }}
                >
                  <FormattedMessage
                    id={"RegVoters"}
                    values={{
                      voters:
                        parseInt(app.raceboard.reg).toLocaleString("en") || 0
                    }}
                  />
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : undefined}
    </Dialog>
  );
};

export default RaceboardNationalReg;
