import React from "react";
import { Dialog, Box, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import voca from "voca";
import removeAccents from "remove-accents";
import { FormattedMessage } from "react-intl";

import { PartyColors } from "../../../mapbox/colors";
import GovJalisco from "../../../mapbox/governorJalisco";
import GovCdmx from "../../../mapbox/governorCdmx";
import CandidateImgs from "../../../mapbox/candidates";
import { StatesAccents } from "../../../mapbox/states";

const RaceboardGovState = ({ open, onClose, app }) => {
  const year = useSelector((state) => state.year.value);

  const { innerWidth } = window;
  const largeScreen = innerWidth > 3000;
  const partyBorder = { left: 0, bottom: largeScreen ? 20 : 10 };

  const runAfterRender = () => {
    const image = document.getElementById("raceboardImg1N");
    const checkBox = document.getElementById("raceboardCheckBoxN");
    if (image && checkBox) {
      checkBox.style.height = `${image.height + (largeScreen ? 20 : 9)}px`;
    }
  };

  const resultSelector = (field) =>
    app.showNationalResult ? app.raceboard[field + "N"] : app.raceboard[field];

  const generateCandidateBox = (index) => {
    const party = resultSelector(`p${index}Party`) || "";
    const name = resultSelector(`p${index}Name`) || "";
    const vote = resultSelector(`p${index}Vote`) || 0;
    const percent = resultSelector(`p${index}Percent`) || 0;

    if (name) {
      return (
        <Box className="partyBox" sx={partyBoxStyle} key={`${index}`}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "94%" }}>
            <Box
              className="partySubBox"
              sx={{
                ...partySubBoxStyle,
                borderBottom: `solid ${partyBorder.bottom}px ${PartyColors[party]?.high}`
              }}
            >
              <Box sx={{ width: "19%", marginBottom: "-4px" }}>
                <img
                  id={`raceboardImg${index}N`}
                  src={
                    year === "jal"
                      ? GovJalisco[
                          removeAccents(name.replaceAll(/\s/g, "")).toUpperCase()
                        ]
                      : GovCdmx[
                          removeAccents(name.replaceAll(/\s/g, "")).toUpperCase()
                        ] 
                        || CandidateImgs.Default 
                  }
                  style={{ width: "100%" }}
                  alt=""
                />
              </Box>
              <Box sx={candidateInfoStyle}>
                <div
                  className="racePartyName"
                  style={{
                    color:
                      PartyColors[party]?.boardText || PartyColors[party]?.high,
                    fontSize: "23px",
                    lineHeight: "23px"
                  }}
                >
                  {party === "NA" ? "PANAL" : party}
                </div>
                <Box className="raceName" sx={raceNameStyle}>
                  <span>{name.toUpperCase()}</span>
                </Box>
              </Box>
              <Box sx={percentBoxStyle}>
                <Text className="percentNum" sx={percentNumStyle}>
                  {percent < 10 ? "\u00A0\u00A0" : ""}
                  {percent}%
                </Text>
                <Text className="voteNum" style={voteNumStyle}>
                  <FormattedMessage
                    id="Votes2"
                    values={{ votes: parseInt(vote).toLocaleString("en") }}
                  />
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    } else {
      return undefined;
    }    
  };

  return (
    <Dialog
      id={app.showNationalResult ? "raceboardGSN" : "raceboardGS"}
      className="raceboardP"
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={0}
      sx={dialogStyle(PartyColors[resultSelector("p1Party")])}
      onLoad={runAfterRender}
    >
      <Box className="raceboardTitleBox" sx={titleBoxStyle}>
        <Box className="raceboardTitle" sx={titleStyle}>
          {app.showNationalResult ? (
            <FormattedMessage id={year === "jal" ? "JalTitle" : "CdmxTitle"} />
          ) : (
            voca.titleCase(
              StatesAccents[app.raceboard.mun.toUpperCase()] ||
                app.raceboard.mun.toUpperCase()
            )
          )}
        </Box>
        <Box className="raceboardSubtitle" sx={subtitleStyle}>
          {!app.showNationalResult
            ? voca.titleCase(app.raceboard.state)
            : year === "cdmx24" ? "2024" : "2018"}
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}
      >
        {Array.from({ length: 4 }, (_, i) => generateCandidateBox(i + 1))}
      </Box>
    </Dialog>
  );
};

export default RaceboardGovState;

// Styles
const dialogStyle = (partyColor) => ({
  width: "530px",
  borderLeft: `solid 0px ${partyColor?.high}`,
  padding: "0px !important",
  fontSize: "16px",
  color: "#111111",
  top: 89
});

const titleBoxStyle = {
  marginLeft: "5%",
  fontWeight: "bold",
  paddingBottom: "4px",
  marginBottom: "10px"
};

const titleStyle = {
  fontSize: "39px",
  lineHeight: "39px",
  paddingBottom: "3px",
  paddingTop: "9px",
  textAlign: "center"
};

const subtitleStyle = {
  fontSize: "25px",
  lineHeight: "25px",
  paddingBottom: "3px",
  paddingTop: "0px",
  fontWeight: "normal",
  textAlign: "center"
};

const partyBoxStyle = {
  display: "flex",
  flex: 1,
  marginTop: "2px",
  backgroundColor: "rgba(255,255,255,0.8)",
  paddingTop: "0.6rem",
  marginBottom: "0.5rem",
  justifyContent: "center"
};

const partySubBoxStyle = {
  display: "flex",
  paddingBottom: "0rem",
  marginBottom: "0.5rem"
};

const candidateInfoStyle = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "0.5%",
  width: "50%",
  marginTop: "0px"
};

const raceNameStyle = {
  position: "relative",
  height: "3rem",
  fontSize: "1.8rem",
  lineHeight: "1.7rem",
  width: "98%"
};

const percentBoxStyle = {
  display: "flex",
  flexDirection: "column",
  textAlign: "right",
  fontWeight: "bold",
  whiteSpace: "nowrap",
  flex: 1
};

const percentNumStyle = {
  fontFamily: "FranklinGothicMedium",
  fontSize: "4.5rem",
  marginTop: "0rem",
  lineHeight: "1"
};

const voteNumStyle = {
  fontFamily: "FranklinGothicHeavy",
  fontWeight: "normal",
  color: "#555"
};
