import GOV12018 from "../assets/img/governor_winners/2018/1.png";
import GOV22018 from "../assets/img/governor_winners/2018/2.png";
import GOV32018 from "../assets/img/governor_winners/2018/3.png";
import GOV42018 from "../assets/img/governor_winners/2018/4.png";
import GOV52018 from "../assets/img/governor_winners/2018/5.png";
import GOV62018 from "../assets/img/governor_winners/2018/6.png";
import GOV72018 from "../assets/img/governor_winners/2018/7.png";
import GOV82018 from "../assets/img/governor_winners/2018/8.png";
import GOV92018 from "../assets/img/governor_winners/2018/9.png";
import GOV102018 from "../assets/img/governor_winners/2018/10.png";
import GOV112018 from "../assets/img/governor_winners/2018/11.png";
import GOV122018 from "../assets/img/governor_winners/2018/12.png";
import GOV132018 from "../assets/img/governor_winners/2018/13.png";
import GOV142018 from "../assets/img/governor_winners/2018/14.png";
import GOV152018 from "../assets/img/governor_winners/2018/15.png";
import GOV162018 from "../assets/img/governor_winners/2018/16.png";
import GOV172018 from "../assets/img/governor_winners/2018/17.png";
import GOV182018 from "../assets/img/governor_winners/2018/18.png";
import GOV192018 from "../assets/img/governor_winners/2018/19.png";
import GOV202018 from "../assets/img/governor_winners/2018/20.png";
import GOV212018 from "../assets/img/governor_winners/2018/21.png";
import GOV222018 from "../assets/img/governor_winners/2018/22.png";
import GOV232018 from "../assets/img/governor_winners/2018/23.png";
import GOV242018 from "../assets/img/governor_winners/2018/24.png";
import GOV252018 from "../assets/img/governor_winners/2018/25.png";
import GOV262018 from "../assets/img/governor_winners/2018/26.png";
import GOV272018 from "../assets/img/governor_winners/2018/27.png";
import GOV282018 from "../assets/img/governor_winners/2018/28.png";
import GOV292018 from "../assets/img/governor_winners/2018/29.png";
import GOV302018 from "../assets/img/governor_winners/2018/30.png";
import GOV312018 from "../assets/img/governor_winners/2018/31.png";
import GOV322018 from "../assets/img/governor_winners/2018/32.png";

import GOV12023 from "../assets/img/governor_winners/2023/1.png";
import GOV22023 from "../assets/img/governor_winners/2023/2.png";
import GOV32023 from "../assets/img/governor_winners/2023/3.png";
import GOV42023 from "../assets/img/governor_winners/2023/4.png";
import GOV52023 from "../assets/img/governor_winners/2023/5.png";
import GOV62023 from "../assets/img/governor_winners/2023/6.png";
import GOV72023 from "../assets/img/governor_winners/2023/7.png";
import GOV82023 from "../assets/img/governor_winners/2023/8.png";
import GOV92023 from "../assets/img/governor_winners/2023/9.png";
import GOV102023 from "../assets/img/governor_winners/2023/10.png";
import GOV112023 from "../assets/img/governor_winners/2023/11.png";
import GOV122023 from "../assets/img/governor_winners/2023/12.png";
import GOV132023 from "../assets/img/governor_winners/2023/13.png";
import GOV142023 from "../assets/img/governor_winners/2023/14.png";
import GOV152023 from "../assets/img/governor_winners/2023/15.png";
import GOV162023 from "../assets/img/governor_winners/2023/16.png";
import GOV172023 from "../assets/img/governor_winners/2023/17.png";
import GOV182023 from "../assets/img/governor_winners/2023/18.png";
import GOV192023 from "../assets/img/governor_winners/2023/19.png";
import GOV202023 from "../assets/img/governor_winners/2023/20.png";
import GOV212023 from "../assets/img/governor_winners/2023/21.png";
import GOV222023 from "../assets/img/governor_winners/2023/22.png";
import GOV232023 from "../assets/img/governor_winners/2023/23.png";
import GOV242023 from "../assets/img/governor_winners/2023/24.png";
import GOV252023 from "../assets/img/governor_winners/2023/25.png";
import GOV262023 from "../assets/img/governor_winners/2023/26.png";
import GOV272023 from "../assets/img/governor_winners/2023/27.png";
import GOV282023 from "../assets/img/governor_winners/2023/28.png";
import GOV292023 from "../assets/img/governor_winners/2023/29.png";
import GOV302023 from "../assets/img/governor_winners/2023/30.png";
import GOV312023 from "../assets/img/governor_winners/2023/31.png";
import GOV322023 from "../assets/img/governor_winners/2023/32.png";

const GovWinners = {
  GOV12018,
  GOV22018,
  GOV32018,
  GOV42018,
  GOV52018,
  GOV62018,
  GOV72018,
  GOV82018,
  GOV92018,
  GOV102018,
  GOV112018,
  GOV122018,
  GOV132018,
  GOV142018,
  GOV152018,
  GOV162018,
  GOV172018,
  GOV182018,
  GOV192018,
  GOV202018,
  GOV212018,
  GOV222018,
  GOV232018,
  GOV242018,
  GOV252018,
  GOV262018,
  GOV272018,
  GOV282018,
  GOV292018,
  GOV302018,
  GOV312018,
  GOV322018,
  
  GOV12023,
  GOV22023,
  GOV32023,
  GOV42023,
  GOV52023,
  GOV62023,
  GOV72023,
  GOV82023,
  GOV92023,
  GOV102023,
  GOV112023,
  GOV122023,
  GOV132023,
  GOV142023,
  GOV152023,
  GOV162023,
  GOV172023,
  GOV182023,
  GOV192023,
  GOV202023,
  GOV212023,
  GOV222023,
  GOV232023,
  GOV242023,
  GOV252023,
  GOV262023,
  GOV272023,
  GOV282023,
  GOV292023,
  GOV302023,
  GOV312023,
  GOV322023
};

export default GovWinners;
