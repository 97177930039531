import React, { useEffect, useCallback, useMemo } from "react";
import { Dialog, Box } from "@mantine/core";
import { useSelector } from "react-redux";
import voca from "voca";
import { useIntl } from "react-intl";
import removeAccents from "remove-accents";
import CandidateImgs from "../../../mapbox/candidates";
import CandidateImgs2024 from "../../../mapbox/candidatesGov2024";
import { Names } from "../../../mapbox/titles";
import { PartyColors } from "../../../mapbox/colors";
import { StatesAccents } from "../../../mapbox/states";

const Raceboard = ({ open, onClose, app }) => {
  const year = useSelector((state) => state.year.value);
  const intl = useIntl();

  const { partyBorderLeft, partyBorderBottom, raceNameLong } = useMemo(
    () => ({
      partyBorderLeft: 0,
      partyBorderBottom: window.innerWidth > 3000 ? 20 : 10,
      raceNameLong: window.innerWidth > 3000 ? "3.5rem !important" : "1.6rem"
    }),
    []
  );

  const sanitizeName = useCallback(
    (name) =>
      removeAccents(
        name
          ?.trim()
          ?.replaceAll(" ", "_")
          .replaceAll("“", "")
          .replaceAll("”", "") || ""
      ),
    []
  );

  const runAfterRender = useCallback(() => {
    const raceboardG = document.getElementById("raceboardG");
    if (raceboardG) {
      raceboardG.style.visibility = app.showNationalResult
        ? "hidden"
        : "visible";
    }
  }, [app.showNationalResult]);

  useEffect(() => {
    runAfterRender();
  }, [app.raceboard.state, runAfterRender]);

  const renderCandidateBox = useCallback(
    (candidateName, candidateParty, coalitionParties) => {
      if (!candidateName || candidateName === "0") return null;
      const imageName = sanitizeName(candidateName);
      const partyColor = PartyColors[candidateParty]?.high;
      const textColor = "#000";
      const coalitionImages = (coalitionParties || "")
        .split("-")
        .map((img) => {
          const logoName = sanitizeName(img);
          return CandidateImgs[logoName] && logoName !== "Independiente"
            ? CandidateImgs[logoName]
            : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAQAAADYv8WvAAAADklEQVR42mNkAAJGEAEAABkAA50Y5HIAAAAASUVORK5CYII="
          }
        );

      return (
        <Box
          className="partyBox"
          sx={{
            display: "flex",
            flex: 1,
            marginBottom: "0.5rem",
            marginTop: "2px",
            backgroundColor: "rgba(255,255,255,0.8)",
            justifyContent: "center",
            padding: "1.2rem",
            paddingBottom: "0.2rem"
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "94%" }}>
            <Box
              className="partySubBox"
              sx={{
                display: "flex",
                borderBottom: `solid ${partyBorderBottom}px ${partyColor}`,
                paddingBottom: "0rem",
                marginBottom: "0.5rem"
              }}
            >
              {CandidateImgs2024[imageName] && (
                <Box
                  sx={{
                    flexDirection: "column",
                    justifyContent: "top",
                    width: "20%",
                    display: "flex"
                  }}
                >
                  <img
                    src={CandidateImgs2024[imageName]}
                    style={{ width: window.innerWidth > 3000 ? "96%" : "97%" }}
                    alt=""
                  />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "0.5%",
                  width: "100%",
                  marginTop: "0px"
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <div
                    className="racePartyName"
                    style={{
                      display: "inline-block",
                      width: "auto",
                      padding: "2px 0px 0px 6px",
                      marginLeft: "0px",
                      overflowWrap: "break-word",
                      lineHeight: "23px"
                    }}
                  >
                    {coalitionImages.map((src, index) => (
                      <img
                        key={index}
                        className="raceboardImgGov2024"
                        src={src}
                        style={{
                          width: "2rem",
                          height: "2rem",
                          marginRight: "0.5rem"
                        }}
                        alt=""
                      />
                    ))}
                  </div>
                </Box>
                <Box
                  className="raceCandBox"
                  sx={{
                    paddingTop: "9px",
                    paddingLeft: "5px",
                    borderLeft: `solid ${partyBorderLeft}px ${partyColor}`
                  }}
                >
                  <Box
                    className="raceName"
                    sx={{
                      position: "relative",
                      height: "3rem",
                      fontSize:
                        candidateName.length > 27 ? raceNameLong : "1.8rem",
                      lineHeight: "1.7rem",
                      width: "98%",
                      color: textColor
                    }}
                  >
                    <span style={{ position: "absolute", bottom: 3 }}>
                      {Names[candidateName]
                        ? Names[candidateName].toUpperCase()
                        : candidateName.toUpperCase()}
                    </span>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    },
    [partyBorderLeft, partyBorderBottom, raceNameLong, sanitizeName]
  );

  return (
    <Dialog
      id="raceboardG"
      className="raceboard"
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={20}
      sx={{
        width: "500px",
        borderLeft: `solid 0px ${PartyColors[app.raceboard.p1Party]?.high}`,
        padding: "0px !important",
        fontSize: "16px",
        color: "#111111",
        top: 89,
        display:
          (app.raceboard.p1Name && app.raceboard.p1Name === "0") ||
          year === "cdmx" ||
          year === "cdmx24" ||
          year === "jal"
            ? "none"
            : "block"
      }}
      onLoad={runAfterRender}
    >
      {/* Title Box */}
      <Box
        className="raceboardTitleBox"
        sx={{
          marginLeft: "5%",
          fontWeight: "bold",
          paddingBottom: "4px",
          marginBottom: "10px"
        }}
      >
        <Box
          className="raceboardTitle"
          sx={{
            fontSize: "39px",
            lineHeight: "39px",
            paddingBottom: "3px",
            paddingTop: "9px",
            textAlign: "center"
          }}
        >
          {app.raceboard.layer === "municipals"
            ? app.raceboard.mun
            : app.raceboard.state === "México"
            ? intl.formatMessage({ id: "MexicoState" })
            : voca.titleCase(
                StatesAccents[app.raceboard.state.toUpperCase()] ||
                  app.raceboard.state.toUpperCase() ||
                  ""
              )}
        </Box>
        <Box
          className="raceboardSubtitle"
          sx={{
            fontSize: "25px",
            lineHeight: "25px",
            paddingBottom: "3px",
            paddingTop: "0px",
            fontWeight: "normal",
            textAlign: "center"
          }}
        >
          {app.raceboard.stateRaceYear}
        </Box>
      </Box>

      {/* Candidate Boxes */}
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}
      >
        {renderCandidateBox(
          app.raceboard.p1Name,
          app.raceboard.p1Party,
          app.raceboard.p1CoalitionParties
        )}
        {renderCandidateBox(
          app.raceboard.p2Name,
          app.raceboard.p2Party,
          app.raceboard.p2CoalitionParties
        )}
        {renderCandidateBox(
          app.raceboard.p3Name,
          app.raceboard.p3Party,
          app.raceboard.p3CoalitionParties
        )}
        {renderCandidateBox(
          app.raceboard.p4Name,
          app.raceboard.p4Party,
          app.raceboard.p4CoalitionParties
        )}
      </Box>
    </Dialog>
  );
};

export default Raceboard;
