import CIUDAD_DE_MEXICO_THIRD from '../assets/img/senate_winners/CDMX/CDMX_THIRD.png';
import CIUDAD_DE_MEXICO_SECOND from '../assets/img/senate_winners/CDMX/CDMX_SECOND.png';
import CIUDAD_DE_MEXICO_FIRST from '../assets/img/senate_winners/CDMX/CDMX_FIRST.png';
import TLAXCALA_SECOND from '../assets/img/senate_winners/Tlaxcala/TLAXCALA_SECOND.png';
import TLAXCALA_THIRD from '../assets/img/senate_winners/Tlaxcala/TLAXCALA_THIRD.png';
import TLAXCALA_FIRST from '../assets/img/senate_winners/Tlaxcala/TLAXCALA_FIRST.png';
import DURANGO_FIRST from '../assets/img/senate_winners/Durango/DURANGO_FIRST.png';
import DURANGO_THIRD from '../assets/img/senate_winners/Durango/DURANGO_THIRD.png';
import DURANGO_SECOND from '../assets/img/senate_winners/Durango/DURANGO_SECOND.png';
import SINALOA_THIRD from '../assets/img/senate_winners/Sinaloa/SINALOA_THIRD.png';
import SINALOA_SECOND from '../assets/img/senate_winners/Sinaloa/SINALOA_SECOND.png';
import SINALOA_FIRST from '../assets/img/senate_winners/Sinaloa/SINALOA_FIRST.png';
import CAMPECHE_THIRD from '../assets/img/senate_winners/Campeche/CAMPECHE_THIRD.png';
import CAMPECHE_FIRST from '../assets/img/senate_winners/Campeche/CAMPECHE_FIRST.png';
import CAMPECHE_SECOND from '../assets/img/senate_winners/Campeche/CAMPECHE_SECOND.png';
import NUEVO_LEON_SECOND from '../assets/img/senate_winners/Nuevo Leon/NUEVO_LEON_SECOND.png';
import NUEVO_LEON_THIRD from '../assets/img/senate_winners/Nuevo Leon/NUEVO_LEON_THIRD.png';
import NUEVO_LEON_FIRST from '../assets/img/senate_winners/Nuevo Leon/NUEVO_LEON_FIRST.png';
import MEXICO_SECOND from '../assets/img/senate_winners/Mexico/MEXICO_SECOND.png';
import MEXICO_THIRD from '../assets/img/senate_winners/Mexico/MEXICO_THIRD.png';
import MEXICO_FIRST from '../assets/img/senate_winners/Mexico/MEXICO_FIRST.png';
import CHIAPAS_SECOND from '../assets/img/senate_winners/Chiapas/CHIAPAS_SECOND.png';
import CHIAPAS_FIRST from '../assets/img/senate_winners/Chiapas/CHIAPAS_FIRST.png';
import CHIAPAS_THIRD from '../assets/img/senate_winners/Chiapas/CHIAPAS_THIRD.png';
import TABASCO_THIRD from '../assets/img/senate_winners/Tabasco/TABASCO_THIRD.png';
import TABASCO_SECOND from '../assets/img/senate_winners/Tabasco/TABASCO_SECOND.png';
import TABASCO_FIRST from '../assets/img/senate_winners/Tabasco/TABASCO_FIRST.png';
import QUERETARO_THIRD from '../assets/img/senate_winners/Queretaro/QUERETARO_THIRD.png';
import QUERETARO_SECOND from '../assets/img/senate_winners/Queretaro/QUERETARO_SECOND.png';
import QUERETARO_FIRST from '../assets/img/senate_winners/Queretaro/QUERETARO_FIRST.png';
import QUINTANA_ROO_THIRD from '../assets/img/senate_winners/Quintana Roo/QUINTANA_ROO_THIRD.png';
import QUINTANA_ROO_FIRST from '../assets/img/senate_winners/Quintana Roo/QUINTANA_ROO_FIRST.png';
import QUINTANA_ROO_SECOND from '../assets/img/senate_winners/Quintana Roo/QUINTANA_ROO_SECOND.png';
import YUCATAN_THIRD from '../assets/img/senate_winners/Yucatan/YUCATAN_THIRD.png';
import YUCATAN_FIRST from '../assets/img/senate_winners/Yucatan/YUCATAN_FIRST.png';
import YUCATAN_SECOND from '../assets/img/senate_winners/Yucatan/YUCATAN_SECOND.png';
import TAMAULIPAS_FIRST from '../assets/img/senate_winners/Tamaulipas/TAMAULIPAS_FIRST.png';
import TAMAULIPAS_THIRD from '../assets/img/senate_winners/Tamaulipas/TAMAULIPAS_THIRD.png';
import TAMAULIPAS_SECOND from '../assets/img/senate_winners/Tamaulipas/TAMAULIPAS_SECOND.png';
import MORELOS_SECOND from '../assets/img/senate_winners/Morelos/MORELOS_SECOND.png';
import MORELOS_THIRD from '../assets/img/senate_winners/Morelos/MORELOS_THIRD.png';
import MORELOS_FIRST from '../assets/img/senate_winners/Morelos/MORELOS_FIRST.png';
import JALISCO_SECOND from '../assets/img/senate_winners/Jalisco/JALISCO_SECOND.png';
import JALISCO_THIRD from '../assets/img/senate_winners/Jalisco/JALISCO_THIRD.png';
import JALISCO_FIRST from '../assets/img/senate_winners/Jalisco/JALISCO_FIRST.png';
import NAYARIT_THIRD from '../assets/img/senate_winners/Nayarit/NAYARIT_THIRD.png';
import NAYARIT_SECOND from '../assets/img/senate_winners/Nayarit/NAYARIT_SECOND.png';
import NAYARIT_FIRST from '../assets/img/senate_winners/Nayarit/NAYARIT_FIRST.png';
import CHIHUAHUA_FIRST from '../assets/img/senate_winners/Chihuahua/CHIHUAHUA_FIRST.png';
import CHIHUAHUA_SECOND from '../assets/img/senate_winners/Chihuahua/CHIHUAHUA_SECOND.png';
import CHIHUAHUA_THIRD from '../assets/img/senate_winners/Chihuahua/CHIHUAHUA_THIRD.png';
import SAN_LUIS_POTOSI_FIRST from '../assets/img/senate_winners/San Luis Potosi/SAN_LUIS_POTOSI_FIRST.png';
import SAN_LUIS_POTOSI_THIRD from '../assets/img/senate_winners/San Luis Potosi/SAN_LUIS_POTOSI_THIRD.png';
import SAN_LUIS_POTOSI_SECOND from '../assets/img/senate_winners/San Luis Potosi/SAN_LUIS_POTOSI_SECOND.png';
import AGUASCALIENTES_THIRD from '../assets/img/senate_winners/Aguascalientes/AGUASCALIENTES_THIRD.png';
import AGUASCALIENTES_SECOND from '../assets/img/senate_winners/Aguascalientes/AGUASCALIENTES_SECOND.png';
import AGUASCALIENTES_FIRST from '../assets/img/senate_winners/Aguascalientes/AGUASCALIENTES_FIRST.png';
import COLIMA_SECOND from '../assets/img/senate_winners/Colima/COLIMA_SECOND.png';
import COLIMA_FIRST from '../assets/img/senate_winners/Colima/COLIMA_FIRST.png';
import COLIMA_THIRD from '../assets/img/senate_winners/Colima/COLIMA_THIRD.png';
import HIDALGO_FIRST from '../assets/img/senate_winners/Hidalgo/HIDALGO_FIRST.png';
import HIDALGO_SECOND from '../assets/img/senate_winners/Hidalgo/HIDALGO_SECOND.png';
import HIDALGO_THIRD from '../assets/img/senate_winners/Hidalgo/HIDALGO_THIRD.png';
import BAJA_CALIFORNIA_FIRST from '../assets/img/senate_winners/Baja California/BAJA_CALIFORNIA_FIRST.png';
import BAJA_CALIFORNIA_THIRD from '../assets/img/senate_winners/Baja California/BAJA_CALIFORNIA_THIRD.png';
import BAJA_CALIFORNIA_SECOND from '../assets/img/senate_winners/Baja California/BAJA_CALIFORNIA_SECOND.png';
import COAHUILA_SECOND from '../assets/img/senate_winners/Coahuila/COAHUILA_SECOND.png';
import COAHUILA_FIRST from '../assets/img/senate_winners/Coahuila/COAHUILA_FIRST.png';
import COAHUILA_THIRD from '../assets/img/senate_winners/Coahuila/COAHUILA_THIRD.png';
import BAJA_CALIFORNIA_SUR_THIRD from '../assets/img/senate_winners/Baja California Sur/BAJA_CALIFORNIA_SUR_THIRD.png';
import BAJA_CALIFORNIA_SUR_SECOND from '../assets/img/senate_winners/Baja California Sur/BAJA_CALIFORNIA_SUR_SECOND.png';
import BAJA_CALIFORNIA_SUR_FIRST from '../assets/img/senate_winners/Baja California Sur/BAJA_CALIFORNIA_SUR_FIRST.png';
import GUERRERO_THIRD from '../assets/img/senate_winners/Guerrero/GUERRERO_THIRD.png';
import GUERRERO_FIRST from '../assets/img/senate_winners/Guerrero/GUERRERO_FIRST.png';
import GUERRERO_SECOND from '../assets/img/senate_winners/Guerrero/GUERRERO_SECOND.png';
import PUEBLA_FIRST from '../assets/img/senate_winners/Puebla/PUEBLA_FIRST.png';
import PUEBLA_SECOND from '../assets/img/senate_winners/Puebla/PUEBLA_SECOND.png';
import PUEBLA_THIRD from '../assets/img/senate_winners/Puebla/PUEBLA_THIRD.png';
import VERACRUZ_SECOND from '../assets/img/senate_winners/Veracruz/VERACRUZ_SECOND.png';
import VERACRUZ_THIRD from '../assets/img/senate_winners/Veracruz/VERACRUZ_THIRD.png';
import VERACRUZ_FIRST from '../assets/img/senate_winners/Veracruz/VERACRUZ_FIRST.png';
import MICHOACAN_SECOND from '../assets/img/senate_winners/Michoacan/MICHOACAN_SECOND.png';
import MICHOACAN_FIRST from '../assets/img/senate_winners/Michoacan/MICHOACAN_FIRST.png';
import MICHOACAN_THIRD from '../assets/img/senate_winners/Michoacan/MICHOACAN_THIRD.png';
import SONORA_FIRST from '../assets/img/senate_winners/Sonora/SONORA_FIRST.png';
import SONORA_SECOND from '../assets/img/senate_winners/Sonora/SONORA_SECOND.png';
import SONORA_THIRD from '../assets/img/senate_winners/Sonora/SONORA_THIRD.png';
import OAXACA_THIRD from '../assets/img/senate_winners/Oaxaca/OAXACA_THIRD.png';
import OAXACA_SECOND from '../assets/img/senate_winners/Oaxaca/OAXACA_SECOND.png';
import OAXACA_FIRST from '../assets/img/senate_winners/Oaxaca/OAXACA_FIRST.png';
import ZACATECAS_FIRST from '../assets/img/senate_winners/Zacatecas/ZACATECAS_FIRST.png';
import ZACATECAS_SECOND from '../assets/img/senate_winners/Zacatecas/ZACATECAS_SECOND.png';
import ZACATECAS_THIRD from '../assets/img/senate_winners/Zacatecas/ZACATECAS_THIRD.png';
import GUANAJUATO_SECOND from '../assets/img/senate_winners/Guanajuato/GUANAJUATO_SECOND.png';
import GUANAJUATO_FIRST from '../assets/img/senate_winners/Guanajuato/GUANAJUATO_FIRST.png';
import GUANAJUATO_THIRD from '../assets/img/senate_winners/Guanajuato/GUANAJUATO_THIRD.png';
import Default from '..//assets/img/candidates/default.png';

const SenateImgs2024 = {
    CIUDAD_DE_MEXICO_THIRD,
    CIUDAD_DE_MEXICO_SECOND,
    CIUDAD_DE_MEXICO_FIRST,
    TLAXCALA_SECOND,
    TLAXCALA_THIRD,
    TLAXCALA_FIRST,
    DURANGO_FIRST,
    DURANGO_THIRD,
    DURANGO_SECOND,
    SINALOA_THIRD,
    SINALOA_SECOND,
    SINALOA_FIRST,
    CAMPECHE_THIRD,
    CAMPECHE_FIRST,
    CAMPECHE_SECOND,
    NUEVO_LEON_SECOND,
    NUEVO_LEON_THIRD,
    NUEVO_LEON_FIRST,
    MEXICO_SECOND,
    MEXICO_THIRD,
    MEXICO_FIRST,
    CHIAPAS_SECOND,
    CHIAPAS_FIRST,
    CHIAPAS_THIRD,
    TABASCO_THIRD,
    TABASCO_SECOND,
    TABASCO_FIRST,
    QUERETARO_THIRD,
    QUERETARO_SECOND,
    QUERETARO_FIRST,
    QUINTANA_ROO_THIRD,
    QUINTANA_ROO_FIRST,
    QUINTANA_ROO_SECOND,
    YUCATAN_FIRST,
    YUCATAN_THIRD,
    YUCATAN_SECOND,
    TAMAULIPAS_FIRST,
    TAMAULIPAS_THIRD,
    TAMAULIPAS_SECOND,
    MORELOS_SECOND,
    MORELOS_THIRD,
    MORELOS_FIRST,
    JALISCO_SECOND,
    JALISCO_THIRD,
    JALISCO_FIRST,
    NAYARIT_THIRD,
    NAYARIT_SECOND,
    NAYARIT_FIRST,
    CHIHUAHUA_FIRST,
    CHIHUAHUA_SECOND,
    CHIHUAHUA_THIRD,
    SAN_LUIS_POTOSI_FIRST,
    SAN_LUIS_POTOSI_THIRD,
    SAN_LUIS_POTOSI_SECOND,
    AGUASCALIENTES_THIRD,
    AGUASCALIENTES_SECOND,
    AGUASCALIENTES_FIRST,
    COLIMA_SECOND,
    COLIMA_FIRST,
    COLIMA_THIRD,
    HIDALGO_FIRST,
    HIDALGO_SECOND,
    HIDALGO_THIRD,
    BAJA_CALIFORNIA_FIRST,
    BAJA_CALIFORNIA_THIRD,
    BAJA_CALIFORNIA_SECOND,
    COAHUILA_SECOND,
    COAHUILA_FIRST,
    COAHUILA_THIRD,
    BAJA_CALIFORNIA_SUR_THIRD,
    BAJA_CALIFORNIA_SUR_SECOND,
    BAJA_CALIFORNIA_SUR_FIRST,
    GUERRERO_THIRD,
    GUERRERO_FIRST,
    GUERRERO_SECOND,
    PUEBLA_FIRST,
    PUEBLA_SECOND,
    PUEBLA_THIRD,
    VERACRUZ_SECOND,
    VERACRUZ_THIRD,
    VERACRUZ_FIRST,
    MICHOACAN_SECOND,
    MICHOACAN_FIRST,
    MICHOACAN_THIRD,
    SONORA_FIRST,
    SONORA_SECOND,
    SONORA_THIRD,
    OAXACA_THIRD,
    OAXACA_SECOND,
    OAXACA_FIRST,
    ZACATECAS_FIRST,
    ZACATECAS_SECOND,
    ZACATECAS_THIRD,
    GUANAJUATO_SECOND,
    GUANAJUATO_FIRST,
    GUANAJUATO_THIRD,
    Default
};

export default SenateImgs2024;