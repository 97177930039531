import pres2006 from '../assets/data/pres2006.csv';
import pres2012 from '../assets/data/pres2012.csv';
import pres2018 from '../assets/data/pres2018.csv';
import pres2024 from '../assets/data/pres2024.csv';
import cong2006 from '../assets/data/cong2006.csv';
import cong2012 from '../assets/data/cong2012.csv';
import cong2018 from '../assets/data/cong2018.csv';
import cong2009 from '../assets/data/cong2009.csv';
import cong2015 from '../assets/data/cong2015.csv';
import cong2021 from '../assets/data/cong2021.csv';
import congN2009 from '../assets/data/congN2009.csv';
import congN2015 from '../assets/data/congN2015.csv';
import congN2021 from '../assets/data/congN2021.csv';
import sen from '../assets/data/sen.csv';
import senRC from '../assets/data/senRC.csv';
import gov from '../assets/data/gov.csv';
import govUncontested2024 from '../assets/data/govUncontested2024.csv';
import govJalisco from '../assets/data/govJalisco.csv';
import govJaliscoRC2018 from '../assets/data/govJaliscoRC2018.csv';
import govJaliscoRC2021 from '../assets/data/govJaliscoRC2021.csv';
import govCdmx2018 from '../assets/data/govCdmx2018.csv';
import govCdmx2024 from '../assets/data/govCdmx2024.csv';
import govCdmxRC2018 from '../assets/data/govCdmxRC2018.csv';
import govCdmxRC2021 from '../assets/data/govCdmxRC2021.csv';

const RaceData = {
    pres2006,
    pres2012,
    pres2018,
    pres2024,
    cong2006,
    cong2012,
    cong2018,
    cong2009,
    cong2015,
    cong2021,
    congN2009,
    congN2015,
    congN2021,
    sen,
    senRC,
    gov,
    govUncontested2024,
    govJalisco,
    govJaliscoRC2021,
    govJaliscoRC2018,
    govCdmx2018,
    govCdmx2024,
    govCdmxRC2021,
    govCdmxRC2018
}

export default RaceData;