export const Titles = {  
  AMLO2018: "Juntos Haremos Historia",
  ANAYA2018: "Por México al Frente",
  MEADE2018: "Todos por México",
  "Andrés Manuel López Obrador2012": "Movimiento Progresista",
  "Enrique Peña Nieto2012": "Compromiso por México",
  "Andrés Manuel López Obrador2006": "Por el Bien de Todos",
  "Roberto Madrazo Pintado2006": "Alianza por México",
  "MORENA-PT-PES2018": "Juntos Haremos Historia",
  "PRI-PVEM-PANAL2018": "Todos por México",
  "PAN-PRD-MC2018": "Por México al Frente",
  "PRD-PT-MC2012": "Movimiento Progresista",
  "PRI-PVEM2012": "Compromiso por México",
  "PRD-PT-CONVERGENCIA2006": "Por el Bien de Todos",
  "PRI-PVEM2006": "Alianza por México",
};

export const Names = {
  CLAUDIA: "Claudia Sheinbaum Pardo",
  XOCHITL: "Xóchitl Gálvez Ruiz",
  MAYNEZ: "Jorge Álvarez Maynez",
  CLAUDIA_Sh: "Claudia Sheinbaum Pardo",
  XOCHITL_Sh: "Xóchitl Gálvez Ruiz",
  MAYNEZ_Sh: "Jorge Álvarez Maynez",
  AMLO: "Andrés Manuel López Obrador",
  ANAYA: "Ricardo Anaya Cortés",
  MEADE: "José Antonio Meade Kuribreña",
  BRONCO: 'Jaime Rodríguez Calderón "Bronco"',
  "MORENA-PT-PVEM2024": "Claudia Sheinbaum Pardo",
  "PAN-PRI-PRD2024": "Xóchitl Gálvez Ruiz",
  "MC2024": "Jorge Álvarez Maynez",
  "MORENA-PT-PES2018": "Andrés Manuel López Obrador",
  "PRI-PVEM-PANAL2018": "José Antonio Meade Kuribreña",
  "PAN-PRD-MC2018": "Ricardo Anaya Cortés",
  "Independiente2018": 'Jaime Rodríguez Calderón "Bronco"',
  "PRI-PVEM2012": "Enrique Peña Nieto",
  "PRD-PT-MC2012": "Andrés Manuel López Obrador",
  "PAN2012": "Josefina Vázquez Mota",
  "PANAL2012": "Gabriel Quadri",
  "PRD-PT-CONVERGENCIA2006": "Andrés Manuel López Obrador",
  "PAN2006": "Felipe Calderón Hinojosa",
  "PRI-PVEM2006": "Roberto Madrazo Pintado",
  "ASDC2006": "Patricia Mercado Castro",
};
