import React, { useEffect } from "react";
import { Dialog, Box, Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

import { useSelector } from "react-redux";
import { Titles /*, Names*/ } from "../../../mapbox/titles";
import { PartyColors } from "../../../mapbox/colors";
import CandidateImgs from "../../../mapbox/candidates";
import CongressWinners from "../../../mapbox/congressWinners";
import DoubleCheck from "../../../assets/img/doublecheck.png";

//import variables from "../../../_variables.scss";
import { FormattedMessage } from "react-intl";
import removeAccents from "remove-accents";

const RaceboardCongNational = ({ open, onClose, app }) => {
  const year = useSelector((state) => state.year.value);

  let checkSize = 17;
  let checkStroke = 3;
  let partyBorderBottom = 10;
  if (window.innerWidth > 3000) {
    //checkSize = 60;
    //checkStroke = 3;
    partyBorderBottom = 20;
  }

  let showDistrictWinner = "none";
  if (app.raceboard.layer === "districts") {
    showDistrictWinner = "none";
  }

  const party1_imgs = app.raceboard.p1PartyN.split("-");
  const party2_imgs = app.raceboard.p2PartyN.split("-");
  const party3_imgs = app.raceboard.p3PartyN.split("-");
  const party4_imgs = app.raceboard.p4PartyN.split("-");

  const p1Party = app.raceboard.p1PartyN;

  const runAfterRender = () => {
    if (document.getElementById("raceboardCheckBoxCongN")) {
      document.getElementById("raceboardCheckBoxCongN").style.height =
        document.getElementById("raceboardRow1CongN").offsetHeight + "px";
    }
  };

  useEffect(() => {
    runAfterRender();
  }, [app.raceboard.state, app.raceboard.mun]);

  return (
    <Dialog
      id={"raceboardN"}
      className={"raceboard"}
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={0}
      sx={{
        width: "450px",
        borderLeft: "solid 0px " + PartyColors[p1Party]?.high,
        padding: "0px !important",
        fontSize: "16px",
        color: "#111111",
        top: 89
      }}
      onLoad={runAfterRender}
    >
      <Box
        className={"raceboardTitleBox"}
        sx={{
          marginLeft: "5%",
          fontWeight: "bold",
          //borderBottom: "solid 3px #111",
          paddingBottom: "4px",
          marginBottom: "10px"
        }}
      >
        <Box
          className={"raceboardTitle"}
          sx={{
            fontSize: "39px",
            lineHeight: "39px",
            paddingBottom: "3px",
            paddingTop: "9px",
            textAlign: "center"
          }}
        >
          <FormattedMessage id={"NationalResults"} />
        </Box>
        <Box
          className={"raceboardSubtitle"}
          sx={{
            fontSize: "25px",
            lineHeight: "25px",
            paddingBottom: "3px",
            paddingTop: "0px",
            fontWeight: "normal",
            textAlign: "center"
          }}
        >&nbsp;</Box>
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}
      >
        <Box
          className={"partyBox"}
          sx={{
            display: "flex",
            flex: 1,
            paddingTop: "0.6rem",
            marginBottom: "0.5rem"
          }}
        >
          <Box
            id={"raceboardCheckBoxCongN"}
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "0px 0px 0px 0px",
              width: "3.5%",
              marginRight: "1%",
              backgroundColor: !app.raceboard.tie
                ? PartyColors[p1Party]?.high
                : "transparent",
              color: "#fff",
              fontWeight: "bold",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <IconCheck
              className={"raceCheckCong"}
              strokeWidth={checkStroke}
              size={checkSize}
              style={{
                marginLeft: "0rem",
                display: !app.raceboard.tie ? "block" : "none"
              }}
            />
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", width: "95.5%" }}
          >
            <Box
              id={"raceboardRow1CongN"}
              className={"partySubBox"}
              sx={{
                display: "flex",
                borderBottom:
                  "solid " +
                  partyBorderBottom +
                  "px " +
                  PartyColors[p1Party]?.high,
                marginRight: "4%",
                paddingBottom: "0rem",
                marginBottom: "0.5rem"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "bottom",
                  width: "74%"
                }}
              >
                <Box sx={{ display: "flex", width: "100%" }}>
                  <Box
                    className={"raceboardWinnerImgBox"}
                    sx={{
                      width: "27%",
                      marginRight: "3%",
                      maxHeight: "5.01rem",
                      overflow: "hidden",
                      display: CongressWinners[
                        removeAccents(
                          app.raceboard.p1Name.replaceAll(/\s/g, "")
                        ).toUpperCase() + "2021"
                      ]
                        ? showDistrictWinner
                        : "none"
                    }}
                  >
                    <img
                      id={"raceboardImg1Cong"}
                      src={
                        CongressWinners[
                          removeAccents(
                            app.raceboard.p1Name.replaceAll(/\s/g, "")
                          ).toUpperCase() + "2021"
                        ]
                          ? CongressWinners[
                              removeAccents(
                                app.raceboard.p1Name.replaceAll(/\s/g, "")
                              ).toUpperCase() + "2021"
                            ]
                          : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAQAAADYv8WvAAAADklEQVR42mNkAAJGEAEAABkAA50Y5HIAAAAASUVORK5CYII="
                      }
                      style={{ width: "100%", minWidth: "4.7rem" }}
                      alt=""
                    />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ width: "95%" }}>
                      <div
                        className={"racePartyName"}
                        style={{
                          color:
                            PartyColors[p1Party]?.boardText ||
                            PartyColors[p1Party]?.high,
                          display: "inline-block",
                          width: "auto",
                          padding: "2px 7px 6px 0px",
                          marginLeft: "0px",
                          whiteSpace: "nowrap",
                          fontSize: "20px",
                          lineHeight: "25px"
                        }}
                      >
                        {app.raceboard.p1PartyN}
                      </div>
                    </Box>
                    <Box>
                      {party1_imgs.map((img, index) => {
                        if (img === "PES" && year === "2015") img = "PES2015";
                        return (
                          <img
                            key={index}
                            className={"raceboardImgCong"}
                            src={
                              CandidateImgs[img]
                                ? CandidateImgs[img]
                                : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAQAAADYv8WvAAAADklEQVR42mNkAAJGEAEAABkAA50Y5HIAAAAASUVORK5CYII="
                            }
                            style={{
                              width: "3rem",
                              height: "3rem",
                              marginRight: "0.5rem"
                            }}
                            alt=""
                          />
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
                <Box
                  className={"raceboardWinnerCong"}
                  sx={{
                    fontFamily: "FranklinGothicMedium",
                    fontSize: "1rem",
                    marginTop: "0.4rem",
                    marginBottom: "0.3rem",
                    width: "100%",
                    display: showDistrictWinner,
                    color: "#555"
                  }}
                >
                  {app.raceboard.p1Name}
                  {app.raceboard.reelected === 1 && (
                    <img
                      src={DoubleCheck}
                      alt=""
                      className={"raceElectCheckCong"}
                      style={{
                        width: "1.25rem",
                        position: "absolute",
                        marginLeft: "7px"
                      }}
                    />
                  )}
                </Box>
                <Box
                  className={"raceTitle"}
                  sx={{
                    marginTop: "0.2rem",
                    marginBottom: "10px",
                    fontSize: "12px",
                    color: "#777",
                    whiteSpace: "nowrap",
                    display: "none"
                  }}
                >
                  {Titles[app.raceboard.p1Name]
                    ? Titles[app.raceboard.p1Name]
                    : "Candidatura " + app.raceboard.p1PartyN}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "right",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  paddingLeft: "3%",
                  justifyContent: "flex-end"
                }}
              >
                <Text
                  className={"percentNumCong"}
                  sx={{
                    fontSize: "4.5rem",
                    // marginTop: "0 !important",
                    // marginBottom: "-1rem"
                    marginTop: "-1rem",
                    marginBottom: "-1.2rem"
                  }}
                >
                  {(app.raceboard.p1PercentN &&
                    app.raceboard.p1PercentN < 10) ||
                  app.raceboard.p1PercentN === 0
                    ? "\u00A0\u00A0"
                    : ""}
                  {app.raceboard.p1PercentN || app.raceboard.p1PercentN === 0
                    ? app.raceboard.p1PercentN
                    : ""}
                  <span className={"percent"}>
                    {app.raceboard.p1PercentN || app.raceboard.p1PercentN === 0
                      ? "%"
                      : ""}
                  </span>
                </Text>
                <Text
                  className={"voteNumCong"}
                  style={{
                    fontFamily: "FranklinGothicHeavy",
                    fontWeight: "normal"
                  }}
                >
                  {(app.raceboard.p1VoteN || app.raceboard.p1VoteN === 0) && (
                    <FormattedMessage
                      id={"Votes2"}
                      values={{
                        votes: app.raceboard.p1VoteN.toLocaleString("en")
                      }}
                    />
                  )}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          className={"partyBox"}
          sx={{
            display: app.raceboard.p1VoteN === 0 ? "none" : "flex",
            flex: 1,
            marginTop: "2px",
            backgroundColor: "rgba(255,255,255,0.8)",
            paddingTop: "0.6rem",
            marginBottom: "0.5rem"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "4.5%",
              marginRight: "0%"
            }}
          ></Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", width: "95.5%" }}
          >
            <Box
              className={"partySubBox"}
              sx={{
                display: "flex",
                borderBottom:
                  "solid " +
                  partyBorderBottom +
                  "px " +
                  PartyColors[app.raceboard.p2PartyN]?.high,
                marginRight: "4%",
                paddingBottom: "0rem",
                marginBottom: "0.5rem"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "top",
                  width: "74%"
                }}
              >
                <Box sx={{ width: "95%" }}>
                  <div
                    className={"racePartyName"}
                    style={{
                      color:
                        PartyColors[app.raceboard.p2PartyN]?.boardText ||
                        PartyColors[app.raceboard.p2PartyN]?.high,
                      display: "inline-block",
                      width: "auto",
                      padding: "2px 7px 6px 0px",
                      marginLeft: "0px",
                      whiteSpace: "nowrap",
                      fontSize: "21px",
                      lineHeight: "25px"
                    }}
                  >
                    {app.raceboard.p2PartyN}
                  </div>
                </Box>
                <Box>
                  {party2_imgs.map((img, index) => {
                    if (img === "PES" && year === "2015") img = "PES2015";
                    return (
                      <img
                        key={index}
                        className={"raceboardImgCong"}
                        src={
                          CandidateImgs[img]
                            ? CandidateImgs[img]
                            : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAQAAADYv8WvAAAADklEQVR42mNkAAJGEAEAABkAA50Y5HIAAAAASUVORK5CYII="
                        }
                        style={{
                          width: "3rem",
                          height: "3rem",
                          marginRight: "0.5rem"
                        }}
                        alt=""
                      />
                    );
                  })}
                </Box>
                <Box
                  className={"raceTitle"}
                  sx={{
                    marginTop: "0.2rem",
                    marginBottom: "10px",
                    fontSize: "12px",
                    color: "#777",
                    whiteSpace: "nowrap",
                    display: "none"
                  }}
                >
                  {Titles[app.raceboard.p2Name]
                    ? Titles[app.raceboard.p2Name]
                    : "Candidatura " + app.raceboard.p2PartyN}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "right",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  paddingLeft: "3%"
                }}
              >
                <Text
                  className={"percentNumCong"}
                  sx={{
                    fontSize: "4.5rem",
                    marginTop: "-1rem",
                    marginBottom: "-1.2rem"
                  }}
                >
                  {(app.raceboard.p2PercentN &&
                    app.raceboard.p2PercentN < 10) ||
                  app.raceboard.p2PercentN === 0
                    ? "\u00A0\u00A0"
                    : ""}
                  {app.raceboard.p2PercentN || app.raceboard.p2PercentN === 0
                    ? app.raceboard.p2PercentN
                    : ""}
                  <span className={"percent"}>
                    {app.raceboard.p2PercentN || app.raceboard.p2PercentN === 0
                      ? "%"
                      : ""}
                  </span>
                </Text>
                <Text
                  className={"voteNumCong"}
                  style={{ color: "#555", fontWeight: "normal" }}
                >
                  {(app.raceboard.p2VoteN || app.raceboard.p2VoteN === 0) && (
                    <FormattedMessage
                      id={"Votes2"}
                      values={{
                        votes: app.raceboard.p2VoteN.toLocaleString("en")
                      }}
                    />
                  )}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          className={"partyBox"}
          sx={{
            display: app.raceboard.p1VoteN === 0 ? "none" : "flex",
            flex: 1,
            marginTop: "2px",
            backgroundColor: "rgba(255,255,255,0.8)",
            paddingTop: "0.6rem",
            marginBottom: "0.5rem"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "4.5%",
              marginRight: "0%"
            }}
          ></Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", width: "95.5%" }}
          >
            <Box
              className={"partySubBox"}
              sx={{
                display: "flex",
                borderBottom:
                  "solid " +
                  partyBorderBottom +
                  "px " +
                  PartyColors[app.raceboard.p3PartyN]?.high,
                marginRight: "4%",
                paddingBottom: "0rem",
                marginBottom: "0.5rem"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "top",
                  width: "74%"
                }}
              >
                <Box sx={{ width: "95%" }}>
                  <div
                    className={"racePartyName"}
                    style={{
                      color:
                        PartyColors[app.raceboard.p3PartyN]?.boardText ||
                        PartyColors[app.raceboard.p3PartyN]?.high,
                      display: "inline-block",
                      width: "auto",
                      padding: "2px 7px 6px 0px",
                      marginLeft: "0px",
                      whiteSpace: "nowrap",
                      fontSize: "21px",
                      lineHeight: "25px"
                    }}
                  >
                    {app.raceboard.p3PartyN}
                  </div>
                </Box>
                <Box>
                  {party3_imgs.map((img, index) => {
                    if (img === "PES" && year === "2015") img = "PES2015";
                    return (
                      <img
                        key={index}
                        className={"raceboardImgCong"}
                        src={
                          CandidateImgs[img]
                            ? CandidateImgs[img]
                            : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAQAAADYv8WvAAAADklEQVR42mNkAAJGEAEAABkAA50Y5HIAAAAASUVORK5CYII="
                        }
                        style={{
                          width: "3rem",
                          height: "3rem",
                          marginRight: "0.5rem"
                        }}
                        alt=""
                      />
                    );
                  })}
                </Box>
                <Box
                  className={"raceTitle"}
                  sx={{
                    marginTop: "0.2rem",
                    marginBottom: "10px",
                    fontSize: "12px",
                    color: "#777",
                    whiteSpace: "nowrap",
                    display: "none"
                  }}
                >
                  {Titles[app.raceboard.p3Name]
                    ? Titles[app.raceboard.p3Name]
                    : "Candidatura " + app.raceboard.p3PartyN}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "right",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  paddingLeft: "3%"
                }}
              >
                <Text
                  className={"percentNumCong"}
                  sx={{
                    fontSize: "4.5rem",
                    marginTop: "-1rem",
                    marginBottom: "-1.2rem"
                  }}
                >
                  {(app.raceboard.p3PercentN &&
                    app.raceboard.p3PercentN < 10) ||
                  app.raceboard.p3PercentN === 0
                    ? "\u00A0\u00A0"
                    : ""}
                  {app.raceboard.p3PercentN || app.raceboard.p3PercentN === 0
                    ? app.raceboard.p3PercentN
                    : ""}
                  <span className={"percent"}>
                    {app.raceboard.p3PercentN || app.raceboard.p3PercentN === 0
                      ? "%"
                      : ""}
                  </span>
                </Text>
                <Text
                  className={"voteNumCong"}
                  style={{ color: "#555", fontWeight: "normal" }}
                >
                  {(app.raceboard.p3VoteN || app.raceboard.p3VoteN === 0) && (
                    <FormattedMessage
                      id={"Votes2"}
                      values={{
                        votes: app.raceboard.p3VoteN.toLocaleString("en")
                      }}
                    />
                  )}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          className={"partyBox"}
          sx={{
            display: app.raceboard.p4PercentN === 0 ? "none" : "flex",
            flex: 1,
            marginTop: "2px",
            backgroundColor: "rgba(255,255,255,0.8)",
            paddingTop: "0.6rem",
            marginBottom: "0.5rem"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "4.5%",
              marginRight: "0%"
            }}
          ></Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", width: "95.5%" }}
          >
            <Box
              className={"partySubBox party4SubBox"}
              sx={{
                display: "flex",
                borderBottom:
                  "solid " +
                  partyBorderBottom +
                  "px " +
                  PartyColors[app.raceboard.p4PartyN]?.high,
                marginRight: "4%",
                paddingBottom: "0rem",
                marginBottom: "0.5rem"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "top",
                  width: "74%"
                }}
              >
                <Box sx={{ width: "95%" }}>
                  <div
                    className={"racePartyName"}
                    style={{
                      color:
                        PartyColors[app.raceboard.p4PartyN]?.boardText ||
                        PartyColors[app.raceboard.p4PartyN]?.high,
                      display: "inline-block",
                      width: "auto",
                      padding: "2px 7px 6px 0px",
                      marginLeft: "0px",
                      whiteSpace: "nowrap",
                      fontSize: "21px",
                      lineHeight: "25px"
                    }}
                  >
                    {app.raceboard.p4PartyN}
                  </div>
                </Box>
                <Box>
                  {party4_imgs.map((img, index) => {
                    if (img === "PES" && year === "2015") img = "PES2015";
                    return (
                      <img
                        key={index}
                        className={"raceboardImgCong"}
                        src={
                          CandidateImgs[img]
                            ? CandidateImgs[img]
                            : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAQAAADYv8WvAAAADklEQVR42mNkAAJGEAEAABkAA50Y5HIAAAAASUVORK5CYII="
                        }
                        style={{
                          width: "3rem",
                          height: "3rem",
                          marginRight: "0.5rem"
                        }}
                        alt=""
                      />
                    );
                  })}
                </Box>
                <Box
                  className={"raceTitle"}
                  sx={{
                    marginTop: "0.2rem",
                    marginBottom: "10px",
                    fontSize: "12px",
                    color: "#777",
                    whiteSpace: "nowrap",
                    display: "none"
                  }}
                >
                  {Titles[app.raceboard.p4Name]
                    ? Titles[app.raceboard.p4Name]
                    : "Candidatura " + app.raceboard.p4PartyN}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "right",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  paddingLeft: "3%"
                }}
              >
                <Text
                  className={"percentNumCong"}
                  sx={{
                    fontSize: "4.5rem",
                    marginTop: "-1rem",
                    marginBottom: "-1.2rem"
                  }}
                >
                  {(app.raceboard.p4PercentN &&
                    app.raceboard.p4PercentN < 10) ||
                  app.raceboard.p4PercentN === 0
                    ? "\u00A0\u00A0"
                    : ""}
                  {app.raceboard.p4PercentN || app.raceboard.p4PercentN === 0
                    ? app.raceboard.p4PercentN
                    : ""}
                  <span className={"percent"}>
                    {app.raceboard.p4PercentN || app.raceboard.p4PercentN === 0
                      ? "%"
                      : ""}
                  </span>
                </Text>
                <Text
                  className={"voteNumCong"}
                  style={{ color: "#555", fontWeight: "normal" }}
                >
                  {(app.raceboard.p4VoteN || app.raceboard.p4VoteN === 0) && (
                    <FormattedMessage
                      id={"Votes2"}
                      values={{
                        votes: app.raceboard.p4VoteN.toLocaleString("en")
                      }}
                    />
                  )}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RaceboardCongNational;
